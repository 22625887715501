import { defineStore } from 'pinia';

const useModuleChapterStore = defineStore('moduleChapterStore', {
  state: () => ({
    modules: {},
  }),
  getters: {
    getChaptersByModule: state => moduleId => state.modules[moduleId]?.chapters || [],
    areChaptersLoaded: state => moduleId => !!state.modules[moduleId]?.chapters,
  },
  actions: {
    fetchChapters(moduleId, isBlueprint = false, isNewlyAdded = false) {
      if (!this.areChaptersLoaded(moduleId)) {
        const endpoint = isBlueprint || isNewlyAdded
          ? `/api/curriculum/blueprints/modules/${moduleId}/chapters`
          : `/api/curriculum/admin/modules/${moduleId}/chapters`;

        return this.$http.get(endpoint).then(response => {
          this.modules[moduleId] = {
            ...this.modules[moduleId],
            chapters: response.data.chapters,
          };
        }).catch(error => {
          this.$logger.warn('Error getting chapters', { id: moduleId }, error);
        });
      }
      return Promise.resolve(); // Return a resolved promise when chapters are already loaded
    },
    clearChapters() {
      this.modules = {};
    },
  },
});

export default useModuleChapterStore;
