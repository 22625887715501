<template>
  <div v-if="questionnaire">
    <form @submit.prevent="submitQuestionnaire">
      <k-questionnaire-prompt ref="prompt"
        v-for="prompt in questionnaire.prompts"
        :key="prompt.id"
        :prompt="prompt"
        :previous-response="getPreviousResponse(prompt.id)"
        :read-only="readOnly"
        @update="updateResponse(prompt.id, $event)"
        :disable-text-toolbar="disableTextToolbar"
      ></k-questionnaire-prompt>
      <div v-if="!readOnly" class="submit-form-button">
        <button @click.prevent="submitQuestionnaire" class="btn btn-primary submit-button">Submit Responses</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
.submit-form-button {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 15px 0;
}

.submit-form-button button {
  margin: 0 auto;
  display: block;
  padding: 10px 25px;
}
</style>

<script>
import KQuestionnairePrompt from './k-questionnaire-prompt.vue';
import ErrorMixin from '../mixins/error-mixins';
import TimeMixin from '../mixins/time-mixins';
import copyObject from '../modules/copy-object';

export default {
  components: {
    KQuestionnairePrompt,
  },

  mixins: [ErrorMixin, TimeMixin],

  props: {
    questionnaire: {
      type: Object,
    },
    latestSubmission: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disableTextToolbar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentResponses: {},
    };
  },

  computed: {
    previousResponses() {
      return this.latestSubmission?.responses;
    },
  },

  methods: {
    getPreviousResponse(promptId) {
      return this.previousResponses?.find(x => x.prompt_id === promptId)?.response;
    },
    updateResponse(promptId, response) {
      this.currentResponses[promptId] = response;
    },
    formatResponse(val, promptId) {
      if (val === undefined || val === null) {
        return val;
      }
      const promptType = this.questionnaire.prompts.find(x => x.id === promptId)?.response_type;
      if (promptType === 'QUANTITATIVE_PERCENT' || promptType === 'QUANTITATIVE_VALUE') {
        return parseInt(val, 10);
      }
      if (promptType === 'MULTIPLE_CHOICE') {
        // If response to multi choice is an empty array, turn it into a null
        // so it doesn't count towards completion percentage
        return val?.length > 0 ? val : null;
      }
      return val;
    },
    getSubmitPayload() {
      return {
        responses: Object.entries(copyObject(this.currentResponses)).map(x => ({
          prompt_id: parseInt(x[0], 10),
          response: this.formatResponse(x[1], parseInt(x[0], 10)),
        })).filter(x => x.response !== undefined && x.response !== null && x.response !== ''), // 0 is allowed
      };
    },
    submitQuestionnaire() {
      this.$emit('submit', this.getSubmitPayload());
    },
    clearResponses() {
      this.$refs.prompt.map(p => p.clearResponse());
    },
  },
};
</script>
