<template>
  <div class="policy content-with-spacing">
    <div v-html="renderedPolicy" v-if="renderedPolicy"></div>
  </div>
</template>

<style>
.policy li {
  color: var(--kate-type-accent);
}

.policies-panel .panel-body {
  padding: 15px 0;
}

.policies-panel ol,
.policies-panel ul {
  padding-left: 25px;
}

</style>

<script>
import { marked } from 'marked';
import ErrorMixin from '../mixins/error-mixins';

export default {
  mixins: [ErrorMixin],
  props: {
    policyType: {
      type: String,
      required: true,
    },
  },
  watch: {
    policyType() {
      this.getPolicy();
    },
    loading(val) {
      this.$emit('loading', val);
      if (val) {
        this.$Loading.start();
      } else {
        this.$Loading.finish();
      }
    },
  },
  data() {
    return {
      loading: false,
      renderedPolicy: null,
    };
  },
  beforeMount() {
    this.getPolicy();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getPolicy() {
      this.loading = true;
      this.$http.get(`/api/profile/policy/${this.policyType}`).then(res => {
        this.renderedPolicy = marked(this.$kpurify.sanitise(res.data));
        this.scrollToTop();
      }).catch(err => {
        this.showError(err, true);
      }).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>
