<template>
  <div>
    <div v-if="images.length > 1" class="gallery-container">
      <div class="active-image">
        <div @click.prevent="openModal()" class="image-container" role="button" aria-label="Open image preview" title="Open image preview">
          <img :src="images[activeImage]" alt="Gallery image">
          <i class="fas fa-external-link-alt"></i>
        </div>
        <button type="button" aria-label="Previous Image" title="Previous Image" class="previous" @click="previousImage()">
          <i class="fas fa-chevron-circle-left"></i>
        </button>
        <button type="button" aria-label="Next Image" title="Next Image" class="next k-text-primary" @click="nextImage()">
          <i class="fas fa-chevron-circle-right"></i>
        </button>
      </div>
      <div class="thumbnails-container">
        <div class="thumbnails" v-for="(image, index) in images" :key="index">
          <img :class="{'active': activeImage == index}"
          alt="Thumbnail"
          :src="image"
          @click="activeImage = index">
        </div>
      </div>
    </div>

    <div v-else class="row single-resource-container">
      <div @click.prevent="openModal()" class="image-container" role="button" aria-label="Open preview">
        <img :src="images" alt="Gallery image">
        <i class="fas fa-external-link-alt"></i>
      </div>
    </div>

    <k-modal @close="closeModal" :show="showModal" class="gallery-modal">
      <template #header>
        <h3>Preview</h3>
      </template>
      <template #body >
        <figure class="preview-image">
          <img class="active-image-preview" :src="images[activeImage]" alt="Preview">
        </figure>
      </template>
      <template #footer>
        <button class="btn btn-danger" @click.prevent="closeModal" aria-label="Close modal" title="Close modal">Close</button>
      </template>
    </k-modal>
  </div>
</template>

<style>
.gallery-modal .modal-container {
  max-height: 90vh;
  width: 90%;
}

.gallery-modal .modal-body {
  padding: 0 5px;
  margin-bottom: 0;
}
</style>

<style scoped>
.gallery-container .active-image {
  width: 100%;
  margin-bottom: 5px;
  text-align: center;
  position: relative;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container img,
.gallery-container .active-image img {
  max-width: 100%;
  max-height: 300px;
}

.gallery-container .active-image button {
  border: none;
  background-color: transparent;
  font-size: 25px;
  opacity: 0.3;
  position: absolute;
  outline: none;
  height: 100%;
  top: 0;
  transition: all 0.2s ease-in-out;
}

.gallery-container .active-image button:hover {
  opacity: 1;
}

.gallery-container .thumbnails-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 5px;
}

.gallery-container .thumbnails-container div {
  width: 100%;
  cursor: pointer;
  opacity: 1;
  overflow: hidden;
  height: 110px;
}

.gallery-container .thumbnails-container div:hover {
  opacity: 0.6;
}

.gallery-container .thumbnails-container div.active {
  opacity: 1;
}

.gallery-container .thumbnails img {
  height: 100%;
}

.image-container {
  position: relative;
  text-align: center;
}

.image-container > i {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image-container:hover i {
  opacity: 1;
}

/* Image Preview */
.preview-image {
  text-align: center;
}
</style>

<script>
import KModal from './k-modal.vue';

export default {
  components: {
    KModal,
  },

  props: {
    images: {
      type: Array,
    },
  },

  data() {
    return {
      activeImage: 0,
      showModal: false,
    };
  },

  mounted() {
    document.addEventListener('keydown', this.handleKeyPress);
  },

  methods: {
    nextImage() {
      this.activeImage = (this.activeImage + 1) % this.images.length;
    },

    previousImage() {
      this.activeImage = (((this.activeImage - 1) % this.images.length) + this.images.length) % this.images.length;
    },

    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

    handleKeyPress(event) {
      if (this.showModal && event.keyCode === 37) {
        this.previousImage();
      }
      if (this.showModal && event.keyCode === 39) {
        this.nextImage();
      }
    },
  },
};
</script>
