export default {
  computed: {
    mentorField() {
      return this.mentorType.toLowerCase().replace(' ', '_');
    },
    mentorIdField() {
      return `${this.mentorField}_id`;
    },
    mentorUserIdField() {
      return `${this.mentorField}_user_id`;
    },
  },
};
