export default {
  data() {
    return {
      initialLoadFinished: false,
    };
  },

  watch: {
    ready() {
      if (this.ready) {
        this.$Loading.finish();
        if (!this.initialLoadFinished) {
          this.$logger.pageReady();
          // Can specify a custom function on the component to call when page is ready
          if (this.pageReadyCallback) {
            this.pageReadyCallback();
          }
          this.initialLoadFinished = true;
        }
      } else {
        this.$Loading.start();
      }
    },
  },
};
