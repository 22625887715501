<template>
  <div class="k-text-search">
    <div class="search-input">
      <i class="fa-duotone fa-magnifying-glass input-icon "></i>
      <i class="fas fa-times reset-icon" @click="resetSearch" title="Reset search text"/>
      <input class="form-control"
        :placeholder="inputPlaceholder"
        v-model="searchText"
      >
    </div>
  </div>
</template>

<style scoped>
.search-input {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input .form-control {
  padding-left: 30px;
}
</style>

<script>
export default {
  props: {
    modelValue: {
      type: String,
    },
    inputPlaceholder: {
      type: String,
      required: false,
      default: 'Search...',
    },
  },
  data() {
    return {
      searchText: this.modelValue,
    };
  },
  watch: {
    modelValue() {
      this.searchText = this.modelValue;
    },
    searchText() {
      this.$emit('update:modelValue', this.searchText);
    },
  },
  methods: {
    resetSearch() {
      this.searchText = '';
      this.$emit('reset');
    },
  },
};
</script>
