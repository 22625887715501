<template>
  <k-tooltip :text="badgeName">
    <div class="badge-icon-container">
      <img class='badge-icon' :src="achievementImageSrc" alt="Badge icon"/>
      <div class="remove-badge" v-if="removable" @click="$emit('remove')"><i class="fas fa-times-circle"></i></div>
      <div class="threshold-score">{{ thresholdScore }}%</div>
    </div>
  </k-tooltip>
</template>

<style scoped>
.badge-icon-container {
  height: 50px;
}

.badge-icon-container img {
  height: 100%;
}

.remove-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50%;
}

.remove-badge i {
  color: var(--kate-danger);
}

.threshold-score {
  margin: auto;
  text-align: center;
}
</style>

<script>
import KTooltip from '../../components/k-tooltip.vue';
import { GOOGLE_CLOUD_STORAGE } from '../../constants';

export default {
  components: {
    'k-tooltip': KTooltip,
  },

  props: {
    removable: {
      type: Boolean,
      default: false,
    },
    iconImage: {
      type: String,
      default: '',
    },
    badgeName: {
      type: String,
      default: '',
    },
    thresholdScore: {
      type: Number,
      default: null,
    },
  },

  computed: {
    achievementImageSrc() {
      return `${GOOGLE_CLOUD_STORAGE}${this.iconImage}`;
    },
  },
};
</script>
