import {
  add,
  parse,
  isMatch,
} from 'date-fns';
import {
  formatInTimeZone,
  zonedTimeToUtc,
} from 'date-fns-tz';

const BACKEND_FORMAT = 'yyyyMMdd_HHmmss';
const PRETTY_FORMAT = 'MMM d, yyyy h:mm aaa';

// in miliseconds
const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

const RTF = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

export default {
  methods: {
    addToDate(dt, delta) {
      return add(dt, delta);
    },
    formatDate(dt) {
      return formatInTimeZone(this.getDate(dt), 'UTC', BACKEND_FORMAT);
    },
    getRelativeTime(dt) {
      const now = new Date();
      // Negative nums give "ago"
      const elapsed = dt - now;

      const unitKeys = Object.keys(units);
      for (let i = 0; i < unitKeys.length; i++) {
        const u = unitKeys[i];
        if ((Math.abs(elapsed) > units[u]) || u === 'second') {
          return RTF.format(Math.round(elapsed / units[u]), u);
        }
      }
      return undefined;
    },
    getDate(dt, customMask) {
      if (customMask) {
        return parse(dt, customMask, new Date());
      }
      if (isMatch(dt, BACKEND_FORMAT)) {
        // Backend format is always UTC
        // Add Timezone info
        return parse(`${dt}Z`, `${BACKEND_FORMAT}X`, new Date());
      }
      return new Date(dt);
    },
    getDateInUtc(dt, timezone) {
      return zonedTimeToUtc(dt || new Date(), timezone);
    },

    dateIsPast(dt) {
      if (this.getDate(dt) <= Date.now()) {
        return true;
      }
      return false;
    },

    parseTimestamp(stamp, customMask, customFormat, timezone) {
      if (!stamp) {
        return '';
      }
      const dt = stamp instanceof Date ? stamp : this.getDate(stamp, customMask);
      const actualTimezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      return formatInTimeZone(dt, actualTimezone, customFormat || PRETTY_FORMAT);
    },
    parseBulkTimestamps(stamps, customMask) {
      const output = [];
      for (let i = 0; i < stamps.length; i++) {
        output.push(this.parseTimestamp(stamps[i], customMask));
      }
      return output;
    },
  },
};
