/* globals ENVIRONMENT */
/* eslint no-param-reassign: "off" */
import posthog from 'posthog-js';

// This is a write-only key so it's safe to expose it here
const POSTHOG_PROJECT_API_KEY = 'phc_4jh3foxiA4DlDLpfG7ffveeitUxQHhB13SYLTovWioS';

export default {
  install(app) {
    if (ENVIRONMENT === 'production' || ENVIRONMENT === 'dev') {
      app.config.globalProperties.$posthog = posthog.init(
        POSTHOG_PROJECT_API_KEY,
        {
          // persistence: 'memory', // This only keeps session in memory, to keep user anonymous
          api_host: 'https://eu.i.posthog.com',
        },
      );
    }
  },
};
