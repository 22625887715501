/* eslint no-param-reassign: "off" */
import { reactive } from 'vue';

class Krumbs {
  constructor() {
    this.crumbs = [];
    this.callbacks = [];
  }

  init(callback) {
    this.callbacks.push(callback);
  }

  runCallbacks() {
    for (let i = 0; i < this.callbacks.length; i++) {
      this.callbacks[i](this.crumbs);
    }
  }

  register(newCrumbs) {
    this.crumbs = newCrumbs;
    this.runCallbacks();
  }

  clear() {
    this.crumbs = [];
    this.runCallbacks();
  }
}

export default {
  install(app) {
    const krumbs = new Krumbs();
    app.config.globalProperties.$crumbs = reactive(krumbs);
  },
};
