/* eslint no-param-reassign: "off" */
import LoadingComponent from './k-loading.vue';
import useLoadingStore from '../../stores/loading';

export default {
  install(app) {
    const loadingStore = useLoadingStore();
    app.component('k-loading-component', LoadingComponent);

    app.config.globalProperties.$Loading = {
      start() {
        loadingStore.loadingStart();
      },
      minimal() {
        loadingStore.loadingMinimal();
      },
      finish() {
        loadingStore.loadingFinish();
      },
    };
  },
};
