<template>
  <div :ref="observedElement" class="intersection-observer"></div>
</template>

<script>
// Interesection Observer checks if element is in view port.
// Add componenent inside/next to div/element you want to check if it's in view or not.
// Then add logic to show/hide/add what you want,
// ie: avoid duplicating a button, have the button at the top of the page with the observer inside the tags.
// if button is no longer in view, then have another button (v-if="!isInViewElement") at the bottom of the page.

export default {
  props: {
    observedElement: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      observedElementInViewport: false,
      observer: undefined,
    };
  },

  watch: {
    observedElementInViewport(value) {
      this.$emit('on-intersection-element', value);
    },
  },

  mounted() {
    const sentinel = this.$refs[this.observedElement];
    this.observer = new window.IntersectionObserver(this.intersectionHandler);
    this.observer.observe(sentinel);
  },

  methods: {
    intersectionHandler(entries) {
      if (entries[0].isIntersecting) {
        this.observedElementInViewport = true;
      } else {
        this.observedElementInViewport = false;
      }
    },
  },
};
</script>
