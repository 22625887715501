export default {
  computed: {
    googleOAuthEndpoint() {
      return `/api/auth/oauth/google/sign_in?${this.googleOAuthParams.join('&')}`;
    },
    googleOAuthParams() {
      return [
        `state=${this.googleOAuthCurrentState}`,
      ];
    },
    googleOAuthCurrentState() {
      // Return back to the current page after complete
      return encodeURIComponent(JSON.stringify({ next: this.$route.fullPath }));
    },
  },
  methods: {
    goToGoogleSignIn() {
      window.location.href = this.googleOAuthEndpoint;
    },
  },
};
