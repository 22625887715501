import { defineStore } from 'pinia';

const useFeatureFlagStore = defineStore('featureFlag', {
  state: () => ({
    entityId: '',
    evaluationContext: {},
  }),

  actions: {
    loadUser(userId, email) {
      // entityId must be a string
      this.entityId = String(userId);
      // evaluationContext is an object all property values must be strings, even booleans
      this.evaluationContext.userId = String(userId);
      this.evaluationContext.email = String(email);
    },
  },
});

export default useFeatureFlagStore;
