<template>
  <div class="k-editable-text-field">
    <fieldset v-if="edit" class="detail-form form-entry">
      <k-dropdown
          v-model="selectedValue"
          :options="options"
          @update:modelValue="confirm"
      ></k-dropdown>
    </fieldset>
    <div class="no-edit" v-else>
      <div class="slot-content">
        <slot>
          <span>
            {{ modelValue }}
          </span>
        </slot>
      </div>
      <button @click="edit = true" class="btn btn-primary" title="Edit" aria-label="Edit">
        <i class="fas fa-edit"></i>
      </button>
    </div>
  </div>
</template>

<style scoped>
.k-editable-text-field {
  margin-bottom: 1em;
}

.field-label {
  display: block;
  font-style: italic;
  color: var(--kate-primary);
}

fieldset,
.no-edit {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
}

.no-edit .slot-content h1,
.no-edit .slot-content h2,
.no-edit .slot-content h3,
.no-edit .slot-content h4,
.no-edit .slot-content h5,
.no-edit .slot-content h6,
.no-edit .slot-content p {
  margin: 0;
}

.btn.btn-success {
  padding: 4px 8px;
}

.btn.btn-primary {
  padding: 3px 6px 4px 8px;
}
</style>

<script>

import KDropdown from './k-dropdown.vue';

export default {

  components: {
    KDropdown,
  },
  props: {
    modelValue: {
      type: [Number, String, Object, Boolean],
      default: undefined,
    },
    options: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      edit: false,
      selectedValue: '',
    };
  },

  mounted() {
    this.selectedValue = this.modelValue;
  },

  watch: {
    modelValue(val) {
      this.selectedValue = val;
    },
    edit(val) {
      this.$emit('editing', val);
    },
  },

  methods: {
    confirm() {
      this.edit = false;
      this.$emit('update:modelValue', this.selectedValue);
    },
  },
};
</script>
