<template>
  <div class="loading-container">
    <h1>Loading Environment...</h1>
    <div class="panel panel-default">
      <div class="panel-body progress-panel-body">
        <k-progress aria-label="Environment loading progress" :show-bar-text="false" :percentage="currentProgress"></k-progress>
        <div class="status-msg">{{msg}}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.status-msg {
  margin-top: 1rem;
}

.progress-panel-body {
  text-align: center;
  width: 50vw;
}
</style>

<script>
import KProgress from '../components/k-progress.vue';
import PageReadyMixin from '../mixins/page-ready-mixin';
import ErrorMixin from '../mixins/error-mixins';

const ASSET_URL_MAP = {
  pak: 'konman',
  lu: 'konman',
  module_pak: 'kloud',
  module_lu: 'kloud',
};

const STATUS_MESSAGES = [
  'Requesting learning environment',
  'Waiting for environment to be ready',
  'Finishing touches',
];

export default {
  mixins: [PageReadyMixin, ErrorMixin],
  components: {
    KProgress,
  },
  data() {
    return {
      kod: null,
      currentProgress: 0,
      interval: null,
      msg: STATUS_MESSAGES[0],
    };
  },

  beforeMount() {
    this.createKod();
  },
  onBeforeUnmount() {
    clearInterval(this.interval);
  },

  methods: {
    isKodReady(conditions) {
      return Boolean(conditions.find(x => x.type === 'Ready' && x.status === 'True'));
    },

    checkIfReady() {
      if (this.isKodReady(this.kod?.status?.conditions)) {
        this.currentProgress = 100;
        clearInterval(this.interval);
        this.interval = null;
        this.msg = STATUS_MESSAGES[2];
        this.$emit('kodReady');
      }
    },

    startIntervalLoop() {
      this.msg = STATUS_MESSAGES[1];
      this.currentProgress = 50;
      this.interval = setInterval(this.getKodStatus, 3000);
    },
    handleError(msg) {
      clearInterval(this.interval);
      this.msg = msg;
      this.currentProgress = 0;
    },

    getKodStatus() {
      const { assetType, assetId } = this.$route.params;
      this.$http.get(
        `/api/curriculum/kods/${assetType}/${assetId}`,
        { params: { version: this.$route.query?.version } },
      ).then(res => {
        this.kod = res.data;
        this.checkIfReady();
      }).catch(err => {
        this.$logger.autowarn('Error getting kod for user', undefined, err);
        this.handleError('Error creating learning environment. Please try again later.');
      });
    },

    createKod() {
      const { assetType, assetId } = this.$route.params;
      if (!assetType || !assetId || !(ASSET_URL_MAP[assetType])) {
        this.showError('Invalid learning environment');
        return;
      }
      this.creationInProgress = true;
      this.$http.post(
        `/api/curriculum/${ASSET_URL_MAP[assetType]}/${assetType}/${assetId}/spin`,
        null,
        { params: { version: this.$route.query?.version } },
      ).then(() => {
        this.startIntervalLoop();
      }).catch(err => {
        this.$logger.autowarn('Error creating kod', undefined, err);
        this.handleError('Error creating environment');
      }).then(() => {
        this.creationInProgress = false;
      });
    },
  },
};
</script>
