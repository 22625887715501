function flatten(array) {
  return array.reduce(
    (previous, current) => (Array.isArray(current)
      ? [...previous, ...flatten(current)]
      : [...previous, current]),
    [],
  );
}

export default flatten;
