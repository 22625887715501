/* eslint no-param-reassign: "off" */
/* eslint no-underscore-dangle: ["error", { "allow": ["_formatMessage"] }] */
const DEFAULT_GO_AWAY = 3000;

class KToast {
  constructor(toasted) {
    this.toasted = toasted;
    this.icons = {
      success: '<i class="fas fa-check-circle"></i>',
      warning: '<i class="fas fa-exclamation-triangle"></i>',
      error: '<i class="fas fa-exclamation-circle"></i>',
      info: '<i class="fas fa-info-circle"></i>',
      wait: '<i class="fas fa-spinner fa-spin"></i>',
      copy: '<i class="fas fa-clipboard"></i>',
    };
  }

  _formatMessage(message, type) {
    return `${this.icons[type] || ''}<span class="toast-text">${message}</span>`;
  }

  showToast(type, message, options = {}) {
    if (options.disableGoAway) {
      this.toasted.show(this._formatMessage(message, type), options);
      return;
    }
    const goAway = options.goAway || DEFAULT_GO_AWAY;
    this.toasted.show(this._formatMessage(message, type), options).goAway(goAway);
  }

  success(message, options = {}) {
    this.showToast('success', message, options);
  }

  warning(message, options = {}) {
    this.showToast('warning', message, options);
  }

  error(message, options = {}) {
    this.showToast('error', message, options);
  }

  info(message, options = {}) {
    this.showToast('info', message, options);
  }

  copy(message, options = {}) {
    this.showToast('copy', message, options);
  }

  showBadgesAchieved(badges) {
    if (!badges) {
      return;
    }
    const toastOptions = {
      position: 'top-center',
      className: 'k-badge-toast',
      containerClass: 'k-badge-toast-container',
      action: [
        {
          class: 'toast-button toast-cancel',
          icon: 'xmark',
          text: 'Close',
          onClick: (e, toastObject) => {
            toastObject.goAway(50);
          },
        },
      ],
    };
    for (let i = 0; i < badges.length; i++) {
      const msg = `<h4>Congratulations!</h4><br>
                    <span>You have achieved the <b class="badge-name">${badges[i].badge_name}</b> badge!</span>`;
      this.toasted.show(
        `<div class="k-badge-toast-content">
          <img src="${badges[i].badge_img_url}" alt="Badge"/>
          <div class="k-badge-toast-text">
            ${this._formatMessage(msg)}
          </div>
          <span class="profile-link">You can view and share your badge <a target="_blank" href="/profile/badges">here</a></span>
        </div>`,
        toastOptions,
      );
    }
  }

  confirmToast(message, messageType, confirmCallback, cancelCallback, overrideOptions = {}) {
    const options = {
      disableGoAway: true,
      position: 'top-right',
      action: [
        {
          class: 'btn toast-button toast-cancel',
          text: 'Cancel',
          onClick: (e, toastObject) => {
            toastObject.goAway(10);
            cancelCallback?.();
          },
        },
        {
          class: 'btn toast-button toast-confirm',
          text: 'Confirm',
          onClick: (e, toastObject) => {
            toastObject.goAway(10);
            confirmCallback?.();
          },
        },
      ],
      ...overrideOptions,
    };
    const fullMessage = options.action?.length > 1 ? `${message}<br><br>Do you want to continue?` : message;
    this.showToast(messageType, fullMessage, options);
  }
}

export default {
  install(app) {
    // VueToasted must be installed before k-toast
    const ktoast = new KToast(app.config.globalProperties.$toasted);
    app.config.globalProperties.$ktoast = ktoast;
  },
};
