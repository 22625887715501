import { ASSET_TYPES } from '../constants';

export default {
  computed: {
    assetIcons() {
      const icons = {};
      Object.keys(ASSET_TYPES).forEach(assetType => {
        icons[assetType] = ASSET_TYPES[assetType].icon;
      });
      return icons;
    },
    defaultChapterIcons() {
      const icons = {};
      Object.keys(ASSET_TYPES).forEach(assetType => {
        icons[ASSET_TYPES[assetType].defaultChapterTitle] = ASSET_TYPES[assetType].icon;
      });
      return icons;
    },
  },

  methods: {
    getAssetIcon(assetType) {
      return this.assetIcons[assetType] || 'fa-duotone fa-bullseye';
    },
    getDefaultChapterIcon(chapterTitle) {
      return this.defaultChapterIcons[chapterTitle];
    },
  },
};
