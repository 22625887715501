<template>
  <k-modal class="k-user-search-modal" @close="close" :show="show">
    <template #header>
      <h3>KATE Users</h3>
    </template>
    <template #body>
      <div class="k-user-search-body">
        <div class="k-user-search-search-bar">
          <label for="user-search-input">
            <input id="user-search-input" class="form-control" type="text" placeholder="Enter name here..." v-model="filterText" v-on:keyup.enter="userSearch">
          </label>
          <span class="loader-icon"><i class="fa fa-spinner fa-spin spin-icon" v-if="loading"></i></span>
          <button class="btn btn-primary" @click="userSearch" :disabled="loading" aria-label="Search" title="Search"><i class="fa fa-search"></i> Search</button>
        </div>
        <div class="list-container">
          <table v-if="searchFoundUsers" class="user-table">
            <thead>
              <tr class="user-headers">
                <th>User ID</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr class="user-row" v-for="u in searchedUsers" :key="u.user_id"
                  @click="$emit('select', u.user_id)">
                <td class="user-id">
                  <span>{{u.user_id}}</span>
                </td>
                <td class="user-name">
                  <router-link :to="{ name: 'dashboard_students', params: { studentId: u.user_id }}">
                    {{u.full_name}}
                    <i class="fa-duotone fa-arrow-up-right-from-square"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="k-user-search-count" v-if="showUserCount">
        <h5>{{searchCount}}</h5>
      </div>
    </template>
  </k-modal>
</template>

<style>
.k-user-search-modal .modal-body {
  margin-bottom: 0;
}

.k-user-search-modal .modal-container {
  min-width: 45%;
}
</style>

<style scoped>
/* Search */
.spin-icon {
  position: relative;
  right: 30px;
  color: var(--kate-type-primary);
}

.k-user-search-search-bar span {
  width: 0;
}

.k-user-search-search-bar {
  text-align: left;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
}

.k-user-search-search-bar label {
  width: 100%;
  margin: 0;
}

.k-user-search-search-bar input {
  margin: 7px auto;
  height: 38px;
  font-size: 1em;
  width: 100%;
  display: block;
  padding: 5px;
  border: var(--input-border);
  color: var(--kate-type-light);
  background-color: var(--input-background);
  outline: 0;
}

.k-user-search-search-bar button {
  height: 38px;
  margin-left: 10px;
  color: var(--kate-type-light);
  background-color: var(--kate-button-primary);
}

.k-user-search-count {
  text-align: center;
}

.k-user-search-count h5 {
  margin-top: 5px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

/* Programmes */
.list-container {
  max-height: 300px;
  overflow: auto;
  display: block;
}

.list-container table.user-table {
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

.user-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.user-headers th {
  text-transform: uppercase;
  background: var(--kate-background-body);
  padding: 10px 5px;
  min-width: 3em;
}

.user-row {
  cursor: pointer;
}

.user-row td {
  color: var(--kate-type-light);
  border-bottom: 1px solid var(--kate-background-body-alpha);
  padding: 6px 5px;
}

.user-row td:first-child {
  font-style: italic;
  font-weight: bold;
}

.user-row:hover {
  background-color: var(--kate-background-alt-alpha);
}

.user-row td span.kloud-enabled {
  color: var(--kate-primary);
  font-weight: bold;
}

.user-row .user-name {
  text-align: left;
}

.user-row .user-name i {
  margin-left: 5px;
  font-size: 0.8em;
}

</style>

<script>
import KModal from '../../components/k-modal.vue';

export default {
  components: {
    'k-modal': KModal,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    allowSelect: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filterText: '',
      searchedText: '',
      searchedUsers: [],
      showUserCount: false,
      loading: false,
      selectedUser: [],
    };
  },

  watch: {
    show() {
      this.clearFiltersAndResults();
      this.loading = false;
    },
    selectedUser() {
      this.$emit('input', this.selectedUser);
    },
  },

  computed: {
    searchCount() {
      if (this.searchedText === '') {
        return 'Please enter a search term';
      } if (this.searchedUsers.length === 1) {
        return 'Found 1 user';
      } if (this.searchedUsers.length >= 50) {
        return 'Found 50+ users';
      }
      return `Found ${this.searchedUsers.length} users`;
    },
    searchFoundUsers() {
      if (this.searchedUsers.length > 0) {
        return true;
      }
      return false;
    },
    filterTextParameters() {
      return this.filterText.split(' ').join(',').toLowerCase();
    },
    apiEndpoint() {
      return `/api/profile/users/search?terms=${this.filterTextParameters}`;
    },
  },

  methods: {
    clearFiltersAndResults() {
      this.filterText = '';
      this.searchedText = '';
      this.searchedUsers = [];
      this.showUserCount = false;
    },
    close() {
      this.clearFiltersAndResults();
      this.$emit('close');
    },
    userSearch() {
      this.loading = true;
      this.showUserCount = false;
      this.$logger.info('Searching for users');
      this.$http.get(this.apiEndpoint).then(res => {
        this.$logger.info('Successfully searched for users');
        this.searchedUsers = res.data;
      }).catch(err => {
        this.$logger.error('Error searching for users', undefined, err);
      }).then(() => {
        this.loading = false;
        this.searchedText = this.filterText;
        this.showUserCount = true;
      });
    },
  },
};
</script>
