import axios from 'axios';
import { SourceMapConsumer } from 'source-map-js';

const tracebackRegex = /(?<source>http(s)?:\/\/[a-zA-Z0-9.:]+\/[a-zA-Z0-9/.]+):(?<line>\d+):(?<column>\d+)/g;

class TracebackMapper {
  constructor() {
    this.queue = [];
    this.fileCache = {};
    this.requestingSourceMap = false;
    this.sourceMapUnavailable = false;
  }

  // eslint-disable-next-line class-methods-use-this
  getSourceMapConsumer(map) {
    // For easier mocking in unit tests
    return new SourceMapConsumer(map);
  }

  getMap(loc) {
    return new Promise((resolve, reject) => {
      if (this.sourceMapUnavailable) {
        reject(Error('Source map unavailable'));
      } else if (this.fileCache[loc]) {
        // Source map already cached - return it from the cache
        resolve(this.fileCache[loc]);
      } else if (this.requestingSourceMap) {
        // Already requesting source map - add new request to queue
        this.queue.push([resolve, reject]);
      } else {
        // Request source map
        this.requestingSourceMap = true;
        axios.get(`${loc}.map`).then(res => {
          // Add the retrieved source map to the cache
          this.fileCache[loc] = res.data;
          resolve(this.fileCache[loc]);
          while (this.queue.length > 0) {
            this.queue.pop()[0](this.fileCache[loc]);
          }
        }).catch(err => {
          this.sourceMapUnavailable = true;
          reject(err);
          while (this.queue.length > 0) {
            this.queue.pop()[1](err);
          }
        });
      }
    });
  }

  getOriginalPosition(source, filename, line, column) {
    return new Promise((resolve, reject) => {
      this.getMap(source).then(newMap => {
        const consumer = this.getSourceMapConsumer(newMap);
        const pos = consumer.originalPositionFor({ line, column });
        const res = {
          filename,
          source: pos.source,
          line: pos.line,
          column: pos.column,
          name: pos.name,
        };
        resolve(res);
      }).catch(() => {
        reject(Error('Source map unavailable'));
      });
    });
  }

  getPositionsForAllLines(tb) {
    const matchReplacements = [];
    let found = tracebackRegex.exec(tb);
    while (found !== null) {
      matchReplacements.push(this.getOriginalPosition(
        found[1], // The built source e.g. http://localhost:8080/index.<HASH>.js
        found[0], // The stack location in the built source e.g. http://localhost:8080/index.<HASH>.js:<LINE>:<COLUMN>
        +found.groups.line, // The line number
        +found.groups.column, // The column number
      ));
      found = tracebackRegex.exec(tb);
    }
    return matchReplacements;
  }

  static processTraceback(tb, replacements) {
    let res = tb;
    let entry;
    for (let i = 0; i < replacements.length; i++) {
      entry = replacements[i];
      res = res.replace(
        replacements[i].filename,
        `${entry.source}:${entry.line}:${entry.column}`,
      );
    }
    return res;
  }

  getRefinedTraceback(tb) {
    const sourcePositions = this.getPositionsForAllLines(tb);
    return new Promise(resolve => {
      if (sourcePositions.length === 0) {
        resolve(tb);
        return;
      }
      Promise.all(sourcePositions).then(res => {
        resolve(TracebackMapper.processTraceback(tb, res));
      }).catch(() => {
        resolve(tb);
      });
    });
  }
}

export default TracebackMapper;
