<template>
  <div class="panel panel-default">
    <div class="ranking-container">
      <span class="high-ranking" v-if="percentile <= 25  && percentile > 0" :class="percentileClass">
        <i class="fas fa-medal"></i>
        You are in the <b>top {{ getPercentile }}%</b>!
        <k-tooltip :text="tooltipText">
          <sup><i class="fas fa-info info-icon info-tooltip-container"></i></sup>
        </k-tooltip>
      </span>
      <span class="low-ranking" v-else-if="percentile >= 26 && percentile <= 40">
        You're just outside the podium places!
        <k-tooltip :text="tooltipText">
          <sup><i class="fas fa-info info-icon info-tooltip-container"></i></sup>
        </k-tooltip>
      </span>
      <span v-else class="no-ranking">
        Reach the top 40% to see your ranking!
        <k-tooltip :text="tooltipText">
          <sup><i class="fas fa-info info-icon info-tooltip-container"></i></sup>
        </k-tooltip>
      </span>
    </div>
  </div>
</template>

<style scoped>
.ranking-container {
  text-align: center;
}

.info-tooltip-container {
  display: block;
  right: 0;
  top: 0;
  color: var(--kate-type-light);
  font-size: 0.75em;
  padding: 3px 5px;
  border-radius: 50%;
  border: var(--border-primary);
  transition: all 0.3s;
  z-index: 2;
  position: relative;
}

.info-tooltip-container:hover {
  background-color: var(--kate-button-primary);
  color: var(--kate-type-dark);
  cursor: help;
}

.fa-medal {
  margin-right: 5px;
}

.gold > i {
  color: var(--kate-gold);
}

.silver > i {
  color: var(--kate-silver);
}

.bronze > i {
  color: var(--kate-bronze);
}
</style>

<script>
import KTooltip from './k-tooltip.vue';

export default {
  components: {
    KTooltip,
  },

  props: {
    percentile: {
      type: Number,
    },
  },

  computed: {
    getPercentile() {
      if (this.percentile <= 5) {
        return '5';
      }
      if (this.percentile <= 15 && this.percentile >= 6) {
        return '15';
      }
      if (this.percentile <= 25 && this.percentile >= 16) {
        return '25';
      }
      return 100 - this.percentile;
    },

    percentileClass() {
      if (this.getPercentile === '25') {
        return 'bronze';
      }
      if (this.getPercentile === '15') {
        return 'silver';
      }
      return 'gold';
    },

    tooltipText() {
      if (this.percentile <= 5 && this.percentile > 0) {
        return 'You\'re doing great!';
      }
      if (this.percentile <= 15 && this.percentile >= 6) {
        return 'You\'re doing really well!';
      }
      if (this.percentile <= 25 && this.percentile >= 16) {
        return 'You\'re doing well!';
      }
      if (this.percentile <= 40 && this.percentile >= 26 && this.percentile >= 0) {
        return `Reach the top 25% to get a bronze medal (currently <b>${this.percentile}%</b>)`;
      }
      return 'Nothing to worry about if you’ve completed the exercise and your code works!<br> If you’d like to challenge your peers you can keep working on your code.';
    },
  },
};
</script>
