<template>
  <div>
    <div class="quiz-question-list panel-body">
      <!-- Have to use the element in questions for v-model directive otherwise parent elements don't watch
      questions correctly and update in the expected way -->
      <!-- eslint-disable-next-line vue/valid-v-model -->
      <quiz-question v-for="(question, index) in orderedQuestions" v-model="questions[orderedIndexToUnorderedIndex(index)]"
        @remove="removeQuestion(index)"
        @move-up="moveQuestionUp(index)"
        @move-down="moveQuestionDown(index)"
        :key="index"></quiz-question>
    </div>
    <button class="btn btn-primary" @click="addNewQuestion">Add Question</button>
  </div>
</template>

<style scoped>
.quiz-question-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 15px;
}
</style>

<script>
import { sortObjectArray } from '../../../../../modules/sort-by-object-property';
import QuizQuestion from './quiz-question.vue';

export default {
  components: {
    'quiz-question': QuizQuestion,
  },
  props: {
    modelValue: {
      type: Array,
    },
  },
  data() {
    return {
      questions: this.modelValue,
    };
  },
  watch: {
    modelValue() {
      this.questions = this.modelValue;
    },
    questions: {
      handler() {
        this.$emit('update:modelValue', this.questions);
      },
      deep: true,
    },
  },
  computed: {
    orderedQuestions() {
      return sortObjectArray(this.questions, 'number');
    },
  },
  methods: {
    orderedIndexToUnorderedIndex(index) {
      // Get the index in questions from an index in orderedQuestions
      return this.questions.indexOf(this.orderedQuestions[index]);
    },
    addNewQuestion() {
      this.questions.push({
        number: this.questions.length + 1,
        question: undefined,
        answers: [],
        hint: undefined,
        resources: [],
        stagedFiles: [],
      });
    },
    removeQuestion(index) {
      if (this.orderedQuestions[index]) {
        const removedQuestion = this.questions.splice(this.questions.indexOf(this.orderedQuestions[index]), 1)[0];
        // Update the numbers for later questions
        this.questions.forEach(q => {
          if (q.number > removedQuestion.number) {
            /* eslint-disable no-param-reassign */
            q.number -= 1;
          }
        });
      }
    },
    moveQuestionUp(index) {
      if (this.orderedQuestions[index] && this.orderedQuestions[index].number > 1) {
        const movingQuestion = this.questions[this.questions.indexOf(this.orderedQuestions[index])];
        this.questions.find(q => q.number === movingQuestion.number - 1).number += 1;
        movingQuestion.number -= 1;
      }
    },
    moveQuestionDown(index) {
      if (this.orderedQuestions[index] && this.orderedQuestions[index].number < this.questions.length) {
        const movingQuestion = this.orderedQuestions[index];
        this.questions.find(q => q.number === movingQuestion.number + 1).number -= 1;
        movingQuestion.number += 1;
      }
    },
  },
};
</script>
