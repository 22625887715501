import flatten from '../modules/flatten';
import { sortObjectArray } from '../modules/sort-by-object-property';
import { getCompletionForChapters } from '../modules/module-completion-calculator';
import { ASSET_TYPES } from '../constants';

export default {
  computed: {
    sortedChapters() {
      if (this.chapters) {
        return sortObjectArray(this.chapters, 'number');
      }
      return [];
    },

    assets() {
      if (this.sortedChapters) {
        return flatten(this.sortedChapters.map(ch => sortObjectArray(ch.assets, 'number') || []));
      }
      return [];
    },

    paks() {
      return this.assetsOfType('pak');
    },

    lus() {
      return this.assetsOfType('learning_unit');
    },

    videos() {
      return this.assetsOfType('video');
    },

    slides() {
      return this.assetsOfType('slide_deck');
    },

    quizzes() {
      return this.assetsOfType('quiz');
    },

    events() {
      return this.assetsOfType('calendar_event');
    },

    meetings() {
      return this.assetsOfType('meeting');
    },

    pages() {
      return this.assetsOfType('markdown_page');
    },

    questionnaires() {
      return this.assetsOfType('questionnaire');
    },

    chapterCompletionScores() {
      return getCompletionForChapters(this.chapters);
    },
  },

  methods: {
    assetsOfType(assetType) {
      if (!(assetType in ASSET_TYPES)) {
        return undefined;
      }
      return this.assets?.filter(a => a.asset_type === assetType) || [];
    },
  },
};
