<template>
  <div id="module-meta">
    <div class="controls">
      <button v-if="!inEditMode" class="btn btn-primary edit-btn" @click="editModuleMeta">
        Edit Meta
      </button>
      <button v-if="inEditMode" class="btn btn-success save-btn" @click="updateModuleMeta" :disabled="!validUpdates">
        Save changes
      </button>
      <button v-if="inEditMode" class="btn btn-danger cancel-btn" @click="revertChanges">
        Cancel changes
      </button>
    </div>

    <div v-if="inEditMode">
      <div class="module-title-container">
        <h2>Module Title</h2>
        <k-text-input
          v-model="updates.name"
          :input-placeholder="'Enter new module title'"
          :edit-mode="true"
          :show-reset="true"
          :show-controls="false"
        ></k-text-input>
        <p v-if="!validTitle">
          <i class="fas fa-exclamation-triangle"></i>
          Please add a title for this module blueprint. This is required
        </p>
      </div>
      <div class="description">
        <h2>Module Description</h2>
        <k-text-editor id="module-description"
          v-model="updates.description"
        ></k-text-editor>
      </div>
      <div class="short-description">
        <k-tooltip text="As seen on programme page in KATE">
          <h2>Short Description</h2>
        </k-tooltip>
        <k-text-editor id="module-short-description"
          v-model="updates.short_description"
          :maxLength="180"
        ></k-text-editor>
      </div>
      <div class="module-tags" v-if="isBlueprint">
        <word-tags v-model="updates.tags" textInputPlaceholder="Type tag here..."></word-tags>
      </div>
      <div class="learning-outcomes">
        <h2>Learning Outcomes</h2>
        <fieldset class="detail-form form-entry">
          <k-multi-input
            v-if="updates && updates.learning_outcomes"
            v-model="updates.learning_outcomes"
            input-placeholder="Enter new learning outcome"
            empty-message="There are currently no learning outcomes in this module"
            :maxlength=512
            :edit-mode="true"
            :nPlaceholders="0"
          ></k-multi-input>
        </fieldset>
      </div>
    </div>
    <div v-else>
      <div class="meta name">
        <h2>Module Title</h2>
        <p>{{ name }}</p>
      </div>
      <div class="meta description">
        <h2>Description</h2>
        <div v-html="description"></div>
        <p v-if="!description" class="empty">
          Please add a description for this module
        </p>
      </div>
      <div class="meta short-description">
        <k-tooltip text="As seen on programme page in KATE">
          <h2>Short Description</h2>
        </k-tooltip>
        <div v-html="shortDescription"></div>
        <p v-if="!shortDescription" class="empty">
          Please add a short description for this module. If left empty a truncated version of the description will be used.
        </p>
      </div>
      <div class="meta module-tags" v-if="isBlueprint">
        <h2>Tags</h2>
        <ul class="k-word-tags-list">
          <li class="k-word-tags-item badge" v-for="tag in tags">{{tag}}</li>
        </ul>
        <p v-if="!tags.length" :class="!tags.length ? 'empty' : ''">
          Please add tags for this module
        </p>
      </div>
      <div class="meta learning-outcomes">
        <h2>Learning Outcomes</h2>
        <ol v-if="learningOutcomes.length">
          <li v-for="lo in learningOutcomes">
            <i class="fas fa-bullseye"></i>
            {{ lo }}
          </li>
        </ol>
        <p v-if="!learningOutcomes.length" :class="!learningOutcomes.length ? 'empty' : ''">
          Please add learning outcomes for this module
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
#module-meta h2 {
  margin: 20px 0;
}

.learning-outcomes ol li {
  display: block;
  margin-top: 20px;
}

.learning-outcomes ol li i {
  color: var(--kate-type-accent);
}

.meta p.empty {
  font-style: italic;
}

.text-area-array-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.text-area-entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.controls button {
  float: right;
  margin-left: 10px;
}

.k-word-tags-item {
  color: var(--kate-type-white);
}

</style>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import GetOrNull from '../../../../modules/get-or-null';
import KMultiInput from '../../../../components/k-multi-input.vue';
import KTextInput from '../../../../components/k-text-input.vue';
import WordTags from '../../../components/word-tags.vue';
import KTextEditor from '../../../../components/k-text-editor.vue';
import KTooltip from '../../../../components/k-tooltip.vue';

const MAX_LEARNING_OUTCOME_LENGTH = 512;

export default {
  components: {
    KTooltip,
    WordTags,
    KTextInput,
    KMultiInput,
    KTextEditor,
  },

  mixins: [ErrorMixin],

  props: {
    isBlueprint: {
      type: Boolean,
      default: false,
    },
    moduleMeta: Object,
  },

  data() {
    return {
      leftPaneChapters: [],
      rightPaneChapters: [],
      currentlySelectedChapter: undefined,
      isInViewElement: false,
      updates: {
        learning_outcomes: undefined,
        name: undefined,
        description: undefined,
        short_description: undefined,
        tags: undefined,
      },
      inEditMode: false,
      showBadgeModal: false,
    };
  },

  computed: {
    learningOutcomes() {
      return GetOrNull('learning_outcomes', this.updates, GetOrNull('learning_outcomes', this.moduleMeta, []));
    },
    name() {
      return GetOrNull('name', this.updates, GetOrNull('name', this.moduleMeta));
    },
    description() {
      return GetOrNull('description', this.updates, GetOrNull('description', this.moduleMeta));
    },
    shortDescription() {
      return GetOrNull('short_description', this.updates, GetOrNull('short_description', this.moduleMeta));
    },
    tags() {
      return GetOrNull('tags', this.updates, GetOrNull('tags', this.moduleMeta, []));
    },
    validTitle() {
      return Boolean(this.updates.name && this.updates.name.length < 256);
    },
    validLearningOutcomes() {
      return this.learningOutcomes.map(el => el && el.length < MAX_LEARNING_OUTCOME_LENGTH);
    },
    validUpdates() {
      if (!this.updates) {
        return false;
      }
      return this.validLearningOutcomes.every(check => check === true) && this.validTitle;
    },
    getEndpoint() {
      if (this.isBlueprint) {
        return `/api/curriculum/blueprints/modules/${this.moduleMeta.id}`;
      }
      return `/api/curriculum/modules/${this.moduleMeta.id}/meta`;
    },
    getPayload() {
      const p = {
        name: this.name,
        description: this.description,
        short_description: this.shortDescription,
        learning_outcomes: this.learningOutcomes.filter(lo => lo.trim().length),
      };
      if (this.isBlueprint) {
        p.tags = GetOrNull('tags', this.updates, GetOrNull('tags', this.moduleMeta, []));
      }
      return p;
    },

  },

  methods: {

    updateModuleMeta() {
      const payload = this.getPayload;
      this.$logger.info('Updating module meta', { moduleId: this.id, blueprint: this.isBlueprint });
      this.$Loading.minimal();
      this.$http.put(this.getEndpoint, payload)
        .then(() => {
          this.$logger.info('Successfully updated module metadata', { moduleId: this.id, blueprint: this.isBlueprint }, true);
          this.$ktoast.success('Module meta updated');
          this.$emit('update', payload);
        })
        .catch(err => {
          this.$logger.autowarn('Error updating module meta', { moduleId: this.id, blueprint: this.isBlueprint }, err);
          this.showError(err);
        })
        .then(() => {
          this.$Loading.finish();
          this.revertChanges();
          this.inEditMode = false;
        });
    },
    editModuleMeta() {
      this.updates.learning_outcomes = this.learningOutcomes;
      this.updates.name = this.name;
      this.updates.description = this.description;
      this.updates.short_description = this.shortDescription;
      this.updates.tags = this.tags;
      this.inEditMode = true;
    },
    revertChanges() {
      this.updates.learning_outcomes = this.moduleMeta.learning_outcomes;
      this.updates.name = this.moduleMeta.name;
      this.updates.description = this.moduleMeta.description;
      this.updates.short_description = this.moduleMeta.short_description;
      this.updates.tags = this.moduleMeta.tags;
      this.inEditMode = false;
    },
    removeTextField(i) {
      this.updates.learning_outcomes.splice(i, 1);
    },
  },
};
</script>
