<template>
  <abstract-achievements-overview :achievement-type="'badge'">
  </abstract-achievements-overview>
</template>
<script>
import AbstractAchievementsOverview from './abstract-achievements-overview.vue';

export default {
  components: { AbstractAchievementsOverview },
};
</script>
