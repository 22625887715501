<template>
  <ul v-if="files" class="file-list">
    <li v-for="(file, idx) in files" :key="idx" class="col-md-4 col-sm-6 col-xs-12">
      <figure class="panel panel-default">
        <div class="panel-header">
          <figcaption class="uploaded-file">{{ getFilename(file) }}</figcaption>
          <k-tooltip text="Remove file" textAlign="center">
            <i class="fas fa-times clickable" @click="removeFile(idx)"></i>
          </k-tooltip>
        </div>
        <div class="panel-image">
          <img v-if="isImage(file)" :src="getImageSrc(file)" >
          <i v-else :class="getIcon(file)"></i>
        </div>
      </figure>
    </li>
  </ul>
</template>

<style scoped>
ul.file-list {
  list-style: none;
  padding-left: 5px;
  margin-bottom: 0;
}

ul.file-list li {
  padding: 8px;
}

ul.file-list li .panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 8px;
  height: 100%;
}

.panel-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 5px 10px;
  font-size: 0.8em;
  height: 30px;
}

.panel-header span.uploaded-file {
  line-height: 1.3;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-items: flex-start;
  padding-right: 5px;
  min-height: 50px;
}

.panel-header span.uploaded-file i.fas {
  margin-right: 5px;
}

.panel-header i:hover {
  color: var(--kate-type-light);
}

.panel-image {
  display: flex;
  height: 150px;
}

.panel-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin: auto;
  border: solid 2px var(--kate-mono-0);
}

.panel-image i {
  margin: auto;
  font-size: 5rem;
  color: var(--kate-primary);
}

</style>

<script>
import KTooltip from './k-tooltip.vue';

export default {
  components: {
    'k-tooltip': KTooltip,
  },
  props: {
    files: {
      type: [FileList, Array],
      default: () => [],
      validator(value) {
        if (value instanceof FileList) {
          return true;
        }
        if (value instanceof Array) {
          for (let i = 0; i < value.length; i++) {
            // Accept File objects or JS objects with name and src properties
            const isFile = value[i] instanceof File || value[i] instanceof Blob;
            const hasFileProperties = value[i].src && value[i].name;
            if (!isFile && !hasFileProperties) {
              return false;
            }
          }
          return true;
        }
        return false;
      },
    },
    filenameOverride: {
      type: String,
    },
  },

  methods: {
    isImage(file) {
      if (file.type) {
        return file.type.slice(0, 5) === 'image';
      }
      return false;
    },
    getIcon(file) {
      if (this.isImage(file)) {
        return 'fas fa-image';
      }
      return 'fas fa-file-alt';
    },
    getFilename(file) {
      return this.filenameOverride || file.name;
    },
    getImageSrc(file) {
      if (file instanceof File || file instanceof Blob) {
        return URL.createObjectURL(file);
      }
      return file.src;
    },

    removeFile(idx) {
      this.$emit('remove-file', {
        index: idx,
        file: this.files[idx],
      });
    },
  },
};
</script>
