<template>
  <div>
    <h1>Achievement Management</h1>
    <k-nav-tabs :currentTab="currentTab" :tabs="tabs"></k-nav-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import ErrorMixin from '../../mixins/error-mixins';
import KNavTabs from '../../components/k-nav-tabs.vue';

export default {
  components: {
    KNavTabs,
  },

  mixins: [ErrorMixin],

  data() {
    return {
      tabs: [
        {
          name: 'Badges',
          routeName: 'badges_overview',
        },
        {
          name: 'Certificates',
          routeName: 'certificates_overview',
        },
      ],
    };
  },

  computed: {
    currentTab() {
      return this.$route.name;
    },
  },

};
</script>
