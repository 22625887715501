<template>
  <li class="list-items chapter-asset-item">
    <div v-if="interactionEnabled">
      <button class="item release-option" :class="{'selected': selectedOverride}" @click="handleClick">
        <i class="fas" :class="getAssetIcon(asset.asset_type)"></i>
        {{ asset.name || asset.title }}
        <i v-if="isChapterSelected" class="fas fa-grip-vertical"></i>
        <i class="fas fa-plus-circle add-icon"></i>
        <i class="fas fa-minus-circle remove-icon"></i>
      </button>
    </div>
    <div v-else class="custom-chapter-item">
      <i class="fas k-asset-icon" :class="getAssetIcon(asset.asset_type)"></i>
      <span>{{ asset.name || asset.title }}</span>
    </div>
  </li>
</template>

<style>
.release-asset-container .chapter.right-pane li.list-items .release-option:hover {
  background-color: var(--kate-danger-alt);
}

.release-asset-container .selected .chapter.right-pane li.list-items.sortable-chosen .release-option:hover {
  background-color: var(--kate-panel-alt);
}

.release-asset-container .chapter.left-pane .remove-icon,
.release-asset-container .chapter.right-pane .add-icon {
  display: none;
}

.release-asset-container .chapter.left-pane .release-option:hover {
  background-color: var(--kate-success);
  color: var(--kate-type-dark);
}

.release-asset-container .chapter.left-pane .release-option.selected:hover {
  background-color: var(--kate-background-body);
  color: var(--kate-type-light);
  cursor: default;
}
</style>

<style scoped>
li {
  display: block;
}

li button > i.fas {
  float: left;
  margin-left: 5px;
  margin-top: 4px;
  color: var(--kate-type-white);
}

li button > i.fas.add-icon,
li button > i.fas.remove-icon,
li button > i.fas.fa-grip-vertical {
  float: right;
}

li button:active,
li button:focus {
  cursor: grabbing;
}

.release-option {
  width: 90%;
  padding: 0.3em;
  margin-top: 0.5em;
  border-radius: 3px;
  transition: all 0.1s ease;
  background-color: var(--kate-background-body);
}

.release-asset-container li.list-items .chapter-asset-item {
  margin: 8px 0;
}

.custom-chapter-item {
  padding-left: 15px;
  line-height: 2;
  color: var(--kate-type-light);
}

.custom-chapter-item i {
  padding-right: 5px;
  min-width: 25px;
  text-align: center;
}
</style>

<script>
import AssetIconMixin from '../../../../../mixins/asset-icon-mixin';

export default {
  mixins: [AssetIconMixin],

  props: {
    asset: {
      type: Object,
      required: true,
    },
    selectedOverride: {
      type: Boolean,
      default: false,
    },
    interactionEnabled: Boolean,
    isChapterSelected: Boolean,
  },

  methods: {
    handleClick() {
      if (!this.selectedOverride) {
        this.$emit('asset-click');
      }
    },
  },
};
</script>
