<template>
  <div class="control-btn start-project-button">
    <button @click="startProject" class="btn btn-primary">
      <i class="fas fa-terminal"></i>
      Start Assignment
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    startProject() {
      this.$emit('fork');
    },
  },
};
</script>
