function stringMatch(str, filterStr, strict, any) {
  /**
   * Returns true if str matches any of the contents of the filterStr, case insensitive
   * @param str string of interest
   * @param filterStr string to check for matches against
   * @param strict if true, filterStr will not be delimited by spaces into separate filter terms and the
   * entire string will need to present for it to be considered a match
   * @param any if true, will consider it a match if any of the terms in filterStr present - does not
   * work if strict is true
   */
  if (!filterStr) {
    return true;
  }
  if (!str) {
    return false;
  }
  // Sanitise special regex characters
  let sanitisedFilter = filterStr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  if (strict) {
    // If strict, don't consider whitespace delimited terms to be separate
    return Boolean(str.match(new RegExp(sanitisedFilter, 'ig')));
  }

  // Replace consecutive whitespace with single whitespace
  sanitisedFilter = sanitisedFilter.replace(/\s\s+/g, ' ');

  // Split filterStr so separate words are matched independently and convert to regex
  const terms = sanitisedFilter.split(' ').filter(x => x.length > 0);
  if (any) {
    // Consider it a match if any of the terms are present
    const pattern = new RegExp(`(${terms.join('|')})`, 'ig');
    return Boolean(str.match(pattern));
  }
  // Consider it a match only if all of the terms are present
  return terms.every(x => Boolean(str.match(new RegExp(x, 'ig'))));
}

export default stringMatch;
