<template>
  <div>
    <h1>Assignment Overview</h1>
    <asset-overview v-if="assignmentsReady"
      asset-icon="fa-laptop-code"
      :allow-asset-creation="false"
      :assets="assignments"
      :kable-headers="kableHeaders"
      :kable-rows="kableRows"
      :show-archived-assets="showArchived"
      @tablecallback="tableCallback"
      @archive-toggle="updateArchiveToggle"
    ></asset-overview>
  </div>
</template>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import ArchiveToastConfirmMixin from '../archive-toast-confirm-mixin';
import AssetOverview from '../asset-overview.vue';

export default {
  components: {
    AssetOverview,
  },

  mixins: [ErrorMixin, ArchiveToastConfirmMixin],
  data() {
    return {
      assignmentsReady: false,
      assignments: [],
      showArchived: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getAssignments();
  },

  watch: {
    assignmentsReady() {
      if (this.assignmentsReady) {
        this.$Loading.finish();
      } else {
        this.$Loading.start();
      }
    },
  },

  computed: {
    kableHeaders() {
      let headers = {
        name: {
          name: 'Title',
          filterable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
        },
        type: {
          name: 'Type',
        },
        repository: {
          name: 'Repository URL',
        },
        last_deployment: {
          name: 'Last Deployed',
          type: 'timestamp',
          sortable: true,
        },
        tags: {
          name: 'Tags',
          type: 'tags',
          filterable: true,
          sortable: true,
        },
        kloud_compatible: {
          name: 'KLOUD Compatible',
        },
      };
      if (this.$permissions.hasPermission('manage_assets')) {
        headers = {
          ...headers,
          archive: {
            name: 'Archive',
            type: 'action',
            sortable: true,
          },
        };
      }
      return headers;
    },

    kableRows() {
      return this.assignments.map(assignment => ({
        name: {
          text: assignment.name,
          path: {
            name: 'curriculum_asset_details',
            params: {
              assetId: assignment.id,
              assetType: 'pak',
            },
          },
        },
        description: assignment.description,
        type: this.getType(assignment),
        last_deployment: assignment.last_deployment,
        repository: this.getLink(assignment.gitlab_repo_url),
        tags: {
          tags: assignment.tags,
          sortBy: assignment.tags ? [...assignment.tags].sort().join(' ') : '',
        },
        archive: {
          text: this.assetArchiveButton(assignment.is_active),
        },
        id: assignment.id,
        is_active: assignment.is_active,
        kloud_compatible: this.getKloudCompatibleIcon(assignment.kloud_compatible),
      })).filter(assignment => (this.showArchived || assignment.is_active));
    },
  },

  methods: {
    updateArchiveToggle(val) {
      this.showArchived = val;
    },

    getLink(url) {
      if (url) {
        return `<a href="${url}" target="_blank">${url}</a>`;
      }
      return '';
    },

    getType(assign) {
      if (assign.language === 'java') {
        return 'Java';
      }
      const type = assign.config.notebook_only ? 'Notebook' : 'IDE';
      return `Python (${type})`;
    },

    getKloudCompatibleIcon(compatible) {
      const compatibleStr = compatible ? 'Compatible' : 'Incompatible';
      return `<div class="tags"><span class="${compatibleStr.toLowerCase()}">${compatibleStr}</span></div>`;
    },

    getAssignments() {
      this.$logger.info('Getting assignments');
      this.assignmentsReady = false;
      this.$http.get('/api/curriculum/admin/pak?active_only=false').then(result => {
        this.$logger.info('Got assignments');
        this.assignments = result.data.paks;
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error getting assignments', undefined, err);
          this.showError(err);
        } else {
          this.$logger.warn('No assignments found');
        }
      }).then(() => {
        this.assignmentsReady = true;
      });
    },

    // Archiving Paks
    archiveCallback(pakId) {
      this.$logger.info('Archiving pak', { pakId });
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/pak/${pakId}/archive`)
        .then(() => {
          this.$logger.info('Assignment has been archived', { pakId });
          this.$ktoast.success('Assignment has been archived');
          this.getAssignments();
        }).catch(err => {
          this.$logger.error('Error archiving assignment', { pakId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    unarchiveCallback(pakId) {
      this.$logger.info('Unarchiving pak', { pakId });
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/pak/${pakId}/unarchive`)
        .then(() => {
          this.$logger.info('Assignment has been unarchived', { pakId });
          this.$ktoast.success('Assignment has been unarchived');
          this.getAssignments();
        }).catch(err => {
          this.$logger.error('Error unarchiving assignment', { pakId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    tableCallback(key, index, row, column) {
      if (column === 'archive') {
        this.tableArchiveCallback(row);
      }
    },
  },
};
</script>
