<template>
  <ul class="thread-tags-tree">
    <li v-if="hasProgramme && isDashboard">
      <router-link
        :to="{ name: 'manage_programme_content', params: { programmeId: thread.programme_id }}">
        <span>
          <i class="fas fa-project-diagram"></i>
          {{ thread.programme_name }}
        </span>
      </router-link>
    </li>
    <li v-if="hasProgramme && hasModule && isDashboard" class="module-tag">
      <router-link
        :to="{ name: 'module_chapter_manager', params: { programmeId:thread.programme_id, moduleId: thread.module_id }}">
        <span>
          {{ thread.module_name }}
        </span>
      </router-link>
      <i v-if="assetName" class="fas fa-angle-right"></i>
    </li>
    <li v-if="hasProgramme && hasModule && assetName" class="content-tag">
      <router-link
        v-if="isDashboard"
        :to="{ name: 'curriculum_asset_details', params: { assetType: assetType, assetId: assetId }}">
        <span>
          <i class="fas k-asset-icon" :class="getAssetIcon(assetType)"></i>
          {{ assetName }}
        </span>
      </router-link>
      <span v-else>
        <i class="fas k-asset-icon" :class="getAssetIcon(assetType)"></i>
        {{ assetName }}
      </span>
    </li>
  </ul>
</template>

<style scoped>
.thread-tags-tree {
  list-style: none;
  padding: 0;
}

.thread-tags-tree li {
  position: relative;
  padding-top: 2px;
  box-sizing: border-box;
}

.thread-tags-tree li:first-child::after {
  height: 15px;
}

.thread-tags-tree li:last-child::after {
  height: 15px;
}

.thread-tags-tree li::before {
  position: absolute;
  top: 15px;
  left: 0;
  width: 10px;
  height: 2px;
  margin: auto;
  content: "";
  background-color: rgb(2 20 23 / 0.5);
}

.thread-tags-tree li:first-child::before,
.thread-tags-tree li:first-child::after {
  display: none;
}

.thread-tags-tree li::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
  height: 100%;
  content: "";
  background-color: rgb(2 20 23 / 0.5);
}

.thread-tags-tree li:only-child {
  padding-left: 0;
}

.thread-tags-tree li:only-child::before,
.thread-tags-tree li:only-child::after {
  display: none;
}

.thread-tags-tree img {
  max-height: 15px;
}

.thread-tags-tree li:nth-child(2) {
  padding-left: 30px;
  display: inline-block;
}

.thread-tags-tree li:nth-child(2)::before {
  left: 15px;
}

.thread-tags-tree li:nth-child(2)::after {
  left: 15px;
  height: 15px;
}

.thread-tags-tree li:nth-child(3) {
  display: inline-block;
}

.thread-tags-tree li:nth-child(3)::before,
.thread-tags-tree li:nth-child(3)::after {
  display: none;
}
</style>

<style>
.thread-tags-tree li,
.thread-tags li {
  list-style: none;
  color: var(--kate-disabled);
  font-size: 0.89em;
}

.thread-tags-tree li span i,
.thread-tags li span i {
  padding-right: 0 5px;
}

.thread-tags-tree li i.module-tag,
.thread-tags li i.module-tag {
  color: var(--kate-primary);
}

.thread-tags li:only-child i.fa-angle-right,
.thread-tags li:last-child i.fa-angle-right {
  display: none;
}

.thread-tags-tree li i.fa-angle-right,
.thread-tags li i.fa-angle-right {
  padding: 0 5px;
}

.thread-tags-tree img,
.thread-tags li img {
  max-height: 20px;
  max-width: 40px;
}
</style>

<script>
import useGlobalStore from '../stores/global';
import AssetIconMixin from '../mixins/asset-icon-mixin';
import { ASSET_TYPES } from '../constants';

export default {
  props: {
    thread: {
      type: Object,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
    };
  },

  mixins: [AssetIconMixin],

  computed: {
    isDashboard() {
      return this.store.isDashboard;
    },
    hasProgramme() {
      if (this.thread.programme_id) {
        return true;
      }
      return false;
    },
    hasModule() {
      if (this.thread.module_id) {
        return true;
      }
      return false;
    },
    assetName() {
      return this.thread[`${this.assetType}_name`];
    },
    assetId() {
      return this.thread[`${this.assetType}_id`];
    },
    assetType() {
      return Object.keys(ASSET_TYPES).find(at => this.thread[`${at}_id`]);
    },
  },
};
</script>
