<template>
  <div class="k-stepper" :class="progressDirection">
    <template v-for="(step, index) in steps">
      <div class="k-step" :class="progressClasses(index)"
      @click="goToStage(index)">
        <div class="circle-container">
          <div class="k-stepper-circle" v-html="getIcon(step)"></div>
        </div>
        <div class="info-container">
            <div class="k-stepper-label">{{step.name}}
              <k-tooltip v-if="tooltip" :text="`<b>${step.name}:</b><br>${step.tooltip}`">
                <sup><i class="fas fa-info info-icon info-tooltip-container"></i></sup>
              </k-tooltip>
            </div>
        </div>
        <div v-if="index < steps.length - 1" class="k-stepper-divider"></div>
      </div>
    </template>
  </div>
</template>

<style scoped>
.k-stepper {
  margin: 1em 0;
}

.k-step {
  display: inline-block;
  font-size: 11pt;
  margin: 0 0.5em;
  position: relative;
  width: 25%;
  min-width: 93px;
}

.k-stepper-divider {
  border-bottom: 3px solid var(--kate-type-primary);
  float: left;
  margin: 0 0.5em;
  width: 100%;
  position: absolute;
  top: 31%;
  right: -56%;
  z-index: 0;
}

.circle-container {
  max-width: 41px;
  position: relative;
  display: inline-block;
}

.info-container {
  display: flex;
  text-align: center;
  margin-top: 5px;
  white-space: nowrap;
  position: relative;
  flex-wrap: nowrap;
  justify-content: center;
  min-width: 110px;
}

.k-step.inactive .k-stepper-divider {
  border-color: var(--kate-type-primary);
}

.k-step.inactive .k-stepper-circle {
  background-color: var(--kate-type-primary);
  color: var(--kate-type-alt-dark);
}

.k-step.inactive .k-stepper-label {
  color: var(--kate-type-primary);
  font-weight: bold;
}

.k-step.active .k-stepper-divider {
  border-color: var(--kate-logo-blue);
}

.k-step.active .k-stepper-circle {
  background-color: var(--kate-logo-blue);
}

.k-step.active .k-stepper-label {
  color: var(--kate-logo-blue);
  font-weight: bold;
}

.k-step.complete .k-stepper-divider {
  border-color: var(--kate-success);
}

.k-step.complete .k-stepper-circle {
  background-color: var(--kate-success);
  color: var(--kate-type-dark);
}

.k-step.complete .k-stepper-label {
  color: var(--kate-type-success);
}

.k-step.complete.clickable:hover .k-stepper-label,
.k-step.complete.clickable:hover .k-stepper-circle,
.k-step.complete.clickable:hover .k-stepper-divider {
  transition: all 0.3s;
}

.k-step.complete.clickable:hover .k-stepper-label {
  color: var(--kate-success-dark);
}

.k-step.complete.clickable:hover .k-stepper-circle {
  background-color: var(--kate-success-dark);
}

.k-step.complete.clickable:hover .k-stepper-divider {
  border-color: var(--kate-success-dark);
}

.k-stepper-circle {
  display: block;
  align-items: center;
  background: var(--kate-type-primary);
  border-radius: 50%;
  color: var(--kate-type-light);
  font-size: 1.4em;
  height: 2em;
  width: 2em;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}

.k-stepper-label {
  color: var(--kate-type-primary);
  margin-right: 5px;
}

/* Info Icon and Tooltip */

.info-tooltip-container {
  display: block;
  right: 0;
  top: 0;
  color: var(--kate-type-light);
  font-size: 0.75em;
  padding: 3px 5px;
  border-radius: 50%;
  border: var(--border-primary);
  transition: all 0.3s;
  z-index: 2;
  position: relative;
}

.info-tooltip-container:hover {
  background-color: var(--kate-button-primary);
  color: var(--kate-type-dark);
  cursor: help;
}

.k-stepper .info-icon {
  display: inherit;
}

/* Vertical */

.k-stepper.vertical-stepper {
  display: flex;
  flex-direction: column;
  min-height: 180px;
  justify-content: space-between;
}

.vertical-stepper .k-step {
  display: inline-block;
  font-size: 11pt;
  width: auto;
}

.vertical-stepper .k-stepper-divider {
  border-bottom: 0;
  border-right: 3px solid var(--kate-mono-0);
  width: 0;
  float: none;
  height: 100%;
  top: unset;
  bottom: -80%;
  right: 48%;
}

.vertical-stepper .info-container {
  display: none;
}

/* Media Query */
@media screen and (max-width: 479px) {
  .info-container {
    display: block;
    min-width: unset;
  }

  .k-step {
    min-width: unset;
  }

  .info-tooltip-container {
    display: inline-block;
    width: 20px;
  }

  .k-step .k-stepper-label {
    font-size: 13px;
  }

  .k-stepper .info-icon {
    display: inline;
  }
}

</style>

<script>
import KTooltip from './k-tooltip.vue';

export default {
  components: {
    KTooltip,
  },

  props: {
    clickable: {
      type: Boolean,
      default: false,
      note: 'Whether or clicking the buttons will go to that stage (assuming that stage is in the past)',
    },
    steps: {
      type: Array,
      required: true,
      notes: 'In the format [{ icon: fa_icon_class, name: stage_string }]',
    },
    modelValue: Number,
    direction: String,
    enableActive: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    getIcon(step) {
      return `<i class="${step.icon || 'fas fa-circle'}"></i>`;
    },
    goToStage(num) {
      if (this.clickable && num < this.modelValue) {
        this.$emit('update:modelValue', num);
      }
    },
    progressClasses(index) {
      const output = [];
      if (index < this.modelValue) {
        output.push('complete');
      }
      if (index > this.modelValue) {
        output.push('inactive');
      }
      if (this.clickable && index < this.modelValue) {
        output.push('clickable');
      }
      if (index === this.modelValue) {
        if (this.enableActive) {
          output.push('active');
        } else {
          output.push('inactive');
        }
      }
      return output.join(' ');
    },
  },

  computed: {
    progressDirection() {
      if (this.direction === 'horizontal') {
        return 'horizontal-stepper';
      }
      if (this.direction === 'vertical') {
        return 'vertical-stepper';
      }
      if (this.direction === 'unset') {
        return 'alt-stepper';
      }
      return 'stepper';
    },
  },

};
</script>
