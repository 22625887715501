<template>
  <div>
    <manage-programme-badges
      :programme="programme"
      :programme-ready="programmeReady"
      :is-blueprint="isBlueprint"
      @unsaved-changes="(val) => unsavedChanges.badges = val"
      @refresh="$emit('refresh')"
    ></manage-programme-badges>
    <manage-programme-certificate
      :programme="programme"
      :programme-ready="programmeReady"
      :is-blueprint="isBlueprint"
      @unsaved-changes="(val) => unsavedChanges.certificate = val"
      @refresh="$emit('refresh')"
    ></manage-programme-certificate>
  </div>
</template>

<script>
import NavControlMixin from '../../mixins/nav-control-mixin';
import AbstractManageProgrammeBadges from './abstract-manage-programme-badges.vue';
import AbstractManageProgrammeCertificate from './abstract-manage-programme-certificate.vue';

export default {
  components: {
    'manage-programme-badges': AbstractManageProgrammeBadges,
    'manage-programme-certificate': AbstractManageProgrammeCertificate,
  },

  mixins: [NavControlMixin],

  props: {
    programme: {
      type: Object,
    },
    programmeReady: {
      type: Boolean,
    },
    isBlueprint: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      unsavedChanges: {
        badges: false,
        certificate: false,
      },
    };
  },

  beforeRouteLeave(to, from, next) {
    return this.beforeLeave(next);
  },

  watch: {
    unsavedChanges: {
      handler() {
        this.pageHasUnsavedChanges = this.unsavedChanges.certificate || this.unsavedChanges.badges;
      },
      deep: true,
    },
  },
};
</script>
