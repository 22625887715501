const ACCEPTED_RESOURCES = 'image/jpeg,image/png';
const MAX_FILE_SIZE = 2000000;

export default {
  data() {
    return {
      resources: [],
    };
  },

  computed: {
    acceptedResources() {
      return ACCEPTED_RESOURCES;
    },
  },

  methods: {
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    formatResourcesForUpload() {
      const resources = [];
      const fileReads = [];

      if (this.resources) {
        for (let j = 0; j < this.resources.length; j++) {
          fileReads.push(this.getBase64(this.resources[j]));
        }
      }
      return Promise.all(fileReads).then(res => {
        for (let i = 0; i < res.length; i++) {
          // Bit before the comma is the encoding e.g. data:image/png;base64
          const base64String = res[i].split(',').pop();
          resources.push({
            name: this.resources[i].name,
            resource_type: 'image', // TODO: Eventually able to add other types of files
            text: undefined, // TODO: Add way for users to add caption text to an image
            file_content: base64String,
            content_type: this.resources[i].type,
          });
        }
        // Return resources when promise resolves
        return resources;
      });
    },
    filesChange(files) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size >= MAX_FILE_SIZE) {
          this.$ktoast.warning('Upload failed: please ensure all files are smaller than 2MB');
          return;
        }
        if (!this.acceptedResources.split(',').find(x => x === files[i].type)) {
          this.$ktoast.warning('Upload failed: only .jpg and .png image files are allowed');
          return;
        }
      }
      this.$logger.info('Added question resources for upload', { files: [...files].map(x => x.name) });
      this.resources = this.resources.concat(Array.from(files));
    },
    pasteResources(payload) {
      const fileArray = [];
      for (let i = 0; i < payload.files.length; i++) {
        const file = payload.files[i];
        if (this.acceptedResources.includes(file.type)) {
          fileArray.push(file);
        }
      }
      if (fileArray.length > 0) {
        this.filesChange(fileArray);
      }
    },
    removeResource(payload) {
      this.$logger.info(`Removing existing file ${payload.file.name}`);
      this.resources.splice(payload.index, 1);
    },
  },
};
