<template>
  <div>
    <h1>Manage Programme Blueprints</h1>
    <k-blueprint
      blueprint-type="programme"
      :kable-rows="kableRows"
      :kable-headers="kableHeaders"
      :table-callback="tableCallback"
    >
      <template #create-form>
        <fieldset class="detail-form form-entry form-required">
          <label for="programme-name-input">Name</label>
          <input id="programme-name-input" class="form-control" placeholder="Programme Name" v-model="newBlueprintName" maxlength="255">
        </fieldset>
        <fieldset class="detail-form form-entry">
          <label for="programme-description-input">Description</label>
          <textarea id="programme-description-input" class="form-control" placeholder="A short description of the programme" v-model="newBlueprintDescription" maxlength="1023"></textarea>
        </fieldset>
        <fieldset class="detail-form form-entry">
          <span>Product</span>
          <k-dropdown
            v-model="newBlueprintProductID"
            :options="products"
          ></k-dropdown>
        </fieldset>
        <fieldset class="detail-form form-entry">
          <label for="programme-name-input" class="pwd-view-switch">
            <input type="checkbox" v-model="newBlueprintIncludeInReport">
            Include in reporting? If programme is included in reporting, learner data will be added to the Tableau dashboards
          </label>
        </fieldset>
        <fieldset class="detail-form form-entry">
          <span>Badges</span>
            <achievement-list v-if="selectedBadges.length > 0"
                              :achievements="selectedBadges"
            ></achievement-list>
            <p v-else>
              There are currently no badges in this programme - click the button below to add some
            </p>
            <button class="btn btn-primary" @click="showBadgeModal = true">Add Badge</button>
          <!-- NB: Modal needs to be outside label for opening / closing to work correctly -->
          <select-badge-modal :show="showBadgeModal"
                              @close="showBadgeModal = false"
                              @choose="addBadge"
          ></select-badge-modal>
        </fieldset>
        <fieldset class="detail-form form-entry">
          <span>Certificates</span>
            <achievement-list v-if="certificateToBeAwarded"
                              :achievements="certificateToBeAwarded"
            ></achievement-list>
            <p v-else>
              There is currently no certificate to be awarded on completion of this blueprint - click the button below to select one
            </p>
            <button class="btn btn-primary" @click="showCertificateModal = true">{{ certificateBtnText }}</button>
          <!-- NB: Modal needs to be outside label for opening / closing to work correctly -->
          <select-certificate-modal
            :show="showCertificateModal"
            @close="showCertificateModal = false"
            @choose="selectCertificate"
          ></select-certificate-modal>
        </fieldset>
        <button class="btn btn-success" @click="createEmptyProgrammeBlueprint" :disabled="!validName || !ready">Create</button>
      </template>
    </k-blueprint>
  </div>
</template>

<style>
td.k-table-key-delete {
  text-align: center;
}

td.k-table-key-delete i.fas.fa-trash-alt {
  color: var(--kate-danger);
}
</style>

<style scoped>
.btn.btn-success {
  float: right;
}
</style>

<script>
import ErrorMixin from '../../../mixins/error-mixins';
import TimeMixin from '../../../mixins/time-mixins';
import PageReadyMixin from '../../../mixins/page-ready-mixin';
import AbstractBlueprint from './abstract-blueprints-over.vue';
import AchievementList from '../../achievements/achievement-list.vue';
import SelectBadgeModal from '../../achievements/select-badge-modal.vue';
import KDropdown from '../../../components/k-dropdown.vue';
import SelectCertificateModal from '../../achievements/select-certificate-modal.vue';

export default {
  components: {
    SelectCertificateModal,
    'k-blueprint': AbstractBlueprint,
    'k-dropdown': KDropdown,
    AchievementList,
    SelectBadgeModal,
  },

  mixins: [ErrorMixin, TimeMixin, PageReadyMixin],

  data() {
    return {
      programmeBlueprints: [],
      programmeBlueprintsReady: false,
      updateInProgress: false,
      newBlueprintDescription: '',
      newBlueprintProductID: undefined,
      newBlueprintIncludeInReport: false,
      products: [],
      newBlueprintName: '',
      confirmDeletionToast: undefined,
      showBadgeModal: false,
      showCertificateModal: false,
      selectedBadges: [],
      certificateToBeAwarded: undefined,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgrammeBlueprints();
    this.getProducts();
  },

  computed: {
    ready() {
      return this.programmeBlueprintsReady && !this.updateInProgress;
    },
    kableHeaders() {
      return {
        name: {
          name: 'Name',
          filterable: true,
          sortable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
          filterable: true,
          sortable: true,
        },
        includeInReport: {
          name: 'Include in report',
        },
        productID: {
          name: 'Product',
        },
        knowledgeBase: {
          name: 'Knowledge Base',
          type: 'action',
          sortable: true,
        },
        kloud: {
          name: 'KLOUD',
          type: 'action',
          sortable: true,
        },
        delete: {
          name: 'Delete',
          type: 'action',
        },
      };
    },
    kableRows() {
      return this.programmeBlueprints.map((val, index) => ({
        programmeId: val.id,
        name: {
          text: val.name,
          path: { name: 'curriculum_manage_programme_blueprint', params: { programmeBlueprintId: val.id } },
        },
        description: val.description,
        includeInReport: val.include_in_reporting ? 'yes' : 'no',
        productID: this.productName(val.product_id),
        knowledgeBase: {
          key: index,
          enabled: val.enable_kb,
          text: this.enabledIcon(val.enable_kb, 'Knowledge Base'),
        },
        kloud: {
          key: index,
          enabled: val.enable_kloud,
          text: this.enabledIcon(val.enable_kloud, 'KLOUD'),
        },
        delete: {
          key: index,
          text: '<i class="fas fa-trash-alt"></i>',
        },
      }));
    },
    validName() {
      return Boolean(this.newBlueprintName.trim().length > 0 && this.newBlueprintName.trim().length < 256);
    },
    certificateBtnText() {
      return this.certificateToBeAwarded ? 'Change Certificate' : 'Add Certificate';
    },
  },

  methods: {
    createEmptyProgrammeBlueprint() {
      const updatePayload = {
        name: this.newBlueprintName.trim(),
        description: this.newBlueprintDescription || null,
        product_id: this.newBlueprintProductID || null,
        include_in_reporting: this.newBlueprintIncludeInReport,
        module_blueprints: [],
        badge_ids: this.selectedBadges.map(badge => badge.id),
        certificate_id: this.certificateToBeAwarded ? this.certificateToBeAwarded[0].id : null,
      };
      this.$logger.info('Creating empty programme blueprint', { updatePayload });
      this.updateInProgress = true;
      this.$http.post('/api/curriculum/blueprints/programmes', updatePayload).then(() => {
        this.$logger.info('Successfully updated blueprint', { updatePayload });
        this.$ktoast.success('Blueprint created');
        this.newBlueprintName = '';
        this.newBlueprintDescription = '';
        this.getProgrammeBlueprints();
      }).catch(err => {
        this.showError(err);
        this.$logger.error('Error creating blueprint', { updatePayload }, err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    getProgrammeBlueprints() {
      this.$logger.info('Getting programme blueprints');
      this.programmeBlueprintsReady = false;
      return this.$http.get('/api/curriculum/blueprints/programmes').then(res => {
        this.programmeBlueprints = res.data.blueprints;
        this.$logger.info('Successfully retrieved programme blueprints');
      }).catch(err => {
        this.$logger.error('Error retrieving programmes', undefined, err);
        this.showError(err);
      }).then(() => {
        this.programmeBlueprintsReady = true;
      });
    },
    confirmDeletion(programmeBlueprintToDelete) {
      this.$ktoast.confirmToast(
        `You are about to delete the programme blueprint ${programmeBlueprintToDelete.name}?`,
        'warning',
        () => {
          this.deleteProgrammeBlueprint(programmeBlueprintToDelete);
        },
      );
    },
    deleteProgrammeBlueprint(programmeBlueprintToDelete) {
      this.$logger.info('Deleting programme blueprint', { programmeBlueprintId: programmeBlueprintToDelete.id });
      this.updateInProgress = true;
      return this.$http.delete(`/api/curriculum/blueprints/programmes/${programmeBlueprintToDelete.id}`).then(() => {
        this.$logger.info('Successfully deleted programme blueprint');
        this.$ktoast.success('Blueprint deleted');
        this.getProgrammeBlueprints();
      }).catch(err => {
        this.$logger.error('Error deleting programme blueprint', undefined, err);
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    tableCallback(key, index, row, header) {
      if (header === 'delete') {
        this.confirmDeletion(this.programmeBlueprints[index]);
      } else if (header === 'kloud' || header === 'knowledgeBase') {
        this.tableFeatureEnableCallback(row, header);
      }
    },
    enabledIcon(enabled, title) {
      if (enabled) {
        return `<div title="${title} enabled for programme. Click to disable." style="text-align: center;"><button class="btn btn-danger" title="Click to disable ${title} for programme">Disable</button></div>`;
      }
      return `<div title="${title} disabled for programme. Click to enable." style="text-align: center;"><button class="btn btn-success" title="Click to enable ${title} for programme">Enable</button></div>`;
    },
    getProducts() {
      this.$logger.info('Getting Product IDs');
      return this.$http.get('/api/curriculum/products').then(res => {
        this.products = res.data;
      }).catch(err => {
        this.$logger.error('Getting Product IDS for New Programme Blueprints', undefined, err);
        this.showError(err);
      });
    },
    productName(prodId) {
      const prodIndex = this.products.findIndex(x => x.id === prodId);
      if (prodIndex < 0 || !prodId) {
        return null;
      }
      return this.products[prodIndex].name;
    },

    getProgrammeFeatureEnableToastMessage(row, featureEnabled) {
      const enabled = row[featureEnabled].enabled;
      const featureEnabledReadable = featureEnabled === 'kloud' ? 'KLOUD' : 'Knowledge Base';
      if (enabled) {
        return `${featureEnabledReadable} will be disabled for every module and assignment.`;
      }
      return `${featureEnabledReadable} will be enabled for every module and assignment.`;
    },

    enableFeatureInProgramme(programmeId, featureEnabled, enabled) {
      const enablePref = enabled ? 'dis' : 'en';
      const featureEnabledURL = featureEnabled === 'knowledgeBase' ? 'knowledge-base' : featureEnabled;
      const featureEnabledReadable = featureEnabled === 'kloud' ? 'KLOUD' : 'Knowledge Base';
      this.$logger.info(`${enablePref}abling ${featureEnabledReadable} for programme`, { programmeId });
      return this.$http.put(
        `/api/curriculum/blueprints/programmes/${programmeId}/${enablePref}able-${featureEnabledURL}`,
      ).then(() => {
        this.$logger.info(`${enablePref}abled ${featureEnabledReadable} for programme`, { programmeId });
        this.$ktoast.success(`${featureEnabledReadable} ${enablePref}abled`);
        this.getProgrammeBlueprints();
      }).catch(err => {
        this.$logger.error(`Error ${enablePref}abling ${featureEnabledReadable} for programme`, { programmeId }, err);
        this.showError(err);
      });
    },

    toastFeatureEnableCallback(row, featureEnabled) {
      const progId = row.programmeId;
      const enabled = row[featureEnabled].enabled;
      this.enableFeatureInProgramme(progId, featureEnabled, enabled);
    },

    tableFeatureEnableCallback(row, featureEnabled) {
      this.$ktoast.confirmToast(
        this.getProgrammeFeatureEnableToastMessage(row, featureEnabled),
        'warning',
        () => {
          this.toastFeatureEnableCallback(row, featureEnabled);
        },
      );
    },
    addBadge(badge) {
      if (!this.selectedBadges.find(b => b.id === badge.id)) {
        this.selectedBadges.push(badge);
      }
    },
    selectCertificate(certificate) {
      this.certificateToBeAwarded = [certificate];
    },
  },
};
</script>
