<template>
  <div class="content-with-spacing">
    <section class="row profile-section">
      <h2><i class="fas fa-code-branch"></i> Your Learning Environments</h2>
      <div class="col-md-12 col-xs-12" v-if="!ready">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
      <div class="col-md-12 col-xs-12" v-else-if="kods.length">
          <kod-row
            v-for="kod in kods"
            :key="kod.metadata.name"
            :labels="kod.metadata.labels"
            :name="kod.metadata.name"
            :createdAt="getDate(kod.metadata.creationTimestamp)"
            >
          </kod-row>
      </div>
      <empty-placeholder v-else title="Not Found" fa-icon="fas fa-cloud" info="You have no running environments"></empty-placeholder>
    </section>

  </div>
</template>

<script>
import KodRow from './kod-row.vue';
import PageReadyMixin from '../mixins/page-ready-mixin';
import EmptyPlaceholder from '../components/empty-placeholder.vue';
import TimeMixin from '../mixins/time-mixins';
import ErrorMixin from '../mixins/error-mixins';

export default {
  components: {
    KodRow,
    EmptyPlaceholder,
  },
  mixins: [PageReadyMixin, TimeMixin, ErrorMixin],
  data() {
    return {
      ready: false,
      creationInProgress: false,
      kods: [],
      interval: null,
      currentlyWaiting: null,
    };
  },
  beforeMount() {
    this.getCurrentlyRunningKods();
    this.interval = setInterval(this.getCurrentlyRunningKods, 10000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getCurrentlyRunningKods() {
      this.$http.get('/api/curriculum/kods').then(res => {
        this.kods = res.data.kods;
      }).catch(err => {
        this.$logger.autowarn('Error getting kods for user', undefined, err);
      }).then(() => {
        this.ready = true;
      });
    },
  },
};
</script>
