<template>
  <k-modal class="update-privileged-user-modal" v-if="privilegedUser"
    :show="showModal"
    :closeOnClickAway="false"
    >
    <template #header>
      <h3><i class="fas fa-edit"></i>Edit Roles - {{privilegedUser.full_name}}</h3>
    </template>
    <template #body>
      <div  class="update-privileged-user-form-container">
        <div class="update-privileged-user-roles-form-container">
          <div>
            <h2>Add Roles</h2>
            <div class="roles-checklist">
              <ul class="checkbox-list">
                <li v-for="role in availableRoles" :key="role.id">
                  <label class="k-custom-checkbox">
                    <input type="checkbox" class="hidden-checkbox" :value="role.name" v-model="newRoles" required>
                    <span class="k-custom-checkbox-box light"></span>
                    <k-tooltip :text="role.description" position="right">
                      <span class="k-custom-checkbox-text">{{ role.name }}</span>
                    </k-tooltip>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div class="existing-roles">
            <h2>Existing Roles</h2>
            <k-table
              :rows="kableRows"
              :headers="kableHeaders"
              :hideable="false"
              @clicked="tableCallback"
              :panel="false"
            ></k-table>
          </div>
        </div>
        <button
            v-if="newRoles.length > 0"
            @click="updatePrivilegedUser"
            class="btn btn-success send-btn"
        >Add Roles</button>
        <k-user-restricted-access v-if="hasProgrammeRestrictedRole"
          :user="privilegedUser"
          :items="programmes"
          title="Programmes"
          dropdownPlaceholder="Add Programmes"
          buttonText="Grant programme access"
          message="This user has a restricted programme role and must be granted individual access to view each programmes content and learners"
          @remove-item="removeProgramme"
          @update-items="addProgrammes"
          ref="progTable"
        ></k-user-restricted-access>
        <k-user-restricted-access v-if="hasEmployerRestrictedRole"
          :user="privilegedUser"
          :items="employers"
          title="Employers"
          dropdownPlaceholder="Add Employers"
          buttonText="Grant employer access"
          message="This user has an employer role and must be granted individual access to view each learners for specific employers"
          @remove-item="removeEmployer"
          @update-items="addEmployers"
          ref="empTable"
        ></k-user-restricted-access>
      </div>
    </template>
    <template #footer>
      <button class="btn btn-danger" @click.prevent="$emit('close')">Close</button>
    </template>
  </k-modal>
</template>

<style>
.update-privileged-user-modal .modal-body {
  height: 80%;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.update-privileged-user-modal .k-word-tags > form > button {
  height: 38px;
}

.update-privileged-user-modal .k-table-container .k-table-panel {
  padding-bottom: 5px;
}

.k-custom-checkbox-text {
  padding-right: 0.3em;
}

.update-privileged-user-modal tr.pending {
  background-color: var(--kate-8) !important;
}

</style>

<style scoped>
.update-privileged-user-form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 15px;
}

.update-privileged-user-roles-form-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-bottom: 15px;
  align-items: stretch;
}

.update-privileged-user-roles-form-container form {
  margin-left: -15px;
}

.existing-roles {
  flex: 1;
  margin-left: 30px;
}
</style>

<script>
import ErrorMixin from '../../mixins/error-mixins';
import KModal from '../../components/k-modal.vue';
import KTable from '../../components/k-table.vue';
import KTooltip from '../../components/k-tooltip.vue';
import KUserRestrictedAccess from './k-user-restricted-access.vue';

export default {
  components: {
    'k-modal': KModal,
    'k-table': KTable,
    'k-tooltip': KTooltip,
    'k-user-restricted-access': KUserRestrictedAccess,
  },

  mixins: [ErrorMixin],

  props: {
    privilegedUser: {
      type: Object,
    },
    roles: {
      type: Array,
    },
    programmes: {
      type: Array,
    },
    employers: {
      type: Array,
    },
  },

  data() {
    return {
      newRoles: [],
    };
  },

  computed: {
    kableHeaders() {
      return {
        role: {
          name: 'Role',
        },
        delete: {
          name: 'Delete',
          type: 'action',
        },
      };
    },
    kableRows() {
      return this.showModal ? this.privilegedUser.roles.map((val, index) => ({
        id: val.id,
        role: val.role.replaceAll('_', ' '),
        delete: {
          key: index,
          text: '<div style="text-align: center; color: var(--kate-danger-alt)"><i class="fas fa-minus-circle"></i></div>',
        },
      })) : [];
    },
    showModal() {
      return Object.keys(this.privilegedUser).length > 0;
    },
    availableRoles() {
      if (this.privilegedUser && this.privilegedUser.roles) {
        const currentRoles = this.privilegedUser.roles.map(x => x.id);
        const available = this.roles.filter(x => currentRoles.indexOf(x.id) === -1);
        return available.map(x => ({ ...x, name: x.name.replaceAll('_', ' ') }));
      }
      return [];
    },
    hasProgrammeRestrictedRole() {
      const existingRoles = this.privilegedUser.roles?.map(x => x.role) || [];
      return existingRoles.includes('content_restricted') || existingRoles.includes('monitoring_restricted');
    },
    hasEmployerRestrictedRole() {
      const existingRoles = this.privilegedUser.roles?.map(x => x.role) || [];
      return existingRoles.includes('employer');
    },
  },

  methods: {
    reset() {
      if (this.$refs.progTable) {
        this.$refs.progTable.reset();
      }
      if (this.$refs.empTable) {
        this.$refs.empTable.reset();
      }
    },
    confirmDeleteUserRole(payload, deletionCallback) {
      this.$ktoast.confirmToast('Are you sure you want to remove this role from this user?', 'warning', () => deletionCallback(payload), undefined);
    },
    tableCallback(key, index, row, column) {
      if (column === 'delete') {
        this.confirmDeleteUserRole(row.id, this.deleteRole);
      }
    },
    addRole(id) {
      this.$logger.info('Adding role to user', { privilegedUserId: this.privilegedUser.id, roleId: id });
      return this.$http.put(`/api/profile/user/${this.privilegedUser.id}/role/${id}`);
    },
    updatePrivilegedUser() {
      this.$logger.info('Adding roles to user');
      const calls = [];
      const roles = this.newRoles.map(x => x.replaceAll(' ', '_'));
      const roleIds = this.roles.filter(x => roles.indexOf(x.name) !== -1).map(x => x.id);
      for (let i = 0; i < roleIds.length; i++) {
        calls.push(this.addRole(roleIds[i]));
      }
      Promise.all(calls).then(() => {
        this.$logger.info('Successfully added roles to user', { userId: this.privilegedUser.id }, true);
        this.$ktoast.success('Success');
        this.$emit('privileged-user-updated');
        this.newRoles = [];
      }).catch(err => {
        this.$logger.error('Error adding roles to user');
        this.showError(err);
      });
    },
    deleteRole(id) {
      this.$logger.info('Removing role from user', { privilegedUserId: this.privilegedUser.id, roleId: id });
      this.$http.delete(`/api/profile/user/${this.privilegedUser.id}/role/${id}`).then(() => {
        this.$logger.info('Successfully removed role from user', { privilegedUserId: this.privilegedUser.id }, true);
        this.$ktoast.success('Role removed from user', { goAway: 2000 });
        this.$emit('privileged-user-updated');
      }).catch(err => {
        this.showError(err);
        this.$logger.autowarn('Error removing role from user', { privilegedUserId: this.privilegedUser.id, roleId: id }, err);
      });
    },
    removeEmployer(employerId) {
      this.$http.delete(`/api/profile/employer/monitoring/${this.privilegedUser.id}/${employerId}`)
        .then(() => {
          this.$logger.info('Successfully removed Employer access from user', { privilegedUserId: this.privilegedUser.id }, true);
          this.$emit('privileged-user-updated');
        }).catch(err => {
          this.showError(err);
          this.$logger.autowarn('Error removing employer access from user', { privilegedUserId: this.privilegedUser.id, employerId });
        });
    },
    removeProgramme(programmeId) {
      this.$http.delete(`/api/profile/user/${this.privilegedUser.id}/programme/access/${programmeId}`)
        .then(() => {
          this.$logger.info('Successfully removed programmes access from user', { privilegedUserId: this.privilegedUser.id }, true);
          this.$emit('privileged-user-updated');
        }).catch(err => {
          this.showError(err);
          this.$logger.autowarn('Error removing programme access from user', { privilegedUserId: this.privilegedUser.id, programmeId });
        });
    },
    addEmployers(employerIds) {
      const calls = employerIds.map(employerId => this.$http.post('/api/profile/employer/monitoring', { user_id: this.privilegedUser.id, employer_id: employerId }));
      Promise.all(calls).then(() => {
        this.$logger.info('Success fully added employers to user', { userId: this.privilegedUser.id }, true);
        this.$ktoast.success('Success', { goAaway: 2000 });
        this.$emit('privileged-user-updated');
      }).catch(err => {
        this.showError(err);
        this.$logger.autowarn('Error while adding employers to user', { privilegedUserId: this.privilegedUser.id });
      });
    },
    addProgrammes(programmeIds) {
      const calls = programmeIds.map(programmeId => this.$http.put(`/api/profile/user/${this.privilegedUser.id}/programme/access/${programmeId}`));
      Promise.all(calls).then(() => {
        this.$logger.info('Success fully added programmes to user', { userId: this.privilegedUser.id }, true);
        this.$ktoast.success('Success', { goAaway: 2000 });
        this.$emit('privileged-user-updated');
      }).catch(err => {
        this.showError(err);
        this.$logger.autowarn('Error while adding programmes to user', { privilegedUserId: this.privilegedUser.id });
      });
    },
  },
};
</script>
