const TRUNCATE_LENGTH = 180;

export default {
  methods: {
    getTruncatedModuleDescription(module) {
      if (module.short_description) {
        return module.short_description;
      }
      if (!module.description) {
        return null;
      }
      let desc = module.description.slice(0, TRUNCATE_LENGTH);
      const ellipsis = '...';
      if (module.description.length > (TRUNCATE_LENGTH - ellipsis.length)) {
        desc += ellipsis;
      }
      return desc;
    },
  },
};
