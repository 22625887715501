function nestedFiles(files, cwd, depth) {
  // The files are an array of arrays
  // Inner array is the path
  // so [[somefolder, somefile.py]] is 'somefolder/somefile.py'
  let currentDepth;
  // This is the first depth
  if (depth === undefined) {
    currentDepth = 0;
  } else {
  // We've been here before
    currentDepth = depth + 1;
  }
  const output = {
    files: [], // array of strings
    folders: [], // Array of objects (Same structure)
    cwd, // The current folder
  };
  // If there's only one item in the array, it's a file
  const singleFiles = files.filter(file => file.length === 1);
  output.files = singleFiles.length ? singleFiles.reduce((acc, val) => acc.concat(val)) : [];
  // Else, its a folder
  const subdirs = files.filter(file => file.length > 1);
  let folderName;
  // Where folders will be
  const folderMappings = {};
  for (let i = 0; i < subdirs.length; i++) {
    folderName = subdirs[i].shift(); // Pops the first item of the array, this is the folder
    if (folderMappings[folderName] === undefined) { // Have we seen the folder before ?
      folderMappings[folderName] = []; // if not, insert it into the mappings
    }
    folderMappings[folderName].push(subdirs[i]); // Adds the rest of the array to the golder mappings
  }
  const folders = Object.keys(folderMappings); // Gets a list of folders in this depth
  let currentFolder;
  for (let i = 0; i < folders.length; i++) {
    currentFolder = folders[i];
    // Recursive repeat until crash or no folders remaining
    output.folders.push(nestedFiles(folderMappings[currentFolder], currentFolder, currentDepth));
  }
  return output;
}

// export default nestedFiles;
export const getNestedFiles = (files, cwd, depth) => nestedFiles(files, cwd, depth);// eslint-disable-line import/prefer-default-export
