export function copyToClipboard(str) { // eslint-disable-line import/prefer-default-export
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str);
    return;
  }
  // Clipboard API not available in browser
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}
