<template>
  <div>
    <div id="k-editor-container">
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="crash-row panel panel-default instruction-panel" v-if="crashed">
            <i class="far fa-frown"></i>
            <div>{{error}}</div>
          </div>
          <div class="toolbar-row" v-if="ideReady">
            <div class="submission-button">
              <button class="btn toggle-theme-btn" :class="themeButtonClass" aria-label="Toggle theme" title="Toggle theme" @click="toggleTheme" v-html="themeButtonText"></button>
              <div v-if="!isSolution && !isDashboard" class="submit-btn-container">
                <k-tooltip :text="disableButton ? 'No changes made' : ''">
                  <button :disabled="disableButton" class="btn btn-success submit-button" @click="onSubmit">Submit <i class="fas fa-arrow-circle-up"></i></button>
                </k-tooltip>
              </div>
            </div>
          </div>
          <div class="ide-row">
            <splitpanes class="custom-theme">
              <pane min-size="2.5" max-size="30" :size="40" class="cm-editor" :class="`cm-s-${currentThemeName}`" id="tree-container">
              <tree-sidebar
                  @fileclicked="fileClicked"
                  :tree="refinedTree"
                  :current-filename="currentFilename"
                  :class="sidebarThemeClass"
                  :changed-files="changedFiles"></tree-sidebar>
              </pane>
              <pane :size="100" min-size="20" id="editor-container">
                <k-editor ref="editor" :theme="currentThemeName" :read-only="readOnly" @docchange="onChange" @init="initialise" :filename="currentFilename"></k-editor>
              </pane>
            </splitpanes>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
/* Hard coding colours is bad, kids */
/* Don't do this */
.dark-theme-editor {
  background-color: #2e3235;
}

.light-theme-editor {
  background-color: #fafafa;
}
/* Code mirror's own classes */
/* stylelint-disable-next-line */
#editor-container .cm-editor {
  height: 60vh;
}

#k-editor-container .ide-row {
  overflow: hidden;
  display: flex;
}

#k-editor-container {
  margin-top: 2em;
}

#editor-container {
  min-width: 200px;
  min-height: 200px;
}

.k-toast-submission-success.submited {
  margin: 0 auto;
}

.error-action-button {
  white-space: nowrap;
}
</style>

<style scoped>
.panel-body {
  position: relative;
}

.project-name {
  text-transform: uppercase;
  font-size: 2em;
  font-weight: bold;
  white-space: nowrap;
}

#tree-container {
  height: 60vh;
}

.submit-btn-container {
  position: relative;
}

.submission-button {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-bottom: 1em;
}

.submit-button {
  margin-left: 1em;
}

.crash-row {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  border-radius: 4px;
  background-color: var(--kate-background-alt) !important;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
}

.crash-row > div {
  font-size: 1.5em;
}

.crash-row > i {
  font-size: 2.7em;
  padding: 0 0 10px;
  color: var(--kate-danger-light);
}
</style>

<script>
import { Splitpanes, Pane } from 'splitpanes';
import KEditor from './k-editor.vue';
import TreeSidebar from './tree-sidebar.vue';
import { getNestedFiles } from '../modules/nested-files';
import StorageMixin from '../mixins/storage-mixins';
import ErrorMixin from '../mixins/error-mixins';
import PageReadyMixin from '../mixins/page-ready-mixin';
import PakEndpointMixin from '../mixins/pak-endpoint-mixin';
import getOrNull from '../modules/get-or-null';
import KTooltip from '../components/k-tooltip.vue';

const ACCEPTABLE_FORMATS = [
  'java',
  'py',
  'cpp',
];

const AVAILABLE_THEMES = [
  'light',
  'dark',
];

const CODE_MIRROR_THEME_MAP = {
  dark: 'dark-theme-editor',
  light: 'light-theme-editor',
};

export default {
  props: {
    isSolution: {
      type: Boolean,
      default: false,
    },
    isDashboard: {
      type: Boolean,
      default: false,
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
    studentId: {
      type: Number,
    },
    projectMeta: {
      type: Object,
      required: true,
    },
    enableCache: {
      type: Boolean,
      default: false,
    },
    isResetting: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    KEditor,
    TreeSidebar,
    Splitpanes,
    Pane,
    KTooltip,
  },

  mixins: [StorageMixin, ErrorMixin, PageReadyMixin, PakEndpointMixin],

  data() {
    return {
      error: undefined,
      hashReady: true,
      treeReady: false,
      fileReady: false,
      editorInitialised: false,
      submitting: false,
      crashed: false,
      branch: 'master',
      fileContent: {},
      fileContentBackup: {},
      changedFiles: [],
      currentFilename: undefined,
      tree: [],
      commitHash: null,
      darkTheme: true,
      paneSize: 20,
    };
  },

  beforeRouteLeave(to, from, next) {
    this.beforeUnloadRouterHandler(to, from, next);
  },

  beforeMount() {
    if (this.isSolution && !this.hasAccessToSolution) {
      this.$logger.warn('Attempted to access unreleased solution - route to 404', { modulePakId: this.modulePakId });
      this.$router.push('/404');
    }
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
    this.$Loading.start();
  },

  beforeUnmount() {
    this.editorInitialised = false;
    if (!this.isResetting) {
      this.backupToCache();
    }
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  },

  watch: {
    darkTheme(theme) {
      this.saveSetting('ide', 'darkTheme', theme);
    },
    metaReadyAndEditorInitialised() {
      this.refresh();
    },
    submitting(val) {
      if (val) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },

  computed: {
    sidebarThemeClass() {
      return this.darkTheme ? CODE_MIRROR_THEME_MAP.dark : CODE_MIRROR_THEME_MAP.light;
    },
    modulePakId() {
      return this.projectMeta ? this.projectMeta.id : undefined;
    },
    loading() {
      return !this.hashReady || !this.treeReady || !this.fileReady;
    },
    ready() {
      return !this.loading;
    },
    cacheKey() {
      return `k-ide-${this.modulePakId}`;
    },
    metaReadyAndEditorInitialised() {
      return this.projectMeta !== undefined && this.editorInitialised;
    },
    projectTitle() {
      if (!this.projectMeta) {
        return this.project;
      }
      return this.projectMeta.name || this.project;
    },
    hasAccessToSolution() {
      if (this.projectMeta) {
        return this.projectMeta.solution_released || this.$permissions.hasPermission('view_solutions_before_release');
      }
      return false;
    },
    readOnly() {
      return this.isDashboard || this.isSolution || this.isExpired || this.loading;
    },
    isSubmittable() {
      return this.hasDiffs && !this.isSolution && !this.isDashboard && !this.isExpired;
    },
    disableButton() {
      return this.loading || !this.isSubmittable;
    },
    currentThemeName() {
      return AVAILABLE_THEMES[Number(this.darkTheme)];
    },
    themeButtonClass() {
      return this.darkTheme ? 'btn-default' : 'btn-primary';
    },
    themeButtonText() {
      const theme = this.darkTheme ? 'Light' : 'Dark';
      return `<i class="fas fa-adjust"></i> ${theme}`;
    },
    project() {
      return this.$route.params.projectName;
    },
    ideReady() {
      return !this.crashed && this.treeReady;
    },
    validFiles() {
      if (!this.tree) {
        return [];
      }
      return this.tree.filter(file => file.type !== 'tree'
        && !file.name.startsWith('.')
        && !file.path.startsWith('.')
        && ACCEPTABLE_FORMATS.indexOf(this.getFileExtension(file.name)) !== -1);
    },
    refinedTree() {
      const files = this.validFiles.map(file => file.path.split('/'));
      return getNestedFiles(files, '.');
    },
    hasDiffs() {
      return this.changedFiles.length > 0;
    },
  },

  methods: {
    initialise() {
      this.$logger.info('Initialised editor', { modulePakId: this.modulePakId });
      this.editorInitialised = true;
      this.loadTheme();
    },
    beforeUnloadRouterHandler(to, from, next) {
      if (!this.isResetting) {
        this.backupToCache();
        if (this.hasDiffs) {
          if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
            next(true);
          } else {
            next(false);
          }
        } else {
          next(true);
        }
      } else {
        next(true);
      }
    },
    beforeUnloadHandler(ev) {
      if (!this.isResetting) {
        this.backupToCache();
        if (this.hasDiffs) {
          ev.preventDefault();
          ev.returnValue = 'You have unsaved changes. Are you sure you want to leave?'; // eslint-disable-line no-param-reassign
        }
      }
    },
    addToChanged(filepath) {
      if (this.changedFiles.indexOf(filepath) === -1) {
        this.changedFiles.push(filepath);
      }
    },
    removeFromChanged(filepath) {
      if (this.changedFiles.indexOf(filepath) > -1) {
        this.changedFiles.splice(this.changedFiles.indexOf(filepath), 1);
      }
    },
    backupToCache() {
      this.$logger.info('Backing up changes to cache', { modulePakId: this.modulePakId });
      if (!this.enableCache || this.cacheKey === undefined) {
        return;
      }
      const payload = {
        currentFilename: this.currentFilename,
        commitHash: this.commitHash,
        fileContent: this.fileContent,
        fileContentBackup: this.fileContentBackup,
        changedFiles: this.changedFiles,
        tree: this.tree,
        repoId: this.modulePakId,
      };
      this.$cache.set(this.cacheKey, payload, 60 * 60 * 24);
    },
    isValidFile(filename) {
      return (!filename.startsWith('.')
        && ACCEPTABLE_FORMATS.indexOf(this.getFileExtension(filename)) !== -1);
    },
    loadEditorData() {
      this.$logger.info('Loading editor data', { modulePakId: this.modulePakId });
      const payload = this.$cache.get(this.cacheKey);
      if (!payload || payload.commitHash !== this.commitHash) {
        this.$logger.info('Invalid cache, redownloading data', { modulePakId: this.modulePakId });
        this.downloadTree();
        return;
      }
      this.$logger.info('Cache found, loading from cache', { modulePakId: this.modulePakId });
      const keys = [
        'currentFilename', 'commitHash', 'fileContent',
        'fileContentBackup', 'changedFiles', 'tree',
      ];
      for (let i = 0; i < keys.length; i++) {
        if (!payload[keys[i]]) {
          this.downloadTree();
          return;
        }
      }
      // cache messed up, reset
      if (!this.isValidFile(payload.currentFilename)) {
        this.downloadTree();
        return;
      }
      this.commitHash = payload.commitHash;
      this.fileContent = payload.fileContent;
      this.fileContentBackup = payload.fileContentBackup;
      this.changedFiles = payload.changedFiles;
      this.tree = payload.tree;
      this.treeReady = true;
      this.loadFile(payload.currentFilename);
      this.$logger.info('Loaded data from cache', { modulePakId: this.modulePakId }, this.cacheKey);
    },
    onChange() {
      this.stageFile();
      if (this.fileContent[this.currentFilename] !== this.fileContentBackup[this.currentFilename]) {
        this.addToChanged(this.currentFilename);
      } else {
        this.removeFromChanged(this.currentFilename);
      }
    },
    toggleTheme() {
      this.darkTheme = !this.darkTheme;
    },
    loadTheme() {
      const darkTheme = this.loadSetting('ide', 'darkTheme');
      if (darkTheme !== undefined) {
        this.darkTheme = darkTheme;
      }
    },
    getLatestCommitHash() {
      this.$logger.info('Getting latest commit hash', { modulePakId: this.modulePakId });
      this.hashReady = false;
      this.$http.get(`/api/events/commits/${this.modulePakId}`).then(res => {
        this.commitHash = res.data.commit_hash;
        this.$logger.info('Got latest commit hash', { modulePakId: this.modulePakId });
        this.loadEditorData();
      }).catch(this.crash).then(() => {
        this.hashReady = true;
      });
    },
    refresh() {
      this.crashed = false;
      if (this.enableCache) {
        this.getLatestCommitHash();
      } else {
        this.downloadTree();
      }
    },
    showSubmissionPopup() {
      this.$ktoast.success('Submitted. To see your results, click the tab marked', {
        goAway: 5000,
        className: 'k-toast-submission-success submited align-center',
        position: 'top-center',
        singleton: true,
        keepOnHover: true,
        action: [{
          class: 'feedback-link btn-primary',
          text: 'Feedback',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
            this.$router.push({ name: 'pak_ov_feedback' });
          },
        }],
      });
    },
    stageFile() {
      // Saves whatevers open in the editor
      if (this.currentFilename !== undefined) {
        this.fileContent[this.currentFilename] = this.getFile();
      }
    },
    fileClicked(filename) {
      this.stageFile();
      this.loadFile(filename);
    },
    getFileExtension(filename) {
      // Really simple, will return the whole file if no extension
      // And will turn up in valid files if that file is called 'py'
      return filename.split('.').pop();
    },
    crash(err) {
      this.showError(err, true);
      this.error = err;
      this.crashed = true;
    },
    getFile() {
      if (!this.editorInitialised) {
        return undefined;
      }
      return this.$refs.editor.getValue();
    },
    openFile(filename) {
      this.$logger.info('Loaded file into editor', { modulePakId: this.modulePakId, filename });
      this.currentFilename = filename;
      if (filename !== undefined && this.editorInitialised) {
        this.fileReady = true;
        this.$refs.editor.setValue(this.fileContent[filename]);
      }
    },
    loadFile(filename) {
      this.$logger.info('Loading file into editor', { modulePakId: this.modulePakId, filename });
      if (this.fileContent[filename] === undefined) {
        this.downloadFile(filename);
      } else {
        this.openFile(filename);
        if (this.editorInitialised) {
          this.$refs.editor.resetHistory();
        }
      }
    },
    getFirstFile() {
      if (this.validFiles.length < 1) {
        this.crash('Could not find any files compatible with the IDE');
        this.fileReady = true; // stop loading
        return;
      }
      const mdFiles = this.validFiles.filter(x => x.path === 'README.md');
      let filename;
      if (mdFiles.length) {
        filename = mdFiles[0].path;
      } else {
        filename = this.validFiles[0].path;
      }
      if (filename !== undefined) {
        this.stageFile();
        this.loadFile(filename);
      }
    },
    downloadTree() {
      this.$logger.info('Getting repo tree', { modulePakId: this.modulePakId });
      this.treeReady = false;
      this.$http.get(`${this.pakDownloadEndpoint}/tree`).then(response => {
        this.tree = response.data.tree;
        this.treeReady = true;
        this.$logger.info('Got repo tree', { modulePakId: this.modulePakId });
        this.getFirstFile();
      }).catch(err => {
        this.$logger.error('Error getting repo tree', { modulePakId: this.modulePakId }, err);
        this.crash(err);
      }).then(() => {
        this.treeReady = true;
      });
    },
    downloadFile(filename) {
      this.$logger.info('Downloading file', { modulePakId: this.modulePakId, filename });
      this.fileReady = false;
      this.$http.get(`${this.pakDownloadEndpoint}/file_content?path=${encodeURIComponent(filename)}`).then(response => {
        this.fileContentBackup[filename] = response.data.file_content;
        this.fileContent[filename] = response.data.file_content;
        this.$logger.info('Downloaded file', { modulePakId: this.modulePakId, filename });
        this.loadFile(filename);
      }).catch(err => {
        this.$logger.info('Error downloading file', { modulePakId: this.modulePakId, filename }, err);
        this.showError(err, true);
      }).then(() => {
        this.fileReady = true;
      });
    },
    onSubmit() {
      if (!this.isSubmittable) {
        return;
      }
      this.submitProject();
    },
    submitProject() {
      this.$logger.info('Submitting project', { modulePakId: this.modulePakId });
      this.submitting = true;
      this.stageFile();
      const now = new Date().toISOString();
      this.$http.post(`/api/curriculum/pak/${this.modulePakId}/submit`, {
        commit_message: `Updated at ${now}`,
        changes: this.fileContent,
      }).then(() => {
        this.$logger.info('Submitted project', { modulePakId: this.modulePakId }, true);
        this.fileContentBackup = { ...this.fileContent };
        this.onChange();
        this.showSubmissionPopup();
      }).catch(err => {
        this.$logger.error('Error submitting project', { modulePakId: this.modulePakId }, err);
        if (err.response) {
          this.showError(err.response.data.msg || err);
          if (getOrNull('response.data.err', err) === 'maintenance') {
            this.showError(
              'Your changes have been saved to your browser. Please try submitting later',
              false,
              5000,
            );
          }
        } else {
          throw err;
        }
      }).then(() => {
        this.submitting = false;
      });
    },
  },
};
</script>
