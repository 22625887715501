<template>
  <div class="pie-chart-container">
    <apexchart type="pie" width="320" :options="chartOptions" :series="seriesData"></apexchart>
  </div>
</template>

<style>
.pie-chart-container .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center {
  justify-content: space-between;
}

.pie-chart-container .apexcharts-datalabels {
  text-shadow: 0 0 4px var(--kate-background-body);
  letter-spacing: 1.4px;
}

.pie-chart-container .apexcharts-legend-text {
  color: var(--kate-type-light) !important;
}

.pie-chart-container .apexcharts-canvas {
  margin: 0 auto;
  filter: var(--kate-filter-neumorph);
}

.pie-chart-container .apexcharts-datalabels text {
  font-weight: 400 !important;
}

.pie-chart-container .apexcharts-datalabels.legend-mouseover-inactive {
  opacity: 1;
}

</style>

<script>
export default {
  props: {
    seriesData: Array,
    seriesLabels: Array,
    showLegend: Boolean,
    customColors: {
      type: Array,
      default: () => ['var(--kate-stroke-light)', 'var(--kate-stroke-dark)', 'var(--kate-danger-alt)'],
    },
  },

  computed: {
    chartOptions() {
      return {
        chart: {
          width: 280,
          type: 'pie',
        },
        fill: {
          colors: this.customColors,
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'round',
          colors: this.customColors,
          width: 1,
          dashArray: 0,
        },
        legend: {
          show: this.showLegend,
          labels: {
            colors: this.customColors,
            useSeriesColors: true,
          },
          markers: {
            fillColors: this.customColors,
          },
          position: 'bottom',
        },
        tooltip: {
          enabled: false,
          fillSeriesColor: false,
        },
        labels: this.seriesLabels,
        responsive: [{
          breakpoint: 1189,
          options: {
            chart: {
              width: 250,
            },
          },
        }],
      };
    },
  },
};
</script>
