<template>
  <div>
    <div class="module-blueprint-controls">
      <slot name="instructions">
      <p>To withdraw a released module, click on the module's card and click <i>Withdraw</i>. The module release date will be unset and it will no longer be available to students.</p>
      <p>Note that no changes (reordering, scheduling a release or withdrawing) will be reflected on <b>EDUKATE</b> until you save your changes via the <i>Save Changes</i> button.</p>
      </slot>
      <div class="module-blueprint-actions">
        <button class="btn btn-danger" @click="relay('reset')">Reset</button>
        <button class="btn btn-primary" aria-label="Toggle" title="Toggle" @click="toggleShowModuleBlueprintsModal">Add Module</button>
        <button class="btn btn-success" @click="relay('saveChanges')" :disabled="disableCommit">Save Changes</button>
      </div>
    </div>
    <div class="nav-tabs-wrapper">
      <k-tooltip :text="this.indicesInEditMode.length > 0 ? 'Some modules are still being edited' : ''">
        <ul class="nav nav-tabs">
          <li class="tablink-container" :class="{ 'active': track==='curriculum', }">
            <a @click="track='curriculum'" :class="{disabled: disabledClass}">Curriculum</a>
          </li>
          <li class="tablink-container" :class="{ 'active': track==='milestone', }" >
            <a @click="track='milestone'" :class="{disabled: disabledClass}">Milestones</a>
          </li>
        </ul>
      </k-tooltip>
    </div>
    <draggable
      v-model="trackModules"
      draggable=".k-module-item"
      ghost-class="ghost"
      @change="relay('orderChanged')"
      :disabled="!isDraggable"
    >
      <k-release-module
        v-for="mod, index in trackModules"
        class='k-module-item'
        :module="mod"
        :key="mod.id"
        :id="mod.id"
        :tags="mod.tags"
        :number="index + 1"
        :isBlueprint="isBlueprint"
        :isNewlyAdded="Boolean(mod.addedModule)"
        :released="mod.released"
        :draggable="isDraggable"
        :allow-remove="true"
        :allow-edit="true"
        :show-badges="true"
        @update="updatePayload => relay('release-update', mod.index, updatePayload)"
        @remove="relay('remove', mod.index)"
        @cancel="relay('cancel', mod.index)"
        @editMode="editMode => toggleEditMode(mod.index, editMode)"
      ></k-release-module>
    </draggable>
    <p v-if="trackModules.length === 0">The are no {{ track }} modules in the programme</p>
    <k-mod-bp-search
      :excluded-modules="this.trackModules.map(mod => mod.id)"
      :show="showModuleBlueprintsModal"
      @close="showModuleBlueprintsModal = false"
      @add="add"
      ></k-mod-bp-search>
  </div>
</template>

<style scoped>
.module-blueprint-controls {
  padding-bottom: 20px;
}

.module-blueprint-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.tablink-container a.disabled {
  color: var(--kate-disabled);
  pointer-events: none;
  cursor: not-allowed;
}
</style>

<style>
.ghost .create-programme-module-card {
  border: 4px solid #5fede3;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.add-module-to-programme-modal .modal-container {
  max-width: 80%;
  max-height: 70%;
}
</style>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import ErrorMixin from '../../../mixins/error-mixins';
import KReleaseModule from './release-module.vue';
import ModBlueprintSearch from '../../curriculum/blueprints/abstract-add-module-blueprint.vue';
import KTooltip from '../../../components/k-tooltip.vue';

export default {
  components: {
    KTooltip,
    draggable: VueDraggableNext,
    'k-release-module': KReleaseModule,
    'k-mod-bp-search': ModBlueprintSearch,
  },

  props: {
    draggable: {
      type: Boolean,
      default: true,
    },
    disableCommit: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    isBlueprint: Boolean,
  },

  mixins: [ErrorMixin],

  data() {
    return {
      track: 'curriculum',
      showModuleBlueprintsModal: false,
      indicesInEditMode: [],
    };
  },

  computed: {
    trackModules: {
      get() {
        return this.modelValue.filter(mod => mod.track === this.track);
      },
      set(val) {
        this.$emit('update:modelValue', this.reorderedModules(val));
      },
    },
    disabledClass() {
      return this.indicesInEditMode.length > 0 ? 'disabled' : '';
    },
    isDraggable() {
      return this.draggable && this.indicesInEditMode.length === 0;
    },
  },

  methods: {
    add(bp) {
      this.relay('add', bp, this.track);
      this.showModuleBlueprintsModal = false;
    },
    toggleEditMode(i, editMode) {
      if (editMode) {
        this.indicesInEditMode.push(i);
      } else if (this.indicesInEditMode.indexOf(i) >= 0) {
        this.indicesInEditMode = this.indicesInEditMode.filter(item => item !== i);
      }
    },
    toggleShowModuleBlueprintsModal() {
      this.showModuleBlueprintsModal = !this.showModuleBlueprintsModal;
    },
    relay(name, ...args) {
      this.$emit(name, ...args);
    },
    reorderedModules(trackMods) {
      // Number of tracked modules shouldn't change in the component
      // Add and removing is done in the component above
      const updatedModules = [];
      let count = 0;
      this.modelValue.forEach(mod => {
        if (mod.track !== this.track) {
          updatedModules.push(mod);
        } else {
          updatedModules.push(trackMods[count]);
          count++;
        }
      });
      return updatedModules;
    },
  },
};
</script>
