<template>
  <router-link class="thread-card" :to="linkToThread" >
    <div class="panel panel-default">
      <div class="panel-body thread-container">
        <div class="thread-info">
          <h2>{{ thread.title }}
          <span v-if="thread.accepted_id" class="answered-label">Answered <i class="fas fa-check"></i></span>
          <span v-if="thread.is_closed" class="closed-label">Closed</span>
          <span v-if="thread.is_discussion" class="discussion-label">Discussion</span>
          <span v-if="thread.is_private && isDashboard" class="private-label">Private</span>
          <span v-if="thread.awaiting_op_answer && isDashboard" class="awaiting-label">Awaiting answer</span>
          </h2>
          <div class="timestamps-container">
            <span class="date-posted">Posted: {{ parseTimestamp(thread.posted_on) }}<span v-if="isDashboard"> by <b>{{ thread.name }}</b></span></span>
          </div>
          <thread-tags :thread="thread"/>
        </div>
        <div class="thread-score">
          <div v-if="isDashboard && thread.assign_to && thread.assignee_avatar_url">
            <k-avatar :avatarUrl="thread.assignee_avatar_url" class="assignee-avatar"></k-avatar>
            <span>Assignee</span>
          </div>
          <div><b>{{ thread.votes }}</b><span>Upvotes</span></div>
          <div :class="thread.selected_reply ? 'selected-answer' : ''"><b>{{ thread.response_count }}</b><span>Answers</span></div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<style scoped>
.thread-card:hover {
  text-decoration: none;
}

.thread-card span,
.thread-card:hover span {
  color: var(--kate-type-primary);
}

.thread-card:hover .panel {
  background-color: var(--kate-background-alt-alpha);
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease;
}

.thread-card .panel {
  transition: all 0.3s ease;
}

.thread-container {
  justify-content: space-between;
}

.thread-info {
  flex: 1;
}

.thread-info h2 {
  margin: 15px 0;
  gap: 15px;
}

.thread-info h2 span {
  font-family: apercuregular, sans-serif;
  font-size: 0.6em;
  padding: 5px;
  border-radius: 4px;
  max-height: 27px;
  white-space: nowrap;
}

.thread-info h2 .answered-label {
  background-color: var(--kate-success);
  color: var(--kate-type-dark);
  min-width: 100px;
}

.thread-info h2 .closed-label {
  background-color: var(--kate-button-secondary);
  color: var(--kate-type-dark);
  min-width: 100px;
}

.thread-info h2 .discussion-label {
  background-color: var(--kate-primary);
  color: var(--kate-type-dark);
  min-width: 100px;
}

.thread-info h2 .private-label {
  background-color: var(--kate-danger);
  color: var(--kate-type-light);
}

.thread-info h2 .awaiting-label {
  background-color: var(--kate-warning);
  color: var(--kate-type-dark);
}

.timestamps-container {
  flex-wrap: nowrap;
  margin-top: 15px;
}

.date-posted b {
  color: var(--kate-type-light);
}

/* Gathering the flexes */
.thread-container,
.timestamps-container,
.thread-tags,
.thread-info h2,
.thread-score,
.thread-score div {
  display: flexbox;
  display: flex;
}

.thread-score {
  margin-left: 15px;
  margin-top: 15px;
  align-items: baseline;
  line-height: 1;
}

.thread-score div {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  height: 50px;
}

.thread-score div b {
  font-size: 1.5em;
  color: var(--kate-type-light);
}

.thread-score div.selected-answer,
.thread-score div.selected-answer b {
  color: var(--kate-success);
}

.assignee-avatar {
  min-height: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
  width: 20px !important;
}

/* Threads */
@media screen and (max-width: 767px) {
  .thread-container {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .thread-container .thread-info h2 {
    display: block;
  }

  .thread-score {
    justify-content: space-around;
    margin-left: 0;
  }
}
</style>

<script>
import useGlobalStore from '../stores/global';
import TimeMixin from '../mixins/time-mixins';
import ThreadTags from './thread-tags.vue';
import KAvatar from '../components/k-avatar.vue';
import { ASSET_TYPES } from '../constants';

export default {
  components: {
    'k-avatar': KAvatar,
    'thread-tags': ThreadTags,
  },

  props: {
    thread: {
      type: Object,
    },
  },

  mixins: [TimeMixin],

  data() {
    return {
      store: useGlobalStore(),
    };
  },

  computed: {
    isDashboard() {
      return this.store.appName === 'dashboard';
    },
    assetId() {
      return Object.keys(ASSET_TYPES).map(at => this.thread[`${at}_id`])[0];
    },
    linkToThread() {
      if (this.thread && this.isDashboard) {
        if (this.thread.module_id && (this.assetId)) {
          return {
            name: 'knowledge_thread',
            params: {
              moduleId: this.thread.module_id,
              threadId: this.thread.id,
            },
          };
        }
        return {
          name: 'knowledge_detached_thread',
          params: {
            threadId: this.thread.id,
          },
        };
      }
      return {
        name: 'knowledge_thread',
        params: {
          threadId: this.thread.id,
        },
      };
    },
  },
};
</script>
