import { plural } from 'pluralize';

export const GOOGLE_CLOUD_STORAGE = 'https://storage.googleapis.com/';

export const FEEDBACK_PARAMS = {
  // Options:
  //    [
  //      title,
  //      name, desc, message,
  //      rowTitlePrefix, rowSubtitlePrefix, errorboxPrefix,
  //      perfectMessage
  //    ]
  //    Currently accepted tabs: errors, failures, results
  file_score: {
    title: 'File Score',
  },
  complexity: {
    title: 'cyclomatic complexity (mccabe)',
    rowTitleKey: 'function',
    rowSubtitleKey: 'line',
    errorboxKey: 'error_message',
    rowTitlePrefix: 'Function: ',
    rowSubtitlePrefix: 'Line number: ',
    perfectMessage: 'Good McCabe Complexity!',
  },
  mlmetrics: {
    rowTitleKey: {
      errors: 'file',
      results: 'description',
    },
    rowSubtitleKey: {
      errors: 'description',
    },
    errorboxKey: {
      errors: 'traceback',
    },
    resultboxKey: {
      results: 'result',
    },
    rowTitlePrefix: { errors: 'File: ' },
    rowSubtitlePrefix: 'Test description: ',
    resultboxPrefix: 'Results:\n',
    title: 'Model Test',
  },
  pep8: {
    rowTitleKey: 'line',
    errorboxKey: 'message',
    rowTitlePrefix: 'Line number: ',
  },
  pylint: {
    rowTitleKey: 'function',
    rowSubtitleKey: 'line',
    errorboxKey: 'message',
    rowTitlePrefix: 'Function: ',
    rowSubtitlePrefix: 'Line number: ',
  },
  tests: {
    title: 'Tests',
    rowTitleKey: 'function',
    rowSubtitleKey: 'description',
    rowTitleDefault: '__main__',
    infoboxKey: 'full_description',
    errorboxKey: 'traceback',
    rowTitlePrefix: 'Function: ',
    rowSubtitlePrefix: 'Test description: ',
  },
};

export const FEEDBACK_RESULT_KEYS = [
  'validation_result',
  'evaluation_result',
  'extra_points_result',
  'quality_result',
  'informative_result',
];

export const METRICS_COLORS = {
  score: 'var(--kate-0)',
  file_score: 'var(--kate-0)',
  unittest: 'var(--kate-1)',
  pylint: 'var(--kate-2)',
  pep8: 'var(--kate-3)',
  complexity: 'var(--kate-4)',
  softmetrics: 'var(--kate-5)',
  mlmetrics: 'var(--kate-6)',
};

export const EMAIL_REGEX = /[^@]+@[^@]+\.[^@]+/;

export const PUBLIC_PAGES = [
  'login_page',
  'login_gl_page',
  'policy_page',
  'help_kate_fingerprints',
  'maintenance',
  'email_verification_page',
  'change_password_page',
];

export const SIDEBAR_ENABLED_PAGES = [
  'module_landing',
  'pak_ov_instructions',
  'pak_ov_web_ide',
  'pak_ov_feedback',
  'pak_ov_ide_solution',
  'pak_ov_notebook',
  'pak_ov_notebook_solution',
  'pak_ov_leaderboard',
  'pak_ov_knowledge_base',
  'ide',
  'file_explorer',
  'module_video',
  'module_slides',
  'module_lu',
  'module_questionnaire',
  'module_quiz',
  'module_calendar_event',
  'module_meeting',
  'module_markdown_page',
  'module_questionnaire',
  'module_knowledge_base',
  'pak_ov_knowledge_base',
  'knowledge_thread',
  // Profile
  'profile_landing',
  'profile_information',
  'profile_email_address',
  'profile_password',
  'profile_questions',
  'profile_achievements',
  'profile_knowledge_thread',
  'profile_git_settings',
  'profile_manage_kods_page',

  // Dashboard - TODO: ARE THESE ALL TRASH?
  'assignment_details',
  'learning_unit_details',
  'slides_details',
  'calendar_event_details',
  'meeting_details',
  'video_details',
  'quiz_details',
  'curriculum_asset_details',

  // Programme management
  'programmes_overview',
  'manage_programme',
  'manage_programme_members',
  'manage_programme_content',
  'manage_programme_achievements',
  'programmes_overview_current_programmes',
  'programmes_overview_create_programme',
  'manage_module',
  'module_meta_manager',
  'module_chapter_manager',
  'module_asset_release',

  'edit_quiz',
  'edit_questionnaire',

  'certificates_overview',
  'badges_overview',

  'knowledge_base_overview',
  'knowledge_thread',

  'knowledge_detached_thread',
  'programmes_create_programme',

  // Student pages
  'dashboard_students',
  'dash_quiz_feedback',
  'dash_questionnaire_feedback',
  'dash_pak_ov_web_ide',
  'dash_pak_ov_feedback',
  'dash_pak_ov_ide_solution',
  'dash_pak_ov_notebook',
  'dash_pak_ov_notebook_solution',

  // New Dashboard

  // Monitor
  'monitor_programme_summary',
  'monitor_engagement_overview',
  'monitor_learner_progress',
  'employer_learner_summary',

  // Curriculum
  // -- Blueprint
  'curriculum_blueprints_programmes',
  'curriculum_blueprints_modules',
  'curriculum_manage_programme_blueprint',
  'manage_programme_blueprint_content',
  'manage_programme_blueprint_achievements',
  'curriculum_module_blueprint_manage',
  'module_blueprint_meta_manager',
  'module_blueprint_chapter_manager',
  'module_blueprint_asset_release',

  // -- Assets
  'curriculum_assets_pak',
  'curriculum_assets_lu',
  'curriculum_assets_slides',
  'curriculum_assets_calendar_event',
  'curriculum_assets_meeting',
  'curriculum_assets_video',
  'curriculum_assets_quiz',
  'curriculum_assets_questionnaire',
  'curriculum_assets_markdown_page',

  // -- Users
  'users',
  'users_privileged_users',
  'learner_support',
  'users_trainer',

];

export const ASSET_TYPES = {
  pak: {
    prettyName: 'Assignment',
    routeName: 'pak',
    apiRoot: 'pak',
    icon: 'fa-duotone fa-laptop-code',
    completion_type: 'submission',
    dataLossField: 'has_submissions',
    additionalReleaseFields: [
      {
        key: 'end_date',
        type: 'date',
        title: 'Deadline',
      },
      {
        key: 'time_limit',
        type: 'time',
        title: 'Time Limit',
      },
      {
        key: 'hide_error_traceback',
        type: 'boolean',
        title: 'Hide Error Traceback',
      },
      {
        key: 'show_leaderboard',
        type: 'boolean',
        title: 'Show Leaderboard',
      },
      {
        key: 'solution_released',
        type: 'boolean',
        title: 'Solution Released',
      },
    ],
  },
  learning_unit: {
    prettyName: 'Learning Unit',
    routeName: 'lu',
    apiRoot: 'learning_unit',
    icon: 'fa-duotone fa-graduation-cap',
    dataLossField: 'has_saves',
  },
  slide_deck: {
    prettyName: 'Slides',
    routeName: 'slides',
    apiRoot: 'slide_deck',
    icon: 'fa-duotone fa-clone',
  },
  calendar_event: {
    prettyName: 'Event',
    routeName: 'calendar_event',
    apiRoot: 'calendar_event',
    icon: 'fa-duotone fa-calendar-alt',
    additionalReleaseFields: [
      {
        key: 'event_start',
        type: 'datetime',
        title: 'Event Start',
      },
      {
        key: 'event_end',
        type: 'datetime',
        title: 'Event End',
      },
      {
        key: 'live_event_link',
        type: 'input-text',
        title: 'Live Event Link',
      },
      {
        key: 'location',
        type: 'input-text',
        title: 'Location',
      },
      {
        key: 'recording_link',
        type: 'multi-link-input',
        title: 'Recording Link',
      },
    ],
    hasTrainers: true,
    releaseWarningMessage:
      `On releasing or changing the start time of an event,
      reminder emails will be scheduled to be sent to all learners with access.
      This process will overwrite any currently scheduled emails for the event.`,
    releaseCallbackData: 'tasks',
  },
  meeting: {
    prettyName: 'Meeting',
    routeName: 'meeting',
    apiRoot: 'meeting',
    icon: 'fa-duotone fa-headset',
  },
  video: {
    prettyName: 'Video',
    routeName: 'video',
    apiRoot: 'video',
    icon: 'fa-duotone fa-film',
  },
  quiz: {
    prettyName: 'Quiz',
    routeName: 'quiz',
    apiRoot: 'quiz',
    icon: 'fa-duotone fa-lightbulb',
    completion_type: 'submission',
    dataLossField: 'has_submissions',
    additionalReleaseFields: [
      {
        key: 'deadline',
        type: 'date',
        title: 'Deadline',
      },
    ],
  },
  questionnaire: {
    prettyName: 'Questionnaire',
    routeName: 'questionnaire',
    apiRoot: 'questionnaire',
    icon: 'fa-duotone fa-ballot-check',
    completion_type: 'submission',
  },
  markdown_page: {
    prettyName: 'Page',
    routeName: 'markdown_page',
    apiRoot: 'markdown_page',
    icon: 'fa-duotone fa-file-lines',
  },
};

Object.keys(ASSET_TYPES).forEach(assetType => {
  ASSET_TYPES[assetType].defaultChapterTitle = plural(ASSET_TYPES[assetType].prettyName);
});
