<template>
  <button v-if="displayCrosshairs" title="Take screenshot" aria-label="Take screenshot"
    id="screenshot" class="screenshot-container"
    @mousemove="move" @mousedown="mouseDown" @mouseup="mouseUp">
    <div class="overlay" :class="{ 'highlighting' : isDraggingMouse }" :style="{ borderWidth: borderWidth }"></div>
    <div class="crosshairs" :class="{ 'hidden' : isDraggingMouse }" :style="{ left: crosshairsLeft + 'px', top: crosshairsTop + 'px' }"></div>
  </button>
</template>

<script>
import useGlobalStore from '../stores/global';
import { h2c } from '../modules/html-2-canvas';

export default {
  data() {
    return {
      store: useGlobalStore(),
      displayCrosshairs: false,
      crosshairsLeft: 0,
      crosshairsTop: 0,
      startX: 0,
      startY: 0,
      borderWidth: 0,
      isMouseDown: false,
      isDraggingMouse: false,
      isScrolling: false,
      lastScrollX: 0,
      lastScrollY: 0,
      croppedImageWidth: 0,
      croppedImageHeight: 0,
      topLeftCornerX: 0,
      topLeftCornerY: 0,
      bodyHeight: 0,
    };
  },

  created() {
    window.addEventListener('scroll', this.scroll);
  },

  beforeUnmount() {
    // Component unmounted so all staged files will be removed
    this.removeKeyListener();
  },

  unmounted() {
    window.removeEventListener('scroll', this.scroll);
  },

  watch: {
    isActive(val) {
      if (val) {
        this.addKeyListener();
        // Set the body height before the crosshairs mess things up
        this.bodyHeight = document.body.scrollHeight;
        this.displayCrosshairs = true;
      } else {
        this.removeKeyListener();
        this.displayCrosshairs = false;
      }
    },
  },

  computed: {
    isActive() {
      return this.store.inScreenshotMode;
    },
  },

  methods: {
    move(event) {
      this.isScrolling = false;
      this.crosshairsTop = event.clientY + window.scrollY;
      this.crosshairsLeft = event.clientX + window.scrollX;
      const endX = event.clientX + window.scrollX;
      const endY = event.clientY + window.scrollY;
      if (this.isMouseDown) {
        this.updateDimensions(endX, endY);
      }
    },
    scroll() {
      if (!this.isDraggingMouse) {
        // Only update crosshair position if not dragging, updateDimensions
        // corretly handles scroll when dragging
        this.crosshairsTop += (window.scrollY - this.lastScrollY);
        this.crosshairsLeft += (window.scrollX - this.lastScrollX);
      }
      this.isScrolling = true;
      this.lastScrollX = window.scrollX;
      this.lastScrollY = window.scrollY;
    },
    mouseDown(event) {
      this.startX = event.clientX + window.scrollX;
      this.startY = event.clientY + window.scrollY;
      this.isMouseDown = true;
    },

    mouseUp() {
      if (this.isDraggingMouse) {
        // Don't take the screenshot unless the mouse moved somehow.
        this.borderWidth = 0;
        this.isDraggingMouse = false;
        this.isMouseDown = false;
        this.takeScreenshot();
      }
    },

    keydownHandler(event) {
      if (this.isActive && event.keyCode === 27) {
        this.store.exitScreenshotMode();
      }
    },

    addKeyListener() {
      document.addEventListener('keydown', this.keydownHandler);
    },

    removeKeyListener() {
      document.removeEventListener('keydown', this.keydownHandler);
    },

    updateDimensions(endX, endY) {
      if (endX !== this.startX && endY !== this.startY) {
        this.isDraggingMouse = true;
        this.croppedImageWidth = Math.abs(endX - this.startX);
        this.croppedImageHeight = Math.abs(endY - this.startY);
      }
      if (endX > this.startX && endY > this.startY) {
        // Dragging right and down
        // Format border width as TOP RIGHT BOTTOM LEFT in px - 12px on right to offset scrollbar
        // and have to offset for scroll position since border width is on fixed window
        this.borderWidth = [`${this.startY - window.scrollY}px`,
          `${window.innerWidth - endX - 12 - window.scrollX}px`,
          `${window.innerHeight - endY + window.scrollY}px`,
          `${this.startX + window.scrollX}px`].join(' ');
        this.topLeftCornerX = this.startX;
        this.topLeftCornerY = this.startY;
      }
      if (endX > this.startX && endY < this.startY) {
        // Dragging right and up
        this.borderWidth = [`${endY - window.scrollY}px`,
          `${window.innerWidth - endX - 12 - window.scrollX}px`,
          `${window.innerHeight - this.startY + window.scrollY}px`,
          `${this.startX + window.scrollX}px`].join(' ');
        this.topLeftCornerX = this.startX;
        this.topLeftCornerY = endY;
      }
      if (endX < this.startX && endY < this.startY) {
        // Dragging left and up
        this.borderWidth = [`${endY - window.scrollY}px`,
          `${window.innerWidth - this.startX - window.scrollX}px`,
          `${window.innerHeight - this.startY + window.scrollY}px`,
          `${endX + window.scrollX}px`].join(' ');
        this.topLeftCornerX = endX;
        this.topLeftCornerY = endY;
      }
      if (endX < this.startX && endY > this.startY) {
        // Dragging left and down
        this.borderWidth = [`${this.startY - window.scrollY}px`,
          `${window.innerWidth - this.startX - window.scrollX}px`,
          `${window.innerHeight - endY + window.scrollY}px`,
          `${endX + window.scrollX}px`].join(' ');
        this.topLeftCornerX = endX;
        this.topLeftCornerY = this.startY;
      }
    },

    takeScreenshot() {
      h2c(document.body, { windowHeight: this.bodyHeight }).then(canvas => {
        // Canvas width and height not the same as CSS style width and height so
        // calculate scale factor and apply to croppedImageWidth / Height (which are in pixels)
        const widthScaleFactor = canvas.width / parseFloat(canvas.style.width.replace('px', ''));
        const heightScaleFactor = canvas.height / parseFloat(canvas.style.height.replace('px', ''));
        const croppedCanvas = document.createElement('canvas', { id: 'croppedCanvas' });
        croppedCanvas.width = this.croppedImageWidth;
        croppedCanvas.height = this.croppedImageHeight;
        const croppedCanvasContext = croppedCanvas.getContext('2d');
        croppedCanvasContext.drawImage(
          canvas,
          this.topLeftCornerX * widthScaleFactor,
          this.topLeftCornerY * heightScaleFactor,
          this.croppedImageWidth * widthScaleFactor,
          this.croppedImageHeight * heightScaleFactor,
          0,
          0,
          croppedCanvas.width,
          croppedCanvas.height,
        );
        croppedCanvas.toBlob(blob => { this.commitScreenshotAndExit(blob); });
      });
    },
    commitScreenshotAndExit(blob) {
      this.store.addScreenshotBlob(blob);
      this.store.exitScreenshotMode();
      this.$ktoast.info('Screen captured', { goAway: 1500 });
    },
  },
};
</script>

<style scoped>
.overlay,
.crosshairs {
  user-select: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 0.5);
  z-index: 999;
}

.overlay.highlighting {
  background: none;
  border-color: rgb(0 0 0 / 0.5);
  border-style: solid;
}

.crosshairs {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2147483645;
}

.crosshairs.hidden {
  display: none;
}

.crosshairs::before,
.crosshairs::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  border: none !important;
}

.crosshairs::before {
  left: -100%;
  top: -100%;
  border-right: 2px solid rgb(255 255 255 / 0.3) !important;
  border-bottom: 2px solid rgb(255 255 255 / 0.3) !important;
}

.crosshairs::after {
  left: -2px;
  top: -2px;
  border-top: 2px solid rgb(255 255 255 / 0.3) !important;
  border-left: 2px solid rgb(255 255 255 / 0.3) !important;
}

.screenshot-container {
  clear: both;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
