<template>
  <k-modal :show="showPreviewModal" @close="$emit('close')" class="preview-modal">
    <template #header>
      <h3>Preview achievement</h3>
    </template>
    <template v-if="achievementId" #body>
      <div class="note-modal-body">
        <img class="image-preview" :src="achievementFullUrl" alt="Achievement preview image"/>
      </div>
    </template>
    <template #footer>
    </template>
  </k-modal>
</template>

<style>
#achievement-overview .preview-modal img.image-preview {
  width: 100%;
  max-height: 95vh;
  max-width: 98vw;
}

#achievement-overview .preview-modal .modal-header,
.preview-modal .modal-footer {
  display: none;
}

#achievement-overview .modal-mask.preview-modal {
  z-index: 9999;
}

#achievement-overview .preview-modal .modal-container {
  max-height: 100%;
}

#achievement-overview .preview-modal .modal-body {
  margin: 0;
  padding: 0;
}

#achievement-overview .preview-modal .modal-close-button {
  z-index: 1;
  color: var(--kate-type-light);
  padding: 5px 12px;
  margin: 5px 5px 0 0;
}

</style>

<script>
import { GOOGLE_CLOUD_STORAGE } from '../../constants';
import ErrorMixin from '../../mixins/error-mixins';
import KModal from '../../components/k-modal.vue';

export default {
  components: {
    KModal,
  },

  mixins: [ErrorMixin],

  props: {
    achievementType: String,
    achievementId: {
      type: Number,
    },
  },

  data() {
    return {
      showPreviewModal: false,
      achievementResourceLink: undefined,
    };
  },

  watch: {
    achievementId(val) {
      if (val) {
        this.previewAchievement();
      } else {
        this.showPreviewModal = false;
        this.achievementResourceLink = undefined;
      }
    },
  },

  computed: {
    achievementFullUrl() {
      if (this.achievementId) {
        return `${GOOGLE_CLOUD_STORAGE}${this.achievementResourceLink}`;
      }
      return '';
    },

    achievementEndpoint() {
      return `/api/curriculum/${this.achievementType}`;
    },
  },

  methods: {
    previewAchievement() {
      this.$logger.info(`Getting ${this.achievementType} source with signed URL`, { achievementId: this.achievementId });
      this.$Loading.minimal();
      this.$http.get(`${this.achievementEndpoint}/${this.achievementId}`)
        .then(result => {
          this.$logger.info(`Got ${this.achievementType} signed URL`, { achievementId: this.achievementId });
          const resData = this.achievementType === 'badge' ? result.data.badge : result.data.certificate;
          this.achievementResourceLink = resData.resource_link;
          this.showPreviewModal = true;
        }).catch(err => {
          this.$logger.error(`Error getting ${this.achievementType} signed URL`, { achievementId: this.achievementId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },
  },
};
</script>
