import { defineStore } from 'pinia';
// To become frontend_settings store
const useThemeStore = defineStore('theme', {
  state: () => ({
    theme: 'legacy',
    osDarkTheme: null,
    hideThemesPopup: true,
    hideChatBotPopup: true,
  }),

  getters: {
    osPreference() {
      if (this.osDarkTheme) {
        return 'dark';
      }
      return 'light';
    },
  },

  actions: {
    setPublicPagesTheme() {
      this.theme = this.osPreference;
    },

    resolveThemePreference(payload) {
      const chatBotEnabled = this.$featureFlags.isEnabled('kate-bot', 'theme-store');
      const frontendSettings = payload.frontend_settings;
      const logPayload = {
        frontendSettings,
        osPreference: this.osPreference,
        chatBotEnabled,
      };
      this.$logger.info('Resolving frontend settings', logPayload);
      if (frontendSettings) {
        this.$logger.info('Loading user preferences from frontend settings', logPayload);
        // Theme
        this.theme = frontendSettings.theme;
        this.hideThemesPopup = Boolean(frontendSettings.hide_themes_popup);
        // Chatbot
        if (chatBotEnabled) {
          this.$logger.info('Chatbot enabled', logPayload);
          this.hideChatBotPopup = Boolean(frontendSettings.hide_chatbot_popup);
        }
        return;
      }
      this.$logger.info('No frontend settings saved for user - using OS preference', logPayload);
      this.theme = this.osPreference;
      this.hideThemesPopup = false;
      if (chatBotEnabled) {
        this.$logger.info('Chatbot enabled - showing popup', logPayload);
        this.hideChatBotPopup = false;
      }
    },
  },
});

export default useThemeStore;
