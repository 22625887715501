<template>
  <div v-if="!isProduction" :class="`environment-banner ${kateEnvironment}`">
    <span>{{ kateEnvironment }}</span>
  </div>
</template>

<style scoped>
.environment-banner > span {
  text-align: center;
  background-color: var(--kate-success);
  text-transform: capitalize;
  font-weight: bold;
  color: var(--kate-type-white);
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 2px;
}

.environment-banner.staging > span {
  background-color: var(--kate-warning);
}

</style>

<script>
import useGlobalStore from '../stores/global';

export default {
  data: () => ({
    store: useGlobalStore(),
  }),

  computed: {
    kateEnvironment() {
      return this.store.environment;
    },
    isProduction() {
      return this.kateEnvironment === 'production';
    },
  },
};
</script>
