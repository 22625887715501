<template>
  <div v-if="ready" id="dash-student" class="content-with-spacing" :class="sidebarClass">
    <section class="student-container">
      <div class="student-details col-one panel panel-default">
        <student-info v-if="studentInfo"
          :studentInfo="studentInfo"
          :studentId="studentId"
          :studentName="studentName"
          :privilegedUser="privilegedUser"
          :hubspotLink="hubspotLink"
          :selectedProgramme="selectedProgramme"
          :employers="employers"
          :programmeId="programmeIdFromRoute"
          :canUpdate="$permissions.hasPermission('manage_programmes')"
          @assign-employer="assignNewEmployer"
          @update-email="getStudentData"
        >
        </student-info>
      </div>
      <template v-if="programmes.length > 0">
        <div class="col-two">
          <div class="panel panel-default programme-details">
            <h2>Programmes</h2>
            <span class="programme-access" v-if="selectedProgramme">
              Programme Access:
              <span>
                <b>
                  <span v-if="!selectedProgramme.is_active" class="fas fa-archive" title="Programme is archived"></span>
                  <span v-if="selectedProgramme.is_frozen" class="frozen">Frozen</span>
                  <span v-else>Granted</span>
                </b>
                <button v-if="$permissions.hasPermission('manage_programmes')" @click="handleFreezeClick()" :class="selectedProgramme.is_frozen ? 'unfreeze' : 'freeze'">
                  <span v-if="selectedProgramme.is_frozen">Unfreeze</span>
                  <span v-else>Freeze</span>
                  <i :class="selectedProgramme.is_frozen ? 'fa-fire-alt' : 'fa-snowflake'" class="fas" :title="`Click to ${selectedProgramme.is_frozen ? 'un' : ''}freeze student access to programme`"></i>
                </button>
              </span>
            </span>
            <span class="learner-status" v-if="selectedProgramme">
              Learner Status:
              <set-learner-status v-if="!selectedProgramme.aptem_learner_status"
                                  :class="learnerStatusClass"
                                  :options="learnerStatus"
                                  :selected-programme="selectedProgramme"
                                  :canUpdate="$permissions.hasPermission('manage_programmes')"
                                  @new-data="assignNewLearnerStatus"
              >
              </set-learner-status>
              <span v-else :class="learnerStatusClass">
                <k-tooltip text="Learner status is set in Aptem and cannot be changed here">
                  {{ selectedProgramme.aptem_learner_status }} <span class="aptem-icon"><b>aptem</b></span>
                </k-tooltip>
              </span>
            </span>
            <span class="graduation-date" v-if="selectedProgramme">
              <set-student-metadata
                v-if="selectedProgramme"
                :inline="true"
                assignedType="graduation date"
                :estimatedGraduationDate="selectedProgramme.estimated_graduation_date"
                :canUpdate="$permissions.hasPermission('manage_programmes')"
                @new-data="assignNewEstimatedGraduationDate"
              ></set-student-metadata>
            </span>
            <a v-if="aptemLink" class="aptem-link" target="_blank"
                :href="aptemLink"
            >Link to Aptem <i class="fas fa-external-link-alt"></i></a>
            <span v-if="currentProgrammeGroups && currentProgrammeGroups.length" class="programme-groups">
              <span>Programme Groups:</span>
              <span v-for="group in currentProgrammeGroups" :key="`${group}_${selectedProgramme.id}`" class="badge">{{ group }}</span>
            </span>
            <k-dropdown placeholder="Select Programme" :options="programmes" v-model="programmeIdFromDropdown"></k-dropdown>
          </div>
        </div>
        <div class="col-three">
          <div class="data-mentor panel panel-default">
            <h2>Learner Support</h2>
            <set-student-metadata
              v-if="selectedProgramme"
              assignedType="coach"
              :mentor="studentCoach"
              :options="coaches"
              :canUpdate="$permissions.hasPermission('manage_coaches')"
              @new-data="assignNewCoach"
            ></set-student-metadata>
            <set-student-metadata
              v-if="selectedProgramme"
              assignedType="data mentor"
              :mentor="studentDataMentor"
              :options="dataMentors"
              :canUpdate="$permissions.hasPermission('manage_mentors')"
              @new-data="assignNewDataMentor"
            ></set-student-metadata>
          </div>
        </div>
      </template>
    </section>

    <div class="col-two col-two-container">
      <k-panel class="activity-panel" title="Activity Feed" :hasContentToggle="false">
        <template #body>
          <learner-activity-feed
            :studentId="studentId"
            :studentName="studentName"
            :programmeId="programmeIdFromRoute"
            :programmeName="currentProgrammeName"
          ></learner-activity-feed>
        </template>
      </k-panel>
      <k-panel v-if="hasViewNotePermissions && currentProgrammeMemberId"
        class="notes-panel"
        title="Notes"
        :showContent="showNotes" @toggle="showNotes = $event">
        <template #body>
          <notes-overview
            :programmeMemberId="currentProgrammeMemberId"
            :isLearnerSupport="isLearnerSupport">
          </notes-overview>
        </template>
      </k-panel>
    </div>
    <k-panel v-if="programmesReady && selectedProgramme"
      :title="currentProgrammeName"
      :showContent="showProgramme" @toggle="(val) => showProgramme = val"
    >
      <template #body>
        <programme-modules
          :programme-id="programmeIdFromRoute"
          :programme-name="currentProgrammeName"
          :student-id="studentId"
          :is-learner-support="isLearnerSupport"
          :programme-member-id="currentProgrammeMemberId">
        </programme-modules>
      </template>
    </k-panel>
    <section v-if="userQuestions" class="panel panel-default">
      <div class="panel-body">
        <div class="heading-container">
          <h2>Knowledge Base</h2>
        </div>
        <k-table
          v-if="userQuestions.length > 0"
          class="user-kb-table"
          :rows="knowledgeBaseTableRows"
          :headers="knowledgeBaseTablesHeaders"
          :max="10"
          :panel="false"
          :hideable="false"
        ></k-table>
        <p v-else>No activity yet.</p>
      </div>
    </section>
  </div>
</template>

<style>
#dash-student .panel-default {
  box-shadow: var(--box-shadow);
}

/* k-table css */
.k-table-key-latest_feedback i,
.k-table-key-project i {
  float: right;
}

#k-dashboard-student-summary-table .k-table-key-project,
#k-dashboard-student-summary-table .k-table-key-programme {
  color: var(--kate-type-light);
}

#k-dashboard-student-summary-table .k-table-key-latest_score,
#k-dashboard-student-summary-table .k-table-key-latest_feedback,
.user-kb-table .k-table-key-status,
.user-kb-table .k-table-key-visibility,
.user-kb-table .k-table-key-answers {
  min-width: 80px;
  width: 135px;
  text-align: center;
}

#k-dashboard-student-summary-table i.fas.fa-archive {
  margin-right: 0.5em;
}

#k-dashboard-student-summary-table .k-table-key-latest_feedback i.fas {
  font-size: 1em;
}

.k-table-container.user-kb-table thead tr th,
.k-table-container.user-kb-table tbody tr td {
  min-width: 126px;
  text-align: center;
}

.k-table-container.user-kb-table tbody tr td.k-table-key-question,
.k-table-container.user-kb-table tbody tr td.k-table-key-module,
.k-table-container.user-kb-table tbody tr td.k-table-key-project {
  text-align: left;
  max-width: 50vw;
  white-space: unset;
}

.student-container h2 {
  margin-top: 0;
}

.student-container i.fas.fa-edit:hover {
  color: var(--kate-type-blue);
  transition: all 0.3s;
}

.student-container i.fas.fa-times-circle {
  color: var(--kate-danger-alt);
}

.student-container i.fas.fa-times-circle:hover {
  color: var(--kate-danger);
}

.student-container i.fas.fa-check:hover {
  color: var(--kate-success-dark);
}

.student-info-header figure.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
}

#dash-student .student-info,
#dash-student .programme-details,
#dash-student .data-mentor {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

#dash-student .show-more {
  display: flex;
  align-items: center;
}

#dash-student .show-more > button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  background-color: transparent;
  color: var(--kate-primary);
}

#dash-student .show-more > button:hover {
  color: var(--kate-secondary);
}

#dash-student .show-more > button > span {
  text-decoration: underline;
}

#dash-student .content-spinner {
  text-align: center;
  font-size: 1.5em;
  width: 100%;
}

.learner-status {
  display: flex;
}

#dash-student > section.student-container > div.col-three > div > div:nth-child(2) > h2,
#dash-student > section.student-container > div.col-three > div > div:nth-child(3) > h2 {
  color: var(--kate-type-primary);
  font-size: 1.2em;
}

i.fa-edit {
  color: var(--kate-type-light);
}

.aptem-icon {
  background-color: var(--kate-mono-0);
  color: var(--kate-type-blue-dark);
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 60px;
}

</style>

<style scoped>
/* Student-container */
.student-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 15px 0;
  margin: 15px 0;
  align-items: stretch;
}

.student-container .panel {
  padding: 15px;
  margin: 0;
  width: 100%;
  align-items: stretch;
}

.student-details {
  flex: 1;
  width: 33%;
}

.programme-access b {
  color: var(--kate-type-success);
  margin-right: 10px;
}

.programme-access .frozen {
  color: var(--kate-disabled);
}

.programme-access button:hover {
  background-color: var(--kate-button-primary-hover);
}

.programme-access button.freeze {
  background-color: var(--kate-button-primary);
}

.programme-access button.unfreeze {
  background-color: var(--kate-success);
  color: var(--kate-type-dark);
}

.company .aptem-settings {
  display: flex;
  gap: 10px;
  align-items: center;
}

.learner-status {
  display: flex;
  gap: 5px;
  padding-bottom: 5px;
}

.learner-status .danger {
  color: var(--kate-type-danger);
}

.learner-status .success {
  color: var(--kate-type-success);
}

.col-two,
.col-three {
  flex: 1;
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
}

/* Notes */

.col-two .activity-panel {
  flex: 1 1 16%;
}

.col-two .notes-panel {
  flex: 2;
  margin-bottom: 20px;
}

/* Misc */
#k-dashboard-student-summary-table {
  display: inline-block;
  width: 100%;
}

.panel-heading {
  padding-left: 0;
}

.heading-container h2 {
  margin: 0;
  padding: 10px 0;
}

.heading-container + div {
  padding-top: 15px;
}

.heading-container.user-activity-container {
  display: flex;
  justify-content: space-between;
}

.tag-edukator {
  margin: 0 0 4px;
  line-height: 1.5;
}

h2 > i.fas.fa-angle-up,
h2 > i.fas.fa-angle-down {
  float: right;
  font-size: 1.5rem;
}

.archive-toggle {
  display: flex;
  align-items: center;
}

.archive-toggle > span:first-child {
  margin-right: 0.5em;
}

@media only screen and (max-width: 1200px) {
  .col-two-container {
    flex-direction: column-reverse;
  }

  .col-two-container .notes-panel {
    margin-bottom: 0;
  }
}
</style>

<script>
import SidebarMixin from '../../mixins/sidebar-mixin';
import useGlobalStore from '../../stores/global';
import KTable from '../../components/k-table.vue';
import KDropdown from '../../components/k-dropdown.vue';
import TimeMixin from '../../mixins/time-mixins';
import CsvMixin from '../../mixins/csv-mixins';
import ErrorMixin from '../../mixins/error-mixins';
import PageReadyMixin from '../../mixins/page-ready-mixin';
import SetStudentMetadata from './set-student-metadata.vue';
import SetLearnerStatus from './set-learner-status.vue';
import ProgrammeModules from './student_components/student-programme-modules.vue';
import LearnerActivityFeed from './learner-activity-feed.vue';
import KPanel from '../../components/k-panel.vue';
import NotesOverview from './notes-overview.vue';
import { sortObjectArray } from '../../modules/sort-by-object-property';
import KTooltip from '../../components/k-tooltip.vue';
import { ASSET_TYPES } from '../../constants';
import StudentInfo from './student-info.vue';

export default {
  components: {
    KTooltip,
    'k-table': KTable,
    'k-dropdown': KDropdown,
    'set-learner-status': SetLearnerStatus,
    'set-student-metadata': SetStudentMetadata,
    ProgrammeModules,
    LearnerActivityFeed,
    KPanel,
    NotesOverview,
    StudentInfo,
  },

  mixins: [TimeMixin, CsvMixin, ErrorMixin, PageReadyMixin, SidebarMixin],

  data() {
    return {
      store: useGlobalStore(),
      studentName: '',
      studentInfo: undefined,
      studentDataReady: false,
      programmes: [],
      programmesReady: false,
      programmeIdFromDropdown: undefined,
      coaches: [],
      coachesReady: false,
      employers: [],
      employersReady: false,
      dataMentors: [],
      dataMentorsReady: false,
      updateInProgress: false,
      showProgramme: true,
      userQuestions: [],
      userQuestionsReady: false,
      learnerStatus: ['Active', 'Graduated', 'On-break', 'Leaver', 'Non-starter', 'Entered EPA'],
      showNotes: true,
    };
  },

  beforeMount() {
    this.mountCallback();
  },

  watch: {
    ready() {
      if (this.programmes.length > 0) {
        this.programmeIdFromDropdown = this.selectProgrammeIdOnStart();
      } else if (this.ready) {
        this.$ktoast.info('Learner is not a member of any programme');
      }
    },

    programmeIdFromDropdown() {
      this.$router.replace({ query: { programmeId: this.programmeIdFromDropdown } });
    },

    studentId() {
      // Reset the page when the learner changes
      this.resetState();
      this.mountCallback();
    },
  },

  computed: {
    programmeIdFromRoute() {
      return this.$route.query?.programmeId ? Number(this.$route.query.programmeId) : undefined;
    },

    isLearnerSupport() {
      const currentUserId = this.store.userId;
      return Boolean((this.studentCoach?.user_id === currentUserId) || (this.studentDataMentor?.user_id === currentUserId));
    },

    hasViewNotePermissions() {
      return this.$permissions.hasPermission('view_user_notes') || this.isLearnerSupport;
    },

    ready() {
      return this.studentDataReady && this.programmesReady && !this.updateInProgress
      && this.userQuestionsReady && this.coachesReady && this.dataMentorsReady && this.employersReady;
    },

    studentId() {
      return Number(this.$route.params.studentId);
    },

    studentCoach() {
      if (this.studentInfo && this.studentInfo.coaches && this.studentInfo.coaches.length > 0) {
        return this.studentInfo.coaches.find(x => x.programme_id === this.programmeIdFromRoute);
      }
      return undefined;
    },

    studentDataMentor() {
      if (this.studentInfo && this.studentInfo.data_mentors && this.studentInfo.data_mentors.length > 0) {
        return this.studentInfo.data_mentors.find(x => x.programme_id === this.programmeIdFromRoute);
      }
      return undefined;
    },

    privilegedUser() {
      if (this.studentInfo && this.studentInfo.permissions && this.studentInfo.permissions.length > 0) {
        return true;
      }
      return false;
    },

    hubspotLink() {
      if (!this.programmes.length) {
        return undefined;
      }
      return this.programmes.find(x => x.id === this.programmeIdFromRoute)?.hubspot_link;
    },

    aptemLink() {
      if (!this.programmes.length) {
        return undefined;
      }
      return this.programmes.find(x => x.id === this.programmeIdFromRoute)?.aptem_link;
    },

    currentProgrammeName() {
      if (!this.programmes.length) {
        return undefined;
      }
      return this.programmes.find(x => x.id === this.programmeIdFromRoute)?.name;
    },

    currentProgrammeMemberId() {
      if (!this.programmes.length) {
        return undefined;
      }
      return this.programmes.find(x => x.id === this.programmeIdFromRoute)?.programme_member_id;
    },

    currentProgrammeGroups() {
      if (!this.programmes.length) {
        return undefined;
      }
      return this.programmes.find(x => x.id === this.programmeIdFromRoute)?.programme_groups;
    },

    knowledgeBaseTableRows() {
      if (!this.userQuestions) {
        return [];
      }
      const getPath = row => {
        if (row.module_id && (row.learning_unit_id || row.pak_id || row.quiz_id || row.video_id || row.slide_deck_id || row.calendar_event_id)) {
          return {
            name: 'knowledge_thread',
            params: {
              moduleId: row.module_id,
              threadId: row.id,
            },
          };
        }
        return {
          name: 'knowledge_detached_thread',
          params: {
            threadId: row.id,
          },
        };
      };
      const output = this.userQuestions.map(row => Object({
        question: {
          text: row.title,
          path: getPath(row),
        },
        module: row.module_name,
        asset: this.getItemOfContent(row),
        status: this.geStatusLabel(row.accepted_id),
        visibility: this.getVisibilityLabel(row.is_private),
        answers: row.response_count,
      }));
      return output;
    },

    knowledgeBaseTablesHeaders() {
      return {
        question: {
          type: 'url',
          name: 'Question Posted',
          filterable: true,
          sortable: true,
          hideable: false,
        },
        module: {
          name: 'Module',
          filterable: true,
          sortable: true,
          hideable: true,
        },
        asset: {
          name: 'Asset',
          filterable: true,
          sortable: true,
          hideable: true,
        },
        status: {
          name: 'Status',
          sortable: true,
          hideable: false,
        },
        visibility: {
          name: 'Visibility',
          sortable: true,
          hideable: true,
        },
        answers: {
          name: 'Answers',
          sortable: true,
        },
      };
    },

    selectedProgramme() {
      if (this.programmes.length === 0) {
        return undefined;
      }
      return this.programmeIdFromRoute ? this.programmes.find(x => x.id === this.programmeIdFromRoute) : this.programmes[0];
    },

    learnerStatusClass() {
      if (this.selectedProgramme.learner_status === 'Leaver' || this.selectedProgramme.learner_status === 'Non-starter') {
        return 'danger';
      }
      if (this.selectedProgramme.learner_status === 'Active' || this.selectedProgramme.learner_status === 'Graduated') {
        return 'success';
      }
      return '';
    },
  },

  methods: {
    resetState() {
      this.studentName = '';
      this.studentInfo = undefined;
      this.studentDataReady = false;
      this.programmes = [];
      this.programmesReady = false;
      this.programmeIdFromDropdown = undefined;
      this.coaches = [];
      this.coachesReady = false;
      this.employers = [];
      this.employersReady = false;
      this.dataMentors = [];
      this.dataMentorsReady = false;
      this.updateInProgress = false;
      this.showProgramme = true;
      this.userQuestions = [];
      this.userQuestionsReady = false;
    },

    mountCallback() {
      this.$Loading.start();
      this.getStudentProgrammes();
      this.getStudentData();
      this.getUserQuestions();
      this.getCoaches();
      this.getDataMentors();
      this.getEmployers();
    },

    selectProgrammeIdOnStart() {
      if (this.programmeIdFromRoute) {
        const pId = parseInt(this.programmeIdFromRoute, 10);
        if (!Number.isInteger(pId) || this.programmes.map(p => p.id).indexOf(pId) === -1) {
          this.$logger.warn('Learner not in specified programme - defaulting to one started most recently');
          return sortObjectArray(this.programmes, 'start_date', true)[0].id;
        }
        return pId;
      }
      return sortObjectArray(this.programmes, 'start_date', true)[0].id;
    },

    registerCrumbs() {
      this.$crumbs.register([{
        text: this.studentName || this.studentId,
        active: true,
      }]);
    },

    getCoaches() {
      this.coachesReady = false;
      if (!this.$permissions.hasPermission('manage_coaches')) {
        this.coachesReady = true;
        return;
      }
      this.$logger.info('Getting coaches');
      this.$http.get('/api/profile/coach').then(res => {
        this.$logger.info('Successfully retrieved coaches');
        this.coaches = res.data.coaches;
      }).catch(error => {
        if (this.$http.isWarning(error)) {
          this.$logger.warn('There was an error while attempting to retrieve coaches', { studentId: this.studentId }, error);
        } else {
          this.$logger.error('There was an error while attempting to retrieve coaches', { studentId: this.studentId }, error);
        }
        this.showError(error);
      }).then(() => {
        this.coachesReady = true;
      });
    },
    getDataMentors() {
      this.dataMentorsReady = false;
      if (!this.$permissions.hasPermission('manage_mentors')) {
        this.dataMentorsReady = true;
        return;
      }
      this.$logger.info('Getting data mentors');
      this.$http.get('/api/profile/data_mentor').then(res => {
        this.$logger.info('Successfully retrieved data mentors');
        this.dataMentors = res.data.data_mentors;
      }).catch(error => {
        if (this.$http.isWarning(error)) {
          this.$logger.warn('There was an error while attempting to retrieve data mentors', { studentId: this.studentId }, error);
        } else {
          this.$logger.error('There was an error while attempting to retrieve data mentors', { studentId: this.studentId }, error);
        }
        this.showError(error);
      }).then(() => {
        this.dataMentorsReady = true;
      });
    },
    getEmployers() {
      this.employersReady = false;
      if (!this.$permissions.hasPermission('manage_programmes')) {
        this.employersReady = true;
        return;
      }
      this.$logger.info('Getting employers');
      this.$http.get('/api/profile/employers').then(res => {
        this.$logger.info('Successfully retrieved employers');
        this.employers = res.data;
      }).catch(error => {
        if (this.$http.isWarning(error)) {
          this.$logger.warn('There was an error while attempting to retrieve employers', { studentId: this.studentId }, error);
        } else {
          this.$logger.error('There was an error while attempting to retrieve employers', { studentId: this.studentId }, error);
        }
        this.showError(error);
      }).then(() => {
        this.employersReady = true;
      });
    },
    getStudentProgrammes() {
      this.$logger.info('Getting student programmes', { studentId: this.studentId });
      this.programmesReady = false;
      this.$http.get(`/api/profile/user/${this.studentId}/programmes?dashboard=true`)
        .then(result => {
          this.programmes = result.data.programmes;
        }).catch(error => {
          this.$logger.error('Error getting student programmes', { studentId: this.studentId }, error);
          this.showError(error);
        }).then(() => {
          this.programmesReady = true;
        });
    },
    getStudentData() {
      this.$logger.info('Getting student profile', { studentId: this.studentId });
      this.studentDataReady = false;
      this.$http.get(`/api/profile/users/${this.studentId}`)
        .then(result => {
          this.studentName = result.data.full_name;
          this.studentInfo = result.data;
          this.registerCrumbs();
          this.$logger.info('Got student profile', { studentId: this.studentId });
        }).catch(error => {
          this.$logger.info('Error getting student profile', { studentId: this.studentId }, error);
          this.showError(error);
        }).then(() => {
          this.studentDataReady = true;
        });
    },
    getUserQuestions() {
      this.$logger.info('Getting user questions', { studentId: this.studentId });
      this.userQuestionsReady = false;
      this.$http.get(`/api/curriculum/knowledge/threads/user/${this.studentId}`)
        .then(response => {
          this.$logger.info('Got user questions', { studentId: this.studentId });
          this.userQuestions = response.data.threads;
        })
        .catch(err => {
          if (this.$http.isWarning(err)) {
            this.$logger.warn('Error getting user questions', { studentId: this.studentId }, err);
          } else {
            this.$logger.error('Error getting user questions', { studentId: this.studentId }, err);
          }
        })
        .then(() => {
          this.userQuestionsReady = true;
        });
    },
    assignNewLearnerStatus(newLearnerStatus) {
      this.updateInProgress = true;
      const payload = { learner_status: newLearnerStatus };
      this.$logger.info('Assigning new learner status to user', { learnerStatus: payload });
      this.$http.put(
        `/api/profile/admin/programmes/${this.selectedProgramme.id}/users/${this.studentId}/learner_status`,
        payload,
      ).then(() => {
        this.$logger.info('Learner status successfully updated', { learnerStatus: payload }, true);
        this.getStudentProgrammes();
      }).catch(err => {
        if (this.$http.isWarning(err)) {
          this.$logger.warn('There was an error while attempting to update learner status', undefined, err);
        } else {
          this.$logger.error('There was an error while attempting to update learner status', undefined, err);
        }
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    assignNewEstimatedGraduationDate(newGraduationDate) {
      this.updateInProgress = true;
      const payload = { egd: this.formatDate(newGraduationDate) };
      this.$logger.info('Assigning new estimated graduation date to user', { estimatedGraduationDate: payload });
      this.$http.put(
        `/api/profile/admin/programmes/${this.selectedProgramme.id}/users/${this.studentId}/graduation_date`,
        payload,
      ).then(() => {
        this.$logger.info('Estimated graduation date successfully updated');
        this.getStudentProgrammes();
      }).catch(err => {
        if (this.$http.isWarning(err)) {
          this.$logger.warn('There was an error while attempting to update estimated graduation date', undefined, err);
        } else {
          this.$logger.error('There was an error while attempting to update estimated graduation date', undefined, err);
        }
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    assignNewCoach(newCoachId) {
      this.updateInProgress = true;
      const payload = {
        coach_and_mentors: [
          {
            user_id: parseInt(this.studentId, 10),
            coach_user_id: parseInt(newCoachId, 10),
            data_mentor_user_id: this.studentDataMentor?.user_id || null,
            programme_id: this.programmeIdFromRoute,
          },
        ],
      };
      this.$logger.info('Assigning new coach to user', { userCoaches: payload });
      this.$http.put('/api/profile/learner_support/bulk', payload).then(() => {
        this.$logger.info('Coach successfully assigned');
        this.getStudentData();
      }).catch(err => {
        if (this.$http.isWarning(err)) {
          this.$logger.warn('There was an error while attempting to assign new coach', undefined, err);
        } else {
          this.$logger.error('There was an error while attempting to assign new coach', undefined, err);
        }
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    assignNewDataMentor(newDataMentorId) {
      this.updateInProgress = true;
      const payload = {
        coach_and_mentors: [
          {
            user_id: parseInt(this.studentId, 10),
            coach_user_id: this.studentCoach?.user_id || null,
            data_mentor_user_id: parseInt(newDataMentorId, 10),
            programme_id: this.programmeIdFromRoute,
          },
        ],
      };
      this.$logger.info('Assigning new data mentor to user', { userDataMentors: payload });
      this.$http.put('/api/profile/learner_support/bulk', payload).then(() => {
        this.$logger.info('Data mentor successfully assigned');
        this.getStudentData();
      }).catch(err => {
        if (this.$http.isWarning(err)) {
          this.$logger.warn('There was an error while attempting to assign new data mentor', undefined, err);
        } else {
          this.$logger.error('There was an error while attempting to assign new data mentor', undefined, err);
        }
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    assignNewEmployer(newEmployerId) {
      this.updateInProgress = true;
      this.$logger.info('Assigning new employer to user');
      this.$http.put(`/api/profile/user/${this.studentId}/programme/${this.programmeIdFromRoute}/employer/${newEmployerId}`).then(() => {
        this.$logger.info('Employer successfully assigned');
        this.getStudentData();
      }).catch(err => {
        if (this.$http.isWarning(err)) {
          this.$logger.warn('There was an error while attempting to assign employer', undefined, err);
        } else {
          this.$logger.error('There was an error while attempting to assign employer', undefined, err);
        }
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    geStatusLabel(status) {
      if (status) {
        return '<span class="tag-answered">Answered <i class="fas fa-check"></i></span>';
      }
      return '';
    },
    getVisibilityLabel(isPrivate) {
      if (isPrivate) {
        return '<span class="tag-private">Private</span>';
      }
      return '<span class="tag-public k-text-primary">Public</span>';
    },
    getItemOfContent(item) {
      let assetName = '';
      Object.keys(ASSET_TYPES).forEach(key => {
        if (item[`${key}_id`]) {
          assetName = item[`${key}_name`];
        }
      });
      return assetName;
    },
    handleFreezeClick() {
      if (this.selectedProgramme.is_frozen) {
        this.unfreezeProgramme();
        return;
      }
      this.freezeProgramme();
    },
    freezeProgramme() {
      this.updateInProgress = true;
      this.$logger.info('Freezing user on programme', { userId: this.studentId, programmeId: this.selectedProgramme.id });
      this.$http.put(`/api/profile/user/${this.studentId}/programmes/${this.selectedProgramme.id}/freeze`).then(() => {
        this.$logger.info('User access to programme frozen');
        this.getStudentProgrammes();
      }).catch(err => {
        this.$logger.error('There was an error while attempting to freeze user access to programme', { userId: this.studentId, programmeId: this.selectedProgramme.id, err });
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    unfreezeProgramme() {
      this.updateInProgress = true;
      this.$logger.info('Unfreezing user on programme', { userId: this.studentId, programmeId: this.selectedProgramme.id });
      this.$http.put(`/api/profile/user/${this.studentId}/programmes/${this.selectedProgramme.id}/unfreeze`).then(() => {
        this.$logger.info('User access to programme unfrozen');
        this.getStudentProgrammes();
      }).catch(err => {
        this.$logger.error('There was an error while attempting to Unfreezing user access to programme', { userId: this.studentId, programmeId: this.selectedProgramme.id, err });
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
  },
};
</script>
