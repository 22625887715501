<template>
  <div>
    <k-panel title="Create"
      icon="fa-drafting-compass"
      :showContent="showCreateForm"
      @toggle="showCreateForm = $event"
    >
      <template #body>
        <p class="create-form-instructions">
          Create an <i class="highlight">empty</i> {{blueprintType}} blueprint by specifying
          a name and a description. Once the blueprint has been created, you can add content
          to it by clicking the link for that blueprint in the table below.
        </p>
        <slot name="create-form">
        </slot>
      </template>
    </k-panel>
    <k-panel title="Current"
      icon="fa-drafting-compass"
      :showContent="showCurrentBlueprints"
      @toggle="showCurrentBlueprints = $event"
    >
      <template #body>
        <p>
          To make changes to a {{blueprintType}} blueprint, click on the blueprint
          <i class="highlight">name</i> in the table</p>
        <k-table
          :headers="kableHeaders"
          :rows="kableRows"
          :hideable="true"
          :panel="false"
          :max="20"
          @clicked="tableCallback">
        </k-table>
      </template>
    </k-panel>
  </div>
</template>

<script>
import KPanel from '../../../components/k-panel.vue';
import KTable from '../../../components/k-table.vue';

export default {
  components: {
    KTable,
    KPanel,
  },

  props: {
    blueprintType: {
      type: String,
      required: true,
    },
    kableRows: {
      type: Array,
      required: true,
    },
    kableHeaders: {
      type: Object,
      required: true,
    },
    tableCallback: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      showCreateForm: false,
      showCurrentBlueprints: true,
    };
  },
};
</script>
