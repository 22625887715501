<template>
  <div>
    <h1>Videos Overview</h1>
    <asset-overview v-if="ready"
      asset-icon="fa-film"
      :assets="sortedVideos"
      :kable-headers="kableHeaders"
      :kable-rows="kableRows"
      :show-archived-assets="showArchived"
      @tablecallback="tableCallback"
      @archive-toggle="updateArchiveToggle"
    >
      <template #new-asset>
        <upload-video @video-uploaded="getVideos"></upload-video>
      </template>
      <template #preview-asset>
        <preview-video :videoId="previewVideoId" @close="closePreviewModal"></preview-video>
          <update-video :video="videoToUpdate"
            @close="closeEditModal"
            @update="getVideos">
          </update-video>
      </template>
    </asset-overview>
  </div>
</template>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import TimeMixin from '../../../../mixins/time-mixins';
import PageReadyMixin from '../../../../mixins/page-ready-mixin';
import ArchiveToastConfirmMixin from '../archive-toast-confirm-mixin';
import getIcon from '../../../../modules/kate-table-icons';
import { sortObjectArray } from '../../../../modules/sort-by-object-property';
import UploadVideo from './upload-video.vue';
import PreviewVideo from './preview-video.vue';
import UpdateVideo from './update-video.vue';
import AssetOverview from '../asset-overview.vue';

export default {
  components: {
    UploadVideo,
    PreviewVideo,
    UpdateVideo,
    AssetOverview,
  },

  mixins: [ErrorMixin, TimeMixin, PageReadyMixin, ArchiveToastConfirmMixin],

  data() {
    return {
      videosReady: false,
      showUploadVideo: false,
      uploadedVideos: [],
      previewVideoId: undefined,
      masks: {
        input: 'DD-MM-YYYY',
      },
      videoToUpdate: undefined,
      showArchived: false,
    };
  },

  beforeMount() {
    this.getVideos();
  },

  watch: {
    ready() {
      if (this.ready) {
        this.$Loading.finish();
      } else {
        this.$Loading.start();
      }
    },
  },

  computed: {
    ready() {
      return this.videosReady;
    },

    sortedVideos() {
      return sortObjectArray(this.uploadedVideos.slice(), 'date_uploaded', true);
    },

    kableHeaders() {
      return {
        edit: {
          name: 'Edit',
          type: 'action',
        },
        preview: {
          name: 'Preview',
          type: 'action',
        },
        name: {
          name: 'Title',
          filterable: true,
          sortable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
        },
        date_recorded: {
          name: 'Recorded',
          sortable: true,
          type: 'date',
        },
        date_uploaded: {
          name: 'Uploaded',
          sortable: true,
          type: 'timestamp',
        },
        displayTags: {
          name: 'Tags',
          type: 'tags',
          filterable: true,
          sortable: true,
        },
        archive: {
          name: 'Archive',
          type: 'action',
          sortable: true,
        },
        delete: {
          name: 'Delete',
          type: 'action',
        },
      };
    },

    kableRows() {
      return this.sortedVideos.map((val, index) => ({
        date_recorded: val.date_recorded,
        date_uploaded: val.date_recorded,
        ...val,
        edit: {
          key: index,
          text: getIcon({ icon: 'edit' }),
        },
        name: {
          key: index,
          text: val.name,
          path: {
            name: 'curriculum_asset_details',
            params: {
              assetId: val.id,
              assetType: 'video',
            },
          },
        },
        preview: {
          key: index,
          text: getIcon({
            color: this.previewColors(val.processing_status),
            icon: this.getStatusIcon(val.processing_status),
            fontSize: '1.4em',
          }),
        },
        displayTags: {
          tags: val.tags,
          sortBy: val.tags ? val.tags.join(' ') : '',
        },
        archive: {
          text: this.assetArchiveButton(val.is_active),
        },
        delete: {
          key: index,
          text: getIcon({ color: 'var(--kate-danger-alt)', icon: 'trash' }),
        },
      })).filter(video => (this.showArchived || video.is_active));
    },
  },

  methods: {
    updateArchiveToggle(val) {
      this.showArchived = val;
    },

    closePreviewModal() {
      this.previewVideoId = undefined;
    },

    closeEditModal() {
      this.videoToUpdate = undefined;
    },

    previewColors(status) {
      return {
        NOT_STARTED: 'var(--kate-primary)',
        IN_PROGRESS: 'var(--kate-primary)',
        FAILED: 'var(--kate-warning)',
        SUCCESS: 'var(--kate-success)',
      }[status];
    },

    getStatusIcon(status) {
      return {
        NOT_STARTED: 'loading',
        IN_PROGRESS: 'loading',
        FAILED: 'warning',
        SUCCESS: 'play',
      }[status];
    },

    getVideos() {
      this.$logger.info('Getting videos');
      this.$Loading.start();
      this.videosReady = false;
      this.$http.get('/api/curriculum/admin/video?active_only=false')
        .then(result => {
          this.uploadedVideos = result.data.videos;
        }).catch(err => {
          if (err.response && err.response.status !== 404) {
            this.$logger.error('Error getting videos', undefined, err);
            this.showError(err);
          } else {
            this.$logger.warn('No videos found');
          }
        }).then(() => {
          this.videosReady = true;
          this.$Loading.finish();
        });
    },

    removeConfirmationCallback(videoId) {
      this.$logger.info('Deleting video', { videoId });
      this.$Loading.start();
      this.$http.delete(`/api/curriculum/admin/video/${videoId}`)
        .then(() => {
          this.$logger.info('Video has been deleted', { videoId });
          this.$ktoast.success('Video has been deleted');
          this.getVideos();
        }).catch(err => {
          this.$logger.autowarn('Error deleting video', { videoId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    removeVideo(videoId) {
      this.$ktoast.confirmToast('You are about to delete this video.', 'warning', () => {
        this.removeConfirmationCallback(videoId);
      });
    },
    // Archiving Videos
    archiveCallback(videoId) {
      this.$logger.info('Archiving video', { videoId });
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/video/${videoId}/archive`)
        .then(() => {
          this.$logger.info('Videos has been archived', { videoId });
          this.$ktoast.success('Video has been archived');
          this.getVideos();
        }).catch(err => {
          this.$logger.error('Error archiving video', { videoId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    unarchiveCallback(videoId) {
      this.$logger.info('Unarchiving video', { videoId });
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/video/${videoId}/unarchive`)
        .then(() => {
          this.$logger.info('Video has been unarchived', { videoId });
          this.$ktoast.success('Video has been unarchived');
          this.getVideos();
        }).catch(err => {
          this.$logger.error('Error unarchiving video', { videoId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    tableCallback(key, index, row, header) {
      if (header === 'delete') {
        this.removeVideo(row.id);
      }
      if (header === 'preview') {
        if (row.processing_status === 'SUCCESS') {
          this.previewVideoId = row.id;
        }
      }
      if (header === 'archive') {
        this.tableArchiveCallback(row);
      }
      if (header === 'edit') {
        const vid = this.uploadedVideos.find(x => x.id === row.id);
        if (vid && typeof vid === 'object') {
          this.videoToUpdate = JSON.parse(JSON.stringify(vid));
        } else {
          this.videoToUpdate = undefined;
        }
      }
    },
  },
};
</script>
