import ChaptersMixin from './chapters-mixin';
import AssetIconMixin from './asset-icon-mixin';
import { ASSET_TYPES } from '../constants';

const DEFAULT_CHAPTER_TITLES = Object.values(ASSET_TYPES).map(x => x.defaultChapterTitle);

export default {
  mixins: [ChaptersMixin, AssetIconMixin],

  data() {
    return {
      showSectionContent: true,
    };
  },

  computed: {
    hasAssets() {
      return this.chapter.assets && this.chapter.assets.length > 0;
    },
    chapters() {
      // Used for the chapter mixin
      return [this.chapter];
    },
    toggleClass() {
      return this.showSectionContent ? 'fa-angle-up' : 'fa-angle-down';
    },
    isLearningUnits() {
      return Boolean(this.chapter.title === 'Learning Units');
    },
    isCustomChapter() {
      return DEFAULT_CHAPTER_TITLES.indexOf(this.chapter.title) === -1;
    },
    chapterProgress() {
      return Math.floor(this.averageMandatoryAssetScore);
    },
    averageMandatoryAssetScore() {
      const scoreSum = this.mandatoryAssetScores.reduce((partial, a) => partial + a, 0);
      return scoreSum / this.mandatoryAssets.length;
    },
    mandatoryAssetScores() {
      return this.mandatoryAssets.map(a => {
        if (a.asset_type !== 'pak' && a.asset_type !== 'quiz') {
          return a.is_complete ? 100 : 0;
        }
        return a.progress_score || a.latest_score || 0;
      });
    },
    mandatoryAssets() {
      return this.chapter.assets.filter(a => a.is_mandatory !== false);
    },
  },

  methods: {
    assetPrettyName(index) {
      return ASSET_TYPES[this.assets[index].asset_type]?.prettyName || '';
    },
  },
};
