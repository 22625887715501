import ErrorMixin from './error-mixins';

export default {

  mixins: [ErrorMixin],

  data() {
    return {
      badges: [],
      badgesReady: false,
      badgesUpdated: false,
      removedBadges: [],
      selectedBadges: [],
    };
  },

  watch: {
    badgesUpdated() {
      this.$emit('badges-updated', this.badgesUpdated);
    },
  },

  methods: {
    filterExcludedBadges(excludedBadges) {
      return this.badges.filter(badge => !excludedBadges?.map(eb => eb.id).includes(badge.id));
    },
    setScoreThreshold(badgeThreshold) {
      this.badgesUpdated = true;
      this.selectedBadges[badgeThreshold.index].threshold_score = parseFloat(badgeThreshold.threshold);
    },
    removeBadges(index) {
      this.badgesUpdated = true;
      this.removedBadges = this.removedBadges.concat(this.selectedBadges.splice(index, 1));
    },
    addBadge(badge) {
      if (!this.selectedBadges.find(b => b.id === badge.id)) {
        this.badgesUpdated = true;
        this.selectedBadges.push({ threshold_score: 100, ...badge });
      }
    },
    getBadges() {
      this.$logger.info('Getting badges');
      this.badgesReady = false;
      this.$http.get('/api/curriculum/badge')
        .then(result => {
          this.badges = result.data.badges;
        }).catch(err => {
          if (!this.$http.errIn(err, [404])) {
            this.showError(err);
          }
          this.$logger.autowarn('Error getting badges', undefined, err);
          this.badges = [];
        }).then(() => {
          this.badgesReady = true;
        });
    },
  },
};
