<template>
  <div>
    <template v-if="$featureFlags.isEnabled('kod-learning-environment')">
      <template v-if="type === 'link'">
        <div class="link-view-container">
          <div class="link-view-header">
            {{ text }}:
          </div>
          <div class="link-view-body">
            <a v-if="legacyKloudUrl" class="kloud-link-anchor" :href="legacyKloudUrl" target="_blank">
              <i class="fas fa-cloud"></i>Learning Environment
            </a>
            <button @click="$emit('open')" class="btn-link kloud-link-anchor">
              <i class="fas fa-cloud"></i>Learning Environment v2
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="learning-env-container">
          <button @click="menuOpen = !menuOpen" class="btn btn-outlined">
            {{ text }} <i class="fas fa-caret-down"></i>
          </button>
        </div>
        <div class="kod-dropdown-items dropdown" :class="additionalClasses">
          <a v-if="legacyKloudUrl" class="kloud-btn btn btn-outlined" :href="legacyKloudUrl" target="_blank">
            <i class="fas fa-cloud"></i>Learning Environment
          </a>
          <button @click="$emit('open')" class="btn-link kloud-link-anchor">
            <i class="fas fa-cloud"></i>Learning Environment v2
          </button>
        </div>
      </template>
    </template>
    <template v-else>
      <template v-if="legacyKloudUrl">
        <template v-if="type === 'link'">
          <div class="link-view-container">
            <div class="link-view-body">
              <a class="btn btn-outlined" :href="legacyKloudUrl" target="_blank">
                <i class="fas fa-cloud"></i>{{ text }}
              </a>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="kloud-launch-button">
            <k-tooltip class="bottom"
              text="Click to open notebook in a cloud-based Jupyter environment, with all the main data science libraries pre-installed"
            >
              <a class="kloud-btn btn btn-outlined" :href="legacyKloudUrl" target="_blank">
                <i class="fas fa-cloud"></i>{{ text }}
              </a>
            </k-tooltip>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<style scoped>
.kod-dropdown {
  position: relative;
}

.link-view-header {
  font-weight: bold;
}

.link-view-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}

.dropdown {
  position: absolute;
  transition: all 0.2s ease-in-out;
  margin-top: 5px;
}

.kod-dropdown-items {
  display: none;
  flex-direction: column;
  z-index: 2;
  gap: 1rem;
  border: 1px solid var(--kate-type-alt);
  padding: 1rem;
  background-color: var(--kate-panel);
  border-radius: 5px;
}

.kod-dropdown-items.open {
  display: flex;
}

i.fa-cloud {
  margin: 0 5px;
  font-size: 0.85rem;
}

</style>

<script>
import KTooltip from './k-tooltip.vue';

export default {
  props: {
    assetType: {
      type: String,
      required: true,
    },
    assetId: {
      type: Number,
      required: true,
    },
    version: {
      type: String,
    },
    text: {
      type: String,
      default: 'Open Learning Environment',
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  components: {
    KTooltip,
  },
  computed: {
    additionalClasses() {
      return this.menuOpen ? 'open' : '';
    },

    legacyKloudUrl() {
      if (this.assetType === 'module_pak') {
        return `/api/curriculum/kloud/pak/${this.assetId}/spin`;
      }
      if (this.assetType === 'module_lu') {
        const url = `/api/curriculum/kloud/lu/${this.assetId}/spin`;
        if (this.version) {
          return `${url}?version=${this.version}`;
        }
        return url;
      }
      // only module_assets are compatible with legacy
      return undefined;
    },
  },
};
</script>
