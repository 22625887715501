<template>
  <div v-if="ready" class="content-with-spacing">
    <section class="user-header">
      <hr>
      <div class="student-info">
        <figure class="avatar">
          <img :src="avatarUrl" alt="profile picture" class="img-circle img-responsive k-profile-avatar"/>
          <figcaption class="change-avatar-text">
            <k-tooltip text="Gravatars are tied to your email. Change it by going to the official site via the link 'Gravatars' below">
              KATE uses <a href="https://gravatar.com">Gravatars</a>
              <sup><i class="fas fa-info-circle info-icon info-tooltip-container"></i></sup>
            </k-tooltip>
          </figcaption>
        </figure>
        <div>
          <h2 v-if="preferredName">{{preferredName}}</h2>
          <h2 v-else>{{fullName}}</h2>
          <span class="user-id">Learner ID: <b>{{ learnerId }}</b></span>
        </div>
      </div>

      <hr class="divider-hr" />

      <div class="mentor-info" v-if="hasCoaches">
        <h2>Coaches</h2>
        <k-dropdown v-model="selectedCoachId" :options="coachOptions" placeholder="Select Coach" :showSearch="false"></k-dropdown>
        <template v-if="selectedCoach">
          <span class="mentor-programme">
            Programme: <b>{{ selectedCoach.programme_name }}</b>
          </span>
          <div class="sub-header-button-container"  v-if="selectedCoach.acuity_link">
            <a :href="selectedCoach.acuity_link" target="_blank">
              <button class="btn btn-primary">Book Appointment <i class="fas fa-calendar"></i></button>
            </a>
          </div>
        </template>
      </div>

      <hr class="divider-hr" />

      <div class="mentor-info" v-if="hasDataMentors">
        <h2>Data Mentors</h2>
        <k-dropdown v-model="selectedDataMentorId" :options="dataMentorOptions" placeholder="Select Data Mentor" :showSearch="false"></k-dropdown>
        <template v-if="selectedDataMentor">
          <span class="mentor-programme">
            Programme: <b>{{ selectedDataMentor.programme_name }}</b>
          </span>
          <div class="sub-header-button-container"  v-if="selectedDataMentor.acuity_link">
            <a :href="selectedDataMentor.acuity_link" target="_blank">
              <button class="btn btn-primary">Book Appointment <i class="fas fa-calendar"></i></button>
            </a>
          </div>
        </template>
      </div>
      <hr class="k-profile-page-hr" />
    </section>

    <section class="activity-heatmap">
      <div class="panel panel-default">
        <div class="panel-heading">
          <span class="panel-title">Activity</span>
        </div>
        <div class="panel-body">
          <heatmap :activities="userActivity"
            domain="year"
            :show-domain="true"
            :buttons="true"
            ref="userHeatmap">
          </heatmap>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
hr:first-child {
  margin-top: 10px;
}

hr:last-child {
  margin-bottom: 10px;
}

.mentor-info,
.student-info,
.user-header {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.mentor-info {
  display: flex;
  flex-direction: column;
}

.change-avatar-text {
  font-size: small;
  text-align: center;
  margin-top: 0.5em;
}

.k-profile-avatar {
  width: 100%;
  max-width: 150px;
  min-width: 100px;
}

.user-header {
  justify-content: space-between;
}

.user-header > div {
  min-width: 25%;
}

.user-header .sub-header-button-container {
  width: 100%;
}

.user-header hr.divider-hr {
  height: 105px;
  border-color: var(--kate-panel-alt);
  margin-top: 0;
  margin-bottom: 0;
  width: unset;
}

.profile-horizontal-hr {
  display: none;
}

.user-header hr {
  border: 1px solid var(--kate-type-primary);
  width: 100%;
}

.activity-heatmap {
  width: 100%;
}

.activity-heatmap .panel-heading {
  background-color: var(--kate-background-body-alpha);
}

.panel-title {
  display: flexbox;
  display: flex;
  justify-content: center;
}

.activity-heatmap .panel-default {
  max-width: 900px;
  margin: 0 auto;
}

.avatar {
  width: 150px;
  height: 175px;
}

.mentor-programme > b {
  color: var(--kate-primary);
}

@media (max-width: 767px) {
  .k-profile-avatar {
    max-width: 100px;
  }

  .user-header hr.divider-hr {
    height: 0;
    width: 100%;
  }

  .avatar {
    height: 150px;
  }
}
</style>

<script>
import useGlobalStore from '../stores/global';
import formatId from '../modules/format-user-id';
import Heatmap from '../components/k-heatmap.vue';
import ErrorMixin from '../mixins/error-mixins';
import PageReadyMixin from '../mixins/page-ready-mixin';
import CoachDataMentorMixin from '../mixins/coach-data-mentor-mixin';
import KTooltip from '../components/k-tooltip.vue';
import KDropdown from '../components/k-dropdown.vue';

export default {
  components: {
    heatmap: Heatmap,
    'k-tooltip': KTooltip,
    'k-dropdown': KDropdown,
  },

  mixins: [ErrorMixin, PageReadyMixin, CoachDataMentorMixin],

  data() {
    return {
      store: useGlobalStore(),
      userActivity: {},
      userActivityReady: false,
    };
  },

  beforeMount() {
    this.getUserActivity();
    this.$Loading.start();
  },

  computed: {
    ready() {
      return this.userActivityReady;
    },

    avatarUrl() {
      return this.store.avatarUrl;
    },

    fullName() {
      if (!this.store.userFullName) {
        return undefined;
      }
      return this.store.userFullName;
    },

    preferredName() {
      if (!this.store.userPreferredName) {
        return undefined;
      }
      return this.store.userPreferredName;
    },

    learnerId() {
      if (!this.store.userId) {
        return undefined;
      }
      return formatId(this.store.userId);
    },
  },

  methods: {
    getUserActivity() {
      this.$logger.info('Getting activity data');
      this.userActivityReady = false;
      this.$http.get('/api/stats/activity')
        .then(response => {
          this.userActivity = response.data.activity;
          this.$logger.info('Got activity data', undefined, true);
        })
        .catch(err => {
          this.$logger.error('Error getting user activity data', undefined, err);
          this.showError(err, true);
        })
        .then(() => {
          this.userActivityReady = true;
        });
    },
  },
};
</script>
