<template>
  <div class="tree-sidebar">
    <tree-node :node="tree" @fileclicked="fileClicked" :current-filename="`./${currentFilename}`"
       :changed-files="sanitisedChangedFiles" :show-cwd="false" :edit-mode="editMode"></tree-node>
  </div>
</template>

<style>
.tree-sidebar > .tree-node {
  position: relative;
  display: block;
  margin-top: 1em;
  font-size: 0.85em;
}

.tree-node > ul.folders-container,
.tree-sidebar > ul {
  padding-left: 0;
}

.tree-sidebar > .tree-node::-webkit-scrollbar-thumb,
.tree-sidebar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 3px;
  box-shadow: none;
}

</style>

<style scoped>
.tree-sidebar {
  width: 100%;
  height: 100%;
  overflow: auto;
  white-space: nowrap;
}

.tree-sidebar::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

</style>

<script>
import TreeNode from './tree-sidebar-node.vue';

export default {
  components: {
    'tree-node': TreeNode,
  },
  props: {
    changedFiles: {
      type: Array,
      default: () => [],
    },
    tree: {
      type: Object,
      required: true,
    },
    currentFilename: {
      type: String,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sanitisedChangedFiles() {
      return this.changedFiles.map(x => `./${x}`);
    },
  },
  methods: {
    fileClicked(payload) {
      this.$emit('fileclicked', payload.slice(2));
    },
  },
};
</script>
