<template>
  <div class="k-editable-text-editor">
    <fieldset v-if="editMode" class="detail-form form-entry">
      <label for="edit-text-editor" class="field-label">{{ label }}</label>
      <k-text-editor id="edit-text-editor"
          v-model="editorText"
          :customToolbar="[['bold', 'italic', 'underline'], ['link'], [{ list: 'ordered' }, { list: 'bullet' }]]"
          :placeholder="'Enter text here...'"
      ></k-text-editor>
    </fieldset>
    <div class="no-edit" v-else>
      <div class="slot-content">
        <slot>
          <div class="desc-area" v-html="$kpurify.sanitise(text) || 'No text found'"></div>
        </slot>
      </div>
      <button @click="switchToEditMode" class="btn btn-primary" title="Edit" aria-label="Edit">
        <i class="fas fa-edit"></i>
      </button>
    </div>
  </div>
</template>

<style scoped>
  .k-editable-text-editor {
    margin-bottom: 1em;
  }

  .field-label {
    display: block;
    font-style: italic;
    color: var(--kate-primary);
  }

  fieldset {
    display: block;
  }

  .no-edit {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 10px;
  }

  .no-edit .slot-content h1,
  .no-edit .slot-content h2,
  .no-edit .slot-content h3,
  .no-edit .slot-content h4,
  .no-edit .slot-content h5,
  .no-edit .slot-content h6,
  .no-edit .slot-content p {
    margin: 0;
  }

  .btn.btn-danger,
  .btn.btn-success {
    padding: 4px 8px;
  }

  .btn.btn-primary {
    padding: 3px 6px 4px 8px;
  }
</style>

<script>
import KTextEditor from './k-text-editor.vue';

export default {
  components: {
    KTextEditor,
  },

  props: {
    text: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editorText: '',
    };
  },

  mounted() {
    this.editorText = this.text;
  },

  watch: {
    text(val) {
      this.editorText = val || '';
    },
    editorText(val) {
      this.$emit('update:text', val);
    },
  },

  methods: {
    switchToEditMode() {
      this.$emit('update:editMode', true);
    },
  },
};
</script>
