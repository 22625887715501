<template>
  <div class="content-with-spacing">
    <div class="maintenance-panel">
      <div class="maintenance-img-container">
      <img src="../../img/edukate_icon.svg" class="edukate-logo-maintenance" alt="Edukate logo">
      </div>
      <div class="maintenance-message">KATE is currently undergoing maintenance. Please try again later.</div>
    </div>
  </div>
</template>

<style>
.maintenance-panel {
  margin-top: 10vh;
}

.maintenance-img-container {
  display: flex;
  justify-content: center;
}

.maintenance-message {
  margin-top: 1em;
  font-size: larger;
  color: var(--kate-mono-0);
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.edukate-logo-maintenance {
  width: 10em;
  opacity: 1;
  animation: fade 6s linear;
  animation-iteration-count: infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}
</style>

<script>
import useGlobalStore from '../stores/global';

const REFRESH_INTERVAL = 2000; // 2 seconds

export default {
  data() {
    return {
      store: useGlobalStore(),
      interval: undefined,
    };
  },

  mounted() {
    this.startLoop();
    this.$logger.pageReady();
  },

  beforeUnmount() {
    if (this.interval !== undefined) {
      clearInterval(this.interval);
    }
  },

  methods: {
    startLoop() {
      this.interval = setInterval(() => {
        this.checkStatus();
      }, REFRESH_INTERVAL);
    },
    checkStatus() {
      this.$http.get('/api/status').then(() => {
        this.store.unsetMaintenance();
        const prevPage = this.$cache.get('maintenance-prev-page');
        if (prevPage !== null) {
          this.$router.push(prevPage);
        } else {
          this.$router.push('/');
        }
      }).catch(() => {
        console.log('maintenance mode'); // eslint-disable-line no-console
      });
    },
  },
};
</script>
