<template>
  <div class="panel panel-default">
    <div class="panel-body">
      <div class="panel-header">
        <slot name="header">
          <h2 v-if="hasHeaderContent">
            <i v-if="icon" :class="iconClass"></i>
            {{ title }}
            <i v-if="hasContentToggle"
              :class="toggleClass"
              class="content-toggle"
              @click="$emit('toggle', !showContent)"
            ></i>
          </h2>
        </slot>
      </div>
      <div v-show="showContent" class="panel-content">
        <slot name="body"></slot>
      </div>
      <div v-if="!showContent" class="panel-content default">
        <i>Content is hidden, click the expand button to show.</i>
      </div>
    </div>
  </div>
</template>

<style scoped>
.panel-header {
  align-items: center;
}

.content-toggle {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--kate-type-primary);
  float: right;
  cursor: pointer;
}

.content-toggle:hover {
  color: var(--kate-type-secondary);
}
</style>

<script>

export default {
  props: {
    title: {
      type: String,
    },
    hasContentToggle: {
      type: Boolean,
      default: true,
    },
    showContent: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
    },
    duotone: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    hasHeaderContent() {
      return this.title || this.icon || this.hasContentToggle;
    },
    toggleClass() {
      return `fas fa-angle-${this.showContent ? 'up' : 'down'}`;
    },
    iconClass() {
      return this.icon ? `k-asset-icon ${this.icon} ${this.duotone ? 'fa-duotone' : 'fas'}` : undefined;
    },
  },
};
</script>
