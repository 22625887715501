<template>
  <div :id="editorId" ref="editor"></div>
</template>

<style>
.cm-editor {
  height: unset;
}
</style>

<script>
import {
  keymap, EditorView, lineNumbers, rectangularSelection, crosshairCursor, drawSelection,
} from '@codemirror/view';
import { EditorSelection, Compartment } from '@codemirror/state';
import { defaultKeymap, history, indentWithTab } from '@codemirror/commands';
import { closeBrackets } from '@codemirror/autocomplete';

import { indentOnInput, indentUnit, bracketMatching } from '@codemirror/language';
import { java } from '@codemirror/lang-java';
import { python } from '@codemirror/lang-python';
import { markdown } from '@codemirror/lang-markdown';

import { materialDark, materialLight } from '@uiw/codemirror-theme-material';

const EXTENSION_MODE_MAP = {
  py: 'python',
  java: 'java',
  md: 'markdown',
};

const MODE_LANG_MAP = {
  python,
  java,
  markdown,
  text: () => [],
};

export default {
  props: {
    theme: {
      type: String,
      default: 'dark',
    },
    readOnly: {
      type: [Boolean],
      default: false,
    },
    filename: {
      type: String,
    },
    text: {
      type: String,
      default: '',
    },
    syntax: {
      type: String,
    },
    lineNumbers: {
      type: Boolean,
      default: true,
    },
    autoRefresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: undefined,
      readOnlyCompartment: new Compartment, // eslint-disable-line
      historyCompartment: new Compartment, // eslint-disable-line
      langCompartment: new Compartment, // eslint-disable-line
      themeCompartment: new Compartment, // eslint-disable-line
      lineNumbersCompartment: new Compartment, // eslint-disable-line
    };
  },
  computed: {
    spaceOnly() {
      if (this.filename === undefined) {
        return false;
      }
      return this.filename.split('.').pop() === 'py';
    },
    editorId() {
      return `editor-${this.$.uid}`;
    },
    language() {
      if (this.syntax) {
        return this.syntax;
      }
      if (this.filename === undefined) {
        return 'text';
      }
      const extension = this.filename.split('.').pop();
      return EXTENSION_MODE_MAP[extension] || 'text';
    },
  },
  mounted() {
    this.setupEditor();
  },
  watch: {
    text(text) {
      this.setValue(text);
    },
    theme(theme) {
      this.setTheme(theme);
    },
    filename() {
      this.setMode(this.language);
    },
    readOnly(nuval) {
      if (this.editor === undefined) {
        return;
      }
      this.editor.dispatch({
        effects: this.readOnlyCompartment.reconfigure(EditorView.editable.of(!nuval)),
      });
    },
  },
  methods: {

    setupEditor() {
      const updateListenerExtension = EditorView.updateListener.of(update => {
        if (update.docChanged) {
          this.emitInput();
        }
      });

      const config = {
        extensions: [
          keymap.of([indentWithTab]),
          keymap.of(defaultKeymap),
          indentOnInput(),
          indentUnit.of('    '),
          bracketMatching(),
          closeBrackets(),
          drawSelection(),
          rectangularSelection(),
          crosshairCursor(),
          updateListenerExtension,
          EditorView.domEventHandlers({
            paste: this.pasteHandler,
          }),
          this.readOnlyCompartment.of(EditorView.editable.of(!this.readOnly)),
          this.historyCompartment.of([history()]),
          this.langCompartment.of(MODE_LANG_MAP[this.language]()),
          this.themeCompartment.of(this.getTheme()),
          this.lineNumbersCompartment.of(this.lineNumbers ? lineNumbers() : []),
        ],
        parent: this.$refs.editor,
      };
      this.editor = new EditorView(config);
      this.setValue(this.text);
      this.$emit('init');
    },
    getValue() {
      return this.editor.state.doc.toString();
    },
    emitInput() {
      this.$emit('docchange');
    },
    setTheme() {
      this.editor.dispatch({ effects: this.themeCompartment.reconfigure(this.getTheme()) });
    },
    getTheme() {
      return this.theme === 'dark' ? materialDark : materialLight;
    },
    setMode(mode) {
      this.editor.dispatch({ effects: this.langCompartment.reconfigure(this.getLanguageExtension(mode)) });
    },
    getLanguageExtension(mode) {
      return MODE_LANG_MAP[mode]();
    },
    setValue(val) {
      this.editor.dispatch({
        changes: { from: 0, to: this.editor.state.doc.length, insert: val },
      });
    },
    resetHistory() {
      this.editor.dispatch({
        effects: this.historyCompartment.reconfigure([]),
      });
      this.editor.dispatch({
        effects: this.historyCompartment.reconfigure([this.getFreshHistory()]),
      });
    },
    getFreshHistory() {
      return history();
    },
    pasteHandler(event, view) {
      if (this.readOnly) {
        return true;
      }
      const text = event.clipboardData.getData('text');
      const ranges = view.state.selection.ranges[0];
      const fromIdx = ranges.from;
      const toIdx = ranges.to;
      // Tabs should be converted into 4 spaces
      const textModified = text.replace(/\t/g, '    ');

      view.dispatch(
        {
          changes: {
            from: fromIdx,
            to: toIdx,
            insert: textModified,
          },
          selection: EditorSelection.single(fromIdx + textModified.length),
        },
      );
      return true;
    },
  },
};
</script>
