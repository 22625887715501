function isEmpty(obj) {
  /**
   * Use to check if a Javascript object is empty, i.e. has no properties
   * @param obj A JS object, { ... }
   */
  for (const prop in obj) { // eslint-disable-line no-restricted-syntax
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
}

export default isEmpty;
