export default {
  methods: {
    downloadStringAsFile(str, filename, filetype) {
      const dataStr = `data:${filetype || 'text/json'};charset=utf-8,${encodeURIComponent(str)}`;
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', filename);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
  },
};
