<template>
  <div>
    <div class='k-timer' v-if='currentTime > 0'>
      <span class='timer-text'>Time remaining:</span>
      <span v-if="days">{{ days }}:</span>
      <span v-if="hours">{{ hours | formatTime }}:</span>
      <span>{{ minutes | formatTime }}:{{ seconds | formatTime }}</span>
    </div>
    <div class="k-timer" v-else>
      The timer has finished.
    </div>
  </div>
</template>

<style scoped>
.k-timer {
  font-family: monospace;
  white-space: nowrap;
  display: flex;
}

.k-timer .timer-text {
  padding-right: 10px;
}
</style>

<script>
export default {
  props: {
    deadline: {
      type: Date,
      required: true,
    },
    speed: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      currentTime: this.deadline - Date.parse(new Date()), // milliseconds remaining
    };
  },
  mounted() {
    setTimeout(this.countdown, this.speed);
  },
  computed: {
    seconds() {
      return Math.floor((this.currentTime / 1000) % 60);
    },
    minutes() {
      return Math.floor((this.currentTime / 1000 / 60) % 60);
    },
    hours() {
      return Math.floor((this.currentTime / (1000 * 60 * 60)) % 24);
    },
    days() {
      return Math.floor(this.currentTime / (1000 * 60 * 60 * 24));
    },
  },
  filters: {
    formatTime(value) {
      if (value < 10) {
        return `0${value}`;
      }
      return value;
    },
  },
  methods: {
    countdown() {
      this.currentTime = this.deadline - Date.parse(new Date());
      if (this.currentTime > 0) {
        setTimeout(this.countdown, this.speed);
      } else {
        this.currentTime = null;
      }
    },
  },
};
</script>
