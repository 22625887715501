<template>
  <ul class="k-word-tags-list">
    <li class="k-word-tags-item badge" v-for="tag in tags" :key="tag">
      {{tag}} <i class="fas fa-times clickable" @click="removeTag(tag)"></i>
    </li>
  </ul>
</template>

<style scoped>
.k-word-tags-list:empty {
  display: none;
}

.k-word-tags-list {
  list-style: none;
  margin-top: 10px;
  padding-left: 0;
}

.k-word-tags-item {
  color: var(--kate-type-light);
}

.k-word-tags-item i.fas.fa-times:hover {
  color: var(--kate-type-primary);
  transition: 0.3s;
}
</style>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
    },
  },

  data() {
    return {
      tags: [],
    };
  },

  watch: {
    modelValue() {
      this.tags = this.modelValue;
    },
    tags() {
      this.$emit('update:modelValue', this.tags);
    },
  },

  methods: {
    removeTag(tag) {
      this.tags = this.tags.filter(x => x !== tag);
    },
  },
};
</script>
