<template>
  <div class="k-time-picker">
    <vue-timepicker format="HH:mm" v-model="time" />
  </div>
</template>

<style>
.k-time-picker li,
.k-time-picker input {
  color: var(--kate-type-light) !important;
  background-color: var(--input-background) !important;
  border: var(--input-border-alt) !important;
}
</style>

<script>

import VueTimepicker from 'vue3-timepicker';
import 'vue3-timepicker/dist/VueTimepicker.css';

export default {
  components: {
    VueTimepicker,
  },
  props: {
    modelValue: {
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      time: {
        HH: '',
        mm: '',
      },
    };
  },

  mounted() {
    this.time = this.parseTime(this.modelValue);
  },

  watch: {
    time() {
      const time = this.time.HH && this.time.mm ? `${this.time.HH}:${this.time.mm}` : '';
      this.$emit('update:modelValue', time);
    },

    modelValue() {
      this.time = this.parseTime(this.modelValue);
    },
  },

  methods: {
    parseTime(payload) {
      if (!payload) {
        return { HH: '', mm: '' };
      }
      const time = payload.split(':');
      return { HH: time[0], mm: time[1] };
    },
  },
};
</script>
