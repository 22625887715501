export default {
  // To use this mixin, have to add a beforeRouteLeave hook in the component like:
  // beforeRouteLeave(to, from, next) {
  //   return this.beforeLeave(next);
  // },
  methods: {
    beforeLeave(next) {
      if (this.pageHasUnsavedChanges) {
        this.nextCallback = next;
        this.navConfirm();
      } else {
        next();
      }
    },
    cancelNav() {
      this.nextCallback(false);
    },
    confirmNav() {
      this.nextCallback();
    },
    navConfirm() {
      this.$ktoast.confirmToast('There are unsaved changes on this page.', 'warning', this.confirmNav, this.cancelNav);
    },
  },

  data() {
    return {
      pageHasUnsavedChanges: false,
      nextCallback: undefined,
    };
  },
};
