<template>
  <div>
    <h1>Slides Overview</h1>
    <asset-overview v-if="ready"
      asset-icon="fa-clone"
      :assets="sortedSlides"
      :kable-headers="kableHeaders"
      :kable-rows="kableRows"
      :show-archived-assets="showArchived"
      @tablecallback="tableCallback"
      @archive-toggle="updateArchiveToggle"
    >
      <template #new-asset>
        <upload-slides @slides-uploaded="getSlides"></upload-slides>
      </template>
      <template #preview-asset>
        <preview-slides :slidesId="previewSlidesId" @close="closePreviewModal"></preview-slides>
        <update-slides @update="getSlides" :slides="slidesToUpdate" @close="closeEditModal"></update-slides>
      </template>
    </asset-overview>
  </div>
</template>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import TimeMixin from '../../../../mixins/time-mixins';
import PageReadyMixin from '../../../../mixins/page-ready-mixin';
import ArchiveToastConfirmMixin from '../archive-toast-confirm-mixin';
import getIcon from '../../../../modules/kate-table-icons';
import { sortObjectArray } from '../../../../modules/sort-by-object-property';
import AssetOverview from '../asset-overview.vue';
import UploadSlides from './upload-slides.vue';
import UpdateSlides from './update-slides.vue';
import PreviewSlides from './preview-slides.vue';

export default {
  components: {
    AssetOverview,
    UploadSlides,
    UpdateSlides,
    PreviewSlides,
  },

  mixins: [ErrorMixin, TimeMixin, PageReadyMixin, ArchiveToastConfirmMixin],

  data() {
    return {
      slidesReady: false,
      uploadedSlides: [],
      previewSlidesId: undefined,
      slidesToUpdate: undefined,
      showArchived: false,
    };
  },

  beforeMount() {
    this.getSlides();
  },

  computed: {
    ready() {
      return this.slidesReady;
    },
    sortedSlides() {
      return sortObjectArray(this.uploadedSlides.slice(), 'date_uploaded', true);
    },

    kableHeaders() {
      return {
        edit: {
          name: 'Edit',
          type: 'action',
        },
        preview: {
          name: 'Preview',
          type: 'action',
        },
        name: {
          name: 'Title',
          filterable: true,
          sortable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
        },
        date_uploaded: {
          name: 'Uploaded',
          sortable: true,
          type: 'timestamp',
        },
        displayTags: {
          name: 'Tags',
          type: 'tags',
          filterable: true,
          sortable: true,
        },
        archive: {
          name: 'Archive',
          type: 'action',
          sortable: true,
        },
        delete: {
          name: 'Delete',
          type: 'action',
        },
      };
    },

    kableRows() {
      return this.sortedSlides.map((val, index) => ({
        date_uploaded: val.date_recorded,
        ...val,
        edit: {
          key: index,
          text: getIcon({ icon: 'edit' }),
        },
        name: {
          key: index,
          text: val.name,
          path: {
            name: 'curriculum_asset_details',
            params: {
              assetId: val.id,
              assetType: 'slide_deck',
            },
          },
        },
        preview: {
          key: index,
          text: getIcon({ icon: 'play' }),
        },
        displayTags: {
          tags: val.tags || [],
          sortBy: val.tags ? [...val.tags].sort().join(' ') : '',
        },
        delete: {
          key: index,
          text: getIcon({ color: 'var(--kate-danger-alt)', icon: 'trash' }),
        },
        archive: {
          text: this.assetArchiveButton(val.is_active),
        },
      })).filter(slides => (this.showArchived || slides.is_active));
    },
  },

  methods: {
    updateArchiveToggle(val) {
      this.showArchived = val;
    },

    closePreviewModal() {
      this.previewSlidesId = undefined;
    },

    closeEditModal() {
      this.slidesToUpdate = undefined;
    },

    getSlides() {
      this.$logger.info('Getting slides');
      this.$Loading.start();
      this.slidesReady = false;
      this.$http.get('/api/curriculum/admin/slide_deck?active_only=false')
        .then(result => {
          this.uploadedSlides = result.data.slides;
        }).catch(err => {
          if (!this.$http.isWarning(err)) {
            this.$logger.error('Error getting slides', undefined, err);
            this.showError(err);
          } else {
            this.$logger.warn('No slides found');
            this.uploadedSlides = [];
          }
        }).then(() => {
          this.slidesReady = true;
          this.$Loading.finish();
        });
    },

    deleteConfirmationCallback(id) {
      this.$logger.info('Deleting slides', { id });
      this.$Loading.start();
      this.$http.delete(`/api/curriculum/admin/slide_deck/${id}`)
        .then(() => {
          this.$logger.info('Slides have been deleted', { id });
          this.$ktoast.success('Slides have been deleted');
          this.getSlides();
        }).catch(err => {
          this.$logger.autowarn('Error deleting slides', undefined, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    deleteSlides(id) {
      this.$ktoast.confirmToast('You are about to delete this set of slides.', 'warning', () => {
        this.deleteConfirmationCallback(id);
      });
    },

    // Archiving Slides
    archiveCallback(id) {
      this.$logger.info('Archiving slides', { id });
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/slide_deck/${id}/archive`)
        .then(() => {
          this.$logger.info('Slides have been archived', { id });
          this.$ktoast.success('Slides has been archived');
          this.getSlides();
        }).catch(err => {
          if (this.$http.isWarning(err)) {
            this.$logger.warn('Error archiving slides', { id }, err);
            this.showError(err);
          } else {
            this.$logger.error('Error archiving slides', { id }, err);
            this.showError(err);
          }
        }).then(() => {
          this.$Loading.finish();
        });
    },

    unarchiveCallback(id) {
      this.$logger.info('Unarchiving slides', { id });
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/slide_deck/${id}/unarchive`)
        .then(() => {
          this.$logger.info('Slides have been unarchived', { id });
          this.$ktoast.success('Slides has been unarchived');
          this.getSlides();
        }).catch(err => {
          if (this.$http.isWarning(err)) {
            this.$logger.warn('Error unarchiving slides', { id }, err);
            this.showError(err);
          } else {
            this.$logger.error('Error unarchiving slides', { id }, err);
            this.showError(err);
          }
        }).then(() => {
          this.$Loading.finish();
        });
    },

    tableCallback(key, index, row, header) {
      const slides = this.uploadedSlides.find(x => x.id === row.id);
      if (header === 'delete') {
        this.deleteSlides(row.id);
      }
      if (header === 'preview') {
        this.previewSlidesId = row.id;
      }
      if (header === 'archive') {
        this.tableArchiveCallback(row);
      }
      if (header === 'edit') {
        if (slides && typeof slides === 'object') {
          this.slidesToUpdate = JSON.parse(JSON.stringify(slides));
        } else {
          this.slidesToUpdate = undefined;
        }
      }
    },
  },
};
</script>
