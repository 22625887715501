<template>
  <li v-if="$permissions.hasPermission('manage_achievements')" class="nav-group nav-group-title">
    <router-link :to="{ name: 'manage_achievements' }">
      <i class="k-asset-icon fas fa-certificate"></i><span>Achievements</span>
    </router-link>
  </li>
</template>

<script>
import SidebarEntryMixin from '../../mixins/sidebar-entry-mixin';

export default {
  mixins: [SidebarEntryMixin],

  data() {
    return {
      toggled: false,
    };
  },

  computed: {
    isVisible() {
      return this.$permissions.hasPermission('manage_achievements');
    },
  },
};
</script>
