export function getCompletionForChapters(chapters) {
  if (!chapters) {
    return [];
  }
  return chapters.reduce(
    (output, chapter) => output
      .concat(chapter.assets
        .filter(a => a.is_mandatory !== false && a.is_complete !== undefined)
        .map(
          a => {
            if (a.asset_type !== 'pak' && a.asset_type !== 'quiz') {
              return a.is_complete ? 100 : 0;
            }
            return a.progress_score || a.latest_score || 0;
          },
        )),
    [],
  );
}

export function average(scores) {
  if (!scores) {
    return undefined;
  }
  return scores.reduce((partial, a) => partial + a, 0) / scores.length;
}

export default { getCompletionForChapters, average };
