import useGlobalStore from '../stores/global';
import useFeatureFlagStore from '../stores/feature-flag';
import useThemeStore from '../stores/theme';
import ErrorMixin from './error-mixins';

export default {
  mixins: [ErrorMixin],

  data() {
    return {
      store: useGlobalStore(),
      themeStore: useThemeStore(),
      featureFlagStore: useFeatureFlagStore(),
    };
  },

  methods: {
    goHome() {
      if (this.store.appName === 'dashboard' && this.store.isEmployer) {
        this.$router.push({ name: 'employer_learner_summary' });
      } else {
        const progPage = this.store.appName === 'dashboard' ? 'monitor_programme_summary' : 'programme_page';
        if (this.$route.query.programmeId) {
          this.$router.push({ name: progPage, params: { programmeId: this.$route.query.programmeId } });
        } else {
          this.$router.push({ path: '/' });
        }
      }
    },
    logIn() {
      this.$logger.info('Logging in user');
      this.$http.get('/api/profile/user').then(response => {
        this.featureFlagStore.loadUser(response.data.id, response.data.email);
        this.store.logIn(response.data);
        this.themeStore.resolveThemePreference(response.data);
        this.$logger.info('Logged in user', undefined, true);
        if (this.store.appName === 'app' && !this.store.termsAccepted) {
          this.$router.push({ name: 'policy_accept_page' });
        } else if (this.$route.query.next) {
          const path = decodeURIComponent(this.$route.query.next);
          this.$router.push({ path });
        } else {
          this.goHome();
        }
      }).catch(err => {
        this.$logger.error('Error logging in user', undefined, err);
        this.showError(err, true);
      });
    },
  },
};
