<template>
  <li class="theme-toggle" id="user-search">
    <button @click="openUserSearch" class="search-user-button btn-icon" title="Search for user" aria-label="Search for user">
      <i class="fas fa-search"></i><span>Search For User</span>
    </button>
    <user-search
      @close="closeUserSearch"
      :show="showUserSearch">
    </user-search>
  </li>
</template>

<style scoped>
  .search-user-button {
    padding: 15px;
  }
</style>

<script>
import UserSearch from '../dashboard/components/user-search.vue';

export default {
  components: { UserSearch },

  mounted() {
    this.$router.beforeEach((to, from, next) => {
      this.showUserSearch = false;
      next();
    });
  },

  data() {
    return {
      showUserSearch: false,
    };
  },

  methods: {
    openUserSearch() {
      this.showUserSearch = true;
    },
    closeUserSearch() {
      this.showUserSearch = false;
    },
  },
};
</script>
