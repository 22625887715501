<template>
  <li class="theme-toggle" id="theme-toggle" ref="themeToggle">
    <button @click="handleToggleClick" v-html="selectedThemeDisplay" class="btn-icon"></button>
    <ul class="dropdown" :class="[{ 'is-open': isOpen }]">
      <li v-for="opt in Object.entries(themeOptions)">
        <button type="button" @click="selectTheme(opt[0])" v-html="opt[1]">
        </button>
      </li>
    </ul>
    <k-popup
      @close="closePopup" :show="!themeStore.hideThemesPopup"
      :body="`You can now choose from three available themes via this dropdown`">
    </k-popup>
  </li>
</template>

<style scoped>
.theme-toggle {
  position: relative;
}

.theme-toggle .dropdown {
  min-width: 135px;
}
</style>

<script>
import KPopup from '../components/k-popup.vue';
import useThemeStore from '../stores/theme';

export default {
  components: {
    KPopup,
  },
  beforeMount() {
    this.selectedTheme = this.themeStore.theme;
  },

  mounted() {
    this.$survey.init();
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  data() {
    return {
      themeStore: useThemeStore(),
      isOpen: false,
      themeOptions: {
        light: '<i class="fa-duotone fa-sun-haze"></i> Light',
        dark: '<i class="fa-duotone fa-moon-stars"></i> Dark',
        legacy: '<i class="fa-duotone fa-building-columns"></i> Legacy',
      },
      selectedTheme: null,
    };
  },

  computed: {
    selectedThemeDisplay() {
      const html = this.themeOptions[this.selectedTheme] || '<i class="fa-duotone fa-eclipse"></i> Theme';
      return `${html} <i class="fas fa-caret-down"></i>`;
    },
  },

  methods: {
    closePopup() {
      this.themeStore.$patch({ hideThemesPopup: true });
      this.updateFrontendSettings(false);
    },

    handleToggleClick() {
      this.isOpen = !this.isOpen;
      this.$survey.trackAction('theme_toggle_click');
    },

    selectTheme(theme) {
      this.selectedTheme = theme;
      this.themeStore.theme = this.selectedTheme;
      this.updateFrontendSettings(true);
      this.isOpen = false;
    },

    handleClickOutside(event) {
      const themeToggle = this.$refs.themeToggle;
      if (themeToggle && !themeToggle.contains(event.target)) {
        this.isOpen = false;
      }
    },

    updateFrontendSettings(showToast) {
      return this.$http.put('/api/profile/user/frontend_settings', {
        theme: this.selectedTheme,
        hide_themes_popup: this.themeStore.hideThemesPopup,
        hide_chatbot_popup: this.themeStore.hideChatBotPopup,
      }).then(() => {
        if (showToast) {
          this.$ktoast.success('Theme preference saved');
        }
        this.$logger.info('User preference saved');
      }).catch(err => {
        this.$logger.warn('Error saving user preference for user', err);
      });
    },
  },
};
</script>
