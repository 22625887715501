<template>
  <li class="nav-group" :class="`${toggled ? 'opened-dropdown': 'closed-dropdown'}`">
    <button class="nav-group-title" aria-label="Toggle dropdown" title="Toggle dropdown" @click="toggled = !toggled">
      <i class="fas fa-address-card"></i><span>Account Details</span>
      <i class="fas fa-angle-down"></i>
    </button>
    <ul>
      <li class="nav-sub-group">
        <div class="nav-sub-group-title">
          <router-link :to="{ name: 'profile_information' }">
            <span>Profile Information</span>
          </router-link>
        </div>
      </li>
      <li class="nav-sub-group">
        <div class="nav-sub-group-title">
          <router-link :to="{ name: 'profile_email_address' }">
            <span>Email Addresses</span>
          </router-link>
        </div>
      </li>
      <li class="nav-sub-group">
        <div class="nav-sub-group-title">
          <router-link :to="{ name: 'profile_password' }">
            <span>Password</span>
          </router-link>
        </div>
      </li>
    </ul>
  </li>
</template>

<style scoped>
.nav-group-title a i {
  color: var(--kate-type-blue);
}
</style>

<script>
export default {
  data() {
    return {
      toggled: false,
    };
  },
};
</script>
