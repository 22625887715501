<template>
  <section class="panel">
    <div class="panel-body">
      <div class="section-header">
        <h3>Knowledge Base</h3>
        <hr>
      </div>
      <div class="section-content row">
        <div class="section-item col-md-4 col-sm-6">
          <div class="section-icon">
            <span class="total-posts">
              <i class="fas fa-comments-alt"></i>
            </span>
          </div>
          <div class="section-stats">
            <span>Numbers of posts</span>
            <span class="item-stats">{{ threads.length }}</span>
            <button aria-label="Toggle" title="Toggle" @click="toggleThreads" class="expand-content" :class="[showThreads ? 'active' : '', threads.length <= 0 ? 'disabled' : '']">
              Expand<i class="fas fa-angle-down"></i>
            </button>
          </div>
        </div>
        <div class="section-item col-md-4 col-sm-6">
          <div class="section-icon">
            <span class="kb-active-learners">
              <i class="fas fa-comments"></i>
            </span>
          </div>
          <div class="section-stats">
            <span>Number of active learners</span>
            <span class="item-stats">{{ knowledgeActivity.length }}</span>
            <button aria-label="Toggle" title="Toggle" @click="toggleActivityDetails" class="expand-content" :class="[showActivityDetails ? 'active' : '', knowledgeActivity.length <= 0 ? 'disabled' : '']">
              Expand<i class="fas fa-angle-down"></i>
            </button>
          </div>
        </div>
      </div>
      <k-table v-if="showThreads || showActivityDetails" class="kb-activity collapsed-content"
        :headers="kableHeaders"
        :rows="kableRows"
        :max="10"
        :hideable="false"
        :panel="false">
      </k-table>
    </div>
  </section>
</template>

<script>
import formatId from '../../../modules/format-user-id';
import KTable from '../../../components/k-table.vue';

export default {
  components: {
    'k-table': KTable,
  },

  props: {
    threads: {
      type: Array,
    },
    knowledgeActivity: {
      type: Array,
    },
  },

  data() {
    return {
      showThreads: false,
      showActivityDetails: false,
    };
  },

  computed: {
    kableHeaders() {
      if (this.showActivityDetails) {
        return {
          learner: {
            name: 'User ID',
            sortable: true,
            type: 'string',
          },
          name: {
            name: 'Name',
            type: 'url',
            filterable: true,
            sortable: true,
            hideable: false,
          },
          questions: {
            name: 'Questions posted',
            type: 'string',
            sortable: true,
            hideable: false,
          },
          answers: {
            name: 'Answers posted',
            type: 'string',
            sortable: true,
            hideable: false,
          },
          accepted: {
            name: 'Accepted answers',
            type: 'string',
            sortable: true,
            hideable: false,
          },
        };
      }
      return {
        question: {
          type: 'url',
          name: 'Question Posted',
          filterable: true,
          sortable: true,
          hideable: false,
        },
        module: {
          name: 'Module',
          filterable: true,
          sortable: true,
          hideable: true,
        },
        project: {
          name: 'Project',
          filterable: true,
          sortable: true,
          hideable: true,
        },
        status: {
          name: 'Status',
          sortable: true,
          hideable: false,
        },
        visibility: {
          name: 'Visibility',
          sortable: true,
          hideable: true,
        },
        answers: {
          name: 'Answers',
          sortable: true,
        },
      };
    },

    kableRows() {
      if (this.showActivityDetails) {
        return this.knowledgeActivity.map(val => ({
          learner: formatId(val.user_id),
          questions: val.question_count,
          answers: val.answer_count,
          accepted: val.accepted_count,
          name: {
            text: val.full_name,
            path: {
              name: 'dashboard_students',
              params: {
                studentId: val.user_id,
              },
            },
          },
        }));
      }
      const getPath = val => ({
        name: 'knowledge_thread',
        params: {
          moduleId: val.module_id,
          threadId: val.id,
        },
      });
      const output = this.threads.map(val => Object({
        question: {
          text: this.$kpurify.sanitise(val.title),
          path: getPath(val),
        },
        module: val.module_name,
        project: this.getItemOfContent(val),
        status: this.geStatusLabel(val.accepted_id),
        visibility: this.getVisibilityLabel(val.is_private),
        answers: val.response_count,
      }));
      return output;
    },
  },

  methods: {
    toggleActivityDetails() {
      if (this.knowledgeActivity.length > 0) {
        this.showActivityDetails = !this.showActivityDetails;
        this.showThreads = false;
      }
    },

    toggleThreads() {
      if (this.threads.length > 0) {
        this.showThreads = !this.showThreads;
        this.showActivityDetails = false;
      }
    },

    geStatusLabel(status) {
      if (status) {
        return '<span class="tag-answered">Answered <i class="fas fa-check"></i></span>';
      }
      return '';
    },

    getVisibilityLabel(isPrivate) {
      if (isPrivate) {
        return '<span class="tag-private">Private</span>';
      }
      return '<span class="tag-public k-text-primary">Public</span>';
    },

    getItemOfContent(item) {
      if (item.learning_unit_id) {
        return item.lu_name;
      }
      if (item.pak_id) {
        return item.pak_name;
      }
      if (item.quiz_id) {
        return item.quiz_name;
      }
      return '';
    },
  },
};
</script>
