<template>
  <div v-if="ready" id="thread-page" class="content-with-spacing">
    <section v-if="question && isDashboard" class="thread-controls">
      <div>
        <button :class="closeClass" @click="closeOpenThread">{{ closeButtonText }}</button>
        <button :class="discussionClass" @click="markThreadAsDiscussion">{{ discussionButtonText }}</button>
      </div>
    </section>
    <section v-if="question" class="question-container">
      <question-card
        :question="question"
        :answers="answers"
        :userVotes="userVotes"
        @openEditModal="openEditModal(question, true)"
        @edited="getThread"
        @awaiting="setAwaiting(question.awaiting_op_answer === null || question.awaiting_op_answer === false ? true : false)"
        @upvote="upvoteQuestion"
        @unvote="unvoteQuestion"
        @voted="getUserVotes"
      ></question-card>
    </section>

    <hr/>

    <k-panel v-if="acceptedAnswer"
             :title="'Accepted Answer'"
             :showContent="showAcceptedAnswer" @toggle="(val) => showAcceptedAnswer = val"
             class="accepted-answer-panel"
    >
      <template #body>
        <answer-card :answer="acceptedAnswer"
                     :question="question"
                     :userVotes="userVotes"
                     @openEditModal="openEditModal(acceptedAnswer, false)"
                     @edited="getThread"
                     @awaiting="setAwaiting(false)"
                     @accepted="acceptAnswer(acceptedAnswer.id)"
                     @unaccepted="unacceptAnswer"
                     @upvote="upvoteAnswer(acceptedAnswer.id)"
                     @unvote="unvoteAnswer(acceptedAnswer.id)"
                     @voted="getUserVotes"
        ></answer-card>
      </template>
    </k-panel>

    <hr v-if="acceptedAnswer"/>

    <section class="thread-body panel panel-default">
      <div class="panel-body">
        <div v-if="showAnswers" class="answers-container">
          <h2>{{ answerPanelTitle }}</h2>
            <answer-card v-for="(answer, index) in sortedAnswers"
              :key="index"
              :answer="answer"
              :question="question"
              :userVotes="userVotes"
              @openEditModal="openEditModal(answer, false)"
              @edited="getThread"
              @awaiting="setAwaiting(false)"
              @accepted="acceptAnswer(answer.id)"
              @unaccepted="unacceptAnswer"
              @upvote="upvoteAnswer(answer.id)"
              @unvote="unvoteAnswer(answer.id)"
              @voted="getUserVotes"
            ></answer-card>
        </div>

        <hr v-if="answers.length > 0" class="thread-hr" />

        <div class="answer-form-container">
          <h2>Reply to thread</h2>
          <form>
            <fieldset class="form-entry">
              <div class="form-info">
              </div>
              <k-text-editor
                id="answerTextEditor"
                v-model="answerBody"
                @pasteResources="pasteResources"
              ></k-text-editor>
            </fieldset>
            <button title="Upload resources" class="btn btn-outlined" @click="openUploadDialog" type="button">
              Upload Resources <i title="Upload resources" class="fas fa-upload clickable"></i>
            </button><br>
            <button v-if="postingAnswer" :disabled="disableSend" class="btn btn-primary send-btn">
              Posting answer <i class="fas fa-spinner fa-spin"></i>
            </button>
            <button v-else @click.prevent="addNewAnswer" :disabled="disableSend" class="btn btn-primary send-btn">
              Post answer <i class="fas fa-plus"></i>
            </button>
          </form>

          <div class="form-entry"><!-- // jscpd:ignore-start -->
            <label for="files-input">
              <input id="files-input" type="file"
                name="files"
                style="display: none;"
                ref="filesInput"
                :accept="acceptedResources"
                @change="filesChange($event.target.files)" multiple>
            </label>
            <k-file-list v-if="resources.length > 0" :files="resources" @remove-file="removeResource"></k-file-list>
          </div>
        </div><!-- // jscpd:ignore-end -->
      </div>
    </section>
    <thread-edit
      class="k-base-edit-modal"
      @close="close"
      @edited="getThread"
      @deleted="deleteAnswer"
      :show="showEditModal"
      :thread="editThread"
    />
  </div>
</template>

<style scoped>
#thread-page {
  padding-top: 15px;
}

#module-overview #thread-page {
  margin-top: 45px;
}

.thread-controls > div {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.thread-controls button {
  margin: 0 0 5px 5px;
}

.thread-body {
  margin-top: 20px;
}

.thread-body > .panel {
  padding: 15px;
  display: inline-block;
  width: 100%;
}

hr {
  border: 1px solid var(--border-secondary);
}

.thread-hr {
  border: 1px solid var(--kate-background-body);
}

/* Post answer */
.answer-form-container {
  padding: 15px;
  width: 100%;
}

.answer-form-container form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 15px;
}

.form-entry {
  flex: 1 1 100%;
}

.form-info {
  color: var(--kate-type-primary);
}
</style>

<style>
.header-buttons-container {
  display: flex;
  column-gap: 15px;
}

.header-buttons-container button {
  padding: 10px 15px;
}

.thread-header .user {
  gap: 15px;
}

.thread-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.user-info .user-name {
  align-items: center;
  line-height: 20px;
  position: relative;
  margin-bottom: 5px;
}

.user-info .user-name h2,
.user-info .user-name h3 {
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .user {
    flex-wrap: wrap;
  }

  .user-info hr.vertical-hr {
    width: 100%;
    height: 0;
    margin: 5px 0;
  }

  .header-buttons-container {
    width: 100%;
  }

  .header-buttons-container button {
    width: 100%;
  }

  .answer-form-container form {
    justify-content: flex-start;
  }
}

</style>

<script>
import useGlobalStore from '../stores/global';
import { genericSort } from '../modules/sort-by-object-property';
import ErrorMixin from '../mixins/error-mixins';
import TimeMixin from '../mixins/time-mixins';
import ResourceMixin from '../mixins/resource-mixins';
import ThreadEdit from './thread-edit.vue';
import QuestionCard from './question-card.vue';
import AnswerCard from './answer-card.vue';
import KFileList from '../components/k-file-list.vue';
import KTextEditor from '../components/k-text-editor.vue';
import PageReadyMixin from '../mixins/page-ready-mixin';
import KPanel from '../components/k-panel.vue';

export default {
  mixins: [ErrorMixin, TimeMixin, ResourceMixin, PageReadyMixin],

  components: {
    'thread-edit': ThreadEdit,
    'question-card': QuestionCard,
    'answer-card': AnswerCard,
    'k-file-list': KFileList,
    'k-text-editor': KTextEditor,
    'k-panel': KPanel,
  },

  props: {
    moduleDetails: {
      type: Object,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
      threadReady: false,
      userVotesReady: false,
      updatingUserVotes: false,
      postingAnswer: false,
      showEditModal: false,
      editThread: {},
      userVotes: [],
      question: undefined,
      answers: [],
      answerBody: '',
      resources: [],
      showAcceptedAnswer: true,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getThread();
  },

  watch: {
    moduleDetails() {
      if (this.moduleDetails && !this.moduleDetails.knowledge_base_enabled) {
        this.$logger.warn('Attempted to access disabled knowledge base - route to 404');
        this.$router.push({ name: '404' });
      }
    },
    threadParams() {
      if (this.isDashboard) {
        // If params change, reload the thread - don't need to do this on app
        // as the page structure (tied to module pages) handles it
        this.getThread();
      }
    },
  },

  computed: {
    ready() {
      const r = this.threadReady && this.userVotesReady;
      return this.isDashboard ? r : this.sidebarReady && r;
    },
    closeClass() {
      return this.question.is_closed ? 'btn btn-success' : 'btn btn-danger';
    },
    discussionClass() {
      return this.question.is_discussion ? 'btn btn-success' : 'btn btn-outlined';
    },
    closeButtonText() {
      return this.question.is_closed ? 'Re-open thread' : 'Close thread';
    },
    discussionButtonText() {
      return this.question.is_discussion ? 'Not discussion' : 'Mark as discussion';
    },
    sidebarReady() {
      return this.$sidebar.ready;
    },
    isDashboard() {
      return this.store.isDashboard;
    },
    threadId() {
      return this.$route.params.threadId;
    },
    moduleId() {
      return this.$route.params.moduleId;
    },
    threadParams() {
      return {
        moduleId: this.moduleId,
        threadId: this.threadId,
      };
    },
    answerPanelTitle() {
      if (this.answers.length > 1) {
        return `All ${this.answers.length} answers`;
      }
      return 'Answer';
    },
    showAnswers() {
      if (this.answers.length > 1) {
        return true;
      }
      if (this.answers.length === 1) {
        return this.answers[0].id !== this.question.accepted_id;
      }
      return false;
    },
    validForm() {
      return Boolean(this.answerBody);
    },
    disableSend() {
      return !this.validForm || this.answerBody.length > 10000 || this.postingAnswer;
    },
    lastEdit() {
      if (this.question.last_edit === this.question.posted_on) {
        return false;
      }
      return true;
    },
    sortedAnswers() {
      return this.answers.slice().sort(this.sortByDatePosted);
    },
    threadEndpoint() {
      if (this.moduleId) {
        return `/api/curriculum/knowledge/${this.moduleId}/threads/${this.threadId}`;
      }
      return `/api/curriculum/knowledge/threads/${this.threadId}`;
    },
    addAnswerEndpoint() {
      if (this.moduleId) {
        return `/api/curriculum/knowledge/${this.moduleId}/threads`;
      }
      return '/api/curriculum/knowledge/threads';
    },
    acceptedAnswer() {
      return this.answers.find(x => x.id === this.question.accepted_id);
    },
  },

  methods: {
    registerCrumbs() {
      if (!this.question) {
        return;
      }
      if (this.isDashboard) {
        this.registerDashboardCrumbs();
      } else {
        this.registerAppCrumbs();
      }
    },

    registerDashboardCrumbs() {
      if (this.isDashboard) {
        this.$crumbs.register([
          {
            text: 'Knowledge Base',
            path: {
              name: 'knowledge_base_overview',
            },
          },
          {
            text: `${this.question.title}`,
            active: true,
          },
        ]);
      }
    },

    registerAppCrumbs() {
      if (!this.moduleDetails) {
        return;
      }
      this.$crumbs.register([
        {
          text: this.moduleDetails.programme_name,
          path: {
            name: 'programme_page',
            params: { programmeId: this.moduleDetails.programme_id },
          },
        },
        {
          text: this.moduleDetails.name,
          path: {
            name: 'module_landing',
            params: { moduleId: this.moduleId },
          },
        },
        {
          text: 'Knowledge Base',
          path: {
            name: 'module_knowledge_base',
          },
        },
        {
          text: this.question.title,
          active: true,
        },
      ]);
    },

    pageReadyCallback() {
      this.registerCrumbs();
    },

    openUploadDialog() {
      this.$refs.filesInput.click();
    },

    sortByDatePosted(a, b) {
      return genericSort(a.posted_on, b.posted_on);
    },

    openEditModal(thread, isQuestion) {
      this.editThread = thread;
      this.editThread.is_question = isQuestion;
      this.showEditModal = true;
    },

    close() {
      this.showEditModal = false;
      this.editThread = {};
    },

    acceptAnswer(id) {
      this.question.accepted_id = id;
    },

    unacceptAnswer() {
      this.question.accepted_id = null;
    },

    deleteAnswer(payload) {
      const idx = this.answers.findIndex(x => x.id === payload.id);
      if (idx !== -1) {
        this.answers.splice(idx, 1);
      }
    },

    setAwaiting(payload) {
      this.question.awaiting_op_answer = payload;
    },

    getThread() {
      this.threadReady = false;
      this.$http
        .get(this.threadEndpoint)
        .then(result => {
          this.question = result.data.question;
          this.answers = result.data.answers;
        })
        .catch(err => {
          if (this.$http.errIn(err, [404])) {
            this.question = {};
            this.answers = [];
            this.$logger.warn(`Could not find thread with id ${this.threadId} - route to 404`);
            this.$router.push({ name: '404' });
          }
        })
        .then(() => {
          this.threadReady = true;
          if (this.question && this.question.id) {
            this.getUserVotes();
          }
        });
    },

    addNewAnswer() {
      this.postingAnswer = true;
      this.$logger.info('Replying to thread');
      this.getCreatePayload().then(payload => {
        this.$http.post(this.addAnswerEndpoint, payload)
          .then(res => {
            this.userVotes.push({ id: res.data.thread_id, votes: 0 });
            this.$logger.info('Posted reply', undefined, true);
            this.$emit('answer-posted');
            this.answerBody = '';
            this.resources = [];
            this.getThread();
            this.postingAnswer = false;
          })
          .catch(err => {
            this.$logger.error('Error posting reply', undefined, err);
            this.showError(err);
          });
      });
    },

    closeOpenThread() {
      this.$logger.info(`${this.question.is_closed ? 'Re-opening' : 'Closing'} thread`);
      this.$http.put(`/api/curriculum/knowledge/threads/${this.threadId}/closed`, {
        is_closed: !this.question.is_closed,
      })
        .then(() => {
          this.getThread();
        })
        .catch(err => {
          this.$logger.autowarn('Error closing/opening thread', undefined, err);
          this.showError(err);
        });
    },

    markThreadAsDiscussion() {
      this.$logger.info(`thread is ${this.question.is_discussion ? 'not' : ''} discussion`);
      this.$http.put(`/api/curriculum/knowledge/threads/${this.threadId}/in-discussion`, {
        is_discussion: !this.question.is_discussion,
      })
        .then(() => {
          this.getThread();
        })
        .catch(err => {
          this.$logger.autowarn('Error setting thread as discussion', undefined, err);
          this.showError(err);
        });
    },

    getCreatePayload() {
      return this.formatResourcesForUpload().then(formattedResources => ({
        body: this.answerBody,
        root_parent_id: this.question.id,
        parent_id: this.question.id,
        resources: formattedResources,
      }));
    },

    upvoteQuestion() {
      const idx = this.userVotes.findIndex(x => x.id === this.question.id);
      this.question.votes += 1;
      this.userVotes[idx].votes = 1;
    },

    unvoteQuestion() {
      const idx = this.userVotes.findIndex(x => x.id === this.question.id);
      this.question.votes += -1;
      this.userVotes[idx].votes = 0;
    },

    upvoteAnswer(id) {
      const answerIdx = this.answers.findIndex(x => x.id === id);
      const idx = this.userVotes.findIndex(x => x.id === id);
      this.answers[answerIdx].votes += 1;
      this.userVotes[idx].votes = 1;
    },

    unvoteAnswer(id) {
      const answerIdx = this.answers.findIndex(x => x.id === id);
      const idx = this.userVotes.findIndex(x => x.id === id);
      this.answers[answerIdx].votes += -1;
      this.userVotes[idx].votes = 0;
    },

    getUserVotes() {
      this.$logger.info('Getting user votes');
      this.$http.get(`/api/curriculum/knowledge/threads/${this.question.id}/votes`)
        .then(result => {
          this.$logger.info('Got user votes');
          // Update userVotes using splice so vue notices
          result.data.votes.forEach((currentValue, index) => {
            this.userVotes.splice(index, 1, currentValue);
          });
        })
        .catch(err => {
          this.$logger.error('Error getting user votes', { questionId: this.question.id }, err);
          this.showError(err);
        })
        .then(() => {
          this.userVotesReady = true;
          this.updatingUserVotes = false;
        });
    },
  },
};
</script>
