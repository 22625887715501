<template>
  <k-modal v-if="isLoggedIn" :show="show" @close="close">
    <template #header>
      <h2 v-if="isContactCoach">Contact your Coach</h2>
      <h2 v-else-if="isContactDataMentor">Contact your Data Mentor</h2>
      <h2 v-else>Ask for help</h2>
      <p v-if="currentProblemGroup">{{currentProblemGroup.msg}}</p>
      <p v-if="currentProblemGroup && currentProblemGroup.key === 'help_content'" class="help-description"></p>
    </template>
    <template #body>
      <div class="issue-body" v-if="ready">
        <form v-if="isContactCoach && !showStandardForm">
          <k-dropdown v-model="selectedCoachId" :options="coachOptions" placeholder="Select Coach" :showSearch="false"></k-dropdown>
          <div v-if="selectedCoach" class="learner-support">
            <span class="mentor-programme">
              Programme: <b>{{ selectedCoach.programme_name }}</b>
            </span>
            <p v-if="selectedCoach.acuity_link">
              You can schedule an appointment to talk with <b class="highlight">{{ selectedCoach.name }}</b> via their
              <a class="acuity" :href="selectedCoach.acuity_link" target="_blank">Acuity calendar<i class="fas fa-external-link-alt"></i></a>
            </p>
            <p>
              <b class="highlight">Coaches</b> are here to help with the non-technical aspects of your study and can provide support
              on topics such as
              <ul>
                <li>Your <b class="highlight">programme</b></li>
                <li><b class="highlight">Personal development</b> and <b class="highlight">careers</b></li>
                <li>Change of <b class="highlight">personal circumstances</b></li>
              </ul>
              For questions relating to content on KATE (assignments, learning units, etc.) please use the
              <b class="highlight">Knowledge Base</b>
            </p>
            <hr>
            <p>
              If you would like to contact your coach through KATE, you can send them a message
              by clicking the button below
            </p>
          </div>
        </form>
        <form v-if="isContactDataMentor && !showStandardForm">
          <k-dropdown v-model="selectedDataMentorId" :options="dataMentorOptions" placeholder="Select Data Mentor" :showSearch="false"></k-dropdown>
          <div v-if="selectedDataMentor" class="learner-support">
            <span class="mentor-programme">
              Programme: <b>{{ selectedDataMentor.programme_name }}</b>
            </span>
            <p v-if="selectedDataMentor.acuity_link">
              You can schedule an appointment to talk with <b class="highlight">{{ selectedDataMentor.name }}</b> via their
              <a class="acuity" :href="selectedDataMentor.acuity_link" target="_blank">Acuity calendar<i class="fas fa-external-link-alt"></i></a>
            </p>
            <p>
              <b class="highlight">Data Mentors</b> are here to help with technical aspects of your study and can provide support
              on topics such as
              <ul>
                <li>
                  How to evidence the application of knowledge, skills and behaviours in the workplace via
                  <b class="highlight">work-based projects and activities</b>
                </li>
                <li>
                    Support development and scoping of work towards all the <b class="highlight">End-Point Assessment (EPA) methods</b>
                    such as <b class="highlight">Portfolios</b>, <b class="highlight">Learning Journals</b> or
                    <b class="highlight">Practical assignments</b>
                </li>
                <li>
                  Provide feedback on and progress towards the <b class="highlight">EPA</b> and also preparation for
                  <b class="highlight">Gateway</b>
                </li> <!-- // jscpd:ignore-start -->
              </ul>
              For questions relating to content on KATE (assignments, learning units, etc.) please use the
              <b class="highlight">Knowledge Base</b>
            </p> <!-- // jscpd:ignore-end -->
            <hr>
            <p>
              If you would like to contact your data mentor through KATE, you can send them a message
              by clicking the button below
            </p>
          </div>
        </form>
        <form v-if="currentProblemGroup && showStandardForm" @submit.prevent="submitForm" class="help-ticket-form" enctype="multipart/form-data">
          <fieldset v-if="!isContactCoach && !isContactDataMentor && defaultModuleName" class="form-entry">
            <label for="module-input">Module:

              <input id="module-input" v-model="moduleName" disabled class="form-input k-issue-text">
            </label>
          </fieldset>

          <fieldset v-if="!isContactCoach && !isContactDataMentor && defaultContentName" class="form-entry">
            <label for="content-input">{{ contentText }}:</label>
            <input id="content-input" v-model="contentName" disabled class="form-input k-issue-text">
          </fieldset>

          <fieldset class="subject-wrapper form-entry">
            <label for="subject-input">Subject:</label>
            <input id="subject-input" placeholder="Enter subject here..." class="form-input k-issue-text" v-model="subject" maxlength="256">
          </fieldset>

          <fieldset class="textareawrapper form-entry">
            <label for="message-input">Message:</label>
            <k-text-editor id="message-input" v-model="message" placeholder="Enter message here..."></k-text-editor>
          </fieldset>

          <fieldset v-if="isBugReport" class="bug-report-field">
            <label for="upload-file-input" class="bug-report-label">
              Attachment(s):
            </label>
            <button type="button" class="btn-icon" @click="$emit('enter-screenshot-mode')">Take screenshot <i title="Take a screenshot in KATE" class="fas fa-camera" ></i></button>
            <hr>
            <button type="button" class="btn-icon" @click="openUploadDialog">Upload image(s) <i title="Upload your own screenshot" class="fas fa-upload"></i></button>
            <input id="upload-file-input" type="file" name="files" accept="image/*" ref="filesInput" style="display: none;" @change="filesChange($event.target.files)" multiple>
            <k-file-list :files="files" @remove-file="removeStagedFile"></k-file-list>
            <k-file-list :files="screenshots" @remove-file="removeScreenshot"></k-file-list>
          </fieldset>
        </form>
      </div>
    </template>
    <template #footer>
      <button v-if="showStandardForm" :disabled="disableSend" @click="submitForm" class="btn btn-primary send-btn">Send <i :class="sendIconClass"></i></button>
      <div v-else-if="selectedCoach || selectedDataMentor" class="mentor-buttons">
        <button v @click="showStandardForm = true" class="btn btn-primary">Send Message <i class="fas fa-angle-right"></i></button>
        <a v-if="isContactCoach && selectedCoach && selectedCoach.acuity_link" class="acuity btn btn-primary" :href="selectedCoach.acuity_link" target="_blank">
          Book Appointment <i class="fas fa-calendar"></i>
        </a>
        <a v-if="isContactDataMentor && selectedDataMentor && selectedDataMentor.acuity_link" class="acuity btn btn-primary" :href="selectedDataMentor.acuity_link" target="_blank">
          Book Appointment <i class="fas fa-calendar"></i>
        </a>
      </div>
    </template>
  </k-modal>
</template>

<style>
.issue-dropdown button.k-search-dropdown-button {
  padding: 10px;
  margin: 0;
  transition: all 0.3s ease;
  border-radius: 4px;
  color: var(--kate-mono-0);
}

.issue-dropdown button.k-search-dropdown-button:hover {
  background-color: var(--kate-button-tertiary-hover);
}

.issue-dropdown .k-search-dropdown-adv-content {
  min-width: 250px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--kate-mono-0);
  border-radius: 0;
  padding: 0;
}

.issue-dropdown .k-search-dropdown-adv,
.issue-dropdown input {
  margin: 7px auto;
  width: 90%;
  display: block;
  padding: 5px;
  border: 1px solid var(--kate-type-primary);
  background-color: var(--kate-type-light);
  outline: 0;
}

.issue-dropdown input::placeholder {
  color: var(--kate-type-alt-dark);
}

.issue-dropdown .k-search-dropdown-label:first-child {
  border-top: 0;
}

.issue-dropdown .k-search-dropdown-label {
  color: var(--kate-type-alt-dark);
  border-top: var(--border-primary);
  border-radius: 0;
  margin: 0;
  padding: 15px 10px;
  width: 100%;
}

.issue-dropdown .k-search-dropdown-label:hover {
  background-color: var(--kate-panel-alt);
}

.help-container .modal-container {
  max-width: 70%;
}

.help-container .modal-body {
  overflow: hidden;
}

.mentor-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 15px;
}

.btn .fas.fa-angle-right,
.btn .fas.fa-calendar {
  position: relative;
  bottom: -1px;
  padding-left: 3px;
}

.highlight {
  font-weight: bold;
  color: var(--kate-2);
}

.mentor-buttons button {
  background-color: transparent;
  border: var(--border-primary);
  color: var(--kate-type-light);
}

.mentor-buttons button:hover {
  background-color: var(--kate-button-outlined-hover);
  border: var(--kate-button-outlined-hover-border);
  color: var(--kate-type-dark);
}

.mentor-buttons a.btn:hover {
  color: var(--kate-type-light) !important;
  background-color: var(--kate-background-alt) !important;
}
</style>

<style scoped>
.help-ticket-form fieldset {
  margin: 10px 0;
}

.bug-report-field {
  overflow-x: hidden;
}

.form-input {
  width: 100%;
  margin-bottom: 0.5em;
}

.issue-body {
  min-height: 200px;
}

.issue-body p {
  color: var(--kate-type-light);
}

.issue-body label {
  padding-left: 0;
}

.acuity {
  margin-top: 10px;
}

.mentor-programme > b {
  color: var(--kate-primary);
}

.k-issue-text {
  width: 100%;
  background-color: var(--input-background);
  border: 0;
  color: var(--kate-type-light);
  padding: 6px 12px;
}

input:disabled::placeholder {
  color: var(--kate-disabled);
}

.k-issue-text:disabled {
  background-color: var(--kate-disabled);
}

.send-btn {
  width: 200px;
  transition: all 0.3s;
}

.send-btn:hover:disabled,
.send-btn:disabled {
  background-color: var(--kate-button-primary);
}

.send-btn:focus {
  color: var(--kate-type-light);
  outline: 0;
}

.send-btn i {
  transform: rotate(15deg);
  margin-left: 20px;
}

.bug-report-label span {
  cursor: pointer;
  padding: 10px;
  font-size: 0.9em;
  color: var(--kate-type-primary);
}

.bug-report-label span i {
  color: var(--kate-type-light);
  padding-left: 5px;
  font-size: 1.15em;
}

.bug-report-label span:hover i,
.bug-report-label span:hover {
  color: var(--kate-primary);
}

.bug-report-label hr {
  margin: 0 5px;
  border-top: 0;
  border-right: var(--border-primary);
  display: inline;
}

@media only screen and (max-width: 767px) {
  .send-btn {
    width: 100%;
  }
}
</style>

<script>
import useGlobalStore from '../stores/global';
import KModal from '../components/k-modal.vue';
import ErrorMixin from '../mixins/error-mixins';
import CoachDataMentorMixin from '../mixins/coach-data-mentor-mixin';
import KFileList from '../components/k-file-list.vue';
import KDropdown from '../components/k-dropdown.vue';
import KTextEditor from '../components/k-text-editor.vue';

const PROBLEM_GROUPS = [
  {
    group: 'course_content',
    msg: 'Get help for assignment or learning units',
    key: 'help_pak_lus',
  },
  {
    group: 'edukate_account',
    msg: 'Report a bug on the platform',
    key: 'report_bug',
  },
  {
    group: 'course_content',
    msg: 'Report a bug with the content',
    key: 'help_content',
  },
  {
    group: 'edukate_account',
    msg: 'Suggest a new feature',
    key: 'feature_request',
  },
  {
    group: 'course_logistics',
    msg: 'Contact Cambridge Spark',
    key: 'other_issue',
  },
  {
    group: 'coach',
    msg: 'Schedule an appointment on Acuity or send a message via KATE',
    key: 'contact_coach',
  },
  {
    group: 'data_mentor',
    msg: 'Schedule an appointment on Acuity or send a message via KATE',
    key: 'contact_data_mentor',
  },
];

export default {
  mixins: [ErrorMixin, CoachDataMentorMixin],
  components: {
    KTextEditor,
    'k-modal': KModal,
    'k-file-list': KFileList,
    'k-dropdown': KDropdown,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    defaultModuleName: {
      type: String,
    },
    defaultAssetName: {
      type: String,
    },
    defaultThreadName: {
      type: String,
    },
    helpKey: {
      type: String,
      default: undefined,
    },
    programmeId: {
      type: Number,
      default: undefined,
    },
    moduleId: {
      type: Number,
      default: undefined,
    },
    moduleAssetTypePretty: {
      type: String,
    },
    moduleAssetId: {
      type: Number,
      default: undefined,
    },
    assetId: {
      type: Number,
      default: undefined,
    },
    moduleAssetType: {
      type: String,
      default: undefined,
    },
    threadId: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
      message: '',
      ready: false,
      isSending: false,
      subject: '',
      programmes: [],
      moduleName: this.defaultModuleName,
      contentName: '',
      files: [],
      screenshots: [],
      showStandardForm: true,
    };
  },

  beforeMount() {
    this.getProgrammes();
  },

  watch: {
    defaultModuleName() {
      this.moduleName = this.defaultModuleName;
    },
    defaultContentName() {
      this.contentName = this.defaultContentName;
    },
    inScreenshotMode() {
      if (this.inScreenshotMode === false) {
        // Exiting screenshot mode --> add screenshot to files
        const blob = this.store.screenshotBlob;
        // Hack using .slice to create a copy of the blob and ensure the Content-Type is set correctly
        if (blob) {
          const screenshot = blob.slice(0, blob.size, 'image/jpeg');
          this.screenshots.push(screenshot);
          // Remove the screenshot from the store
          this.store.clearScreenshotBlob();
        }
      }
    },

    show() {
      if (this.show) {
        if (this.isContactCoach || this.isContactDataMentor) {
          // Hide form initially if contacting a coach or data mentor
          this.showStandardForm = false;
        } else {
          // If not contacting coach, want to show usual form by default
          this.showStandardForm = true;
        }
      }
    },
  },

  computed: {
    isDashboard() {
      return this.store.isDashboard;
    },
    contentText() {
      if (this.moduleAssetTypePretty) {
        return this.moduleAssetTypePretty;
      }
      if (this.defaultThreadName) {
        return 'Thread';
      }
      return 'Content';
    },
    defaultContentName() {
      return this.defaultAssetName || this.defaultThreadName;
    },
    isLoggedIn() {
      return this.store.isLoggedIn;
    },
    sendIconClass() {
      return this.isSending ? 'fas fa-spinner fa-spin' : 'fas fa-paper-plane';
    },
    validForm() {
      return Boolean(this.message && this.subject && this.currentProblemGroup !== undefined);
    },
    disableSend() {
      return !this.validForm || !this.ready || this.isSending;
    },
    currentProblemGroup() {
      if (!this.helpKey) {
        return undefined;
      }
      return PROBLEM_GROUPS.find(x => x.key === this.helpKey);
    },
    isBugReport() {
      if (!this.currentProblemGroup) {
        return undefined;
      }
      return this.currentProblemGroup.key === 'report_bug';
    },
    isContactCoach() {
      if (!this.currentProblemGroup) {
        return undefined;
      }
      return this.currentProblemGroup.key === 'contact_coach';
    },
    isContactDataMentor() {
      if (!this.currentProblemGroup) {
        return undefined;
      }
      return this.currentProblemGroup.key === 'contact_data_mentor';
    },
    mentorType() {
      if (this.isContactCoach) {
        return 'coach';
      }
      if (this.isContactDataMentor) {
        return 'data_mentor';
      }
      return undefined;
    },
    inScreenshotMode() {
      return this.store.inScreenshotMode;
    },
    allFiles() {
      return [...this.screenshots, ...this.files];
    },
  },

  methods: {
    getImageSrc(file) {
      return URL.createObjectURL(file);
    },
    clearFields() {
      this.message = '';
      this.subject = '';
      if (!this.defaultModuleName) {
        this.moduleName = '';
      }
      if (!this.defaultContentName) {
        this.contentName = '';
      }
    },
    clearFiles() {
      this.screenshots = [];
      this.files = [];
    },
    getIssueTags() {
      return this.programmes.map(x => `cohort:${x.key}`);
    },
    getProgrammes() {
      this.$http.get('/api/curriculum/programmes', {
      }).then(response => {
        this.programmes = response.data.programmes;
      }).catch(err => {
        this.showError(err, true);
      }).then(() => {
        this.ready = true;
      });
    },
    openUploadDialog() {
      this.$refs.filesInput.click();
    },
    close() {
      this.clearFields();
      this.clearFiles();
      this.$emit('close');
    },
    filesChange(files) {
      this.$logger.info('Staged files for upload', { files: [...files].map(x => x.name) });
      this.files = Array.from(files);
    },
    submitForm() {
      if (this.disableSend) {
        return;
      }
      if (this.isBugReport) {
        this.postBugReport();
      } else {
        this.contactMentor();
      }
    },
    postBugReport() {
      this.isSending = true;
      const jsonPayload = {
        subject: this.subject,
        description: this.$kpurify.sanitise(this.message),
        programme_id: this.programmeId,
        module_id: this.moduleId,
      };
      if (this.moduleName) {
        jsonPayload.module_name = this.moduleName;
      }
      if (this.defaultAssetName) {
        jsonPayload.asset_name = this.contentName;
        jsonPayload.asset_type = this.moduleAssetType;
        if (this.moduleAssetId) {
          jsonPayload.module_asset_id = this.moduleAssetId;
        }
        if (this.assetId) {
          jsonPayload.asset_id = this.assetId;
        }
      }
      if (this.defaultThreadName) {
        jsonPayload.asset_name = this.contentName;
      }
      if (this.threadId) {
        jsonPayload.thread_id = this.threadId;
      }

      // Build form data and post to bug-report endpoint
      const formData = new FormData();
      formData.append('json_payload', JSON.stringify(jsonPayload));
      for (let i = 0; i < this.screenshots.length; i++) {
        formData.append('files', this.screenshots[i], `kate-screenshot-${i + 1}.jpg`);
      }
      for (let i = 0; i < this.files.length; i++) {
        formData.append('files', this.files[i], this.files[i].filename);
      }
      this.$http.post(`/api/support/bug-report${this.isDashboard ? '?is_internal=true' : ''}`, formData)
        .then(() => {
          this.$ktoast.info('Bug report sent');
          this.clearFields();
          this.close();
        }).catch(err => {
          this.showError(err, true);
        }).then(() => {
          this.isSending = false;
        });
    },
    contactMentor() {
      this.isSending = true;
      const jsonPayload = {
        subject: this.subject,
        description: this.$kpurify.sanitise(this.message),
        mentor_type: this.mentorType,
        mentor_programme_id: this.mentorType === 'coach' ? this.selectedCoach.programme_id : this.selectedDataMentor.programme_id,
        programme_id: this.programmeId,
        module_id: this.moduleId,
        module_asset_id: this.moduleAssetId,
        module_asset_type: this.moduleAssetType,
      };
      this.$http.post('/api/support/contact-mentor', jsonPayload)
        .then(() => {
          this.$ktoast.info('Message sent');
          this.clearFields();
          this.close();
        }).catch(err => {
          this.showError(err, true);
        }).then(() => {
          this.isSending = false;
        });
    },
    removeStagedFile(payload) {
      this.$logger.info(`Removing staged file ${payload.file.name}`);
      this.files.splice(payload.index, 1);
    },
    removeScreenshot(payload) {
      this.$logger.info(`Removing screenshot ${payload.file.name}`);
      this.screenshots.splice(payload.index, 1);
    },
  },
};
</script>
