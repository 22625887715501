<template>
  <div>
    <manage-chapters v-if="editChapterMode"
      :id="id"
      :isBlueprint="isBlueprint"
      :moduleChapters="chapters"
      :editChapterMode="editChapterMode"
      @chaptersUpdated="$emit('chaptersUpdated', $event)"
      @closeEdit="editChapterMode = !editChapterMode"
    ></manage-chapters>
    <section v-else>
      <button class="btn btn-primary chapter-mode-btn" @click="editChapterMode = !editChapterMode">
        Manage Chapters
      </button>
      <chapter-overview v-for="chapter in chapters"
        :key="chapter.number"
        :chapter="chapter">
      </chapter-overview>
    </section>
  </div>
</template>

<style scoped>
button.chapter-mode-btn {
  float: right;
  margin-bottom: 15px;
}
</style>

<script>
import ChapterOverview from './chapters/chapter-overview.vue';
import ManageChapters from './chapters/manage-chapters.vue';

export default {
  components: {
    ChapterOverview,
    ManageChapters,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
    isBlueprint: {
      type: Boolean,
      required: true,
    },
    chapters: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      editChapterMode: false,
    };
  },
};
</script>
