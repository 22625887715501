<template>
  <div>
    <div class="create-programme-module-card panel">
      <div class="create-programme-module-details">
        <div class="create-programme-module-number">
          {{ number }}
        </div>
        <div class="create-programme-text-container">
          <div class="module-card-header">
            <div class="module-card-header-title">
              <k-tooltip v-if="!isBlueprint && released && updateReleaseDatePending && !dateIsPast(releaseDate)" :text="pendingWithdrawalText">
                <i class="fas fa-exclamation-triangle pending-withdrawal"></i>
              </k-tooltip>
              <h3> {{ module.name }}</h3>
              <div v-if="!editingBlueprint">
                <k-tooltip v-if="updatePending" class="pending-label-container" text="Click on 'Save Changes' to confirm the update.
                To cancel the update, click on this card and select 'Cancel Update'.">
                  <span class="pending-label">Ready to update</span>
                </k-tooltip>
                <span v-else-if="!isBlueprint && released" class="released-label badge">Released <i class="fas fa-check"></i></span>
                <span v-else-if="!isBlueprint" class="unreleased-label k-text-primary badge">Not Released</span>
              </div>
            </div>
            <div v-if="allowEdit" class="module-card-header-buttons">
              <button v-if="revertButtonShowing" @click.stop="revertPendingChanges" class="btn revert-change btn-danger">
                Revert changes <i class="fas fa-undo"></i>
              </button>
              <k-tooltip :text="editModeText" class="edit-update-btn">
                <button @click.stop="toggleEditMode" class="btn" :class="inEditMode ? 'update btn-success' : 'edit btn-primary'" :disabled="inEditMode && !validForm" :title="inEditMode ? 'Update' : 'Edit'">
                  {{ inEditMode ? "Update" : "Edit" }}
                  <i v-if="!inEditMode" class="fas fa-pen"></i>
                  <i v-else-if="inEditMode" class="fas fa-check"></i>
                </button>
              </k-tooltip>
            </div>
          </div>
          <div class="module-card-content">
            <!-- Edit mode -->
            <template v-if="inEditMode">
              <!-- Blueprint -->
              <template v-if="isBlueprint">
                <form class="blueprint-release pad-below">
                  <fieldset class="form-entry">
                    <label for="blueprint-release-input"><i class="fas fa-thumbtack"></i>Release after:</label>
                    <input id="blueprint-release-input" v-model.number="newReleaseAfterDays" type="number" placeholder="(days)" class="form-control">
                  </fieldset>
                  <fieldset class="form-entry">
                    <label for="blueprint-completion-input"><i class="fas fa-check"></i>Completion time:</label>
                    <input id="blueprint-completion-input" v-model.number="newExpectedCompletionDays" type="number" placeholder="(days)" class="form-control">
                  </fieldset>
                </form>
                <div v-if="module.programmeBlueprintBadges" class="add-badges badges">
                  <button class="btn btn-primary" @click.stop.prevent="showBadgeModalForBP = true">Add badges</button>
                  <select-badge-modal :show="showBadgeModalForBP"
                                      :specificBadges="moduleBadgeOptions"
                                      @close="showBadgeModalForBP = false"
                                      @choose="addBadge"
                  ></select-badge-modal>
                  <div v-for="badge in badgesSortedByThreshold" :key="badge.id">
                    <badge-icon :removable="true"
                                :icon-image="badge.resource_link"
                                :badge-name="badge.name"
                                :threshold-score="badge.threshold_score"
                                @remove="removePendingBadge(badge.id)"
                    ></badge-icon>
                  </div>
                </div>
              </template>
              <!-- Module -->
              <template v-else>
                <div>
                  <div class="date-edits">
                    <k-date-picker inputId="new-release-date-input"
                      label="Release Date"
                      class="date-picker"
                      mask="MMM D, YYYY"
                      type="datetime"
                      v-model="updates.release_date"
                      :modelConfig="{ timeAdjust: '00:00:00' }"
                    ></k-date-picker>
                    <button v-if="releaseDate" class="btn btn-warning withdraw" @click.stop.prevent="withdrawModule">Withdraw Module</button>
                  </div>
                </div>
                <div>
                  <div class="date-edits">
                    <k-date-picker inputId="new-expected-completion-date-input"
                      label="Expected Completion Date"
                      class="date-picker"
                      mask="MMM D, YYYY"
                      type="datetime"
                      v-model="updates.expected_completion_date"
                      :modelConfig="{ timeAdjust: '00:00:00' }"
                    ></k-date-picker>
                  </div>
                </div>
                <div v-if="module.programmeBadges" class="add-badges badges">
                  <button class="btn btn-primary" @click="showBadgeModal = true">Add badges</button>
                  <select-badge-modal :show="showBadgeModal"
                    :specificBadges="moduleBadgeOptions"
                    @close="showBadgeModal = false"
                    @choose="addBadge"
                  ></select-badge-modal>
                  <div v-for="badge in badgesSortedByThreshold" :key="badge.id">
                    <badge-icon :removable="true"
                                :icon-image="badge.resource_link"
                                :badge-name="badge.name"
                                :threshold-score="badge.threshold_score"
                                @remove="removePendingBadge(badge.id)"
                    ></badge-icon>
                  </div>
                </div>
              </template>
            </template>
            <!-- Normal mode -->
            <template v-else>
              <div class="release-date-container">
                <h4 v-if="releaseDate" class="release-date">
                  <k-tooltip text="Content in the module becomes available to students at midnight (UTC) on this date">
                    Release: <i :class="dateIsPast(releaseDate) ? 'green' : 'amber'">{{ formatDateString(releaseDate) }}</i>
                  </k-tooltip>
                </h4>
                <h4 v-if="expectedCompletionDate" class="release-date">
                  <k-tooltip text="Content in the module is expected to have been completed by midnight (UTC) on this date">
                    Completion: <i :class="dateIsPast(expectedCompletionDate) ? 'amber' : 'green'">{{ formatDateString(expectedCompletionDate) }}</i>
                  </k-tooltip>
                </h4>
                <h4 v-if="releaseAfterDays" class="release-date">
                  <k-tooltip :text="`Content in the module becomes available to students ${releaseAfterDays} days after the programme starts`">
                    Release after <i class="green">{{ releaseAfterDays }} days</i>
                  </k-tooltip>
                </h4>
                <h4 v-if="expectedCompletionDays" class="release-date">
                  <k-tooltip :text="`Content in the module is expected to take ${expectedCompletionDays} days to complete after the module is released`">
                    Completion time <i class="amber">{{ expectedCompletionDays }} days</i>
                  </k-tooltip>
                </h4>
              </div>
            </template>
            <template v-if="!editingExistingModule">
              <div class="module-description" v-html="getTruncatedModuleDescription(module)"></div>
              <div class="release-module-asset-counts">
                <div class="module-meta" >
                  <i v-if="!chaptersReady" class="fas fa-spinner fa-spin"></i>
                  <div v-if="hasTags && chaptersReady" class="tag-container">
                    <span v-for="t in tags" class="badge">{{t}}</span>
                  </div>
                  <div v-if="showBadges && !inEditMode" class="badges">
                    <div v-for="badge in badgesSortedByThreshold">
                      <badge-icon :removable="false"
                                  :icon-image="badge.resource_link"
                                  :badge-name="badge.name"
                                  :threshold-score="badge.threshold_score"></badge-icon>
                    </div>
                    <div v-if="!badgesSortedByThreshold.length" class="badge-empty-placeholder">No badges in this module</div>
                  </div>
                  <k-asset-counts
                    v-if="chaptersReady"
                    :assets="assets"
                  ></k-asset-counts>
                  <div v-if="!isBlueprint" class="pad-below" >
                    <router-link v-if="!inEditMode && !addedModule"
                                 :to="{ name: 'manage_module', params: {programmeId: dashboardId, moduleId: id }}">
                        Manage module data and content<i class="fas fa-external-link-alt"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="create-programme-module-controls-container">
        <div class="create-programme-module-emit-buttons">
          <k-tooltip v-if="allowRemove" :text="removeModuleTooltip">
            <button class="btn" @click="handleRemove" :disabled="!canDeleteModule" aria-label="Remove" title="Remove">
              <i class="fas fa-trash-alt"></i>
            </button>
          </k-tooltip>
          <k-tooltip v-if="showAdd" :text="addToolTipText">
            <button class="btn" @click="$emit('add')" :disabled="disableAdd" aria-label="Add" title="Add">
              <i class="fas fa-plus-circle"></i>
            </button>
          </k-tooltip>
        </div>
        <div v-if="draggable" class="drag">
          <i class="fas fa-grip-vertical"></i>
        </div>
      </div>
    </div>
    <div v-if="track" class="create-programme-module-track">
      <input type="radio" id="curriculum" value="curriculum" v-model="trackVal"/>
      <label for="curriculum">Curriculum</label>
      <input type="radio" id="milestone" value="milestone" v-model="trackVal"/>
      <label for="milestone">Milestones</label>
    </div>
  </div>
</template>

<style>
.create-programme-module-card.k-module-item.sortable-chosen.ghost .drag,
.create-programme-module-card.k-module-item.sortable-chosen.ghost {
  cursor: grabbing;
}

.create-programme-module-card.k-module-item.sortable-chosen.ghost {
  outline: 3px solid var(--kate-primary);
}
</style>

<style scoped>
.release-date-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.release-date-container .release-date {
  margin: 0;
}

.release-date-container .release-date:nth-child(1n) {
  border-right: var(--border-primary);
  padding-right: 15px;
}

.release-date-container .release-date:last-child {
  border-right: 0;
}

.module-description {
  margin: 15px 0;
}

form.blueprint-release {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1em;
}

form.blueprint-release fieldset {
  max-width: 50%;
}

form.blueprint-release fieldset i.fa-thumbtack {
  color: var(--kate-lu);
}

form.blueprint-release fieldset i.fa-check {
  color: var(--kate-success);
}

form.blueprint-release fieldset i {
  margin-right: 8px;
}

.pad-below {
  padding-bottom: 1em;
}

.date-edits .date-picker {
  width: 50%;
  margin-bottom: 10px;
}

.fieldset {
  display: inherit;
}

.title-edit {
  resize: horizontal;
  white-space: nowrap;
  overflow: hidden;
  max-width: 450px;
  min-width: 250px;
  display: block;
}

textarea {
  border-radius: 4px;
  padding: 5px;
}

.pending-label-container {
  display: flex;
}

.create-programme-module-details {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 20fr;
  gap: 15px;
}

.create-programme-module-details.disabled {
  pointer-events: none;
}

.create-programme-module-card {
  display: grid;
  grid-template-columns: 20fr 1fr;
  background-color: var(--kate-panel-alt);
  min-height: 7em;
  border-radius: 10px 4px 4px 10px;
  transition: all 0.3s;
}

.create-programme-module-card:hover,
.create-programme-module-card:hover .create-programme-module-number {
  background-color: var(--kate-background-alt-alpha);
}

.create-programme-module-card:hover {
  box-shadow: var(--box-shadow);
}

.create-programme-module-card:active {
  background-color: var(--kate-background-alt-alpha);
  outline: 3px solid var(--kate-primary);
  box-shadow: var(--box-shadow);
}

.create-programme-module-card.disabled:hover {
  background-color: var(--kate-panel-alt);
}

.create-programme-text-container {
  flex-direction: column;
}

.date-edits,
.module-card-header,
.module-card-header-title,
.module-card-header-buttons,
.create-programme-text-container {
  display: flex;
  gap: 15px;
}

.module-card-header {
  padding: 15px 0 0;
  justify-content: space-between;
  align-items: center;
}

.module-card-header h3 {
  margin-top: 0;
}

.module-card-header-title {
  align-items: center;
}

.pending-withdrawal {
  color: var(--kate-warning);
  font-size: 1.5em;
}

.create-programme-module-emit-buttons {
  display: flex;
  gap: 10px;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
}

.create-programme-module-emit-buttons button {
  padding: 15px;
}

.create-programme-module-emit-buttons .btn {
  background-color: transparent;
  border: none;
}

.create-programme-module-emit-buttons .btn i {
  font-size: 1.3em;
}

.create-programme-module-emit-buttons .btn .fa-plus-circle {
  color: var(--kate-success);
}

.create-programme-module-emit-buttons .btn .fa-plus-circle:hover {
  color: var(--kate-success-light);
}

.create-programme-module-number {
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--kate-primary-dark);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  color: var(--kate-type-light);
  width: 60px;
}

span.released-label {
  color: var(--kate-type-dark);
  background-color: var(--kate-success);
}

span.unreleased-label {
  background-color: var(--kate-danger-alt);
  white-space: nowrap;
}

span.pending-label {
  color: var(--kate-type-dark);
  background-color: var(--kate-warning);
}

button.release,
button.unrelease,
button.withdraw {
  margin-right: 5px;
  display: inline;
  width: fit-content;
  align-self: flex-start;
}

.tag-container {
  padding-bottom: 20px;
}

.release-module-asset-counts {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 1em;
}

.release-module-asset-counts > a {
  width: 100%;
}

.create-programme-module-controls-container {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.create-programme-module-controls-container .drag {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
}

.release-module-asset-counts > a i.fas,
button > i.fas {
  padding-left: 5px;
}

.create-programme-module-track {
  margin-bottom: 2em;
}

.badges {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.add-badges {
  margin-bottom: 10px;
}

.badge-empty-placeholder {
  font-style: italic;
}

.disabled-link {
  pointer-events: none;
  cursor: default;
  color: var(--kate-disabled);
}

@media (max-width: 767px) {
  .module-card-header,
  .module-card-header-title,
  .date-edits {
    display: flex;
    flex-wrap: wrap;
  }

  .date-edits .date-picker,
  .module-card-header h3 {
    width: 100%;
    margin-bottom: 0;
  }

  .module-card-content {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
}
</style>

<script>
import TimeMixin from '../../../mixins/time-mixins';
import ChaptersMixin from '../../../mixins/chapters-mixin';
import ModuleMixin from '../../../mixins/module-mixin';
import KTooltip from '../../../components/k-tooltip.vue';
import KAssetCounts from '../../../components/k-asset-counts.vue';
import KDatePicker from '../../../components/k-date-picker.vue';
import GetOrNull from '../../../modules/get-or-null';
import SelectBadgeModal from '../../achievements/select-badge-modal.vue';
import { sortObjectArray } from '../../../modules/sort-by-object-property';
import isEmpty from '../../../modules/is-empty';
import copyObject from '../../../modules/copy-object';
import useModuleChapterStore from '../../../stores/module-chapter-store';
import BadgeIcon from '../../achievements/badge-icon.vue';

export default {
  components: {
    BadgeIcon,
    KDatePicker,
    SelectBadgeModal,
    KTooltip,
    KAssetCounts,
  },

  mixins: [TimeMixin, ChaptersMixin, ModuleMixin],

  props: {
    id: Number,
    module: {
      type: Object,
      default: () => ({}),
    },
    tags: Array,
    number: Number,
    isBlueprint: Boolean,
    isNewlyAdded: Boolean,
    released: Boolean,
    draggable: {
      type: Boolean,
      default: false,
    },
    allowRemove: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
    disableAdd: {
      type: Boolean,
      default: false,
    },
    track: {
      type: String,
      default: undefined,
    },
    showBadges: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inEditMode: false,
      newReleaseAfterDays: undefined,
      newExpectedCompletionDays: undefined,
      newBadgesInProgrammeBPModule: [],
      newModuleDetails: {},
      confirmRemoveModuleToast: undefined,
      chaptersReady: false,
      updates: {
        release_date: undefined,
        expected_completion_date: undefined,
        badges_in_module: [],
      },
      trackVal: this.track,
      showBadgeModal: false,
      showBadgeModalForBP: false,
      moduleChapterStore: useModuleChapterStore(),
    };
  },

  beforeMount() {
    this.getChapters();
  },

  watch: {
    updates: {
      handler(updatesValues) {
        this.updateNewModuleDetails(updatesValues);
      },
      deep: true,
    },
    updatePending() {
      if (!this.updatePending) {
        this.resetForm();
      }
    },
    module() {
      this.trackVal = this.module.track;
    },
    trackVal() {
      this.$emit('update-track', this.trackVal);
    },
    newReleaseAfterDays(val) {
      this.updateNewModuleDetails({ newReleaseAfterDays: val });
    },
    newExpectedCompletionDays(val) {
      this.updateNewModuleDetails({ newExpectedCompletionDays: val });
    },
    newBadgesInProgrammeBPModule(val) {
      this.updateNewModuleDetails({ newBadgesInProgrammeBPModule: val });
    },
  },

  computed: {
    chapters() {
      return this.moduleChapterStore.getChaptersByModule(this.id);
    },
    // Updates for modules in programme blueprints
    releaseAfterDays() {
      return GetOrNull('newReleaseAfterDays', this.module, this.module.release_after_days);
    },
    expectedCompletionDays() {
      return GetOrNull('newExpectedCompletionDays', this.module, this.module.expected_completion_days);
    },
    badgesInProgrammeBPModule() {
      return GetOrNull('newBadgesInProgrammeBPModule', this.module, this.module.badges_in_module_blueprint) || [];
    },
    // Updates for modules in programmes
    releaseDate() {
      return GetOrNull('updates', this.module, this.module).release_date;
    },
    expectedCompletionDate() {
      return GetOrNull('updates', this.module, this.module).expected_completion_date;
    },
    badgesInModule() {
      return GetOrNull('updates', this.module, this.module).badges_in_module;
    },
    updateReleaseDatePending() {
      return Boolean('updates' in this.module && 'release_date' in this.module.updates);
    },
    addedModule() {
      return Boolean(this.module.addedModule);
    },
    addToolTipText() {
      if (this.disableAdd) {
        return 'This module is already used this programme blueprint';
      }
      return 'Add module blueprint';
    },
    editingExistingModule() {
      return this.inEditMode && !this.isBlueprint && !this.addedModule;
    },
    editingBlueprint() {
      return this.inEditMode && this.isBlueprint;
    },
    dashboardId() {
      return this.$route.params.programmeId;
    },
    pendingWithdrawalText() {
      return `This module will be withdrawn${this.releaseDate && !this.dateIsPast(this.releaseDate) ? ` until ${this.formatDateString(this.releaseDate)}` : ' from release.'}`;
    },
    editModeText() {
      if (this.inEditMode) {
        return "Stash changes and exit edit mode. When ready to commit, click 'Save Changes'";
      }
      return 'Edit release and completion dates';
    },
    removeModuleTooltip() {
      if (this.isBlueprint) {
        return 'Remove module blueprint';
      }
      if (this.canDeleteModule) {
        return 'Remove this module from the programme';
      }
      return 'This module cannot be removed due to the risk of data loss - it has associated saves / submissions';
    },
    canDeleteModule() {
      if (this.isBlueprint) {
        return true;
      }
      return !this.chapters.flatMap(c => c.assets.map(a => (a.asset_type === 'learning_unit' ? a.has_saves : a.has_submissions))).includes(true);
    },
    updatePending() {
      return Boolean('updates' in this.module);
    },
    revertButtonShowing() {
      return (this.updatePending || this.inEditMode);
    },
    validForm() {
      return true;
    },
    hasTags() {
      return Boolean(this.tags && this.tags.length > 0);
    },
    badgesSortedByThreshold() {
      if (this.isBlueprint) {
        return sortObjectArray(
          (this.inEditMode ? this.newBadgesInProgrammeBPModule : this.badgesInProgrammeBPModule) || [],
          'threshold_score',
        );
      }
      return sortObjectArray(
        (this.inEditMode ? this.updates.badges_in_module : this.badgesInModule) || [],
        'threshold_score',
      );
    },
    moduleBadgeOptions() {
      if (this.isBlueprint) {
        return this.module.programmeBlueprintBadges?.filter(badge => !this.newBadgesInProgrammeBPModule?.map(eb => eb.id).includes(badge.id)) || [];
      }
      return this.module.programmeBadges?.filter(badge => !this.updates.badges_in_module?.map(eb => eb.id).includes(badge.id)) || [];
    },
  },

  methods: {
    updateNewModuleDetails(updatedValObj) {
      if (this.inEditMode) {
        this.newModuleDetails = { ...this.newModuleDetails, ...updatedValObj };
      }
    },
    resetForm() {
      this.newModuleDetails = {};
      this.updates.release_date = this.releaseDate ? this.getDate(this.releaseDate) : undefined;
      this.updates.badges_in_module = copyObject(this.badgesInModule || []);
      this.updates.expected_completion_date = this.expectedCompletionDate ? this.getDate(this.expectedCompletionDate) : undefined;
      this.newReleaseAfterDays = this.releaseAfterDays;
      this.newExpectedCompletionDays = this.expectedCompletionDays;
      this.newBadgesInProgrammeBPModule = copyObject(this.badgesInProgrammeBPModule || []);
    },
    formatDateString(dateString) {
      if (dateString) {
        return this.parseTimestamp(this.getDate(dateString), undefined, 'MMM d, yyyy');
      }
      return '';
    },
    revertPendingChanges() {
      this.cancelUpdate();
      this.resetForm();
      this.$emit('editMode', false);
      this.inEditMode = false;
    },
    toggleEditMode() {
      if (!this.allowEdit) {
        return;
      }
      if (this.inEditMode) {
        // Leaving edit mode - only emit an update if anything has actually changed
        if (!isEmpty(this.newModuleDetails)) {
          this.$emit('update', this.newModuleDetails);
        }
      } else {
        // Entering edit mode
        this.updates.release_date = this.updates.release_date ? this.updates.release_date : this.releaseDate;
        this.updates.expected_completion_date = this.updates.expected_completion_date ? this.updates.expected_completion_date : this.expectedCompletionDate;
        this.updates.badges_in_module = this.updates.badges_in_module.length ? this.updates.badges_in_module : this.badgesInModule || [];
        this.newReleaseAfterDays = this.newReleaseAfterDays ? this.newReleaseAfterDays : this.releaseAfterDays;
        this.newExpectedCompletionDays = this.newExpectedCompletionDays ? this.newExpectedCompletionDays : this.expectedCompletionDays;
        this.newBadgesInProgrammeBPModule = this.newBadgesInProgrammeBPModule.length ? this.newBadgesInProgrammeBPModule : this.badgesInProgrammeBPModule;
      }
      this.$nextTick(() => {
        // Next tick so newXxx watchers are triggered __before__ editMode updates
        this.$emit('editMode', !this.inEditMode);
        this.inEditMode = !this.inEditMode;
      });
    },
    addBadge(badge) {
      if (this.isBlueprint) {
        if (!this.newBadgesInProgrammeBPModule.map(b => b.id).includes(badge.id)) {
          this.newBadgesInProgrammeBPModule.push(badge);
        }
        return;
      }
      if (!this.updates.badges_in_module.map(b => b.id).includes(badge.id)) {
        this.updates.badges_in_module.push(badge);
      }
    },
    cancelUpdate() {
      this.$emit('cancel');
    },
    withdrawModule() {
      this.updates.release_date = undefined;
    },
    async getChapters() {
      this.$logger.info('Getting chapters');
      this.chaptersReady = false;
      await this.moduleChapterStore.fetchChapters(this.id, this.isBlueprint, this.isNewlyAdded);
      this.chaptersReady = true;
    },
    handleRemove() {
      if (this.isBlueprint) {
        this.$emit('remove');
      } else {
        this.confirmModuleRemoval();
      }
    },
    removePendingBadge(badgeId) {
      if (this.isBlueprint) {
        this.newBadgesInProgrammeBPModule = this.newBadgesInProgrammeBPModule.filter(b => b.id !== badgeId) || [];
        return;
      }
      this.updates.badges_in_module = this.updates.badges_in_module.filter(b => b.id !== badgeId);
    },
    confirmCallback() {
      this.$emit('remove');
    },
    confirmModuleRemoval() {
      this.$ktoast.confirmToast(
        'This module has no associated student saves or submissions and can be removed.',
        'warning',
        () => this.confirmCallback(),
      );
    },
  },
};
</script>
