<template>
  <div>
    <k-panel title="Certificate"
      icon="fa-certificate"
      :duotone="false"
      :showContent="showContent"
      @toggle="showContent = $event"
    >
      <template #body>
        <p>
          A certificate can be assigned to a programme. The certificate can then be awarded to
          a learner on completion of the programme via the student dashboard page
        </p>
        <certificate-list v-if="certificateId"
          :achievements="selectedCertificates"
          achievement-type="certificate"
          :editable="true"
          @remove-achievement="removeCertificate"
        ></certificate-list>
        <empty-placeholder v-else
          title="No certificate in this programme"
          info="Click 'Add Certificate' button below to include certificate in this programme."
          fa-icon="fas fa-square-dashed-circle-plus"
        ></empty-placeholder>
        <div class="certificate-controls">
          <button class="btn btn-primary" @click="showCertificateModal = true">
            {{ buttonText }}
          </button>
          <button class="btn btn-success save-certificate" :disabled="!unsavedChanges" @click="updateCertificate">
            Confirm
          </button>
        </div>
        <select-certificate-modal :show="showCertificateModal"
          @close="showCertificateModal = false"
          @choose="addCertificate"
        ></select-certificate-modal>
      </template>
    </k-panel>
  </div>
</template>

<style scoped>
.certificate-controls {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.panel-content > p {
  margin-bottom: 30px;
}
</style>

<script>
import KPanel from '../../components/k-panel.vue';
import SelectCertificateModal from '../achievements/select-certificate-modal.vue';
import CertificateList from '../achievements/achievement-list.vue';
import PageReadyMixin from '../../mixins/page-ready-mixin';
import EmptyPlaceholder from '../../components/empty-placeholder.vue';
import ErrorMixin from '../../mixins/error-mixins';
import copyObject from '../../modules/copy-object';

export default {
  components: {
    KPanel,
    CertificateList,
    SelectCertificateModal,
    EmptyPlaceholder,
  },

  mixins: [PageReadyMixin, ErrorMixin],

  props: {
    programme: {
      type: Object,
    },
    programmeReady: {
      type: Boolean,
    },
    isBlueprint: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mounted: false,
      showContent: true,
      showCertificateModal: false,
      // Note: only one certificate can be selected so 1-element array
      selectedCertificates: undefined,
      unsavedChanges: false,
    };
  },

  mounted() {
    this.mounted = true;
    this.loadCertificateFromProgramme();
  },

  watch: {
    programme() {
      this.loadCertificateFromProgramme();
    },
    unsavedChanges() {
      this.$emit('unsaved-changes', this.unsavedChanges);
    },
  },

  computed: {
    apiEndpoint() {
      return `/api/curriculum/${this.isBlueprint ? 'blueprints/' : ''}programmes/${this.entityId}/certificate`;
    },
    entityId() {
      return Number(this.isBlueprint ? this.$route.params.programmeBlueprintId : this.$route.params.programmeId);
    },
    entityName() {
      return this.isBlueprint ? 'programme blueprint' : 'programme';
    },
    ready() {
      return this.programmeReady && this.mounted;
    },
    certificateId() {
      return this.selectedCertificates ? this.selectedCertificates[0]?.id : null;
    },
    buttonText() {
      return this.certificateId ? 'Change Certificate' : 'Add Certificate';
    },
  },

  methods: {
    loadCertificateFromProgramme() {
      const progCert = copyObject(this.programme || {})?.certificate;
      this.selectedCertificates = progCert ? [progCert] : undefined;
    },
    updateCertificate() {
      this.$Loading.start();
      this.$http.put(this.apiEndpoint, { certificate_id: this.certificateId }).then(() => {
        this.unsavedChanges = false;
        this.$emit('refresh');
        this.$ktoast.success(`Certificate updated for ${this.entityName}`);
      }).catch(err => {
        this.$logger.autowarn(`Error updating certificate for ${this.entityName}`, {
          id: this.entityId,
          certificateId: this.certificateId,
        }, err);
        this.showError(err);
      }).then(() => {
        this.$Loading.finish();
      });
    },
    addCertificate(certificate) {
      this.unsavedChanges = true;
      this.selectedCertificates = [certificate];
    },
    removeCertificate() {
      this.unsavedChanges = true;
      this.selectedCertificates = undefined;
    },
  },
};
</script>
