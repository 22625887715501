<template>
  <div class="edit-user-info">
    <div>
      <span v-if="selectedProgramme.learner_status">{{ selectedProgramme.learner_status }}</span>
      <span v-else>Select learner status</span>
      <i v-if="canUpdate"
        class="fas fa-edit clickable"
        title="Click to change value"
        @click="editData = !editData">
      </i>
    </div>
    <div>
      <k-dropdown v-if="canUpdate && editData"
        v-model="newUserInfo"
        :options="options"
        :showSearch="false"
        :placeholder="`Select Learner Status`"
      >
      </k-dropdown>
    </div>
  </div>
</template>

<style>
.edit-user-info .k-search-dropdown-adv-content {
  min-width: 160px;
}
</style>

<style scoped>
.edit-user-info i {
  color: var(--kate-type-light);
  padding-left: 5px;
}
</style>

<script>
import KDropdown from '../../components/k-dropdown.vue';

export default {
  components: {
    'k-dropdown': KDropdown,
  },

  props: {
    selectedProgramme: {
      type: Object,
    },
    options: {
      type: Array,
    },
    canUpdate: {
      type: Boolean,
    },
  },

  data() {
    return {
      editData: false,
      newUserInfo: undefined,
    };
  },

  watch: {
    newUserInfo() {
      if (this.newUserInfo && this.canUpdate) {
        this.$emit('new-data', this.newUserInfo);
        this.editData = false;
      }
    },
  },
};
</script>
