<template>
  <div class="filter-container">
    <div class="k-filter-dropdown">
      <button
        class="k-filter-dropdown-button btn btn-outlined"
        :class="showFilters ? 'active' : ''"
        aria-label="Filters" title="Filters" @click="toggleFilterDropdown"
        ref="kdropdown">
        <i class="fas fa-filter"></i> Filters
        <span class="active-filters-num" v-if="numberOfSelectedFilters > 0">- {{ numberOfSelectedFilters }} selected</span>
      </button>
    </div>
    <ul class="filters-list" v-if="showFilters">
      <li v-for="filter in filterOptions" :key="filter.key">
        <label class="k-custom-checkbox">
          <input type="checkbox" :value="filter.key" v-model="activeFilters[filter.key]" class="hidden-checkbox"/>
          <span class="k-custom-checkbox-box"></span>
          <span class="k-custom-checkbox-text">{{ filter.text }}</span>
        </label>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.filter-container .k-filter-dropdown-button.btn-outlined:focus,
.filter-container .k-filter-dropdown-button.btn-outlined.active {
  background: var(--kate-background-body-alpha);
  border-color: var(--kate-secondary);
  color: var(--kate-type-light);
}

.filter-container .k-filter-dropdown-button i {
  padding-right: 5px;
}

.active-filters-num {
  color: var(--kate-type-primary);
  font-weight: 400;
  font-size: 0.9em;
}

.filter-container {
  position: relative;
}

.filters-list {
  position: absolute;
  right: 0;
  z-index: 1;
  min-width: 280px;
  padding: 15px;
  list-style: none;
  background-color: var(--input-background);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: var(--box-shadow);
}

.filters-list li .k-custom-checkbox-text {
  color: var(--kate-type-light);
  padding: 5px;
}

.filters-list li .k-custom-checkbox-text,
.filters-list .k-custom-checkbox-box {
  cursor: pointer;
}

.filters-list li:hover .k-custom-checkbox-text {
  color: var(--kate-secondary);
}

input.hidden-checkbox:checked + .k-custom-checkbox-box {
  background-color: var(--kate-secondary);
}

input.hidden-checkbox:checked + .k-custom-checkbox-box::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid var(--kate-background-body);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
</style>

<script>

export default {

  props: {
    defaultSettings: {
      type: Object,
      default: () => ({}),
    },
    filterOptions: {
      type: Array,
      require: true,
      validator(value) {
        if (value instanceof Array) {
          for (let i = 0; i < value.length; i++) {
            // Accept Filter objects with the required properties
            const hasFilterProperties = value[i].text && value[i].func && value[i].key;
            if (!hasFilterProperties) {
              return false;
            }
          }
          return true;
        }
        return false;
      },
    },
  },

  data() {
    return {
      showFilters: false,
      activeFilters: {},
    };
  },

  mounted() {
    this.loadFromRoute();
  },

  watch: {
    activeFilters: {
      handler() {
        this.emitFilters();
      },
      deep: true,
    },
  },

  computed: {
    numberOfSelectedFilters() {
      return this.filterOptions.filter(x => this.activeFilters[x.key]).length;
    },
  },

  methods: {
    toggleFilterDropdown() {
      this.showFilters = !this.showFilters;
    },

    emitFilters() {
      this.updateRouteFilters();
      const filters = this.filterOptions.filter(x => this.activeFilters[x.key]);
      const filterFunc = x => {
        for (let i = 0; i < filters.length; i++) {
          if (!filters[i].func(x)) {
            return false;
          }
        }
        return true;
      };
      this.$emit('filter', filterFunc);
    },

    updateRouteFilters() {
      // Vue doesn't like it if you update the route without changes
      // This method checks that the value actually changes before updating.
      // const filterKeys = Object.keys(this.activeFilters);
      const filterKeys = this.filterOptions.map(x => x.key);
      const query = { ...this.$route.query };
      let key;
      let change = false;
      let queryVal;
      let jsonVal;

      for (let i = 0; i < filterKeys.length; i++) {
        key = filterKeys[i];
        queryVal = query[key];
        if (this.activeFilters[key] || this.defaultSettings[key]) {
          // For filters that are set to true by default, they need to be explicitly set to false if when off,
          // otherwise they will be overridden by the default settings when sharing urls.
          jsonVal = JSON.stringify(this.activeFilters[key]);
          if (jsonVal !== queryVal) {
            query[key] = jsonVal;
            change = true;
          }
        } else if (queryVal) {
          delete query[key];
          change = true;
        }
      }
      if (change) {
        this.$router.replace({ query });
      }
    },

    loadFromRoute() {
      const filterOptionsKeys = this.filterOptions.map(x => x.key);
      const query = this.$route.query;
      let activeFilters = {};
      if (Object.keys(query).length) {
        filterOptionsKeys.forEach(key => {
          if (query[key] !== undefined) {
            activeFilters[key] = JSON.parse(query[key]);
          }
        });
      } else {
        activeFilters = { ...this.defaultSettings };
      }
      this.activeFilters = activeFilters;
    },
  },
};
</script>
