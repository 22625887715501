import Papa from 'papaparse';
import TimeMixin from './time-mixins';

export default {
  mixins: [TimeMixin],
  methods: {
    parseCsvFile(file, complete, step) {
      if (complete && step) {
        throw new Error('Cannot define both callback and step');
      }
      Papa.parse(file, {
        complete,
        step,
        header: true,
        skipEmptyLines: true,
      });
    },
    parseCsvString(csv) {
      return Papa.parse(csv);
    },
    getRowText(text) {
      if (text === null) {
        return '';
      }
      if (typeof text === 'object') {
        return text.text;
      }
      return text;
    },
    convertTableToCsv(headers, rows) {
      const papaParams = this.convertTableToPapa(headers, rows);
      const csvOutput = Papa.unparse(papaParams);
      return csvOutput;
    },
    downloadCsvFromTable(headers, rows, filename) {
      const csvraw = encodeURIComponent(this.convertTableToCsv(headers, rows));
      const csv = `data:text/csv;charset=utf-8,${csvraw}`;
      const link = document.createElement('a');
      link.href = csv;
      link.download = filename || 'table.csv';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    },
    convertTableToPapa(headers, rows) {
      const headerKeys = Object.keys(headers);
      const data = [];
      const fields = [];
      for (let i = 0; i < headerKeys.length; i++) {
        fields.push(headers[headerKeys[i]].name || headerKeys[i]);
      }
      let row;
      let cell;
      let head;
      for (let i = 0; i < rows.length; i++) {
        row = [];
        for (let k = 0; k < headerKeys.length; k++) {
          head = headerKeys[k];
          if (headers[head].type === 'timestamp') {
            cell = this.parseTimestamp(this.getRowText(rows[i][head]));
          } else if (headers[head].type === 'date') {
            cell = this.parseTimestamp(this.getRowText(rows[i][head]), undefined, 'MMM d, yyyy');
          } else {
            cell = this.getRowText(rows[i][head]);
          }
          row.push(cell);
        }
        data.push(row);
      }
      return { fields, data };
    },
  },
};
