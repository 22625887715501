<template>
  <div class="content-with-spacing" id="policy-container">
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <h1>EDUKATE.AI Policy</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <div class="panel panel-default policies-panel">
          <div class="panel-body">
            <policy v-if="isPolicyStage" @loading="assignLoading" :policy-type="currentPolicyType" :key="currentPolicyType"></policy>
            <div v-else class="opt-in-panel">
              <h1>Can we keep in touch?</h1>
              <div class="opt-in-text">
                <p>Cambridge Spark would like to keep you updated regarding EDUKATE.AI product features and project releases.</p>
              </div>
              <div class="row figure-container">
                <figure>
                  <img src="../../img/EDUKATE_mail.png" alt="hiring"/>
                  <!-- <figcaption>New jobs opportunities</figcaption> -->
                </figure>
              </div>
              <div class="opt-in-buttons">
                <button class="btn btn-success" @click="acceptOptIn">Yes</button>
                <button class="btn btn-danger" @click="rejectOptIn">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <div class="button-group">
          <template v-if="isPolicyStage">
          <button :disabled="disableButtons" @click="prev" class="btn btn-primary" v-if="currentPolicyIndex !== 0">
            Previous
          </button>
          <template v-if="lastPage">
            <button :disabled="disableButtons" class="btn btn-danger" @click="logOut">
              Logout
            </button>
            <button :disabled="disableButtons" class="btn btn-success" @click="accept">
              Accept
            </button>
          </template>
          <button :disabled="disableButtons" class="btn btn-primary" @click="next" v-if="!lastPage">
            Next
          </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.panel {
  background-color: var(--input-background);
  max-height: 36em;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--kate-type-light);
}

.policies-panel {
  padding: 15px;
}

.opt-in-panel p {
  color: var(--kate-type-light);
  text-align: left;
}

#policy-container {
  margin-left: auto;
  max-width: 50em;
  margin-right: auto;
}

.button-group {
  float: right;
  padding-bottom: 15px;
}

.figure-container img {
  width: 150px;
  height: auto;
}

.opt-in-panel figure {
  padding: 15px;
  margin: 0 auto;
}

.figure-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0 30px;
}

.opt-in-text {
  text-align: center;
}

.opt-in-buttons {
  text-align: center;
}

.opt-in-buttons button {
  padding: 8px 18px;
  margin: 5px;
}
</style>

<script>
import useGlobalStore from '../stores/global';
import Policy from './policy.vue';
import ErrorMixin from '../mixins/error-mixins';

const POLICY_TYPES = [
  'terms_and_conditions',
  'privacy_policy',
  'cookies_policy',
];

const POLICY_STAGE = 0;
const COMMUNICATION_STAGE = 1;

export default {
  mixins: [ErrorMixin],
  components: {
    policy: Policy,
  },
  data() {
    return {
      store: useGlobalStore(),
      stage: POLICY_STAGE,
      currentPolicyIndex: 0,
      isLoading: true,
      isSubmitting: false,
      accepted: false,
    };
  },
  computed: {
    isPolicyStage() {
      return this.stage === POLICY_STAGE;
    },
    isCommunicationStage() {
      return this.stage === COMMUNICATION_STAGE;
    },
    disableButtons() {
      return this.isLoading || this.isSubmitting;
    },
    currentPolicyType() {
      return POLICY_TYPES[this.currentPolicyIndex];
    },
    lastPage() {
      return this.currentPolicyIndex === POLICY_TYPES.length - 1;
    },
    userId() {
      return this.store.userId;
    },
  },
  mounted() {
    this.$Loading.finish();
    this.$logger.pageReady();
  },
  beforeRouteLeave(to, from, next) {
    if (this.accepted || to.name === 'login_page') {
      next();
    } else {
      next(false);
    }
  },
  methods: {
    assignLoading(val) {
      this.isLoading = val;
    },
    next() {
      this.currentPolicyIndex = Math.min(this.currentPolicyIndex + 1, POLICY_TYPES.length - 1);
    },
    prev() {
      this.currentPolicyIndex = Math.max(this.currentPolicyIndex - 1, 0);
    },
    goHome() {
      this.$router.push({ path: '/' });
    },

    updateOptIn(optin) {
      this.isSubmitting = true;
      this.$Loading.start();
      this.$http.put('/api/profile/user', {
        opt_in_communication: optin || false,
      }).then(() => {
      }).catch(err => {
        this.showError(err, true);
      }).then(() => {
        this.$Loading.finish();
        this.isSubmitting = false;
        this.goHome();
      });
    },

    acceptOptIn() {
      this.updateOptIn(true);
    },

    rejectOptIn() {
      this.updateOptIn(false);
    },

    accept() {
      this.isSubmitting = true;
      this.$Loading.start();
      this.$http.post('/api/profile/policy/accept').then(() => {
        this.$Loading.finish();
        this.isSubmitting = false;
        this.accepted = true;
        this.store.acceptTerms();
        this.stage = COMMUNICATION_STAGE;
      }).catch(err => {
        this.showError(err, true);
      });
    },
    logOut() {
      this.store.logOut();
      this.$router.push({ name: 'login_page' });
    },
  },
};
</script>
