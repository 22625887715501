<template>
  <k-error-page>
    <template #code>
      <h1>404</h1>
    </template>
    <template #error-message>
      <p>The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
    </template>
  </k-error-page>
</template>

<script>
import KErrorPage from './k-error-page.vue';

export default {
  components: {
    KErrorPage,
  },
};
</script>
