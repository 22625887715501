<template>
  <li class="nav-sub-group nav-sub-dropdown" :class="`${toggled ? 'opened-dropdown': 'closed-dropdown'}`">
    <button class="nav-sub-group-dropdown-title nav-sub-group-title" aria-label="Toggle dropdown" title="Toggle dropdown" @click="toggled = !toggled">
      <i class="k-asset-icon fa-duotone fa-cubes"></i>
      <span>Assets</span>
      <i class="fas fa-angle-down"></i>
    </button>
    <ul class="third-level">
      <li class="third-level-nav-sub-group" v-for="page in refinedPages">
        <div class="nav-third-level-title">
          <router-link :to="{  name: page.route}">
            <span>{{ page.title }}</span>
          </router-link>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  data() {
    return {
      toggled: false,
      pages: [
        { route: 'curriculum_assets_pak', title: 'Assignments' },
        { route: 'curriculum_assets_lu', title: 'Learning Units' },
        { route: 'curriculum_assets_slides', title: 'Slides' },
        { route: 'curriculum_assets_video', title: 'Videos' },
        { route: 'curriculum_assets_quiz', title: 'Quizzes' },
        { route: 'curriculum_assets_questionnaire', title: 'Questionnaires' },
        { route: 'curriculum_assets_calendar_event', title: 'Calendar Events' },
        { route: 'curriculum_assets_meeting', title: 'Meetings' },
        { route: 'curriculum_assets_markdown_page', title: 'Markdown Pages' },
      ],
    };
  },
  computed: {
    refinedPages() {
      return this.pages.filter(x => this.$permissions.hasDashboardPagePermission(x.route));
    },
  },
};
</script>
