/* eslint no-param-reassign: "off" */
import Purify from 'dompurify';

export function sanitizeHtml(content, customConfig = {}) {
  const defaultConfig = {
    FORBID_TAGS: ['img', 'script', 'svg'],
  };

  const config = { ...defaultConfig, ...customConfig };

  return Purify.sanitize(content, config);
}

export default {
  install(app) {
    app.config.globalProperties.$kpurify = {
      sanitise: (item, customConfig) => sanitizeHtml(item, customConfig),
    };
  },
};
