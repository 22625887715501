<template>
  <k-panel title="Add Trainers" :showContent="show" @toggle="(val) => show = val" class="add-trainers">
    <template #body>
      <p>Search users on KATE and click to put them in the list to be added as trainers</p>
      <button class="btn user-search btn-default" @click.prevent="showUserSearch = true"><i class="fas fa-search"></i> Search for existing user</button>
      <user-search
          @close="showUserSearch = false"
          @select="addUser"
          :allowSelect="true"
          :show="showUserSearch">
      </user-search>
      <k-table v-if="kableRows.length > 0"
        :headers="kableHeaders"
        :rows="kableRows"
        @clicked="tableCallback"
        :panel="false"
        :hideable="false">
      </k-table>
      <button v-if="kableRows.length > 0" @click="addTrainers" class="btn btn-success">
        Add Trainers
      </button>
    </template>
  </k-panel>
</template>

<style>
.add-trainers .k-table-container .k-table-panel {
  padding-bottom: 0;
}

.k-table-key-remove {
  color: var(--kate-danger);
  text-align: center;
}
</style>

<style scoped>
.user-search {
  margin-bottom: 10px;
}
</style>

<script>
import UserSearch from '../components/user-search.vue';
import KTable from '../../components/k-table.vue';
import KPanel from '../../components/k-panel.vue';
import ErrorMixin from '../../mixins/error-mixins';

export default {
  components: {
    UserSearch,
    KTable,
    KPanel,
  },

  mixins: [ErrorMixin],

  data() {
    return {
      show: true,
      showUserSearch: false,
      users: [],
      loadingUser: false,
    };
  },

  watch: {
    loadingUser(val) {
      if (val) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },

  computed: {
    kableHeaders() {
      return {
        id: {
          name: 'ID',
          sortable: true,
        },
        name: {
          name: 'Name',
          sortable: true,
          filterable: true,
          type: 'url',
        },
        email: {
          name: 'Email',
          sortable: true,
          filterable: true,
        },
        roles: {
          name: 'Roles',
          type: 'tags',
          filterable: true,
          sortable: true,
        },
        remove: {
          name: 'Remove',
          type: 'action',
        },
      };
    },
    kableRows() {
      return this.users.map(x => ({
        id: x.id,
        name: {
          text: x.full_name,
          path: {
            name: 'dashboard_students',
            params: {
              studentId: x.id,
            },
          },
        },
        email: x.email,
        roles: {
          tags: x.user_roles ? x.user_roles.map(r => r.replaceAll('_', ' ')).sort() : [],
        },
        remove: {
          key: x.id,
          text: '<i class="fas fa-times-circle"></i>',
        },
      }));
    },
  },

  methods: {
    addUser(id) {
      if (this.users.find(x => x.id === id)) {
        this.$ktoast.warning('User already added', { goAway: 1500 });
        return;
      }
      this.loadingUser = true;
      this.$logger.info('Getting user details');
      this.$http.get(`/api/profile/users/${id}`).then(res => {
        this.$logger.info('Successfully retrieved user details');
        this.users.push(res.data);
        this.$ktoast.success(`User ${res.data.full_name} added`, { goAway: 1500 });
      }).catch(err => {
        this.$logger.autowarn('There was an error while attempting to retrieve user details', undefined, err);
        this.showError(err);
      }).then(() => {
        this.loadingUser = false;
      });
    },
    removeUser(user) {
      this.users = this.users.filter(x => x.id !== user.id);
    },
    tableCallback(key, index, row, column) {
      if (column === 'remove') {
        this.removeUser(row);
      }
    },
    addTrainers() {
      this.$logger.info('Adding trainers');
      const payload = {
        trainers: this.users.map(x => ({
          user_id: x.id,
        })),
      };
      this.$http.post('/api/profile/trainer/bulk', payload).then(() => {
        this.$logger.info('Successfully added trainers to KATE');
        this.$ktoast.success('Trainers added', { goAway: 1500 });
        this.users = [];
        this.$emit('update');
      }).catch(err => {
        this.$logger.autowarn('There was an error while attempting to add trainers to KATE', undefined, err);
        this.showError(err);
      });
    },
  },
};
</script>
