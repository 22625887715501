<template>
  <div class="popup-container" v-show="show" :class="position">
    <button class="btn-icon" @click="close" role="button" aria-label="Close popup" title="Close popup"><i class="fas fa-times"></i></button>
    <div class="popup-header">
      <h3>What's new?</h3>
    </div>
    <div class="popup-body">
      <p v-html="body"></p>
    </div>
    <div class="popup-footer">
      <button class="btn btn-outlined" @click="close">Close</button>
    </div>
  </div>
</template>

<style scoped>
.popup-container {
  position: absolute;
  z-index: 999;
  transition: opacity 0.3s ease;
  padding: 15px;
  width: 300px;
  background-color: var(--kate-panel);
  border-radius: 15px;
  box-shadow: 0 0 10px 5px var(--kate-button-secondary);
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
}

.popup-container h3 {
  font-size: 1.2rem;
}

.popup-container p,
.popup-container .btn-icon {
  font-size: 1rem;
}

.popup-container::before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  border-width: 11px;
  border-style: solid;
  filter: blur(2.5px);
}

.popup-container::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 12px;
  border-style: solid;
}

button.btn-icon {
  position: absolute;
  right: 15px;
  top: 15px;
}

@media (max-width: 767px) {
  .popup-container {
    position: relative;
  }
}

.popup-container.center {
  left: 50%;
  transform: translate(-50%, 5%);
}

.popup-container.top {
  bottom: 120%;
}

.popup-container.left {
  right: 30px;
}

.popup-container.left.center {
  left: -160px;
  transform: translate(-53%, -50%);
}

.popup-container.right {
  right: -317px;
  left: unset;
  transform: unset;
  top: 0;
}

.popup-container.right.center {
  right: -317px;
  transform: translateY(-50%);
  top: 50%;
}

/* triangle */
/* top-aligned, bottom-left */
.popup-container.top.bottom-left::before {
  right: 8px;
}

.popup-container.top.bottom-left::after {
  right: 7px;
}

/* top aligned, bottom-right */
.popup-container.top.bottom-right::before {
  left: 8px;
}

.popup-container.top.bottom-right::after {
  left: 7px;
}

/* bottom aligned */
.popup-container.bottom::before {
  top: -24px;
  border-color: transparent transparent var(--kate-button-secondary) transparent;
}

.popup-container.bottom::after {
  top: -20px;
  border-color: transparent transparent var(--kate-panel) transparent;
}

/* left aligned */
.popup-container.left::before {
  right: -24px;
  border-color: transparent transparent transparent var(--kate-button-secondary);
}

.popup-container.left::after {
  right: -20px;
  border-color: transparent transparent transparent var(--kate-panel);
}

/* right aligned */
.popup-container.right::before {
  left: -24px;
  border-color: transparent var(--kate-button-secondary) transparent transparent;
}

.popup-container.right::after {
  left: -20px;
  border-color: transparent var(--kate-panel) transparent transparent;
}

/* left/top aligned, top-right/left */
.popup-container.left.top-right::before,
.popup-container.right.top-left::before {
  top: 6px;
}

.popup-container.left.top-right::after,
.popup-container.right.top-left::after {
  top: 5px;
}

/* left and right aligned, centered */
.popup-container.left.centered::before,
.popup-container.left.centered::after,
.popup-container.right.centered::before,
.popup-container.right.centered::after {
  top: 50%;
  transform: translateY(-80%);
}

/* left/right aligned, bottom-right */
.popup-container.left.bottom-right::before,
.popup-container.right.bottom-left::before {
  bottom: 6px;
}

.popup-container.left.bottom-right::after,
.popup-container.right.bottom-left::after {
  bottom: 5px;
}

/* top aligned */
.popup-container.top::before {
  bottom: -26px;
  border-color: var(--kate-button-secondary) transparent transparent transparent;
}

.popup-container.top::after {
  bottom: -22px;
  border-color: var(--kate-panel) transparent transparent transparent;
}

/* top aligned, centered / bottom aligned, centered */
.popup-container.top.centered::before,
.popup-container.bottom.centered::before {
  left: 50%;
  transform: translateX(-50%);
}

.popup-container.top.centered::after,
.popup-container.bottom.centered::after {
  left: 50%;
  transform: translateX(-50%);
}

/* bottom aligned, top-left */
.popup-container.bottom.top-left::before {
  left: 6px;
}

.popup-container.bottom.top-left::after {
  left: 5px;
}

/* bottom aligned, top-right */
.popup-container.bottom.top-right::before {
  right: 6px;
}

.popup-container.bottom.top-right::after {
  right: 5px;
}

@media (min-width: 768px) and (max-width: 1084px) {
  .popup-container.center {
    left: 50%;
    transform: translate(-50%, 110px);
  }
}

</style>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
    body: String,
    // sets where the pop up will show in relation of the new feature.
    // ['Position of popup', 'alignment of popup', 'position of arrow']
    position: {
      type: Array,
      default: () => ['bottom', 'center', 'centered'],
    },
  },
  mounted() {
    document.addEventListener('keydown', this.keydownHandler);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    keydownHandler(e) {
      // Close on esc keydown
      if (this.show && e.keyCode === 27) {
        this.close();
      }
    },
  },
};
</script>
