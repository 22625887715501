<template>
  <li v-if="!noPermissions" class="nav-group" :class="`${toggled ? 'opened-dropdown': 'closed-dropdown'}`">
    <div class="nav-group-title" aria-label="Toggle dropdown" title="Toggle dropdown" @click="toggled = !toggled">
      <a><i class="k-asset-icon fa-duotone fa-building-columns "></i><span>Curriculum</span></a>
      <i class="fas fa-angle-down"></i>
    </div>
    <ul class="second-level">
      <blueprints-dropdown v-if="$permissions.hasPermission('manage_blueprints')"></blueprints-dropdown>
      <assets-dropdown v-if="$permissions.hasPermission('view_assets')" class="nav-group"></assets-dropdown>
    </ul>
  </li>
</template>

<script>
import SidebarEntryMixin from '../../mixins/sidebar-entry-mixin';
import BlueprintsDropdown from './blueprints-dropdown.vue';
import AssetsDropdown from './assets-dropdown.vue';

const REQUIRED_PERMS = [
  'manage_blueprints',
  'manage_assets',
  'view_assets',
];

export default {
  mixins: [SidebarEntryMixin],

  components: {
    BlueprintsDropdown,
    AssetsDropdown,
  },

  data() {
    return {
      toggled: false,
    };
  },

  computed: {
    isVisible() {
      return !this.noPermissions;
    },

    noPermissions() {
      for (let i = 0; i < REQUIRED_PERMS.length; i++) {
        if (this.$permissions.hasPermission(REQUIRED_PERMS[i])) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
