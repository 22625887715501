<template>
  <aside class="sidebar sidebar-panel" :class="isSidebarOpen ? '' : 'collapsed'" v-if="isLoggedIn && sidebarEnabled">
    <transition name="slide">
      <div>
        <app-sidebar v-if="isApp && !isProfile" :sidebar-open="isSidebarOpen"></app-sidebar>
        <profile-sidebar v-if="isProfile"></profile-sidebar>
        <dashboard-sidebar v-if="isDashboard && !isProfile"></dashboard-sidebar>
      </div>
    </transition>
  </aside>
</template>

<style>
aside:empty {
  width: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.sidebar-panel {
  margin-top: 70px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--kate-sidebar-background);
  position: fixed;
  left: 0;
  top: 0;
  height: calc(100% - 70px);
  z-index: 999;
  padding: 0 0 15px;
  width: 310px;
  transition: width 0.2s ease-in-out;
  white-space: nowrap;
  box-shadow: var(--box-shadow);
}

/* == Header Title == */
.sidebar-panel .sidebar-title,
.sidebar-panel .sidebar-title a,
.sidebar-panel .header-button {
  display: flexbox;
  display: flex;
  align-items: center;
}

.sidebar-panel .sidebar-title a {
  padding: 12px 8px 10px;
  color: var(--kate-type-primary);
  text-decoration-color: transparent;
}

.sidebar-panel .sidebar-title a:hover {
  color: var(--kate-type-alt);
}

.sidebar-panel .sidebar-title a i {
  font-size: 1em;
}

/* == Header Button == */
.sidebar-panel .header-button {
  margin: 0 auto;
  width: 90%;
  text-align: center;
}

.sidebar-panel .header-button a {
  background-color: var(--kate-primary);
  border: 1px solid var(--kate-primary);
  color: var(--kate-type-dark);
  border-radius: 4px;
  padding: 8px 25px;
  position: relative;
  width: 100%;
}

.sidebar-panel .header-button a:hover {
  background-color: transparent;
  color: var(--kate-primary);
}

.sidebar-panel .header-button a i {
  padding-left: 4px;
}

/* == Panel Nav == */
.sidebar-panel .sidebar-panel-nav {
  padding: 0;
}

.sidebar-panel-nav a {
  text-decoration-color: transparent;
}

/* == Nav Group Titles == */
.sidebar-panel .nav-group-title {
  background-color: var(--kate-panel-alpha);
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--kate-sidebar-background);
  cursor: pointer;
  align-items: center;
  position: relative;
}

.sidebar-panel .nav-group-title.module-overview {
  align-items: normal;
}

.sidebar-panel .nav-group-title.knowledge-base,
.sidebar-panel ul li.nav-group-title {
  padding: 0;
}

.sidebar-panel.collapsed:hover div.nav-group-title,
.sidebar-panel.collapsed:hover .nav-group-title.knowledge-base a,
.sidebar-panel.collapsed:hover ul > li.nav-group-title > a,
.sidebar-panel .nav-group-title.knowledge-base a,
.sidebar-panel ul li.nav-group-title a {
  padding: 10px 0;
  width: 100%;
}

.sidebar-panel .nav-group-title a,
.sidebar-panel .nav-group-title {
  color: var(--kate-type-light);
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  white-space: normal;
  border-radius: 0;
  width: 100%;
}

.sidebar-panel .nav-group-title a:hover,
.sidebar-panel .nav-group-title:hover {
  text-decoration: none;
}

.sidebar-panel .nav-group-title a > i,
.sidebar-panel .nav-group-title > i {
  min-width: 50px;
  text-align: center;
}

.nav-group-title span {
  flex: 1;
  text-align: left;
}

.sidebar-panel .nav-sub-group button.nav-sub-group-dropdown-title,
.sidebar-panel .nav-sub-group .nav-sub-group-dropdown-title a {
  font-size: 1.15em;
  display: flex;
  align-items: center;
  white-space: normal;
}

.sidebar-panel .nav-group-title i,
.sidebar-panel.collapsed:hover .nav-group-title a > i {
  font-size: 20px;
  margin: 0;
}

/* == Nav Sub Menu Dropdown == */
.sidebar-panel .nav-sub-group.nav-sub-dropdown .nav-sub-group-dropdown-title {
  display: flex;
  padding-right: 15px;
  align-items: center;
  background-color: var(--kate-background-body);
  width: 100%;
  justify-content: space-between;
  gap: 0;
}

.sidebar-panel .nav-sub-group.nav-sub-dropdown .nav-sub-group-dropdown-title:hover,
.sidebar-panel .nav-sub-group.nav-sub-dropdown.opened-dropdown .nav-sub-group-dropdown-title {
  background-color: var(--kate-background);
}

.sidebar-panel .nav-group .nav-sub-group.nav-sub-dropdown:first-child {
  border-bottom: 2px solid var(--kate-sidebar-background);
}

.sidebar-panel .nav-sub-group.nav-sub-dropdown button.nav-sub-group-dropdown-title,
.sidebar-panel .nav-sub-group.nav-sub-dropdown .nav-sub-group-dropdown-title a {
  padding-left: 45px;
}

.sidebar-panel .nav-sub-group.nav-sub-dropdown .nav-sub-group-dropdown-title a:hover {
  text-decoration: none;
}

/* == Nav Sub Menu == */
.sidebar-panel .nav-group ul {
  padding: 0;
}

.sidebar-panel .nav-group .nav-sub-group {
  list-style: none;
  border-top: var(--border-secondary);
  cursor: pointer;
}

.sidebar-panel .nav-group-title:hover {
  background-color: var(--kate-panel-alt);
}

.sidebar-panel .nav-group .nav-sub-group:first-child {
  border: 0;
}

.sidebar-panel .nav-group .nav-third-level-title,
.sidebar-panel .nav-group .nav-third-level-title .asset-title,
.sidebar-panel .nav-group .nav-sub-group-title,
.sidebar-panel .nav-group .nav-sub-group-title .asset-title {
  padding: 0;
  display: flex;
  flex: 1;
  align-items: center;
  gap: 15px;
}

.sidebar-panel .nav-group .nav-third-level-title .asset-title,
.sidebar-panel .nav-group .nav-sub-group-title .asset-title {
  justify-content: space-between;
}

.sidebar-panel .nav-group .nav-third-level-title i.completed,
.sidebar-panel .nav-group .nav-sub-group-title i.completed {
  color: var(--kate-success);
  padding: 0 5px;
}

.sidebar-panel .nav-group .nav-third-level-title:hover,
.sidebar-panel .nav-group .nav-sub-group-title:hover {
  text-decoration: none;
  background-color: var(--kate-background-body-alpha);
}

.sidebar-panel .nav-group .nav-third-level-title:hover a,
.sidebar-panel .nav-group .nav-sub-group-title:hover a {
  text-decoration: none;
}

.sidebar-panel .nav-group .nav-sub-group button,
.sidebar-panel .nav-group .nav-sub-group a {
  color: var(--kate-type-primary);
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  padding: 18px 10px;
  width: 100%;
  justify-content: left;
}

.sidebar-panel .nav-sub-dropdown .third-level-nav-sub-group button,
.sidebar-panel .nav-sub-dropdown .third-level-nav-sub-group a {
  padding: 18px 85px;
}

.sidebar-panel .nav-group .nav-third-level-title > a:hover .asset-title > span,
.sidebar-panel .nav-group .nav-sub-group-title > a:hover .asset-title > span {
  text-decoration: underline;
}

.sidebar-panel .nav-group .nav-sub-group button i:first-child,
.sidebar-panel .nav-group .nav-sub-group a i {
  padding: 0 15px 0 5px;
  font-style: normal;
  align-items: center;
  display: flex;
  font-size: 1em;
}

.sidebar-panel .nav-group .nav-sub-group a i.k-asset-icon {
  width: 40px;
  margin: 0;
  justify-content: center;
}

.sidebar-panel .nav-group.profile-overview i,
.sidebar-panel .nav-group.module-overview i {
  color: var(--kate-logo-lime-green);
}

.sidebar-panel .nav-group.knowledge-base i {
  color: var(--kate-logo-purple);
}

.sidebar-panel .nav-group.profile-keys i {
  color: var(--kate-assignment);
}

.sidebar-panel .nav-group .nav-sub-group a.router-link-active .pak-title,
.sidebar-panel .nav-group .nav-sub-group a.router-link-active {
  color: var(--kate-type-accent);
  text-decoration: none;
}

.sidebar-panel .nav-group .nav-sub-group a.router-link-active {
  background-color: var(--kate-background-body-alpha);
}

/* == Nav Sub Menu Toggle == */
.sidebar-panel .nav-group.closed-dropdown ul {
  height: 0;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 0.25s ease-out, opacity 0.4s linear;
  overflow: hidden;
}

.sidebar-panel .nav-group.opened-dropdown ul {
  height: auto;
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.25s ease-out, opacity 0.4s linear;
  overflow: visible;
}

/* == Scrollbar == */
.sidebar-panel::-webkit-scrollbar-thumb {
  border-radius: 0;
  box-shadow: none;
}

.sidebar-panel::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

/* == COMPACT == */
.sidebar-panel.collapsed {
  width: 50px;
  transition: all 0.2s ease-in-out;
  overflow-x: hidden;
}

.sidebar-panel.collapsed:hover {
  width: 310px;
  transition: all 0.2s ease-in-out;
}

.sidebar-panel.collapsed .nav-group-title + ul,
.sidebar-panel.collapsed li a > span,
.sidebar-panel.collapsed .sidebar-title a > small,
.sidebar-panel.collapsed .header-button a span,
.sidebar-panel.collapsed .count-bubble {
  width: 0;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: relative;
}

.sidebar-panel.collapsed .module-progress {
  width: 100%;
  height: 0;
  opacity: 0;
  transition: opacity 0.3s, height 0.3s;
  position: relative;
}

.sidebar-panel .module-progress,
.sidebar-panel.collapsed:hover .module-progress {
  height: auto;
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s, height 0.3s;
}

.sidebar-panel .nav-group .nav-sub-group-link .asset-title,
.sidebar-panel.collapsed:hover .nav-group-title + ul li a > span,
.sidebar-panel .nav-group .nav-sub-group-title span.asset-title,
.sidebar-panel.collapsed:hover li a > span {
  white-space: normal;
  text-align: left;
  flex: 1;
}

.sidebar-panel.collapsed .header-button a span.download-btn {
  position: absolute;
  left: 0;
  right: 0;
  transition: opacity 0.2s ease;
}

.sidebar-panel.collapsed:hover .header-button a span.download-btn {
  position: relative;
}

.sidebar-panel.collapsed:hover li a > span,
.sidebar-panel.collapsed:hover .sidebar-title a > small,
.sidebar-panel.collapsed:hover .header-button a span,
.sidebar-panel.collapsed:hover .nav-group .nav-group-title > i,
.sidebar-panel.collapsed:hover .count-bubble {
  display: inline-block;
  width: auto;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.sidebar-panel.collapsed div.nav-group-title,
.sidebar-panel.collapsed .nav-group-title.knowledge-base a,
.sidebar-panel.collapsed button.nav-group-title,
.sidebar-panel.collapsed ul > li.nav-group-title > a {
  padding: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.sidebar-panel.collapsed .nav-group-title.knowledge-base a,
.sidebar-panel.collapsed .nav-group-title i,
.sidebar-panel.collapsed .nav-group-title a > i {
  min-width: 50px;
}

.sidebar-panel.collapsed .nav-group-title i,
.sidebar-panel.collapsed .nav-group-title a > i {
  padding: 0;
  margin: 0;
}

.sidebar-panel.collapsed .nav-group-title,
.sidebar-panel.collapsed .nav-group-title a {
  width: 100%;
  text-align: left;
}

.sidebar-panel.collapsed:hover .nav-group-title,
.sidebar-panel.collapsed:hover .nav-group-title a {
  width: 100%;
  text-align: unset;
}

.sidebar-panel .nav-group .nav-group-title > i.fas.fa-angle-down {
  margin-right: 15px;
}

.sidebar-panel.collapsed:hover .nav-group-title + ul {
  display: block;
  width: auto;
}

@media only screen and (max-width: 767px) {
  .sidebar-panel.collapsed {
    width: 0;
  }
}

</style>

<script>
import useGlobalStore from '../stores/global';
import AppSidebar from './app/app-sidebar.vue';
import ProfileSidebar from './profile/profile-sidebar.vue';
import DashboardSidebar from './dashboard/dashboard-sidebar.vue';

export default {
  components: {
    'app-sidebar': AppSidebar,
    'profile-sidebar': ProfileSidebar,
    'dashboard-sidebar': DashboardSidebar,
  },

  data: () => ({
    store: useGlobalStore(),
  }),

  beforeMount() {
    this.$sidebar.ready = false;
  },

  computed: {
    isLoggedIn() {
      return this.store.isLoggedIn;
    },

    isDashboard() {
      return this.store.appName === 'dashboard';
    },

    isApp() {
      return this.store.appName === 'app';
    },

    isProfile() {
      return this.$route.name.startsWith('profile_');
    },

    sidebarEnabled() {
      return this.$sidebar.visibility && this.$sidebar.isEnabled(this.$route.name);
    },

    isSidebarOpen() {
      return this.store.isSidebarOpen && this.sidebarEnabled;
    },
  },
};
</script>
