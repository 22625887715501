function getOrNull(pathString, object, defaultVal) {
  const path = pathString.replace('[', '.').replace(']', '').split('.');
  return path.reduce((xs, x) => {
    if (xs && xs[x]) {
      return xs[x];
    }
    if (defaultVal !== undefined) {
      return defaultVal;
    }
    return null;
  }, object);
}

export default getOrNull;
