import { defineStore } from 'pinia';

const useLoadingStore = defineStore('loading', {
  state: () => ({
    isLoading: false,
    isMinimal: false,
  }),

  actions: {
    loadingStart() {
      this.isLoading = true;
    },
    loadingMinimal() {
      this.isLoading = true;
      this.isMinimal = true;
    },
    loadingFinish() {
      this.isLoading = false;
      this.isMinimal = false;
    },
  },
});

export default useLoadingStore;
