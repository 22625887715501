<template>
  <div class="student-info">
    <div class="student-info-header">
      <div>
        <h2>{{ studentName }}</h2>
        <span class="tag-edukator" v-if="privilegedUser">Cambridge Spark</span>
      </div>
      <k-avatar :avatarUrl="studentInfo.avatar_url"></k-avatar>
    </div>
      <span>Learner ID: <b>{{ formattedId }}</b></span>
      <div class="email-container">
        <student-emails :email="studentInfo.email"
                        :secondary-emails="secondaryEmailOptions"
                        :student-id="studentId"
                        :can-update="canUpdate"
                        @update-email="$emit('update-email')"
        ></student-emails>
      </div>
      <a v-if="hubspotLink" class="hubspot-link" :href="hubspotLink" target="_blank">
        Link to Hubspot <i class="fas fa-external-link-alt"></i>
      </a>
      <div class="user-roles" v-if="sanitisedRoles">
        <span>User Roles:</span>
        <span v-for="roles in sanitisedRoles" :key="roles.name" class="badge">{{ roles.name }}</span>
      </div>
    <span class="company" v-if="selectedProgramme">
      <set-student-metadata
          v-if="!studentAptemEmployer"
          assignedType="Company"
          :inline="true"
          :employer="studentEmployer"
          :options="employers"
          :canUpdate="canUpdate"
          :showSearch="true"
          @new-data="(employerId) => $emit('assign-employer', employerId)"
      ></set-student-metadata>
      <span v-else>
        <k-tooltip text="Company is set in Aptem and cannot be changed here">
          <div class="aptem-settings">
            <span>Company:</span>{{ studentAptemEmployer }}<div class="aptem-icon"><b>aptem</b></div>
          </div>
        </k-tooltip>
      </span>
    </span>
    <sign-in-link v-if="$permissions.hasPermission('manage_roles')"
                  :student-id="studentId" :student-name="studentName"></sign-in-link>
  </div>
</template>

<style scoped>
.student-info-header,
.email-container,
fieldset.form-entry,
.aptem-settings {
  display: flex;
}

.student-info-header {
  justify-content: space-between;
}

.user-roles .badge {
  font-size: 0.8em;
  font-weight: unset;
}

.email-container {
  flex-wrap: nowrap;
  gap: 5px;
  align-items: baseline;
}

form.edit-email {
  flex: 1;
}

input.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.email-container .save-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.aptem-settings {
  gap: 8px;
}

.student-info i.fas.fa-check:hover {
  color: var(--kate-type-white);
}
</style>

<script>
import formatId from '../../modules/format-user-id';
import getOrNull from '../../modules/get-or-null';
import ErrorMixin from '../../mixins/error-mixins';
import KAvatar from '../../components/k-avatar.vue';
import { EMAIL_REGEX } from '../../constants';
import SetStudentMetadata from './set-student-metadata.vue';
import KTooltip from '../../components/k-tooltip.vue';
import SignInLink from './student_components/sign-in-link.vue';
import StudentEmails from './student-emails.vue';

export default {
  components: {
    StudentEmails,
    SignInLink,
    KAvatar,
    SetStudentMetadata,
    KTooltip,
  },

  mixins: [ErrorMixin],

  props: {
    studentInfo: {
      type: Object,
      required: true,
    },
    studentId: {
      type: Number,
    },
    programmeId: {
      type: Number,
    },
    studentName: String,
    privilegedUser: Boolean,
    hubspotLink: String,
    selectedProgramme: Object,
    employers: Array,
    canUpdate: Boolean,

  },

  data() {
    return {
      editEmailMode: false,
      updateInProgress: false,
      newEmail: this.studentInfo.email,
    };
  },

  computed: {

    validEmail() {
      return EMAIL_REGEX.test(this.newEmail);
    },

    formattedId() {
      return formatId(this.studentId);
    },

    sanitisedRoles() {
      if (this.studentInfo && this.studentInfo.user_roles && this.studentInfo.user_roles.length > 0) {
        const userRoles = this.studentInfo.user_roles.map(x => ({ name: x }));
        return userRoles.map(x => ({ ...x, name: x.name.replaceAll('_', ' ') }));
      }
      return undefined;
    },

    studentEmployer() {
      if (this.studentInfo && this.studentInfo.employers && this.studentInfo.employers.length > 0) {
        return this.studentInfo.employers.find(x => x.programme_id === this.programmeId);
      }
      return undefined;
    },

    studentAptemEmployer() {
      return getOrNull('aptem_company_name', this.studentEmployer);
    },

    secondaryEmailOptions() {
      return this.studentInfo.non_primary_emails ? this.studentInfo.non_primary_emails.map(e => ({
        id: e.email_id,
        name: e.email,
      })) : [];
    },
  },
};
</script>
