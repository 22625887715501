<template>
  <div :class="isDashboard ? store.sidebarClass : null">
    <div v-if="allReady" id="quiz-page" class="content-with-spacing">
      <div class="heading-container" :class="{ dashboard: isDashboard }">
        <div class="heading-content">
          <div class="title-container">
            <h1>{{ quizMeta.name }}</h1>
            <k-asset-tags :asset="quizMeta"></k-asset-tags>
            <p class="description" v-html="getMarkdown(quizMeta.description)"></p>
            <span v-if="quizMeta.deadline" class="due-date">Due: <b>{{ parseTimestamp(quizMeta.deadline, undefined, "do MMM yyyy") }}</b></span>
          </div>
          <div class="heading-details">
            <div v-if="showResult">
              <k-progress v-if="quizSubmission" class="score" shape="circle" size="small" :percentage="overallScore" ariaLabel="Quiz score"></k-progress>
              <p v-if="quizSubmission" class="submission-date">Last submission: <b>{{parseTimestamp(quizSubmission.date_timestamp)}}</b></p>
              <div v-if="showResult && !isDashboard" class="reset-form-button">
                <button @click.prevent="resetQuiz" class="btn btn-secondary reset-button" type="button" title="Retake Quiz">Retake quiz</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <empty-placeholder v-if="!hasSubmissions && isDashboard" info="The student has not made a submission yet"></empty-placeholder>
        <k-quiz v-else :quizMeta="quizMeta"
          ref="quiz"
          :latest-quiz-submission="quizSubmission"
          :previous-answers="quizPreviousAnswers"
          :show-result="showResult"
          :read-only="readOnly"
          @submit="submitQuiz"
          >
        </k-quiz>
      </div>
    </div>
  </div>
</template>

<style scoped>
.heading-container {
  display: flex;
  flex-direction: column;
  margin: 20px auto !important;
}

.heading-container.dashboard {
  margin-top: 40px !important;
}

.heading-content {
  display: flex;
  gap: 15px;
  width: 100%;
  margin: 0 auto !important;
  justify-content: space-between;
}

.heading-container .heading-details {
  flex: 1 1 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;
}

.title-container {
  align-self: unset;
}

#content .title-container h1 {
  margin: 0 !important;
  align-self: unset;
  padding: 10px 0;
  width: 100%;
}

.submission-date,
.due-date {
  padding-top: 5px;
  display: block;
  margin: 0;
}

.due-date b {
  color: var(--kate-type-danger);
}

.submission-date b {
  color: var(--kate-type-light);
}

.heading-details .due-date,
.heading-details .submission-date,
.heading-details .score {
  width: 100%;
}

.reset-form-button {
  position: relative;
  display: inline-block;
  width: 100%;
}

.reset-form-button button {
  margin-top: 10px;
  padding: 10px 25px;
}

@media (max-width: 1024px) {
  .heading-container {
    display: block;
  }

  .heading-container .title-container {
    flex: 1 1 100%;
  }

  #content .heading-container .title-container h1 {
    font-size: 1.8em;
    text-align: left;
  }

  .due-date,
  .heading-container .heading-details {
    text-align: center;
  }
}
</style>

<script>
import useGlobalStore from '../stores/global';
import KQuiz from './k-quiz.vue';
import TimeMixins from '../mixins/time-mixins';
import ErrorMixin from '../mixins/error-mixins';
import { markdown } from '../modules/maths-markdown';
import KProgress from '../components/k-progress.vue';
import KAssetTags from '../components/k-asset-tags.vue';
import EmptyPlaceholder from '../components/empty-placeholder.vue';

export default {
  mixins: [TimeMixins, ErrorMixin],

  components: {
    KQuiz,
    KProgress,
    KAssetTags,
    EmptyPlaceholder,
  },

  data() {
    return {
      store: useGlobalStore(),
      quizMetaReady: false,
      quizMeta: undefined,
      quizSubmissionReady: false,
      quizSubmission: undefined,
      isSubmitting: false,
      quizPreviousAnswers: undefined,
      showResult: false,
      studentName: undefined,
      disableSubmission: true,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getLatestQuizSubmission();
    this.getQuizMeta();
    this.getUserProfile();
  },

  watch: {
    allReady(val) {
      if (val) {
        this.$Loading.finish();
        this.$nextTick(() => {
          this.disableSubmission = false;
        });
      } else {
        this.$Loading.start();
      }
    },
    isSubmitting(val) {
      if (val) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },

  computed: {
    allReady() {
      return this.quizMetaReady && this.quizSubmissionReady;
    },
    isDashboard() {
      return this.store.isDashboard;
    },
    moduleQuizId() {
      return this.$route.params.moduleQuizId;
    },
    studentId() {
      return this.$route.params.studentId;
    },
    overallScore() {
      if (this.quizSubmission) {
        return this.quizSubmission.overall_score;
      }
      return 0;
    },
    hasSubmissions() {
      if (this.quizSubmission) {
        return true;
      }
      return false;
    },
    studentUrl() {
      if (this.isDashboard) {
        return `/api/stats/students/${this.studentId}/feedback/quiz/${this.moduleQuizId}`;
      }
      return `/api/stats/quiz/${this.moduleQuizId}/feedback`;
    },
    readOnly() {
      if (this.isDashboard) {
        return true;
      }
      return false;
    },
  },

  methods: {
    registerCrumbs() {
      let crumbs;
      if (this.isDashboard) {
        crumbs = [
          {
            text: this.studentName,
            path: {
              name: 'dashboard_students',
              params: { studentId: this.studentId },
            },
          },
          { text: this.quizMeta.name, active: true },
        ];
      }
      this.$crumbs.register(crumbs);
    },

    getUserProfile() {
      if (!this.isDashboard) {
        return;
      }
      this.$http.get(`/api/profile/users/${this.studentId}`).then(res => {
        this.studentName = res.data.full_name;
        this.registerCrumbs();
      }).catch(() => {});
    },

    getQuizMeta() {
      this.$logger.info('Getting quiz info', { moduleQuizId: this.moduleQuizId });
      this.$http.get(`/api/curriculum/quiz/${this.moduleQuizId}`).then(response => {
        this.quizMeta = response.data;
        this.quizMetaReady = true;
        this.$logger.info('Got quiz info', { moduleQuizId: this.moduleQuizId }, true);
        this.registerCrumbs();
      }).catch(err => {
        if (this.$http.isWarning(err)) {
          this.$logger.warn('Quiz meta not found', { moduleQuizId: this.moduleQuizId }, err);
        } else {
          this.$logger.error('Error getting quiz meta', { moduleQuizId: this.moduleQuizId }, err);
          this.showError(err, true);
        }
      });
    },

    updateQuizScore() {
      this.$sidebar.updateAssetScore('quiz', this.quizMeta.id, this.quizSubmission.overall_score);
    },

    updateQuizCompletion() {
      if (this.quizSubmission.overall_score === 100) {
        this.$sidebar.updateCompletionStatus('quiz', this.quizMeta.id, true);
      } else {
        this.$sidebar.updateCompletionStatus('quiz', this.quizMeta.id, false);
      }
    },

    getLatestQuizSubmission() {
      this.$logger.info('Getting latest quiz submission', { moduleQuizId: this.moduleQuizId });
      this.$http.get(this.studentUrl).then(response => {
        this.quizSubmission = response.data;
        this.quizPreviousAnswers = response.data.selected_answers;
        this.showResult = response.data.completed;
        this.$logger.info('Got latest quiz submission', { moduleQuizId: this.moduleQuizId });
      }).catch(err => {
        if (this.$http.isWarning(err)) {
          this.$logger.warn('Latest quiz submission not found', { moduleQuizId: this.moduleQuizId }, err);
        } else {
          this.$logger.error('Error getting latest quiz submission', { moduleQuizId: this.moduleQuizId }, err);
          this.showError(err, true);
        }
      }).then(() => {
        this.quizSubmissionReady = true;
      });
    },

    submitQuiz(payload, isSave = false) {
      if (this.isDashboard || this.disableSubmission) {
        return;
      }
      if (!isSave) {
        this.isSubmitting = true;
      }
      const endpoint = isSave ? 'save' : 'take';
      this.$logger.info('Submitting quiz', { moduleQuizId: this.quizMeta.quiz_id }, true);
      this.$http.post(`/api/curriculum/quiz/${this.moduleQuizId}/${endpoint}`, payload)
        .then(res => {
          this.$Loading.finish();
          this.isSubmitting = false;
          if (!isSave) {
            this.quizSubmission = res.data;
            this.submissionToast(res.data.badges_achieved);
            this.updateQuizScore();
            this.updateQuizCompletion();
            this.quizPreviousAnswers = payload.selected_answers;
          }
          this.showResult = !isSave;
        }).catch(err => {
          if (this.$http.isWarning(err)) {
            this.$logger.warn('Error submitting quiz', { moduleQuizId: this.moduleQuizId }, err);
            this.showError(err, true);
          } else {
            this.$logger.error('Error submitting quiz', { moduleQuizId: this.moduleQuizId }, err);
            this.showError(err, true);
          }
        }).then(() => {
          this.$Loading.finish();
        });
    },

    submissionToast(badges) {
      if (badges?.length > 0) {
        this.$ktoast.showBadgesAchieved(badges);
      } else {
        this.$ktoast.success('Quiz submitted');
      }
    },

    resetQuiz() {
      this.$emit('reset');
      this.showResult = false;
      this.clearAnswers();
    },

    clearAnswers() {
      this.disableSubmission = true;
      this.$refs.quiz.clearAnswers();
      this.$nextTick(() => {
        this.disableSubmission = false;
      });
    },

    getMarkdown(str) {
      return markdown(str);
    },
  },
};
</script>
