<template>
    <div>
      <h1>Learner Support</h1>
      <div class="panel panel-body">
        <h2>Assign Learner Support</h2>
        <k-panel class="instructions"
          :showContent="showContent"
          @toggle="showContent = $event"
          title="Instructions"
        >
          <template #body>
            <p class="info-text">
              To assign a <i class="highlight">coach</i> or a <i class="highlight">data mentor</i> to <i class="highlight">learners</i>
              for a particular programme, prepare a <i class="highlight">.csv</i>
              file with these columns:
              <ol>
                <li><code>user_id</code></li>
                <li><code>learner_email</code></li>
                <li><code>learner_name</code></li>
                <li><code>data_mentor_email</code></li>
                <li><code>coach_email</code></li>
                <li><code>programme_id</code></li>
                <li><code>programme_name</code></li>
              </ol>
              <b>You can also download a <i class="highlight">.csv</i> template from a programme in the dropdown below.</b><br>
            </p>
            <h3>Note:</h3>
            <ul>
              <li>A learner can have <i class="highlight">one coach or data mentor per programme</i> that they are a member of.</li>
              <li>The <code>user_id</code> is the learner's <i class="highlight">KATE ID</i> as a number. For example, the learner
              <code>CS-00123</code> has an ID of <i class="highlight">123</i>.</li>
              <li>If you cannot find a <i class="highlight">coach</i> or a <i class="highlight">data mentor</i> from the lists below, they may need to be added to KATE first.</li>
              <li> You can change the <i class="highlight">coach</i> or a <i class="highlight">data mentor</i> assigned to a particular <i class="highlight">learner</i> on their
              page in the dashboard.</li>
            </ul>
          </template>
        </k-panel>
        <hr>
        <div class="actions-container">
          <div class="programme-dropdown">
            <div class="detail-form">
              <div>Select a programme and download the learner spreadsheet template to assign mentors to learners <span class="form-required">*</span></div>
              <k-dropdown :options="programmes" v-model="programmeId"></k-dropdown>
            </div>
            <k-tooltip text="Downloading the CSV file may take a few seconds">
              <button id="download-button"
                  type="button"
                  class="btn btn-outlined"
                  @click="downloadCsv"> Download Learner CSV file <i class="fas fa-download"></i></button>
          </k-tooltip>
          </div>
          <div class="upload-field">
            <dropfield v-on:file="handleFileStaged"
            :immediatePost="false"
            ref="dropfield"
            :customMessage="dropFieldMessage"
            accept=".csv"
            url=""></dropfield>
          </div>
        </div>
        <k-modal :show="showUploadModal" @close="closeUploadModal" class="upload-confirm-modal">
          <template #header>
            <h3>Uploading learner support</h3>
          </template>
          <template #body>
            <span class="info-text"><i class="highlight">{{ userAssignData.length }}</i> {{ pluralize('record', userAssignData.length) }} found.</span>
          </template>
          <template #footer>
            <button class="btn btn-success confirm-upload" @click.prevent="assignLearnerSupport">
              <i class="fas fa-check"></i> Upload
            </button>
            <button class="btn btn-danger cancel-upload" @click.prevent="closeUploadModal">
              Cancel
            </button>
          </template>
        </k-modal>
      </div>
      <mentors :mentorType="'Coach'" @data-fetched="handleFetchedData"></mentors>
      <mentors :mentorType="'Data Mentor'" @data-fetched="handleFetchedData"></mentors>
    </div>
</template>

<style scoped>
hr {
  border-top: 1px solid var(--kate-type-primary);
}

.highlight {
  color: var(--kate-type-warning);
}

.actions-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.programme-dropdown {
  flex: 0 0 50%;
}

.upload-field {
  flex: 1;
}

.btn.btn-outlined {
  margin: 1rem 0;
}

.instructions {
  margin-bottom: 2rem;
}
</style>
<script>
import Dropfield from '../../components/dropfield.vue';
import KPanel from '../../components/k-panel.vue';
import KDropdown from '../../components/k-dropdown.vue';
import ErrorMixin from '../../mixins/error-mixins';
import PageReadyMixin from '../../mixins/page-ready-mixin';
import PluralMixin from '../../mixins/plural-mixin';
import MentorMixin from '../../mixins/mentor-mixin';
import CsvMixins from '../../mixins/csv-mixins';
import Mentors from './mentors-table.vue';
import KTooltip from '../../components/k-tooltip.vue';
import KModal from '../../components/k-modal.vue';

export default {
  components: {
    KModal,
    Dropfield,
    KTooltip,
    KDropdown,
    Mentors,
    KPanel,
  },

  mixins: [ErrorMixin, PageReadyMixin, MentorMixin, CsvMixins, PluralMixin],

  data() {
    return {
      dropFieldMessage: `Click to browse files or drag here </br></br>
      <span class="fas fa-file-csv"></span>`,
      programmes: [],
      programmesReady: false,
      mentorsReady: false,
      coachesReady: false,
      programmeId: undefined,
      stagedFile: undefined,
      userAssignData: [],
      mentors: [],
      coaches: [],
      expectedColumns: [
        'user_id',
        'learner_email',
        'learner_name',
        'data_mentor_email',
        'coach_email',
        'programme_id',
        'programme_name',
      ],
      res: {},
      invalidEntries: [],
      showContent: true,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgrammes();
  },

  computed: {
    ready() {
      return Boolean(this.programmesReady && this.mentorsReady && this.coachesReady);
    },
    showUploadModal() {
      return Boolean(this.userAssignData.length);
    },
  },

  methods: {
    getProgrammes() {
      this.$logger.info('Getting programmes');
      this.programmesReady = false;
      return this.$http.get('/api/curriculum/programmes?active_only=false').then(res => {
        this.programmes = res.data.programmes;
        this.$logger.info('Successfully retrived programmes');
      }).catch(err => {
        this.$logger.error('Error retrieving programmes', undefined, err);
        this.showError(err);
      }).then(() => {
        this.programmesReady = true;
      });
    },
    handleFetchedData(data) {
      if (data.mentorType === 'Coach') {
        this.coaches = data.data;
        this.coachesReady = true;
      } else if (data.mentorType === 'Data Mentor') {
        this.mentors = data.data;
        this.mentorsReady = true;
      }
    },
    downloadCsv() {
      if (!this.programmeId) {
        this.$logger.info('Please select a programme first');
        this.$ktoast.warning('To download the CSV file, please select a programme first', { goAway: 3500 });
      } else {
        this.$Loading.start();
        this.$http.get(`/api/curriculum/programmes/${this.programmeId}/members`).then(res => {
          this.$logger.info('Successfully retrieved members for this programme', { programmeId: this.programmeId });
          const transformedData = res.data.members.map(item => ({
            user_id: item.user_id,
            programme_id: item.programme_id,
            data_mentor_email: item.data_mentor_email,
            coach_email: item.coach_email,
            learner_email: item.email,
            learner_name: item.full_name,
            programme_name: item.programme_name,
          }));
          this.downloadCsvFromTable(
            {
              user_id: {},
              learner_email: {},
              learner_name: {},
              data_mentor_email: {},
              coach_email: {},
              programme_id: {},
              programme_name: {},
            },
            transformedData,
            `${transformedData[0].programme_name} Members.csv`,
          );
        }).catch(err => {
          if (this.$http.errIn(err, [404])) {
            this.$logger.warn('There are no members in this programme', { programmeId: this.programmeId });
            this.$ktoast.warning('There are no learners in this programme', { goAway: 3500 });
          } else {
            this.$logger.error('There was an error while attempteing to retrieve this programme', { programmeId: this.programmeId });
            this.showError(err);
          }
        }).then(() => {
          this.$logger.info('Downloaded members info for programme', { programmeId: this.programmeId });
          this.programmeId = undefined;
          this.$Loading.finish();
        });
      }
    },
    validateFields(fields) {
      if (fields.length === 7 && this.expectedColumns.every(x => fields.includes(x))) {
        return true;
      }
      return false;
    },
    resetUpload() {
      this.stagedFile = undefined;
      this.$refs.dropfield.reset();
      this.userAssignData = [];
      this.invalidEntries = [];
      this.res = {};
    },
    processEntry(email, key) {
      if (!email) {
        this.res[key] = null;
      } else {
        let item;
        if (key === 'coach_user_id') {
          item = this.coaches.find(c => c.email === email);
        } else {
          item = this.mentors.find(c => c.email === email);
        }
        if (item) {
          this.res[key] = parseInt(item.id, 10);
        } else {
          this.invalidEntries.push(email);
        }
      }
    },
    processCsvData(data) {
      this.invalidEntries = [];
      this.userAssignData = [];
      data.forEach(row => {
        this.res = {};
        const {
          user_id: userID,
          programme_id: programmeID,
          coach_email: coachEmail,
          data_mentor_email: dataMentorEmail,
        } = row;
        this.res = {
          user_id: parseInt(userID, 10),
          programme_id: parseInt(programmeID, 10),
        };
        this.processEntry(coachEmail, 'coach_user_id');
        this.processEntry(dataMentorEmail, 'data_mentor_user_id');
        this.userAssignData.push(this.res);
      });
    },
    handleFileStaged(file) {
      this.stagedFile = file[0];
      this.parseCsvFile(file[0], results => {
        if (results.errors.length) {
          this.$ktoast.warning('Error parsing file.<br>Is the file a csv file?');
          this.resetUpload();
          return;
        }
        if (!this.validateFields(results.meta.fields)) {
          this.$ktoast.warning(`Incorrect column headers. Expected ${this.expectedColumns.join(', ')} columns only`, { goAway: 5000 });
          this.resetUpload();
          return;
        }
        this.processCsvData(results.data);
        if (this.invalidEntries.length) {
          this.$ktoast.warning(`The following emails can't be found: ${this.invalidEntries.join(', ')}<br>`
          + 'Please make sure emails are correct and they are in the correct column', { goAway: 5000 });
          this.resetUpload();
        }
      });
    },
    assignLearnerSupport() {
      this.$Loading.start();
      const payload = { coach_and_mentors: this.userAssignData };
      this.$logger.info('Assigning learner support');
      this.$http.put('/api/profile/learner_support/bulk', payload).then(() => {
        this.$logger.info('Learner support assigned');
        this.$ktoast.success('Learner support assigned');
      }).catch(err => {
        this.$logger.error('There was an error while attempting to assign learner support', undefined, err);
        this.showError(err);
      }).then(() => {
        this.resetUpload();
        this.$Loading.finish();
      });
    },
    closeUploadModal() {
      this.resetUpload();
    },
  },
};
</script>
