<template>
  <div class="k-progress">
    <div v-if="shape == 'circle'"
        role="progressbar"
        :class="[circleSize, progressTheme]"
        class="progress-circle-rounded"
        :aria-label="ariaLabel"
        :title="ariaLabel"
      >
      <div class="progress-circle-number">
        <span class="progress-percent" :style="`font-size: ${percentageDivSize}px`" data-percent>{{percentagePretty}}%</span>
      </div>
      <div class="progress-circle-fill">
        <svg class="icon" viewBox="0 0 40 40">
          <circle r="15" cy="20" cx="20" />
          <circle
            :class="`progress-circle-${progressColour}`"
            r="15"
            cy="20"
            cx="20"
            :stroke-dashoffset="100 - percentageInt"
            transform="rotate(-90,20,20)"
          />
        </svg>
      </div>
    </div>
    <div v-else>
      <span v-if="showBarText" class="progress-bar-text">
        {{ percentagePretty }}% <b v-if="customText">{{customText}}</b>
      </span>
      <div class="progress" :class="[progressTheme, barSize]">
        <div class="progress-bar bg-gradient"
          role="progressbar"
          :aria-valuenow="percentagePretty"
          :aria-valuemin="valueMin"
          :aria-valuemax="valueMax"
          :aria-label="ariaLabel"
          :title="ariaLabel"
          :style="`width:${percentageInt}%`"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<style>
span.progress-percent {
  line-height: 4;
}

.overall-score {
  display: flexbox;
  display: flex;
  justify-content: center;
}

.overall-score > div {
  align-self: center;
  text-align: center;
}

.overall-score .well {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  padding: 0;
}

.progress-circle {
  display: inline-block;
  position: relative;
  width: 170px;
}

.progress-circle-medium {
  display: inline-block;
  position: relative;
  width: 140px;
}

.progress-circle-small {
  display: inline-block;
  position: relative;
  width: 90px;
}

.progress-circle-x-small {
  display: inline-block;
  position: relative;
  width: 64px;
}

.progress-circle-x-small .progress-circle-fill circle {
  stroke-width: 2px;
}

.dark .progress-circle-fill circle:nth-child(2) {
  animation: load-circle 1s;
  stroke: var(--kate-success);
  stroke-dasharray: 100;
}

.dark .progress-circle-fill circle:nth-child(2).progress-circle-warning {
  stroke: var(--kate-warning);
}

.dark .progress-circle-fill circle:nth-child(2).progress-circle-danger {
  stroke: var(--kate-danger);
}

.progress-circle-number {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
}

.progress-circle-rounded circle:nth-child(2) {
  stroke-linecap: round;
}

/* Duotone Progress Circle */

.progress-circle-fill circle {
  fill: none;
  stroke-width: 3px;
  stroke: var(--kate-disabled);
}

.duotone .progress-circle-fill circle {
  fill: none;
  stroke-width: 4px;
  stroke: var(--kate-stroke-light-alpha);
}

.duotone .progress-circle-fill circle:nth-child(2) {
  animation: load-circle 1s;
  stroke-dasharray: 97;
  stroke: var(--kate-stroke-light);
}

.duotone.progress-circle-rounded circle:nth-child(2) {
  stroke-linecap: butt;
}

/*  Animation */
@keyframes load-circle {
  0% {
    stroke-dashoffset: 100;
  }
}

/* BAR */

.k-progress .progress {
  height: auto;
  background-color: var(--kate-panel-alt);
  border-radius: 4px;
  margin-bottom: 0;
  width: 100%;
}

.progress-bar-success {
  background-color: var(--kate-success);
}

.progress-bar-warning {
  background-color: var(--kate-logo-orange);
}

.progress-bar-danger {
  background-color: var(--kate-danger);
}

.progress.dark {
  background-color: var(--kate-background-body-alpha);
}

.progress.darker {
  background-color: var(--kate-background);
  border: var(--input-border);
}

.progress.light {
  background-color: var(--kate-mono-0);
}

.progress.bar-x-small,
.progress.bar-x-small .progress-bar {
  min-height: 5px;
  font-size: 0.65rem;
}

.progress.bar-x-small .progress-bar span {
  padding: 2px 5px;
  line-height: 1;
}

.progress.bar-small,
.progress.bar-small .progress-bar {
  min-height: 15px;
  font-size: 0.75rem;
}

.progress.bar-medium,
.progress.bar-medium .progress-bar {
  min-height: 20px;
}

.progress.bar-default,
.progress.bar-default .progress-bar {
  min-height: 25px;
}

.progress-bar {
  display: flex;
  align-items: center;
  border-radius: 0;
  line-height: normal;
  text-align: center;
}

span.progress-bar-text {
  color: var(--kate-type-light);
  font-size: 0.85em;
  padding: 5px;
  display: block;
}

@media (max-width: 767px) {
  .k-progress .progress,
  .k-progress.squared .progress {
    width: auto;
  }
}
</style>

<script>
import format from 'number-format.js';

export default {
  props: {
    size: String,
    shape: String,
    customText: String,
    progressTheme: {
      type: String,
      default: 'dark',
    },
    percentage: {
      type: Number,
      default: 0,
    },
    valueMin: {
      type: Number,
      default: 0,
    },
    valueMax: {
      type: Number,
      default: 100,
    },
    precision: {
      type: Number,
      default: 4,
    },
    showBarText: {
      type: Boolean,
      default: true,
    },
    ariaLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      percentageDivSize: 22,
    };
  },
  computed: {
    percentagePretty() {
      if (this.percentage === 100) {
        return '100';
      }
      if (Number.isNaN(this.percentageInt)) {
        return format(`0.${'0'.repeat(Math.max(this.precision - 2, 0))}`, 0);
      }
      return format(`0.${'0'.repeat(Math.max(this.precision - 2, 0))}`, this.percentage);
    },
    percentageInt() {
      return parseInt(this.percentage, 10);
    },
    circleSize() {
      if (this.size === 'xsmall') {
        return 'progress-circle-x-small';
      }
      if (this.size === 'small') {
        return 'progress-circle-small';
      }
      if (this.size === 'medium') {
        return 'progress-circle-medium';
      }
      return 'progress-circle';
    },

    barSize() {
      if (this.size === 'xsmall') {
        return 'bar-x-small';
      }
      if (this.size === 'small') {
        return 'bar-small';
      }
      if (this.size === 'medium') {
        return 'bar-medium';
      }
      return 'bar-default';
    },

    progressColour() {
      if (this.percentage > 75) {
        return 'success';
      }
      if (this.percentage < 30) {
        return 'danger';
      }
      return 'warning';
    },
  },
  mounted() {
    this.calculateFontDivSize();
  },
  methods: {
    calculateFontDivSize() {
      // Solving for y = c/x
      // y is the font-size, x is the precision
      // c is the number we totally calculated with great precision
      // multiplier is for different circle sizes

      let multiplier;

      switch (this.size) {
        case 'xsmall':
          multiplier = 1;
          break;

        case 'small':
          multiplier = 1.5;
          break;

        default:
          multiplier = 2;
      }

      this.percentageDivSize = Math.round((98 / (2 * this.precision)) * multiplier);
    },
  },
};
</script>
