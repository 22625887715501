<template>
  <ul class="tree-node">
    <li class="cwd entry" v-if="showCwd">
      <button type="button" class="folder-name btn-icon" aria-label="Toggle" title="Toggle" @click="toggleExpansion">
        <i :class="folderIcon" class="folder-icon"></i>{{finalNodeCwd}}<i class="expanded-button" :class="iconClass"></i>
      </button>
    </li>
    <template v-if="!folderOnly || !showCwd">
      <li class="file entry" v-show="expanded"
        :class="{ active: isActiveFile(file), changed: hasChanged(file) }"
        v-for="file in finalNode.files"
        @click="fileClicked(file)"
        :key="file"
      >
        <i :class="getFileIcon(file)"></i>{{file}}<template v-if="hasChanged(file)">*</template>
      </li>
      <li v-if="finalNode.folders.length > 0" class="folders-container">
        <ul >
          <li class="folder" v-show="expanded" v-for="folder in finalNode.folders">
            <tree-node ref="childTreeNode"
              @fileclicked="fileClicked"
              :node="folder"
              :depth="depth + 1"
              :current-filename="sanitisedFilename"
              :changed-files="sanitisedChangedFiles"
              :edit-mode="editMode"
            ></tree-node>
          </li>
        </ul>
      </li>
    </template>
  </ul>

</template>

<style scoped>
li {
  list-style: none;
}

.tree-node {
  display: inline-block;
  margin-bottom: 5px;
  padding: 0;
}

.tree-node ul {
  padding: 0;
}

.tree-node > .file {
  border-left: 2px inset rgb(200 255 3 / 0.3);
  padding-left: 2px;
}

.file.entry.active,
.entry.active {
  background-color: var(--kate-secondary);
  color: var(--kate-type-dark);
  padding-right: 5px;
}

.file.entry.active:hover {
  color: var(--kate-type-dark);
}

.file.entry.active > i,
.entry.active > i {
  color: var(--kate-type-dark);
}

.entry {
  cursor: pointer;
  padding: 2px 0;
}

.entry span:hover,
.file.entry:hover {
  color: var(--kate-type-link-hover);
}

.file.entry i,
.entry i.folder-icon {
  padding-right: 5px;
}

.entry i.expanded-button {
  padding-left: 5px;
}

.file.entry {
  color: var(--kate-ide-pre-text);
  padding-left: 10px;
}

.cm-s-monokai .folder-name {
  min-width: 1em;
  color: var(--kate-mono-0);
}

.cm-s-eclipse .folder-name {
  min-width: 1em;
  color: var(--kate-type-primary);
}

/* Icons Colour */
.tree-node i.folder-icon.fa-folder-open,
.tree-node i.folder-icon.fa-folder {
  color: var(--kate-primary);
}

.tree-node i.fa-file-pdf {
  color: var(--kate-danger-alt);
}

.tree-node i.fa-book {
  color: var(--kate-secondary);
}

.tree-node i.fa-image {
  color: var(--kate-logo-green);
}

</style>

<script>
const FILE_ICONS = {
  py: 'fab fa-python',
  java: 'fab fa-java',
  pdf: 'fas fa-file-pdf',
  ipynb: 'fas fa-book',
};

const LU_FOLDERS = ['lessons', 'practicals', 'additional_materials'];

export default {
  name: 'tree-node',
  props: {
    changedFiles: {
      type: Array,
      default: () => [],
    },
    node: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
    expand: {
      type: Boolean,
      default: true,
    },
    currentFilename: {
      type: String,
    },
    showCwd: {
      type: Boolean,
      default: true,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      expanded: true,
    };
  },

  mounted() {
    this.expanded = this.expand;
  },

  computed: {
    iconClass() {
      return this.expanded ? 'fas fa-caret-down' : 'fas fa-caret-right';
    },

    folderIcon() {
      return this.expanded ? 'fas fa-folder-open' : 'fas fa-folder';
    },

    sanitisedFilename() {
      return this.sanitisePath(this.currentFilename);
    },

    sanitisedChangedFiles() {
      return this.changedFiles.map(x => this.sanitisePath(x)).filter(x => x !== undefined);
    },

    totalCount() {
      return this.node.files.length + this.node.folders.length;
    },

    nodeType() {
      return this.getNodeType(this.node);
    },

    folderOnly() {
      return this.nodeType === 'folder-only';
    },

    fileOnly() {
      return this.nodeType === 'file-only';
    },

    mixedNodeType() {
      return this.nodeType === 'mixed';
    },

    finalNodeEntry() {
      return this.getFirstNodeWithContent(this.node);
    },

    finalNode() {
      return this.finalNodeEntry.node;
    },

    finalNodeCwd() {
      return this.finalNodeEntry.cwd;
    },

  },

  methods: {
    sanitisePath(path) {
      if (path === undefined || !path.startsWith(`${this.finalNodeCwd}/`)) {
        return undefined;
      }
      return path.replace(`${this.finalNodeCwd}/`, '');
    },
    isActiveFile(filename) {
      return filename === this.sanitisedFilename;
    },

    hasChanged(filename) {
      return this.sanitisedChangedFiles.indexOf(filename) !== -1;
    },

    fileClicked(filename) {
      this.$emit('fileclicked', `${this.finalNodeCwd}/${filename}`);
    },

    toggleExpansion() {
      if (this.folderOnly) {
        this.$refs.childTreeNode.toggleExpansion();
      } else {
        this.expanded = !this.expanded;
      }
    },

    getFileIcon(filename) {
      if (this.isActiveFile(filename) && this.editMode) {
        return 'fas fa-pen';
      }
      const extension = this.getFileExtension(filename);
      return FILE_ICONS[extension] || 'fas fa-file';
    },

    getFileExtension(filename) {
      return filename.split('.').pop();
    },

    getNodeType(node) {
      if (node.files.length + node.folders.length > 1) {
        return 'mixed';
      }
      if (node.folders.length === 1) {
        return 'folder-only';
      }
      if (node.files.length === 1) {
        return 'file-only';
      }
      return '';
    },

    getFirstNodeWithContent(node) {
      if (this.getNodeType(node) === 'folder-only' && !LU_FOLDERS.includes(node.folders[0].cwd)) {
        const betterNode = this.getFirstNodeWithContent(node.folders[0]);
        return { cwd: `${node.cwd}/${betterNode.cwd}`, node: betterNode.node };
      }
      return { node, cwd: node.cwd };
    },
  },
};
</script>
