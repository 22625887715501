<template>
  <div class="exception-renderer-cell-renderer" v-if="cell">
    <!-- html just displays the htl straight -->
    <!-- css has been placed for the dataframe.to_html output -->
    <h3 class="exception-renderer-cell-title" v-if="cell.title">{{cell.title}}</h3>
    <div v-if="cell.cell_type === 'html'" class="exception-renderer-cell-body">
      <div v-html="cell.html" :class="cell.class">
      </div>
    </div>
    <!-- Comparison just outputs it left and right next to each other -->
    <div v-else-if="cell.cell_type === 'comparison'" class="exception-renderer-comparison">
      <div class="exception-renderer-cell-left">
        <cell-renderer :cell="cell.left" />
      </div>
      <div class="exception-renderer-cell-right">
        <cell-renderer :cell="cell.right" />
      </div>
    </div>
  </div>
</template>

<style>
.exception-renderer-cell-left {
  border-right: 1px solid var(--kate-type-light);
}

.exception-renderer-cell-title {
  text-decoration: underline;
}

.exception-renderer-cell-renderer .exception-renderer-comparison {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 50% 50%;
}

.exception-renderer-cell-body {
  overflow-y: auto;
}

.exception-renderer-cell-renderer .exception-renderer-cell-title {
  margin-bottom: 1em;
}

table.dataframe {
  margin-right: auto;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  color: var(--kate-type-primary);
  font-size: 12px;
  table-layout: fixed;
}

table.dataframe thead {
  border-bottom: 1px solid var(--kate-background);
  vertical-align: bottom;
  background: var(--input-background);
}

table.dataframe tr,
table.dataframe th,
table.dataframe td {
  text-align: right;
  vertical-align: middle;
  padding: 0.5em;
  line-height: normal;
  white-space: pre;
  max-width: none;
  border: none;
  color: var(--kate-type-light);
}

table.dataframe th {
  font-weight: bold;
}

table.dataframe tbody tr:nth-child(even) {
  background: var(--input-background);
}

table.dataframe tbody tr:nth-child(odd) {
  background: var(--kate-background-body);
}

table.dataframe tbody tr:hover {
  background: var(--kate-panel-alt);
}
</style>

<script>

export default {
  name: 'cell-renderer',
  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
};
</script>
