<template>
  <div id="k-text-editor" class="content-with-spacing">
    <v-editor
      class="custom-editor"
      v-model="text"
      :id="id"
      :editorToolbar="customToolbar"
      :editorOptions="editorOptions"
      :placeholder="readOnly ? '' : placeholder"
      :disabled="readOnly"
    ></v-editor>
    <span class="character-limit-warning" v-if="overLimit">
      <i class="fas fa-exclamation-triangle"></i>
      Exceeded character limit
    </span>
  </div>
</template>

<style>
#k-text-editor {
  width: 100%;
}

.custom-editor {
  width: 100%;
  border-radius: 4px;
  margin: 5px 0;
  min-height: 200px;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.custom-editor .ql-snow.ql-toolbar button {
  padding: 3px 5px;
  height: 30px;
  width: 30px;
  position: relative;
}

.custom-editor .ql-snow.ql-toolbar button svg {
  display: none;
}

/* Fontawesome Icons */
button.ql-bold::before,
button.ql-italic::before,
button.ql-link::before,
button.ql-underline::before,
button.ql-list::before,
button.ql-code::before,
button.ql-code-block::before,
button.ql-script[value="sub"]::before,
button.ql-script[value="super"]::before {
  font-family: "Font Awesome 6 Pro", sans-serif;
  position: absolute;
}

button.ql-bold::before,
button.ql-code-block::before,
button.ql-script[value="sub"]::before,
button.ql-script[value="super"]::before {
  top: 5px;
  left: 9px;
}

button.ql-bold::before {
  content: "\f032";
}

button.ql-italic::before {
  content: "\f033";
  top: 5px;
  left: 10px;
}

button.ql-link::before {
  content: "\f0c1";
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
}

button.ql-underline::before {
  content: "\f0cd";
  top: 5px;
  left: 8px;
}

button.ql-code::before {
  content: "\f121";
  top: 5px;
  left: 5px;
  font-weight: 900;
}

button.ql-code-block::before {
  content: "\e267";
}

button.ql-script[value="sub"]::before {
  content: "\f12c";
}

button.ql-script[value="super"]::before {
  content: "\f12b";
}

button.ql-script[value="sub"]::after {
  content: "Subscript";
}

button.ql-script[value="super"]::after {
  content: "Superscript";
}

/* Link tooltip */
.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  height: unset;
  padding: 5px;
  min-width: 170px;
  width: unset;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action {
  margin-left: 10px;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "Save";
  margin-left: 0;
  padding: 9px !important;
  border-radius: 2px;
  transition: all 0.3s;
}

button.ql-code,
button.ql-code-block {
  position: relative;
}

button.ql-code-block::after,
button.ql-code::after,
button.ql-script::after {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding: 8px;
  border-radius: 2px;
  min-width: 150px;
  bottom: 100%;
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  z-index: 0;
  pointer-events: none;
  margin-bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
}

button.ql-code::after {
  content: "Add code inline";
}

button.ql-code-block::after {
  content: "Add code block";
}

button.ql-code:hover::after,
button.ql-code-block:hover::after,
button.ql-script:hover::after {
  opacity: 1;
  z-index: 2;
}

.custom-editor .ql-snow.ql-toolbar {
  padding-top: 4px !important;
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  display: flex;
  height: 40px;
}

.custom-editor .ql-container.ql-snow {
  border: 0;
}

.custom-editor .ql-editor {
  height: 100%;
  width: 100%;
  padding: 8px;
  flex: 1;
}

button.ql-list[value="bullet"]::before,
button.ql-list[value="ordered"]::before {
  font-family: "Font Awesome 6 Pro", sans-serif;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
}

button.ql-list[value="bullet"]::before {
  content: "\f03a"; /* FontAwesome icon for bullet list */
}

button.ql-list[value="ordered"]::before {
  content: "\f0cb"; /* FontAwesome icon for numbered list */
}

</style>

<script>
import { VueEditor } from 'vue3-editor';

export default {
  components: {
    'v-editor': VueEditor,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
    },
    maxLength: {
      type: Number,
      default: 10000,
    },
    customToolbar: {
      type: Array,
      default: () => [
        ['bold', 'italic', 'underline'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['code', 'code-block'],
        [{ script: 'sub' }, { script: 'super' }],
      ],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disableToolbar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      text: '',
      optionsEditor: {
        formats: ['bold', 'italic', 'underline', 'link', 'code', 'code-block', 'script', 'list'],
      },
    };
  },
  mounted() {
    this.addPasteEventListener();
    this.text = this.modelValue;
  },

  watch: {
    text() {
      this.$emit('update:modelValue', this.text);
    },

    modelValue() {
      this.text = this.modelValue;
    },
  },

  computed: {
    overLimit() {
      if (!this.modelValue) {
        return false;
      }
      return this.modelValue.length - this.maxLength > 0;
    },

    editorOptions() {
      return this.disableToolbar ? { modules: { toolbar: false } } : this.optionsEditor;
    },
  },

  methods: {
    addPasteEventListener() {
      if (!this.id) {
        this.$logger.error('No ID specified for k-editor component - paste event listener cannot be initialised');
        return;
      }
      document.getElementById(this.id).addEventListener('paste', e => {
        if (e.clipboardData.files && e.clipboardData.files.length > 0) {
          e.stopPropagation();
          e.preventDefault();
          this.$emit('pasteResources', { files: e.clipboardData.files });
        }
      });
    },
  },
};
</script>
