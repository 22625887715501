<template>
  <table class="readings-table">
    <thead>
      <tr>
        <th><h4 class="feedback-panel-title"><i class="fas fa-comments-alt"></i> Recommended Reading</h4></th>
        <th>Was this helpful?</th>
      </tr>
    </thead>
    <tbody>
      <reading-unit v-for="(reading, index) in readingList"
        :key="index"
        :url="reading.url"
        :rating="reading.rating"
        :title="reading.name"
        :id="reading.id"
        @rated="(rating) => rateReadingUnit(rating)"
      >
      </reading-unit>
    </tbody>
  </table>
</template>

<style>
.readings-table {
  width: 100%;
}

.readings-table thead th {
  font-size: 0.85em;
}

.readings-table th,
.readings-table td {
  padding: 7px 0;
}

.readings-table tbody tr {
  border-bottom: 1px solid #b3b3b3;
}

</style>

<script>
import ReadingUnit from './reading-unit.vue';

export default {
  components: {
    ReadingUnit,
  },

  props: {
    readingList: {
      type: Array,
      required: true,
      note:
        'Array of objects with keys, e.g. [{reading_time, 4, tags: [sometag], title: sometitle, url: some.url.com}]',
    },
  },

  methods: {
    rateReadingUnit(value) {
      this.$emit('rated', { ...value, index: this.readingList.findIndex(x => x.id === value.id) });
    },
  },
};
</script>
