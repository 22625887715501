<template>
  <k-modal class="update-achievement-modal" v-if="achievement"
    :show="show"
    :closeOnClickAway="false"
    @close="$emit('close')">
    <template #header>
      <h3><i class="fas fa-edit"></i>Edit {{ achievementType }} - {{ achievement.name }}</h3>
    </template>
    <template #body>
      <div class="achievement-form-container col-md-6 col-xs-12">
        <fieldset class="form-entry">
          <label for="achievement-name-input">Name</label>
          <input id="achievement-name-input" v-model="newAchievementName" class="form-control" placeholder="Type {{ achievementType }} name here...">
        </fieldset>
        <fieldset class="form-entry achievement-description">
          <label for="achievement-description-input">Description</label>
          <textarea v-model="newAchievementDescription"
            id="achievement-description-input"
            class="form-control"
            type="textarea"
            rows="3"
            placeholder="Briefly describe the {{ achievementType }}">
          </textarea>
        </fieldset>
      </div>
      <div class="col-md-6 col-xs-12">
        <h3>Update {{ achievementType }}</h3>
        <dropfield @file="filesChange"
          ref="achievementUploadField"
          :customMessage="dropfieldMessage"
          :immediatePost="false"
          :disableDropfield="updateInProgress"
          :enablePreviews="true"
          accept="image/jpeg,image/png"
          url=""
          id="k-update-achievement-dropfield">
        </dropfield>
      </div>
      <div v-if="this.achievement.resource_link" class="achievement-preview">
        <figure>
          <figcaption><i>Current file:</i> <b>{{ achievement.icon }}</b></figcaption>
          <img :src="achievementSource" alt="Achievement preview image" width="250px">
        </figure>
      </div>
    </template>
    <template #footer>
      <button class="btn cancel-btn btn-outlined" @click="$emit('close')" aria-label="Cancel" title="Cancel">Cancel</button>
      <button @click="updateAchievement" class="btn btn-success send-btn" aria-label="Update" title="Add">Update</button>
    </template>
  </k-modal>
</template>

<style>
.update-achievement-modal .modal-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.update-achievement-modal .k-word-tags > form > button {
  height: 38px;
}

.update-achievement-modal .k-search-dropdown-adv-content {
  position: unset;
}

#k-update-achievement-dropfield {
  padding-top: 15px;
}
</style>

<style scoped>
.achievement-form-container fieldset:last-child {
  margin-top: 15px;
}

textarea {
  resize: vertical;
  max-height: 30vh;
  min-height: 70px;
}

.achievement-preview {
  width: 100%;
}

.achievement-preview figcaption {
  color: var(--kate-type-primary);
}

.achievement-preview figcaption b {
  color: var(--kate-type-light);
}

</style>

<script>
import ErrorMixin from '../../mixins/error-mixins';
import TimeMixin from '../../mixins/time-mixins';
import { GOOGLE_CLOUD_STORAGE } from '../../constants';
import KModal from '../../components/k-modal.vue';
import Dropfield from '../../components/dropfield.vue';

export default {
  components: {
    KModal,
    Dropfield,
  },
  mixins: [ErrorMixin, TimeMixin],
  props: {
    achievementType: String,
    achievement: {
      type: Object,
    },
  },
  data() {
    return {
      newAchievementName: undefined,
      newAchievementDescription: undefined,
      updateInProgress: false,
      dropfieldMessage: '<i class="fas fa-cloud-upload-alt"></i>Click or drag image file here to update.',
      file: undefined,
    };
  },
  watch: {
    achievement() {
      if (this.achievement) {
        this.newAchievementName = this.achievement.name;
        this.newAchievementDescription = this.achievement.description;
      } else {
        this.newAchievementName = undefined;
        this.newAchievementDescription = undefined;
      }
    },
    updateInProgress() {
      if (this.updateInProgress) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },

  computed: {
    show() {
      return Boolean(this.achievement);
    },

    fileName() {
      if (this.file) {
        return this.file[0].name;
      }
      return this.achievement.resource_link.split('/').pop();
    },

    achievementSource() {
      return `${GOOGLE_CLOUD_STORAGE}${this.achievement?.resource_link}`;
    },

    achievementEndpoint() {
      return `/api/curriculum/${this.achievementType}`;
    },
  },

  methods: {
    updateAchievementMeta() {
      const updatePayload = {
        file_name: this.fileName,
        name: this.newAchievementName,
        description: this.newAchievementDescription,
        icon: this.fileName,
      };
      this.$logger.info(`Updating ${this.achievementType}`, { id: this.achievement.id, updatePayload });
      this.$http.put(`${this.achievementEndpoint}/${this.achievement.id}`, updatePayload).then(() => {
        this.$logger.info(`Successfully updated ${this.achievementType}`, { id: this.achievement.id });
        this.$ktoast.success(`${this.achievementType} updated`, { goAway: 2000 });
        this.clearFields();
        this.$emit('update');
        this.$emit('close');
      }).catch(err => {
        this.showError(err);
        this.$logger.error(`Error updating ${this.achievementType}`, { id: this.achievement.id, updatePayload }, err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },

    updateAchievement() {
      this.updateInProgress = true;
      if (this.file) {
        const payload = {
          file_name: this.file[0].name,
          file_size: this.file[0].size,
          content_type: this.file[0].type,
        };
        this.$logger.info('Getting signed resumable upload URL');
        this.$http.post(`${this.achievementEndpoint}/${this.achievement.id}/upload-url`, payload)
          .then(response => {
            this.$logger.info('Got signed resumable upload URL');
            const url = response.data.upload_url;
            this.$logger.info('Attempting file upload');
            this.$http.put(url, this.file[0]).then(() => {
              this.$logger.info('File uploaded successfully');
              this.updateAchievementMeta();
            }).catch(err => {
              this.$logger.error(`Error uploading ${this.achievementType} with signed resumable upload URL`, undefined, err);
              this.showError(err);
              this.updateInProgress = false;
            });
          }).catch(err => {
            this.$logger.error('Error getting signed resumable upload URL', undefined, err);
            this.showError(err);
            this.updateInProgress = false;
          });
      } else {
        this.updateAchievementMeta();
      }
    },

    filesChange(file) {
      this.$logger.info('Staged file for update', { file: file.name });
      this.file = file;
    },

    clearFields() {
      this.$refs.achievementUploadField.reset();
      this.newAchievementName = undefined;
      this.newAchievementDescription = undefined;
    },
  },
};
</script>
