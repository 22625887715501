<template>
  <ul class="achievement-list" :class="{ 'interact': interact }">
    <li v-for="(achievement, index) in achievements" :key="achievement.id" @click="handleAchievementClick(achievement)">
      <achievement-item :achievement="achievement"
                  :editable="editable"
                  :achievement-type="achievementType"
                  @remove-achievement="$emit('remove-achievement', index)"
                  @threshold-score="$emit('threshold-score', {index, threshold: $event})"
      ></achievement-item>
    </li>
  </ul>
</template>

<style scoped>
.achievement-list {
  list-style: none;
  padding: 0;
  margin-bottom: 1em;
}

.achievement-list > li {
  display: flex;
  padding: 1em;
  border-bottom: 2px solid var(--kate-primary-dark);
}

.achievement-list.interact > li {
  cursor: pointer;
}

.achievement-list.interact > li:hover {
  background-color: var(--kate-button-primary-hover);
}
</style>

<script>
import AchievementItem from './achievement-item.vue';

export default {
  components: {
    AchievementItem,
  },

  props: {
    interact: {
      type: Boolean,
      default: false,
    },
    achievements: {
      type: Array,
    },
    achievementType: {
      type: String,
      default: 'achievement',
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleAchievementClick(achievement) {
      if (this.interact) {
        this.$emit('achievement-click', achievement);
      }
    },
  },
};
</script>
