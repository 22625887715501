<template>
  <div class="tooltip-box" :class="position">
    <slot />
    <div class="tooltip k-text-primary" :class="textAlign" v-if="text != ''">
      <span class="text" v-html="text"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
    position: {
      type: String,
    },
    textAlign: {
      type: String,
    },
  },
};
</script>

<style>
.tooltip-box {
  position: relative;
  display: inline-block;
}

.tooltip-box:hover .tooltip {
  opacity: 1;
  z-index: 9;
}

.tooltip {
  text-align: center;
  padding: 8px;
  border-radius: 2px;
  min-width: 150px;
  bottom: 100%;
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  z-index: 0;
  background-color: var(--kate-background);
  pointer-events: none;
  margin-bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  /* border: 1px solid rgb(103 120 155 / 50%); */
  border: var(--border-secondary);
}

span.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--kate-background) transparent transparent transparent;
  filter: drop-shadow(1px 0 0 #272c44);
}

/* Placement - defaults on top */
.tooltip-box.bottom .tooltip {
  margin-top: 5px;
  bottom: unset;
}

.tooltip-box.bottom .tooltip .text::after {
  border-width: 0 5px 5px;
  border-color: transparent transparent var(--kate-background) transparent;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

/* right */
.tooltip-box.right .tooltip {
  margin-left: 5px;
  transform: translateY(60%);
  left: 100%;
  bottom: 50%;
}

.tooltip-box.right .tooltip .text::after {
  border-width: 5px 5px 5px 0;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
  border-color: transparent var(--kate-background) transparent transparent;
}

.tooltip-box .tooltip.right {
  text-align: right;
}

/* left */
.tooltip-box.left .tooltip {
  margin-right: 5px;
  right: 100%;
  transform: translateY(60%);
  left: unset;
  bottom: 50%;
}

.tooltip-box.left .tooltip .text::after {
  border-width: 5px 0 5px 5px;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
  border-color: transparent transparent transparent var(--kate-background);
  left: 100%;
}

.tooltip-box .tooltip.left {
  text-align: left;
}

/* Text align center */
.tooltip-box .tooltip.center {
  text-align: center;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

</style>
