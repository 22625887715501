<template>
  <k-panel :hasContentToggle="false">
    <template #body>
      <h2 class="prompt-container">
        <span class="prompt-number">{{ prompt.number }}.</span>
        <span class="prompt" v-html="getMarkdown(prompt.prompt)"></span>
      </h2>
      <section class="response">
        <k-text-editor
          v-if="responseType === 'LONG_TEXT' || responseType === 'SHORT_TEXT'"
          v-model="currentResponse"
          :id="`promptResponse-${prompt.id}`"
          :maxLength="maxTextLength"
          :placeholder="textPlaceholder"
          :read-only="readOnly"
          :disable-toolbar="disableTextToolbar"
        ></k-text-editor>
        <k-percentage-slider
          v-if="responseType === 'QUANTITATIVE_PERCENT'"
          :read-only="readOnly"
          v-model="currentResponse"
        ></k-percentage-slider>
        <k-number-choice
          v-if="responseType === 'QUANTITATIVE_VALUE'"
          :read-only="readOnly"
          v-model="currentResponse"
        ></k-number-choice>
        <k-multi-choice
          v-if="responseType === 'MULTIPLE_CHOICE'"
          v-model="currentResponse"
          :readOnly="readOnly"
          :choices="prompt.answers.choices"
          :choiceAmount="prompt.answers.choice_amount"
        ></k-multi-choice>
      </section>
    </template>
  </k-panel>
</template>

<style>
.prompt-container span p {
  color: var(--kate-type-light);
}
</style>

<style scoped>
.prompt {
  width: 95%;
}

.heading-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.prompt-container {
  display: flex;
  flex-wrap: nowrap;
  font-size: 1.35em;
}

.prompt-number {
  padding-right: 5px;
  min-width: 30px;
}

.prompt-container span p {
  color: var(--kate-type-light);
}
</style>

<script>
import { markdown } from '../modules/maths-markdown';
import KTextEditor from '../components/k-text-editor.vue';
import KPanel from '../components/k-panel.vue';
import KPercentageSlider from '../components/k-percentage-slider.vue';
import KNumberChoice from '../components/k-number-choice.vue';
import KMultiChoice from '../components/k-multi-choice.vue';

export default {
  components: {
    KPanel,
    KTextEditor,
    KPercentageSlider,
    KNumberChoice,
    KMultiChoice,
  },

  props: {
    prompt: {
      type: Object,
    },
    previousResponse: {
      type: [Array, String, Number],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disableTextToolbar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentResponse: undefined,
    };
  },

  mounted() {
    this.currentResponse = this.previousResponse;
  },

  watch: {
    currentResponse() {
      this.$emit('update', this.currentResponse);
    },
  },

  computed: {
    maxValue() {
      if (this.prompt.response_type === 'QUANTITATIVE_PERCENT') {
        return 100;
      }
      if (this.prompt.response_type === 'QUANTITATIVE_VALUE') {
        return 10;
      }
      return undefined;
    },
    maxTextLength() {
      return this.prompt.response_type === 'SHORT_TEXT' ? 512 : 10000;
    },
    textPlaceholder() {
      if (this.prompt.response_type === 'LONG_TEXT') {
        return 'Enter your response here...';
      }
      if (this.prompt.response_type === 'SHORT_TEXT') {
        return `Enter your response here - only a short response is required (max ${this.maxTextLength} characters)`;
      }
      return undefined;
    },
    responseType() {
      return this.prompt.response_type || this.prompt.responseType;
    },
  },

  methods: {
    clearResponse() {
      this.currentResponse = undefined;
    },
    getMarkdown(str) {
      return markdown(str);
    },
  },
};
</script>
