<template>
  <div class="k-search-dropdown-menu">
    <div class="k-search-dropdown-field">
      <button type="button"
        class="k-search-dropdown-button btn btn-outlined"
        :class="buttonClass"
        :disabled="disabled"
        aria-label="Toggle menu" title="Toggle menu" @click="toggleMenu"
        @blur="handleClickAwayFromDropdown"
        ref="kdropdown">
        {{ placeholder }} <i class="fas fa-caret-down"></i>
      </button>
    </div>
    <div class="k-search-dropdown-adv-content" v-show="showMenu">
      <div v-if="showSearch" class="search-input">
        <i class="fa-duotone fa-magnifying-glass input-icon"></i>
        <input class="k-search-dropdown-adv"
          v-model="filterText"
          :disabled="disabled"
          :class="dropdownClass"
          :placeholder="searchPlaceholder || placeholder"
          @focus="handleSearchFocus"
          @blur="handleClickAwayFromSearch"
          ref="kinput">
      </div>
      <button class="clear-btn btn" v-if="selectOptions && modelValue"
        @mousedown="selectAllOptions" title="Select all" aria-label="Select all">
        Select all
      </button>
      <ul class="k-search-dropdown-options">
        <li class="k-search-dropdown-label"
          v-for="option in filteredOptions"
          :key="option.id">
          <label class="k-custom-checkbox" >
            <input type="checkbox" :value="option.name || option.id" :checked="isOptionSelected(option)"
              class="hidden-checkbox"/>
            <span class="k-custom-checkbox-box"></span>
            <button class="k-custom-checkbox-text btn-icon" v-html="option.displayName || option.name || option.id" @mousedown="toggleOption(option)"></button>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.search-input {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.k-search-dropdown-menu {
  position: relative;
}

.k-search-dropdown-options {
  max-height: 20em;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}

.btn-outlined {
  width: 100%;
  padding: 12px;
}

.k-search-dropdown-label {
  color: var(--kate-type-light);
  display: flex;
  margin-bottom: 5px;
  text-align: start;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.k-search-dropdown-label span {
  flex-grow: 1;
  cursor: pointer;
  padding: 8px;
}

.k-search-dropdown-label:only-child {
  margin-bottom: 0;
}

.k-search-dropdown-label:hover {
  background-color: var(--kate-panel-alt);
}

.k-search-dropdown-label.selected-option {
  background-color: var(--kate-panel-alt);
  color: var(--kate-type-accent);
}

.k-search-dropdown-adv {
  width: 100%;
  padding: 8px 10px 8px 30px;
  background-color: var(--input-background);
  border: var(--input-border);
}

.k-search-dropdown-adv:focus {
  outline: var(--input-border-focus);
}

.k-search-dropdown-adv-content {
  position: absolute;
  padding: 5px;
  width: 100%;
  background-color: var(--kate-background-alt);
  border: 1px solid var(--kate-background-alpha);
  z-index: 99;
  box-shadow: var(--box-shadow);
  max-width: 100%;
  overflow-x: auto;
}

.dropdown-slim .k-search-dropdown-field .k-search-dropdown-button {
  padding: 1px;
}

button.clear-btn {
  width: 100%;
  background-color: var(--kate-button-tertiary);
  color: var(--kate-type-light);
  box-shadow: var(--box-shadow);
  margin-bottom: 5px;
}

button.clear-btn:hover {
  background-color: var(--kate-type-primary);
}

.clear-icon-btn {
  background-color: transparent;
  color: var(--kate-danger);
}

.clear-icon-btn:hover {
  background-color: transparent;
  color: var(--kate-danger-light);
}
</style>

<script>
import stringMatch from '../modules/string-match';

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    selectOptions: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showMenu: false,
      filterText: '',
    };
  },

  mounted() {
    this.selectAllOptions();
  },

  computed: {
    buttonClass() {
      return this.type === 'alt' ? 'dropdown-border-alt' : '';
    },
    dropdownClass() {
      if (!this.modelValue.length || this.showMenu) {
        return '';
      }
      return 'selected';
    },
    refinedOptions() {
      return this.options.map(x => (typeof x === 'object' ? x : { id: x, name: x }));
    },
    filteredOptions() {
      if (!this.filterText) {
        return this.refinedOptions;
      }
      return this.refinedOptions.filter(x => stringMatch(x.name, this.filterText) || stringMatch(x.key, this.filterText));
    },
  },

  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleOption(option) {
      const index = this.modelValue.indexOf(option.id);
      if (index === -1) {
        this.$emit('update:modelValue', [...this.modelValue, option.id]);
      } else {
        const newValues = [...this.modelValue];
        newValues.splice(index, 1);
        this.$emit('update:modelValue', newValues);
      }
    },
    isOptionSelected(option) {
      return this.modelValue.includes(option.id);
    },
    handleClickAwayFromDropdown(event) {
      const isClickingSearch = event.relatedTarget && event.relatedTarget === this.$refs.kinput;
      if (!isClickingSearch && this.showMenu) {
        this.showMenu = false;
      }
    },
    handleClickAwayFromSearch(event) {
      const isClickingDropdown = event.relatedTarget && event.relatedTarget === this.$refs.kdropdown;
      if (!isClickingDropdown && this.showMenu) {
        this.showMenu = false;
      }
    },
    handleSearchFocus() {
      if (!this.disabled) {
        this.filterText = '';
      }
    },
    selectAllOptions() {
      const allOptionsIds = this.refinedOptions.map(option => option.id);
      this.$emit('update:modelValue', allOptionsIds);
    },
  },
};
</script>
