<template>
  <ul class="k-multi-choice">
    <li><span v-if="choiceAmount > 1" class="choice-info">{{ choiceAmountText }}</span></li>
    <li v-for="(choice, i) in choices" :class="readOnly || disableChoice(choice) ? 'read-only' : ''">
      <input class="choice"
        v-model="selectedChoices"
        :readonly="readOnly"
        :id="`${uuid}-${i}`"
        :type="inputType"
        :value="choice"
        :disabled="disableChoice(choice)"
      >
      <label :for="`${uuid}-${i}`">{{ choice }}</label>
      <div class="check" :class="inputType"><div class="inside"></div></div>
    </li>
  </ul>
</template>

<style scoped>
ul.k-multi-choice {
  list-style: none;
  padding-left: 0;
}

ul.k-multi-choice > li {
  position: relative;
  color: var(--kate-type-primary);
  width: fit-content;
}

ul.k-multi-choice .choice-info {
  display: inline-block;
  font-style: italic;
  margin-bottom: 5px;
}

.read-only {
  pointer-events: none;
}

ul.k-multi-choice > li label {
  padding-left: 35px;
}

</style>

<script>
import PluralMixin from '../mixins/plural-mixin';

const NUMBER_WORDS = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
};

export default {
  mixins: [PluralMixin],

  props: {
    modelValue: {
      type: [Array, String],
      default(rawProps) {
        // Same logic as setSelectedChoices but uses rawProps which are defined before mount.
        // Calling setSelectedChoices would not work as intended here because the isCheckbox
        // computed would evaluate to undefined, so null would be returned instead of an empty array
        const val = rawProps.modelValue;
        const isCheckbox = rawProps.choiceAmount > 1;
        return isCheckbox ? val || [] : val || null;
      },
    },
    choices: {
      type: Array,
      required: true,
    },
    choiceAmount: {
      type: Number,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  beforeMount() {
    this.uuid = this.$.uid;
  },

  data() {
    return {
      uuid: null,
      selectedChoices: this.setSelectedChoices(this.modelValue),
    };
  },

  watch: {
    modelValue() {
      this.selectedChoices = this.setSelectedChoices(this.modelValue);
    },
    selectedChoices() {
      this.$emit('update:modelValue', this.selectedChoices);
    },
  },

  computed: {
    inputType() {
      return this.choiceAmount === 1 ? 'radio' : 'checkbox';
    },
    isCheckbox() {
      return this.inputType === 'checkbox';
    },
    choiceAmountText() {
      const nw = NUMBER_WORDS[this.choiceAmount] || this.choiceAmount;
      return `Select up to ${nw} ${this.pluralize('option', this.choiceAmount)}`;
    },
  },

  methods: {
    setSelectedChoices(val) {
      // If checkbox, need to make sure selectedChoices is always an array
      return this.isCheckbox ? val || [] : val || null;
    },
    disableChoice(choice) {
      if (!this.selectedChoices) {
        return false;
      }
      // If checkbox, disable input if too many selected while
      // still allowing to de-select currently selected checkboxes
      return this.isCheckbox && this.selectedChoices.length >= this.choiceAmount && this.selectedChoices.indexOf(choice) === -1;
    },
  },
};
</script>
