<template>
  <div id="module-blueprint-container">
    <h1>Manage Module Blueprints</h1>
    <k-blueprint
      :kable-rows="kableRows"
      :kable-headers="kableHeaders"
      :table-callback="tableCallback"
      blueprint-type="module"
    >
      <template #create-form>
        <fieldset class="detail-form form-entry form-required col-md-8">
          <label for="module-name-input">Name</label>
          <input id="module-name-input" class="form-control" placeholder="Module Name" v-model="newBlueprintName" maxlength="255">
        </fieldset>
        <div class="col-md-4">
          <word-tags v-model="newBlueprintTags" textInputPlaceholder="Type tag here..."></word-tags>
        </div>
        <fieldset class="detail-form form-entry form-required col-md-12">
          <label for="module-description-input">Description</label>
          <textarea id="module-description-input" class="form-control" placeholder="A short description of the module" v-model="newBlueprintDescription" maxlength="1023"></textarea>
        </fieldset>
        <fieldset class="detail-form form-entry form-required col-md-12">
          <k-multi-input
            label="Learning Outcomes"
            ref="learningOutcomesMultiInput"
            v-model="newLearningOutcomes"
            :input-placeholder="'Enter new Learning Outcome'"
            :empty-message="'There are no learning outcomes listed in this module blueprint'"
            :maxlength=512
            :edit-mode="true"
          ></k-multi-input>
        </fieldset>
        <button class="btn btn-success" @click="createEmptyModuleBlueprint" :disabled="!validForm || !ready">Create</button>
      </template>
    </k-blueprint>
  </div>
</template>

<style scoped>
  fieldset + div,
  fieldset {
    margin: 10px 0;
  }
</style>

<style>
.module-blueprint-container .panel-content fieldset {
  margin: 5px 0;
}

td.k-table-key-delete,
td.k-table-key-edit {
  text-align: center;
}

td.k-table-key-edit i.fas.fa-edit {
  color: var(--kate-primary);
}

td.k-table-key-delete i.fas.fa-trash-alt {
  color: var(--kate-danger);
}

a.action.ripple.toast-button.toast-cancel,
a.action.ripple.toast-button.toast-confirm {
  margin: 0 !important;
}

.text-area-array-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.text-area-entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
</style>

<script>
import ErrorMixin from '../../../mixins/error-mixins';
import PageReadyMixin from '../../../mixins/page-ready-mixin';
import TimeMixin from '../../../mixins/time-mixins';
import WordTags from '../../components/word-tags.vue';
import AbstractBlueprint from './abstract-blueprints-over.vue';
import KMultiInput from '../../../components/k-multi-input.vue';

const MAX_LEARNING_OUTCOME_LENGTH = 512;
const MAX_DESCRIPTION_LENGTH = 1024;

export default {
  components: {
    KMultiInput,
    WordTags,
    'k-blueprint': AbstractBlueprint,
  },

  mixins: [ErrorMixin, TimeMixin, PageReadyMixin],

  data() {
    return {
      moduleBlueprints: [],
      moduleBlueprintsReady: false,
      updateInProgress: false,
      newBlueprintName: '',
      newBlueprintDescription: '',
      newBlueprintIcon: 'python.png',
      newLearningOutcomes: [],
      newBlueprintTags: [],
      confirmDeletionToast: undefined,
      iconModalInstantiated: false,
      showIconModal: false,
      blueprintToEdit: undefined,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getModuleBlueprints();
  },

  computed: {
    ready() {
      return this.moduleBlueprintsReady && !this.updateInProgress;
    },
    kableHeaders() {
      return {
        name: {
          name: 'Name',
          type: 'url',
          filterable: true,
          sortable: true,
        },
        description: {
          name: 'Description',
          filterable: true,
          sortable: true,
        },
        tags: {
          name: 'Tags',
          type: 'tags',
          filterable: true,
          sortable: true,
        },
        copyModule: {
          name: 'Make a copy',
          type: 'action',
        },
        delete: {
          name: 'Delete',
          type: 'action',
        },
      };
    },
    kableRows() {
      return this.moduleBlueprints.map((val, index) => ({
        name: {
          key: index,
          text: val.name,
          path: { name: 'curriculum_module_blueprint_manage', params: { moduleBlueprintId: val.id } },
        },
        description: val.description,
        tags: {
          tags: val.tags || [],
          sortBy: val.tags ? [...val.tags].sort().join(' ') : '',
        },
        copyModule: {
          text: '<button class="btn btn-warning">Duplicate</button>',
        },
        delete: {
          key: index,
          text: '<i class="fas fa-trash-alt"></i>',
        },
      }));
    },
    validDescription() {
      if (!this.newBlueprintDescription) {
        return false;
      }
      return this.newBlueprintDescription.length < MAX_DESCRIPTION_LENGTH;
    },
    validLearningOutcomes() {
      return this.newLearningOutcomes.map(el => el.length < MAX_LEARNING_OUTCOME_LENGTH);
    },
    validName() {
      return Boolean(this.newBlueprintName.trim().length > 0 && this.newBlueprintName.trim().length < 256);
    },
    validForm() {
      return this.validDescription && this.validName && this.validLearningOutcomes.every(check => check === true);
    },
  },

  methods: {
    createEmptyModuleBlueprint() {
      const updatePayload = {
        name: this.newBlueprintName.trim(),
        description: this.newBlueprintDescription,
        icon: this.newBlueprintIcon,
        assets: [],
        tags: this.newBlueprintTags,
        learning_outcomes: this.newLearningOutcomes.filter(lo => lo.trim().length),
      };
      this.$logger.info('Creating empty module blueprint', { updatePayload });
      this.updateInProgress = true;
      this.$http.post('/api/curriculum/blueprints/modules', updatePayload).then(() => {
        this.$logger.info('Successfully updated blueprint', { updatePayload });
        this.$ktoast.success('Blueprint created');
        this.newBlueprintName = '';
        this.newBlueprintDescription = '';
        this.newBlueprintIcon = 'python.png';
        this.newBlueprintTags = [];
        this.newLearningOutcomes = [];
        this.getModuleBlueprints();
        this.$refs.learningOutcomesMultiInput.defaultState(this.newLearningOutcomes);
      }).catch(err => {
        this.showError(err);
        this.$logger.error('Error creating blueprint', { updatePayload }, err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    getModuleBlueprints() {
      this.$logger.info('Getting module blueprints');
      this.moduleBlueprintsReady = false;
      return this.$http.get('/api/curriculum/blueprints/modules').then(res => {
        this.moduleBlueprints = res.data.blueprints;
        this.$logger.info('Successfully retrieved module blueprints');
      }).catch(err => {
        this.$logger.error('Error retrieving module blueprints', undefined, err);
        this.showError(err);
      }).then(() => {
        this.moduleBlueprintsReady = true;
      });
    },
    confirmDeletion(moduleBlueprintToDelete) {
      this.$ktoast.confirmToast(this.getDeleteMessage(moduleBlueprintToDelete), 'warning', () => {
        this.deleteModuleBlueprint(moduleBlueprintToDelete);
      });
    },
    deleteModuleBlueprint(moduleBlueprintToDelete) {
      this.$logger.info('Deleting module blueprint', { moduleBlueprintId: moduleBlueprintToDelete.id });
      this.updateInProgress = true;
      return this.$http.delete(`/api/curriculum/blueprints/modules/${moduleBlueprintToDelete.id}`).then(() => {
        this.$logger.info('Successfully deleted module blueprint');
        this.$ktoast.success('Blueprint deleted');
        this.getModuleBlueprints();
      }).catch(err => {
        this.$logger.error('Error deleting module blueprint', undefined, err);
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    tableCallback(key, index, row, header) {
      if (header === 'delete') {
        this.confirmDeletion(this.moduleBlueprints[index]);
      }
      if (header === 'edit') {
        this.startBlueprintEdit(this.moduleBlueprints[index]);
      }
      if (header === 'copyModule') {
        this.copyModuleBlueprint(this.moduleBlueprints[index]);
      }
    },
    startBlueprintEdit(blueprint) {
      this.blueprintToEdit = blueprint;
    },
    copyModuleBlueprint(blueprint) {
      return this.$http.post(`/api/curriculum/blueprints/modules/${blueprint.id}/duplicate`).then(() => {
        this.$logger.info('Duplicates module blueprint');
        this.$ktoast.success('Blueprint duplicated');
        this.getModuleBlueprints();
      }).catch(err => {
        this.$logger.error('Error duplicating blueprint', undefined, err);
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    getDeleteMessage(moduleBlueprint) {
      let msg = `<i class="fas fa-exclamation-circle"></i><span class="toast-text">You are about to delete </b>${moduleBlueprint.name}</b>?<br>`;
      if (moduleBlueprint.programme_blueprints.length > 0) {
        const progs = moduleBlueprint.programme_blueprints.map(x => `<li>${x.name}</li>`).join('\n');
        msg += `It is used in the following programme blueprint(s):</span><ul>${progs}</ul>`;
      }
      return msg;
    },
    addNewTextField() {
      this.newLearningOutcomes.push('');
    },
    removeTextField(i) {
      this.newLearningOutcomes.splice(i, 1);
    },
  },
};
</script>
