<template>
  <figure class="avatar">
    <img :src="avatarUrl" alt=""/>
  </figure>
</template>

<style scoped>
figure.avatar {
  width: 80px;
  min-width: 80px;
  height: 80px;
  background: var(--kate-type-dark);
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

figure.avatar > img {
  height: 100%;
}
</style>

<script>
export default {
  props: {
    avatarUrl: {
      type: String,
    },
  },
};
</script>
