<template>
  <div class="panel panel-default">
    <div class="panel-body email-edit-settings email-edit-section">
      <section class="email-edit-table">
        <h3>Existing emails <i class="fas fa-spinner fa-spin" v-if="taskInProgress"></i></h3>
        <p>You can sign in to KATE using any of your verified email addresses. Your <b class="highlight">primary</b> email address is used for all communication from the platform, including notifications and reminders.</p>
        <ul>
          <email-item v-for="email in emails"
            :key="email.email"
            :email="email"
            :taskInProgress="taskInProgress"
            @refresh="resendVerification"
            @remove="removeEmail"
          ></email-item>
        </ul>
      </section>
      <hr class="k-profile-page-hr" />
      <section>
        <h3>Add new email <i class="fas fa-spinner fa-spin" v-if="taskInProgress"></i></h3>
        <p>When you add a <b class="highlight">new</b> email address to your KATE account, a verification email will be sent to your <b class="highlight">current primary email address</b>. This is to ensure that you have access to this KATE account.</p>
        <p>If you no longer have access to your <b class="highlight">current primary email address</b>, please contact us on <a href="mailto:help@cambridgespark.com?subject=Request%20to%20change%20email">help@cambridgespark.com</a></p>
        <form class="add-new-email-section email-edit-section change-email-controls" @submit.prevent="addNewEmail">
          <input v-model="newEmail" class="form-control">
          <button class="btn btn-success" :disabled="!validEmail || taskInProgress" @submit.prevent>Add email</button>
        </form>
      </section>
      <hr class="k-profile-page-hr" />
      <section class="change-primary-email-section email-edit-section">
        <h3>Change primary email <i class="fas fa-spinner fa-spin" v-if="taskInProgress"></i></h3>
        <p>When you change your primary email address, a verification email will be sent to the <b class="highlight">new primary email address</b>. This is to ensure that you have access to that email address.</p>
        <div class="change-email-controls">
          <k-dropdown :options="emailOptions" v-model="selectedPrimaryEmail" :show-search="false"></k-dropdown>
          <button class="btn btn-success" :disabled="!changedPrimary || taskInProgress" @click="changePrimaryEmail">Confirm</button>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.email-edit-section {
  margin-top: 1em;
}

.email-edit-section h3 {
  margin-bottom: 0.5em;
}

.change-email-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1em;
  align-items: center;
  flex-direction: row;
}

.change-email-controls input {
  display: block;
  background-color: var(--input-background);
  color: var(--kate-type-light);
}

.change-email-controls input,
.k-search-dropdown-menu {
  flex: 1;
}

@media screen and (min-width: 768px) {
  .change-email-controls {
    max-width: 50vw;
  }
}

@media screen and (max-width: 767px) {
  .change-email-controls {
    max-width: 100vw;
  }

  .add-new-email-section.change-email-controls {
    flex-direction: column;
    align-items: baseline;
  }
}
</style>

<script>
import ErrorMixin from '../mixins/error-mixins';
import KDropdown from '../components/k-dropdown.vue';
import EmailItem from './email-item.vue';
import { EMAIL_REGEX } from '../constants';

export default {
  components: {
    KDropdown,
    EmailItem,
  },

  mixins: [ErrorMixin],

  data: () => ({
    emails: [],
    newEmail: '',
    selectedPrimaryEmail: undefined,
    taskInProgress: false,
  }),

  beforeMount() {
    this.getEmails().then(() => {
      this.$emit('emails-ready');
    });
  },

  computed: {
    emailOptions() {
      return this.emails.filter(x => !x.in_progress).map(x => ({ id: x.id, name: x.email }));
    },
    currentPrimaryEmail() {
      return this.emails.find(x => x.is_primary);
    },
    changedPrimary() {
      if (!this.currentPrimaryEmail) {
        return false;
      }
      return this.currentPrimaryEmail.id !== this.selectedPrimaryEmail;
    },
    validEmail() {
      const emailTest = RegExp(EMAIL_REGEX, 'g');
      return emailTest.test(this.newEmail);
    },
  },

  methods: {
    getEmails() {
      this.$logger.info('Getting existing emails');
      this.taskInProgress = true;
      return this.$http.get('/api/profile/emails').then(res => {
        this.$logger.info('Got existing emails');
        this.emails = res.data.emails;
        this.selectedPrimaryEmail = this.emails.find(x => x.is_primary).id;
      }).catch(err => {
        this.$logger.error('Error getting emails for user', undefined, err);
        this.showError(err);
      }).then(() => {
        this.taskInProgress = false;
      });
    },
    changePrimaryEmail() {
      this.$logger.info('Changing primary email');
      this.taskInProgress = true;
      this.$http.put(`/api/profile/emails/${this.selectedPrimaryEmail}/primary`).then(() => {
        this.$logger.info('Changed primary email', undefined, true);
        this.$ktoast.success('Primary email successfully changed');
        this.getEmails();
      }).catch(err => {
        this.$logger.error('Error changing primary email for user', undefined, err);
        this.showError(err);
        this.taskInProgress = false;
      });
    },
    resendVerification(email) {
      this.addEmail(email.email);
    },
    addNewEmail() {
      this.addEmail(this.newEmail).then(() => {
        this.newEmail = '';
      });
    },
    addEmail(email) {
      this.$logger.info('Adding new email');
      this.taskInProgress = true;
      return this.$http.post('/api/profile/emails/new', {
        email,
      }).then(() => {
        this.$logger.info('Added new email', undefined, true);
        this.$ktoast.success('Email verification sent. Please check your email');
        this.getEmails();
      }).catch(err => {
        this.$logger.info('Error adding new email for user', undefined, err);
        this.showError(err);
        this.taskInProgress = false;
      });
    },
    removeEmail(email) {
      if (email.in_progress) {
        this.removeUnverifiedEmail(email.email);
      } else {
        this.removeExistingEmail(email.id);
      }
    },
    removeUnverifiedEmail(emailAddress) {
      this.$logger.info('Removing unverified email');
      this.taskInProgress = true;
      this.$http.delete('/api/profile/emails/new', {
        data: {
          email: emailAddress,
        },
      }).then(() => {
        this.$logger.info('Removed unverified email', undefined, true);
        this.$ktoast.success('Email removed');
        this.getEmails();
      }).catch(err => {
        this.$logger.error('Error removing unverified email for user', undefined, err);
        this.showError(err);
        this.taskInProgress = false;
      });
    },
    removeExistingEmail(emailId) {
      this.$logger.info('Removing email');
      this.taskInProgress = true;
      this.$http.delete(`/api/profile/emails/${emailId}`).then(() => {
        this.$logger.info('Removed email', undefined, true);
        this.$ktoast.success('Email successfully deleted');
        this.getEmails();
      }).catch(err => {
        this.$logger.error('Error removing email for user', undefined, err);
        this.showError(err);
        this.taskInProgress = false;
      });
    },
  },
};
</script>
