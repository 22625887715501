const ICONS = {
  warning: 'fas fa-exclamation-triangle',
  edit: 'fas fa-edit',
  trash: 'fas fa-trash-alt',
  loading: 'fas fa-spinner fa-spin',
  play: 'fas fa-play-circle',
  NOT_STARTED: 'fas fa-spinner fa-spin',
  IN_PROGRESS: 'fas fa-spinner fa-spin',
  FAILED: 'fas fa-exclamation-triangle',
  SUCCESS: 'fas fa-play-circle',
};

function generatePreviewIcon({ color = '#5cfff3', icon, fontSize = '1em' }) {
  if (icon) {
    return `<div style="text-align: center; color: ${color}; font-size: ${fontSize}"><i class="${icon}"></i></div>`;
  }
  return '';
}

export default function getIcon({ color, icon, fontSize = '1em' }) {
  if (icon) {
    return generatePreviewIcon({ color, icon: ICONS[icon], fontSize });
  }
  return '';
}
