<template>
  <div v-if="asset.is_mandatory === false" class="tags">
    <span class="tag-optional">Optional</span>
  </div>
</template>

<script>
export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
};
</script>
