<template>
  <div class="content-with-spacing">
    <section class="row profile-section">
      <h2><i class="fas fa-code-branch"></i> HTTPS Settings</h2>
      <div class="col-md-12 col-xs-12 token-col">
        <git-http></git-http>
      </div>
    </section>

    <hr class="k-profile-page-hr" />

    <section class="row profile-section">
      <h2><i class="fas fa-key"></i> SSH Settings</h2>
      <git-ssh @sshReady="ready = true"></git-ssh>
    </section>
  </div>
</template>

<style scoped>
.token-col {
  padding-left: 0;
}
</style>

<script>
import GitHttpSettings from './git-https-settings.vue';
import GitSshSettings from './git-ssh-settings.vue';
import PageReadyMixin from '../mixins/page-ready-mixin';

export default {
  components: {
    'git-http': GitHttpSettings,
    'git-ssh': GitSshSettings,
  },

  mixins: [PageReadyMixin],

  data() {
    return {
      ready: false,
    };
  },
};
</script>
