<template>
  <div>
    <k-panel :hasContentToggle="false"
      title="Create Programme"
      icon="fa-project-diagram"
    >
      <template #body>
        <div class="programme-details-form">
          <h3>{{formattedProgName}}</h3>
          <div class="detail-form">
            <span>Blueprint <i class="form-required">*</i></span>
            <k-dropdown :options="sortedBlueprints" v-model="progBlueprintId"></k-dropdown>
          </div>
          <fieldset class="detail-form form-entry">
            <label for="programme-name-input">Name <span class="form-required">*</span>
            <input id="programme-name-input" class="form-control" placeholder="Type Programme name here..." v-model="progName">
            </label>
          </fieldset>
          <div class="detail-form">
            <span>Theme</span>
            <k-dropdown :options="themes" v-model="progThemeId" :clearOption="true"></k-dropdown>
          </div>
          <div class="detail-form sub-section">
            <div class="detail-form form-entry">
              <k-date-picker
                label="Start"
                :required="true"
                inputId="prog-start-date" v-model="progStartDate">
              </k-date-picker>
            </div>
            <div class="detail-form form-entry">
              <k-date-picker label="Planned End Date" inputId="prog-end-date" v-model="progEndDate"></k-date-picker>
            </div>
          </div>
          <fieldset class="detail-form description form-entry">
            <label for="text-editor">Description</label>
            <p class="info">This will appear in the programme banner on KATE as rendered HTML</p>
            <k-text-editor id="text-editor"
            v-model="progDescription"
            :customToolbar="[['bold', 'italic', 'underline'], ['link'], [{ list: 'ordered' }, { list: 'bullet' }]]"
            :placeholder="'Briefly describe the programme...'"
          ></k-text-editor>
          </fieldset>
          <fieldset class="detail-form form-entry">
            <label for="tag-input">Groups</label>
            <small class="info">
              <i class="fas fa-exclamation-triangle icon-warning"></i> You will not be able to change these once the programme is created
            </small>
            <label class="k-custom-checkbox" for="blueprint-group-checkbox">
              <input type="checkbox" class="hidden-checkbox" v-model="useBlueprintGroups" id="blueprint-group-checkbox">
              <span class="k-custom-checkbox-box"></span>
              <span class="k-custom-checkbox-text"> Copy groups from module blueprints?</span>
            </label>
            <k-word-tags label='' v-model="progGroups" :textInputPlaceholder="'Type groups here'"></k-word-tags>
          </fieldset>
          <fieldset class="detail-form form-entry">
            <span>Product</span>
            <k-dropdown
              v-model="progProductId"
              :options="products"
              :clearOption="true"
            ></k-dropdown>
          </fieldset>
          <fieldset class="detail-form form-entry">
            <label class="k-custom-checkbox" for="include-report-checkbox">
              <input type="checkbox" class="hidden-checkbox" v-model="progIncludeInReport" id="include-report-checkbox">
              <span class="k-custom-checkbox-box"></span>
              <span class="k-custom-checkbox-text"> Include in reporting? If programme is included in reporting, learner data will be added to the Tableau dashboards</span>
            </label>
          </fieldset>
          <fieldset class="detail-form form-entry">
            <span>Badges</span>
            <achievement-list v-if="selectedBadges.length > 0"
              :achievements="selectedBadges"
            ></achievement-list>
            <p v-else>
              There are currently no badges in this programme - click the button below to add some
            </p>
            <button class="btn btn-primary" @click="showBadgeModal = true">Add Badge</button>
            <!-- NB: Modal needs to be outside label for opening / closing to work correctly -->
            <select-badge-modal :show="showBadgeModal"
              @close="showBadgeModal = false"
              @choose="addBadge"
            ></select-badge-modal>
          </fieldset>
          <fieldset class="detail-form form-entry">
            <span>Certificates</span>
            <achievement-list v-if="certificateToBeAwarded"
                              :achievements="certificateToBeAwarded"
                              :achievement-type="'certificate'"
            ></achievement-list>
            <p v-else>
              There is currently no certificate to be awarded on completion of this programme - click the button below to select one
            </p>
            <button class="btn btn-primary" @click="showCertificateModal = true">{{ certificateBtnText }}</button>
            <!-- NB: Modal needs to be outside label for opening / closing to work correctly -->
            <select-certificate-modal
                :show="showCertificateModal"
                @close="showCertificateModal = false"
                @choose="selectCertificate"
            ></select-certificate-modal>
          </fieldset>
          <div class="next-button">
            <button class="btn btn-success" @click="createProgramme"
                    :disabled="!validDetails || creationInProgress">
              <i class="fa fa-spinner fa-spin" v-if="creationInProgress"></i>
              Create Programme
            </button>
          </div>
        </div>
      </template>
    </k-panel>
    <k-panel v-if="progBlueprintId"
      :showContent="showAddModuleBlueprint"
      @toggle="showAddModuleBlueprint = $event"
      title="Add Module"
    >
      <template #body>
        <div v-if="!allModuleBlueprintsReady">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div v-else class="add-new-module-blueprint">
          <div class="add-module-filter-form">
            <label for="search-module-blueprint-input">Search</label>
            <input id="search-module-blueprint-input" v-model="addModuleBlueprintsFilter">
          </div>
          <div class="add-module-filter-results">
            <ul>
              <li v-for="mod in paginatedEntries" :key="mod.key">
                <k-release-module
                  class="k-module-item"
                  :module="mod"
                  :id="mod.id"
                  :name="mod.name"
                  :show-add="true"
                  :is-blueprint="true"
                  @add="addModuleBlueprint(mod.id)"
                  :key="`add-${mod.id}`">
                </k-release-module>
              </li>
            </ul>
          </div>
          <pagination
            v-if="numOfPages"
            v-model="currentPage"
            :count="numOfPages"
            :total-entries="refinedModuleBlueprintSearch.length"
            :displayed-entries="paginatedEntries.length">
          </pagination>
        </div>
      </template>
    </k-panel>
    <k-panel v-if="progBlueprintId"
      :hasContentToggle="false"
      title="Modules in Blueprint"
      icon="fa-compass-drafting"
    >
      <template #body>
        <button class="btn btn-warning" @click="resetModuleBlueprints">
          Reset to default
        </button>
        <div v-if="modulesLoading">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div v-else class="module-blueprints">
          <draggable v-model="moduleBlueprints" draggable=".k-module-item" ghost-class="ghost">
            <k-release-module
              v-for="mod, index in moduleBlueprints"
              class="k-module-item"
              :module="mod"
              :id="mod.id"
              :number="index + 1"
              :is-blueprint="true"
              :allow-remove="true"
              :track="mod.track"
              :show-badges="true"
              @update-track="track => setTrack(index, track)"
              @remove="removeModuleBlueprint(index)"
              :key="`details-${mod.id}`">
            </k-release-module>
          </draggable>
        </div>
      </template>
    </k-panel>
  </div>
</template>

<style scoped>
.add-module-filter-form {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: right;
}

.add-module-filter-results ul {
  list-style: none;
  padding-left: 0;
}

h3:empty {
  display: none;
}

.programme-details-form {
  grid-gap: 1em;
  display: flex;
  flex-wrap: wrap;
}

.programme-details-form > h3 {
  width: 100%;
  height: 1.3em;
  margin-top: 5px;
}

.programme-details-form .sub-section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.programme-details-form .sub-section .detail-form {
  min-width: 30%;
  max-width: 250px;
  flex: 1;
  margin-bottom: 10px;
}

.detail-form {
  min-width: 15em;
  flex: 1 1 40%;
}

.detail-form .k-date-picker {
  margin-right: 10px;
}

.detail-form #text-editor {
  width: 100%;
  margin-bottom: 1rem;
  padding: 8px;
  resize: none;
}

.detail-form label {
  display: block;
}

.detail-form label.k-custom-checkbox {
  display: flex;
}

.k-custom-checkbox {
  margin: 15px 0;
}

.next-button {
  text-align: right;
  flex: 1 1 100%;
}

.module-blueprints {
  margin-top: 1em;
}
</style>

<style>
.ghost .create-programme-module-card {
  border: 4px solid var(--kate-primary);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
</style>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import { sortObjectArray } from '../../modules/sort-by-object-property';
import ErrorMixin from '../../mixins/error-mixins';
import TimeMixin from '../../mixins/time-mixins';
import PageReadyMixin from '../../mixins/page-ready-mixin';
import KDropdown from '../../components/k-dropdown.vue';
import KDatePicker from '../../components/k-date-picker.vue';
import KReleaseModule from './modules/release-module.vue';
import stringMatch from '../../modules/string-match';
import Pagination from '../../components/k-pagination.vue';
import KWordTags from '../components/word-tags.vue';
import KPanel from '../../components/k-panel.vue';
import SelectBadgeModal from '../achievements/select-badge-modal.vue';
import SelectCertificateModal from '../achievements/select-certificate-modal.vue';
import AchievementList from '../achievements/achievement-list.vue';
import KTextEditor from '../../components/k-text-editor.vue';

export default {
  components: {
    'k-dropdown': KDropdown,
    'k-date-picker': KDatePicker,
    'k-release-module': KReleaseModule,
    'k-word-tags': KWordTags,
    'k-panel': KPanel,
    'k-text-editor': KTextEditor,
    pagination: Pagination,
    draggable: VueDraggableNext,
    SelectBadgeModal,
    SelectCertificateModal,
    AchievementList,
  },

  mixins: [ErrorMixin, PageReadyMixin, TimeMixin],

  props: {
    products: {
      type: Array,
    },
  },

  data() {
    return {
      // form data
      progBlueprintId: undefined,
      progName: '',
      progType: undefined,
      progThemeId: undefined,
      progDescription: '',
      progProductId: undefined,
      progIncludeInReport: false,
      progStartDate: undefined,
      progEndDate: undefined,
      progEnableKloud: undefined,
      progEnableKb: undefined,
      progGroups: [],
      blueprints: [],
      themes: [],
      originalModuleBlueprints: [],
      moduleBlueprints: [],
      allModuleBlueprints: [],
      // modals
      showAddModuleBlueprint: false,
      addModuleBlueprintsFilter: '',
      showBadgeModal: false,
      selectedBadges: [],
      // ready flags
      creationInProgress: false,
      modulesLoading: false,
      blueprintsReady: false,
      themesReady: false,
      allModuleBlueprintsReady: false,
      maxItemsPerPage: 10,
      currentPage: 0,
      showCertificateModal: false,
      certificateToBeAwarded: undefined,
      useBlueprintGroups: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgrammeBlueprints();
    this.getThemes();
    this.getAllModuleBlueprints();
  },

  watch: {
    useBlueprintGroups() {
      if (this.useBlueprintGroups) {
        if (this.blueprintGroups.length === 0) {
          this.$ktoast.info('No groups were found in module blueprints but you can still add your own groups');
        }
        this.addBlueprintGroupsToProgrammeGroups();
      } else {
        this.removeBlueprintGroupsFromProgrammeGroups();
      }
    },
    progBlueprintId() {
      const progBlueprint = this.blueprints.find(x => x.id === this.progBlueprintId);
      if (progBlueprint) {
        this.moduleBlueprints = sortObjectArray(progBlueprint.module_blueprints, 'number', false).map(mod => ({
          badges_in_module_blueprint: progBlueprint.badges.filter(
            b => b.module_blueprints?.map(mb => mb.module_blueprint_id)?.includes(mod.id),
          ) || [],
          ...mod,
        }));
        this.originalModuleBlueprints = JSON.parse(JSON.stringify(this.moduleBlueprints));
        this.progDescription = progBlueprint?.description || '';
        this.progProductId = progBlueprint.product_id;
        this.progIncludeInReport = progBlueprint.include_in_reporting;
        this.progName = progBlueprint.name;
        this.progEnableKloud = progBlueprint.enable_kloud;
        this.progEnableKb = progBlueprint.enable_kb;
        this.selectedBadges = [...progBlueprint.badges];
        this.certificateToBeAwarded = progBlueprint.certificate ? [progBlueprint.certificate] : undefined;
      } else {
        // Resetting form - progBlueprintId will be undefined so no blueprint is selected
        this.moduleBlueprints = [];
        this.originalModuleBlueprints = [];
        this.progDescription = '';
        this.progProductId = undefined;
        this.progIncludeInReport = false;
        this.progName = '';
        this.progEnableKloud = false;
        this.progEnableKb = false;
        this.selectedBadges = [];
        this.certificateToBeAwarded = undefined;
      }
      if (this.useBlueprintGroups) {
        if (this.blueprintGroups.length === 0) {
          this.$ktoast.info('No groups were found in module blueprints but you can still add your own groups');
        }
        this.addBlueprintGroupsToProgrammeGroups();
      }
    },
  },

  computed: {
    ready() {
      return this.blueprintsReady && this.themesReady && this.allModuleBlueprintsReady;
    },
    sortedBlueprints() {
      return sortObjectArray(this.blueprints.slice(), 'name', false);
    },
    refinedModuleBlueprintSearch() {
      return this.allModuleBlueprints.filter(x => (
        this.moduleBlueprints.find(y => y.id === x.id) === undefined
        && (stringMatch(x.name, this.addModuleBlueprintsFilter))
      ));
    },
    formattedProgName() {
      return this.progName.trim();
    },
    validDetails() {
      return Boolean(this.progBlueprintId && this.formattedProgName && this.progStartDate && this.moduleBlueprints.length);
    },
    progTheme() {
      const comp = this.themes.find(x => x.id === this.progThemeId);
      return comp ? comp.name : comp;
    },
    numOfPages() {
      if (this.refinedModuleBlueprintSearch.length <= this.maxItemsPerPage) {
        return undefined;
      }
      return Math.ceil(this.refinedModuleBlueprintSearch.length / this.maxItemsPerPage);
    },
    paginatedEntries() {
      if (!this.numOfPages) {
        return this.refinedModuleBlueprintSearch;
      }
      const startingIndex = this.maxItemsPerPage * this.currentPage;
      const endingIndex = Math.min(this.maxItemsPerPage + startingIndex, this.refinedModuleBlueprintSearch.length);
      return this.refinedModuleBlueprintSearch.slice(startingIndex, endingIndex);
    },
    certificateBtnText() {
      return this.certificateToBeAwarded ? 'Change Certificate' : 'Add Certificate';
    },
    blueprintGroups() {
      const currentModuleBpIds = this.moduleBlueprints.map(x => x.id);
      const groups = this.allModuleBlueprints
        .filter(x => currentModuleBpIds.includes(x.id))
        .map(x => x.blueprint_groups)
        .flat();
      return groups || [];
    },
  },

  methods: {
    addModuleBlueprint(modId) {
      const newModuleBlueprint = this.allModuleBlueprints.find(x => x.id === modId);
      this.moduleBlueprints.push({ track: 'curriculum', ...newModuleBlueprint });
      this.addBlueprintGroupsToProgrammeGroups();
    },
    addBlueprintGroupsToProgrammeGroups() {
      this.blueprintGroups.forEach(x => {
        if (!this.progGroups.includes(x) && this.useBlueprintGroups) {
          this.progGroups.push(x);
        }
      });
    },
    removeBlueprintGroupsFromProgrammeGroups() {
      this.progGroups = this.progGroups.filter(x => !this.blueprintGroups.includes(x));
    },
    getCreatePayload() {
      return {
        name: this.formattedProgName,
        programme_blueprint_id: this.progBlueprintId,
        start_date: this.progStartDate ? this.formatDate(this.progStartDate) : undefined,
        planned_end_date: this.progEndDate ? this.formatDate(this.progEndDate) : undefined,
        theme_id: this.progThemeId,
        description: this.progDescription || null,
        product_id: this.progProductId || null,
        include_in_reporting: this.progIncludeInReport,
        module_blueprints: this.moduleBlueprints.map(x => ({ id: x.id, track: x.track })),
        enable_kloud: this.progEnableKloud,
        enable_kb: this.progEnableKb,
        groups: this.progGroups,
        badge_ids: this.selectedBadges.map(x => x.id),
        certificate_id: this.certificateToBeAwarded ? this.certificateToBeAwarded[0].id : null,
        copy_blueprint_groups: this.useBlueprintGroups,
      };
    },
    resetForm() {
      this.progBlueprintId = undefined;
      this.progThemeId = undefined;
      this.progStartDate = undefined;
      this.progEndDate = undefined;
      this.useBlueprintGroups = false;
      this.progGroups = [];
    },
    resetModuleBlueprints() {
      this.moduleBlueprints = JSON.parse(JSON.stringify(this.originalModuleBlueprints));
    },
    removeModuleBlueprint(index) {
      this.moduleBlueprints.splice(index, 1);
    },
    setTrack(index, track) {
      this.moduleBlueprints[index].track = track;
    },
    createProgramme() {
      this.$logger.info('Creating new programme');
      this.creationInProgress = true;
      const payload = this.getCreatePayload();
      this.$http.post('/api/curriculum/programmes', payload).then(res => {
        this.$ktoast.success(`Created programme: ${res.data.programme_id}`);
        this.$logger.info('Successfully created new programme', { programmeId: res.data.programme_id }, true);
        this.$emit('programme-created');
        this.resetForm();
      }).catch(err => {
        this.$logger.error('Error creating new programme', undefined, err);
        this.showError(err);
      }).then(() => {
        this.creationInProgress = false;
      });
    },
    getProgrammeBlueprints() {
      this.$logger.info('Getting programme blueprints');
      this.blueprintsReady = false;
      return this.$http.get('/api/curriculum/blueprints/programmes').then(res => {
        this.blueprints = res.data.blueprints;
        this.$logger.info('Successfully retrieved programme blueprints');
      }).catch(err => {
        this.showError(err);
        this.$logger.error('Error retrieving programme blueprints');
      }).then(() => {
        this.blueprintsReady = true;
      });
    },
    getThemes() {
      this.$logger.info('Getting themes');
      this.themesReady = false;
      this.$http.get('/api/curriculum/themes').then(res => {
        this.themes = res.data;
        this.$logger.info('Successfully retrieved themes');
      }).catch(err => {
        this.showError(err);
        this.$logger.error('Error retrieving themes');
      }).then(() => {
        this.themesReady = true;
      });
    },
    getAllModuleBlueprints() {
      this.$logger.info('Getting all module blueprints');
      this.allModuleBlueprints = [];
      this.allModuleBlueprintsReady = false;
      this.$http.get('/api/curriculum/blueprints/modules').then(res => {
        this.allModuleBlueprints = res.data.blueprints;
      }).catch(err => {
        this.showError(err);
        this.$logger.warn('Error retrieving module blueprints');
      }).then(() => {
        this.allModuleBlueprintsReady = true;
      });
    },
    addBadge(badge) {
      if (!this.selectedBadges.find(b => b.id === badge.id)) {
        this.selectedBadges.push(badge);
      }
    },
    selectCertificate(certificate) {
      this.certificateToBeAwarded = [certificate];
    },
  },
};
</script>
