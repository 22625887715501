export function genericSort(a, b) {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
}

function sortAscending(property) {
  function sortFunc(a, b) {
    return genericSort(a[property], b[property]);
  }
  return sortFunc;
}

function sortDescending(property) {
  const ascSortFunc = sortAscending(property);
  function sortFunc(a, b) {
    return -1 * ascSortFunc(a, b);
  }
  return sortFunc;
}

function getSortFunction(property, descending) {
  if (descending) {
    return sortDescending(property);
  }
  return sortAscending(property);
}

export function sortObjectArray(array, property, descending) {
  /**
   * Returns a new array containing the elements from the original array, sorted according to a specified property
   * on the objects in that array.
   * @param array Array to sort from. Original array remains unchanged.
   * @param property Object property used in the sort to determine the order of the elements. If property is not
   * present on element, they will be sorted to the end of the resulting array.
   * @param descending If true, sort in descending order.
   */
  // Separate the elements of the array into those that have the relevant property and those that don't
  const withProp = array.filter(x => x[property]);
  const withoutProp = array.filter(x => !x[property]);
  // Sort the ones with property
  withProp.sort(getSortFunction(property, descending));
  // Concatenate and return
  return withProp.concat(withoutProp);
}

export default { genericSort, sortObjectArray };
