<template>
  <li class="profile-page" ref="profilePage">
    <button type="button" class="btn-icon user-avatar" aria-label="User" title="User" @click="toggleOpen">
      <img id="navbar-avatar" :src="avatarUrl" class="img-circle"  alt=""/>
      {{ displayName }}
      <i class="fas fa-caret-down"></i>
    </button>
    <ul class="dropdown" :class="[{ 'is-open': isOpen }, { 'dashboard-dropdown' : isDashboard }]">
      <li v-if="formattedUserId" class="user-id">
        <span>{{ isDashboard ? 'User' : 'Learner' }} ID: <b>{{ formattedUserId }}</b></span>
      </li>
      <li class="profile-link">
        <router-link :to="{ name: 'profile_landing' }">
          <i class="fas fa-user-circle"></i>My Profile
        </router-link>
      </li>
      <li>
        <button type="button" id="logout-button" @click="logout"><i class="fas fa-sign-out-alt"></i>Log Out</button>
      </li>
    </ul>
  </li>
</template>

<script>
import useGlobalStore from '../../stores/global';
import formatId from '../../modules/format-user-id';

export default {

  props: {
    isOpen: {
      type: Boolean,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
    };
  },

  watch: {
    route() {
      // Close the dropdown if the route changes
      this.$emit('close');
    },
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  computed: {
    route() {
      return this.$route;
    },
    isDashboard() {
      return this.store.isDashboard;
    },
    name() {
      return this.store.userFullName;
    },
    userPreferredName() {
      return this.store.userPreferredName;
    },
    formattedUserId() {
      if (!this.store.userId) {
        return undefined;
      }
      return formatId(this.store.userId);
    },
    avatarUrl() {
      return this.store.avatarUrl;
    },
    displayName() {
      if (!this.userPreferredName && this.name) {
        return this.name.split(' ')[0];
      }
      return this.userPreferredName;
    },
  },

  methods: {
    logout() {
      this.$Loading.start();
      this.$http.post('/api/auth/logout', {}, {
        // respect set-cookie header for logging out
        withCredentials: true,
      }).then(() => {
        this.store.logOut();
        this.$router.push({ name: 'login_page' });
      }).then(() => {
        this.$Loading.finish();
      });
    },

    handleClickOutside(event) {
      const profilePage = this.$refs.profilePage;
      if (profilePage && !profilePage.contains(event.target)) {
        this.$emit('close');
      }
    },

    toggleOpen() {
      if (this.isOpen) {
        this.$emit('close');
      } else {
        this.$emit('open');
      }
    },
  },
};

</script>
