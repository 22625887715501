<template>
  <label :for="classId" class="k-toggle" :class="size">
    <span>{{ label }}</span>
    <input :id="classId" type="checkbox" class="alt_checkbox"  v-model="checked" :disabled="disabled" name="toggle" />
    <span aria-hidden="true"></span>
  </label>
</template>

<style scoped>
.k-toggle {
  display: flex;
  align-items: center;
  min-height: 30px;
}

.k-toggle span {
  display: inline-block;
  line-height: 20px;
  padding: 5px;
}

.alt_checkbox {
  position: absolute;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

.alt_checkbox + [aria-hidden]::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--kate-mono-0);
  border-radius: 100px;
  transition: all 0.25s cubic-bezier(1, 0.1, 0, 0.9) 0.1s;
  transform: translateZ(0) scale(0);
}

.alt_checkbox + [aria-hidden]::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 2px;
  display: block;
  height: 26px;
  width: 26px;
  background-color: var(--kate-mono-0);
  border-radius: 100%;
  transform: translateY(-50%);
  box-shadow: inset -1px -1px 0 1px rgb(0 0 0 / 0.5);
  transition: all 0.25s cubic-bezier(1, 0.1, 0, 0.9);
}

.alt_checkbox:checked + [aria-hidden] {
  background-color: var(--kate-primary-dark);
}

.alt_checkbox:checked + [aria-hidden]::after {
  left: calc(100% - 28px);
}

.alt_checkbox + [aria-hidden] {
  position: relative;
  display: inline-block;
  background-color: var(--kate-type-primary);
  width: 60px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(1, 0.1, 0, 0.9);
}

.alt_checkbox + [aria-hidden] span {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding: 5px 10px 5px 5px;
  width: 15px;
}

.alt_checkbox + [aria-hidden] span:last-of-type {
  padding-left: 0;
  padding-right: 15px;
}

/* Small */
.small .alt_checkbox + [aria-hidden] {
  width: 47px;
  height: 21px;
}

.small .slider::before {
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 3px;
}

.small .alt_checkbox + [aria-hidden]::after {
  left: 2px;
  height: 16px;
  width: 16px;
}

.small .alt_checkbox:checked + [aria-hidden]::after {
  left: calc(100% - 19px);
}

</style>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
    },
    round: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'large', // large or small
    },
    label: {
      type: String,
      required: true,
    },
    classId: {
      type: String,
      default: 'k-toggle-input',
    },
  },
  data() {
    return {
      checked: this.modelValue,
    };
  },
  watch: {
    modelValue() {
      this.checked = this.modelValue;
    },
    checked() {
      this.$emit('update:modelValue', this.checked);
    },
  },
};
</script>
