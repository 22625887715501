<template>
  <div>
    <h1 v-if="mentorType === 'Trainer'">{{ plural(mentorType) }}</h1>
    <div :class="{ 'panel panel-body': mentorType === 'Trainer' }">
      <add-trainers v-if="mentorType === 'Trainer'" @update="getMentors"></add-trainers>
      <k-panel :title="panelTitle" :showContent="show" @toggle="(val) => show = val">
        <template #body>
          <k-table :rows="kableRows" :headers="kableHeaders" :hideable="true" :panel="false" :max="10" @clicked="tableCallback"></k-table>
        </template>
      </k-panel>
      <k-modal :show="showModal" @close="showModal = false">
        <template #header>
          <h3>{{ selectedMentor.name }}</h3>
        </template>
        <template #body>
          <label for="acuity-link-input">Acuity Link</label>
          <input id="acuity-link-input" type="text"
            class="form-control"
            placeholder="Add acuity link for scheduling"
            v-model="selectedMentor.acuity_link">
        </template>
        <template #footer>
          <div class="form-buttons">
            <button class="btn btn-primary" id="add-user-individual-button" @click="updateAcuityLink">
              Update
            </button>
          </div>
        </template>
      </k-modal>
    </div>
  </div>
</template>

<script>
import AddTrainers from './add-trainers.vue';
import KPanel from '../../components/k-panel.vue';
import KTable from '../../components/k-table.vue';
import KModal from '../../components/k-modal.vue';
import ErrorMixin from '../../mixins/error-mixins';
import PageReadyMixin from '../../mixins/page-ready-mixin';
import PluralMixin from '../../mixins/plural-mixin';
import MentorMixin from '../../mixins/mentor-mixin';

export default {
  components: {
    AddTrainers,
    KTable,
    KModal,
    KPanel,
  },

  mixins: [ErrorMixin, PageReadyMixin, MentorMixin, PluralMixin],

  props: {
    mentorType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      show: true,
      mentors: [],
      showModal: false,
      selectedMentor: {},
      updating: false,
      mentorsReady: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getMentors();
  },

  watch: {
    mentorType() {
      if (this.mentorType) {
        this.getMentors();
      }
    },
  },

  computed: {
    ready() {
      return !this.updating && this.mentorsReady;
    },
    panelTitle() {
      return this.plural(this.mentorType);
    },
    hasAcuity() {
      return this.mentorType === 'Coach' || this.mentorType === 'Data Mentor';
    },
    canUpdateLink() {
      if (this.mentorType === 'Coach') {
        return this.$permissions.hasPermission('manage_coaches');
      }
      if (this.mentorType === 'Data Mentor') {
        return this.$permissions.hasPermission('manage_mentors');
      }
      return false;
    },
    kableHeaders() {
      const headers = {
        id: {
          name: 'ID',
          sortable: true,
        },
        name: {
          name: this.mentorType,
          sortable: true,
          filterable: true,
          type: 'url',
        },
        email: {
          name: 'Email',
          sortable: true,
          filterable: true,
        },
      };
      if (this.hasAcuity) {
        headers.acuity_link = {
          name: 'Acuity Link',
        };
      }
      if (this.canUpdateLink) {
        headers.edit = {
          name: 'Edit Link',
          type: 'action',
        };
      }
      return headers;
    },
    kableRows() {
      return this.mentors.map((x, index) => (this.createRow(x, index)));
    },
  },
  methods: {
    createRow(mentor, index) {
      const row = {
        id: mentor.id,
        name: {
          text: mentor.name,
          path: {
            name: 'dashboard_students',
            params: {
              studentId: mentor.id,
            },
          },
        },
        email: mentor.email,
      };
      if (this.hasAcuity) {
        row.acuity_link = mentor.acuity_link ? `<a href="${mentor.acuity_link}" target="_blank">${mentor.acuity_link}</a>` : '<i class="fas fa-exclamation-triangle"></i><span> No acuity link specified</span>';
      }
      if (this.canUpdateLink) {
        row.edit = {
          key: index,
          text: `<i title="Click to edit ${this.mentorType} acuity link" class="fas fa-edit"></i>`,
        };
      }
      return row;
    },
    tableCallback(key, index, row, column) {
      if (column === 'edit') {
        this.selectedMentor = JSON.parse(JSON.stringify(this.mentors.find(x => x.id === row.id)));
        this.showModal = true;
      }
    },
    getMentors() {
      this.mentorsReady = false;
      this.$logger.info(`Getting ${this.plural(this.mentorType.toLowerCase())}`);
      this.$http.get(`/api/profile/${this.mentorField}`).then(res => {
        this.$logger.info(`Successfully retrieved ${this.plural(this.mentorType.toLowerCase())}`);
        this.mentors = res.data[this.plural(this.mentorField)];
        this.$emit('data-fetched', { mentorType: this.mentorType, data: this.mentors });
      }).catch(err => {
        this.$logger.error(`There was an error while attempting to retrieve ${this.plural(this.mentorType.toLowerCase())}`, undefined, err);
        this.showError(err);
      }).then(() => {
        this.mentorsReady = true;
      });
    },
    updateAcuityLink() {
      this.showModal = false;
      this.updating = true;
      const logPayload = {};
      logPayload[this.mentorIdField] = this.selectedMentor.id;
      this.$logger.info(`Updating ${this.mentorType.toLowerCase()} acuity link`, logPayload);
      this.$http.put(`/api/profile/${this.mentorField}`, {
        user_id: this.selectedMentor.id,
        acuity_link: this.selectedMentor.acuity_link,
      }).then(() => {
        this.$logger.info(`Successfully updated ${this.mentorType.toLowerCase()} acuity link`, logPayload);
        this.getMentors();
      }).catch(err => {
        this.$logger.error(`There was an error while attempting to update ${this.mentorType.toLowerCase()} acuity link`, logPayload, err);
        this.showError(err);
      }).then(() => {
        this.updating = false;
      });
    },
  },
};
</script>
