<template>
  <div class="spinner-overlay" :class="loaderClass" v-if="currentlyLoading">
    <div class="spinner center">
      <img src="../../../img/Edukate_Loader.svg" alt="Edukate loader">
    </div>
  </div>
</template>

<style>
#content .spinner-overlay {
  flex: inherit;
  height: 100%;
  margin-top: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  z-index: 9999;
  background-color: rgb(0 0 0 / 0.75);
  transition: margin-left 0.2s ease-in-out;
}

.spinner-overlay-no-bg {
  background-color: transparent !important;
}

#content .sidebar-open + .spinner-overlay {
  transition: margin-left 0.2s ease-in-out;
}

.spinner img {
  width: 150px;
}

.spinner {
  font-size: 75px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.spinner.center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 150px;
  height: 140px;
  z-index: 999999;
}

</style>

<script>
import useLoadingStore from '../../stores/loading';

export default {
  data() {
    return {
      store: useLoadingStore(),
    };
  },

  computed: {
    currentlyLoading() {
      return this.store.isLoading;
    },
    loaderClass() {
      return this.store.isMinimal ? 'spinner-overlay-no-bg' : '';
    },
  },
};

</script>
