<template>
  <div>
    <h2 v-if="!inline">
      {{ assignedType }}
      <button class="btn-icon" v-if="!editData">
        <i v-if="canUpdate"
          class="fas fa-edit clickable"
          title="Click to change value"
          @click="editData = !editData"></i>
      </button>
      <button class="btn-icon" v-else>
        <i v-if="canUpdate"
          class="fas fa-check clickable"
          title="Click to save changes"
          @click="emitChanges"></i>
        <i v-if="canUpdate"
          class="fas fa-times-circle clickable"
          title="Click to cancel edit"
          @click="editData = !editData"></i>
      </button>
    </h2>
    <div class="update-options" v-if="editData">
      <span v-if="inline">{{ assignedType }}: </span>
      <k-dropdown v-if="canUpdate && showDropdown"
        v-model="newUserInfo"
        :options="options"
        :showSearch="showSearch"
        :clear-option="assignedType == 'coach' || assignedType == 'data mentor' ? true : false"
      >
      </k-dropdown>
      <k-date-picker v-if="canUpdate && !showDropdown"
        v-model="newUserInfo"
        type="date"
      >
      </k-date-picker>
      <button class="btn-icon" v-if="inline">
        <i v-if="canUpdate"
           class="fas fa-check clickable"
           title="Click to save changes"
           @click="emitChanges"></i>
        <i v-if="canUpdate"
           class="fas fa-times-circle clickable"
           title="Click to cancel edit"
           @click="cancelEdit"></i>
      </button>
    </div>
    <div class="update-options" v-else>
      <span v-if="inline">{{ assignedType }}: </span>
      <div v-if="mentor">
        <span>
          <a :href="mentor.acuity_link" target="_blank">
            <b>{{ mentor.name }}</b><i class="fas fa-external-link-alt"></i>
          </a>
        </span>
        <p>{{ mentor.email }}</p>
      </div>
      <div v-else-if="employer">
        <b>{{ employer.name }}</b>
      </div>
      <div v-else-if="estimatedGraduationDate">
        <b>{{ parseTimestamp(estimatedGraduationDate, undefined, "do MMM yyyy") }}</b>
      </div>
      <div v-else-if="estimatedGraduationDate === undefined || estimatedGraduationDate === null">
        <span>No {{ assignedType }} assigned</span>
      </div>
      <button class="btn-icon" v-if="inline">
        <i v-if="canUpdate"
           class="fas fa-edit clickable"
           title="Click to change value"
           @click="editData = !editData"></i>
      </button>
    </div>
  </div>
</template>

<style scoped>
h2 {
  text-transform: capitalize;
  display: flex;
  gap: 15px;
}

i.fas.fa-check {
  padding-right: 10px;
}

.update-options {
  display: flex;
  gap: 10px;
  align-items: center;
}

.update-options .k-search-dropdown-menu {
  min-width: 200px !important;
}
</style>

<script>
import KDropdown from '../../components/k-dropdown.vue';
import KDatePicker from '../../components/k-date-picker.vue';
import TimeMixin from '../../mixins/time-mixins';

export default {
  components: {
    'k-dropdown': KDropdown,
    'k-date-picker': KDatePicker,
  },

  mixins: [TimeMixin],

  props: {
    assignedType: {
      type: String,
    },
    mentor: {
      type: Object,
    },
    employer: {
      type: Object,
    },
    estimatedGraduationDate: {
      type: [Date, String],
    },
    options: {
      type: Array,
    },
    canUpdate: {
      type: Boolean,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editData: false,
      newUserInfo: this.initialiseDefaultUserInfo(),
    };
  },

  watch: {
    mentor() {
      this.newUserInfo = this.mentor ? this.mentor.id : null;
    },
    employer() {
      this.newUserInfo = this.employer ? this.employer.id : null;
    },
  },

  computed: {
    showDropdown() {
      return Boolean(this.options);
    },
  },

  methods: {
    emitChanges() {
      if (this.canUpdate) {
        this.$emit('new-data', this.newUserInfo);
        this.editData = false;
      }
    },

    cancelEdit() {
      this.newUserInfo = this.initialiseDefaultUserInfo();
      this.editData = !this.editData;
    },

    initialiseDefaultUserInfo() {
      if (this.mentor) {
        return this.mentor.user_id;
      } if (this.employer) {
        return this.employer.employer_id;
      } if (this.estimatedGraduationDate) {
        return this.estimatedGraduationDate;
      }
      return undefined;
    },
  },
};
</script>
