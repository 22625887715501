export default {
  methods: {
    /**
     * Dump payload to localstorage
     * @param {String} key the key where it will be stored (Preferably component name
     * @param {Object} payload The object to store in localStorage
     */
    dumpToStorage(key, payload) {
      localStorage[key] = JSON.stringify(payload);
    },
    /**
     * Load object from storage
     * @param {String} Key the value is stored under
     * @return {(Object|undefined)} Either the object if key exists, or undefined
     */
    loadFromStorage(key) {
      if (localStorage[key] === undefined) {
        return undefined;
      }
      return JSON.parse(localStorage[key]);
    },
    loadSetting(page, settingsKey) {
      const settings = this.loadFromStorage(page);
      if (settings === undefined) {
        return undefined;
      }
      return settings[settingsKey];
    },
    saveSetting(page, settingsKey, payload) {
      const settings = this.loadFromStorage(page) || {};
      settings[settingsKey] = payload;

      this.dumpToStorage(page, settings);
    },

  },
};
