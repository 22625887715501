import axios from 'axios';
import ErrorMixin from './error-mixins';
import TimeMixin from './time-mixins';
import { sortObjectArray } from '../modules/sort-by-object-property';

const REFRESH_INTERVAL = 5 * 60 * 1000;

export default {
  mixins: [ErrorMixin, TimeMixin],

  data() {
    return {
      notifications: [],
      notificationRefreshInterval: undefined,
      retryCount: 0,
    };
  },

  methods: {
    getNotifications() {
      this.$logger.info('Getting notifications for user');
      this.$http.get('/api/notification/notifications').then(res => {
        this.$logger.info('Got notifications');
        this.notifications = sortObjectArray(res.data.notifications, 'posted_at', true);
        this.retryCount = 0;
      }).catch(err => {
        if (axios.isCancel(err)) {
          this.retryCount += 1;
          if (this.retryCount <= 3) {
            this.$logger.warn('Request to get notifications cancelled: retrying', undefined, err);
            this.getNotifications();
          } else {
            this.$logger.warn('Cancellation retries exceeded for this attempt', undefined, err);
            this.retryCount = 0;
          }
          return;
        }
        if (this.$http.errIn(err, [401, 403])) {
          this.$logger.warn('User was not authorised to get notifications', undefined, err);
        } else {
          this.$logger.warn('Error getting notification', undefined, err);
        }
        this.retryCount = 0;
      });
    },
    startNotificationRefreshCycle() {
      this.$logger.info('Starting recurring notification check');
      clearInterval(this.notificationRefreshInterval);
      this.notificationRefreshInterval = setInterval(() => {
        this.getNotifications();
      }, REFRESH_INTERVAL);
    },
    readNotification(notificationId) {
      this.$logger.info('Marking notification as read', { notificationId });
      this.$http.put(`/api/notification/${notificationId}/read`).then(() => {
        this.$logger.info('Notification marked as read', { notificationId });
      }).catch(err => {
        this.$logger.error('Error marking notification as read', { notificationId }, err);
      });
    },
  },
};
