<template>
  <k-modal class="update-meeting-modal" v-if="meeting"
    :show="show"
    :closeOnClickAway="false"
    @close="$emit('close')">
    <template #header>
      <h3><i class="fas fa-edit"></i>Edit Event - {{eventNameEditing}}</h3>
    </template>
    <template #body>
      <div class="meeting-form-container col-md-7 col-xs-12">
        <fieldset class="form-entry">
          <label for="mtg-title-input">Title</label>
          <input id="mtg-title-input" v-model="newMeetingName" class="form-control" placeholder="Type Event name here...">
        </fieldset>
        <fieldset class="form-entry">
          <label for="mtg-description-input">Description</label>
          <textarea id="mtg-description-input" v-model="newMeetingDescription"
            class="form-control"
            type="textarea"
            rows="3"
            placeholder="Briefly describe the Event">
          </textarea>
        </fieldset>
        <div>
          <word-tags v-model="newMeetingTags"
            dropdownPlaceholder="Modules"
            textInputPlaceholder="Type tag here..."
            dropdownSearchPlaceholder="Search by module name or standardised code">
          </word-tags>
        </div>
      </div>
    </template>
    <template #footer>
      <button @click="updateMeeting" class="btn btn-success send-btn" :disabled="!validForm" aria-label="Update" title="Update">Update</button>
    </template>
  </k-modal>
</template>

<style>
.update-meetings-modal .k-word-tags > form > button {
  height: 38px;
}
</style>

<style scoped>
.meeting-form-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.meeting-form-container form {
  margin-left: -15px;
}
</style>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import KModal from '../../../../components/k-modal.vue';
import WordTags from '../../../components/word-tags.vue';

export default {
  components: {
    'k-modal': KModal,
    'word-tags': WordTags,
  },
  mixins: [ErrorMixin],
  props: {
    meeting: {
      type: Object,
    },
  },
  data() {
    return {
      newMeetingName: undefined,
      newMeetingDescription: undefined,
      newMeetingTags: [],
    };
  },
  watch: {
    meeting() {
      this.newMeetingName = this.meeting ? this.meeting.name : undefined;
      this.newMeetingDescription = this.meeting ? this.meeting.description : undefined;
      this.newMeetingTags = this.meeting ? this.meeting.tags || [] : [];
    },
  },
  computed: {
    show() {
      return Boolean(this.meeting);
    },
    eventNameEditing() {
      return this.newMeetingName ? this.newMeetingName : this.meeting.name;
    },
    validTitle() {
      return this.newMeetingName ? this.newMeetingName.trim().length > 0 : false;
    },
    validForm() {
      return Boolean(this.validTitle);
    },
  },
  methods: {

    updateMeeting() {
      const updatePayload = {
        name: this.newMeetingName,
        description: this.newMeetingDescription,
        tags: this.newMeetingTags,
      };
      this.$logger.info('Updating meeting', { meetingId: this.meeting.id, updatePayload });
      this.$http.put(`/api/curriculum/admin/meeting/${this.meeting.id}`, updatePayload).then(() => {
        this.$logger.info('Successfully updated meeting', { meetingId: this.meeting.id });
        this.$ktoast.success('Meeting updated', { goAway: 2000 });
        this.clearFields();
        this.$emit('update');
        this.$emit('close');
      }).catch(err => {
        this.showError(err);
        this.$logger.error('Error updating meeting', { meetingId: this.meeting.id, updatePayload }, err);
      });
    },

    clearFields() {
      this.newMeetingName = undefined;
      this.newMeetingDescription = undefined;
      this.newMeetingTags = [];
    },
  },
};
</script>
