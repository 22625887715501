<template>
  <div>
    <h1>Markdown Page Overview</h1>
    <asset-overview v-if="ready"
      asset-icon="fa-file-lines"
      :allow-asset-creation="false"
      :assets="pages"
      :kable-headers="kableHeaders"
      :kable-rows="kableRows"
      :show-archived-assets="showArchived"
      @tablecallback="tableCallback"
      @archive-toggle="updateArchiveToggle"
    ></asset-overview>
  </div>
</template>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import PageReadyMixin from '../../../../mixins/page-ready-mixin';
import AssetOverview from '../asset-overview.vue';
import ArchiveToastConfirmMixin from '../archive-toast-confirm-mixin';

export default {
  components: {
    AssetOverview,
  },

  mixins: [ErrorMixin, PageReadyMixin, ArchiveToastConfirmMixin],
  data() {
    return {
      ready: false,
      pages: [],
      showArchived: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getPages();
  },

  computed: {
    kableHeaders() {
      return {
        name: {
          name: 'Name',
          filterable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
        },
        repository: {
          name: 'Repository URL',
        },
        last_deployment: {
          name: 'Last Deployed',
          type: 'timestamp',
          sortable: true,
        },
        tags: {
          name: 'Tags',
          type: 'tags',
          filterable: true,
          sortable: true,
        },
        topics: {
          name: 'Topics',
          type: 'tags',
          filterable: true,
          sortable: true,
        },
        archive: {
          name: 'Archive',
          type: 'action',
          sortable: true,
        },
      };
    },

    kableRows() {
      return this.pages.map(page => ({
        name: {
          text: page.name,
          path: {
            name: 'curriculum_asset_details',
            params: {
              assetId: page.id,
              assetType: 'markdown_page',
            },
          },
        },
        description: page.description,
        last_deployment: page.last_deployment,
        repository: this.getLink(page.gitlab_repo_url),
        tags: {
          tags: page.tags,
          sortBy: page.tags ? [...page.tags].sort().join(' ') : '',
        },
        topics: {
          tags: page.topics,
          sortBy: page.topics ? [...page.topics].sort().join(' ') : '',
        },
        id: page.id,
        archive: {
          text: this.assetArchiveButton(page.is_active),
        },
        is_active: page.is_active,
      })).filter(p => (this.showArchived || p.is_active));
    },
  },

  methods: {
    updateArchiveToggle(val) {
      this.showArchived = val;
    },

    getLink(url) {
      if (url) {
        return `<a href="${url}" target="_blank">${url}</a>`;
      }
      return '';
    },

    getPages() {
      this.$logger.info('Getting pages');
      this.ready = false;
      this.$http.get('/api/curriculum/admin/markdown_page').then(result => {
        this.$logger.info('Got pages');
        this.pages = result.data.markdown_pages;
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error getting pages', undefined, err);
          this.showError(err);
        } else {
          this.$logger.warn('No pages found');
        }
      }).then(() => {
        this.ready = true;
      });
    },

    tableCallback(key, index, row, header) {
      if (header === 'archive') {
        this.tableArchiveCallback(row);
      }
    },

    archiveCallback(mpId) {
      this.$logger.info('Archiving markdown page', { mpId });
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/markdown_page/${mpId}/archive`)
        .then(() => {
          this.$logger.info('Markdown page has been archived', { mpId });
          this.$ktoast.success('Markdown page has been archived');
          this.getPages();
        }).catch(err => {
          this.$logger.error('Error archiving markdown page', { mpId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    unarchiveCallback(mpId) {
      this.$logger.info('Unarchiving markdown page', { mpId });
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/markdown_page/${mpId}/unarchive`)
        .then(() => {
          this.$logger.info('Markdown page has been unarchived', { mpId });
          this.$ktoast.success('Markdown page has been unarchived');
          this.getPages();
        }).catch(err => {
          this.$logger.error('Error unarchiving markdown page', { mpId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },
  },
};
</script>
