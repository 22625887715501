<template>
  <div v-if="ready" id="learner-progress">
    <div>
      <h1>Learner Progress</h1>
    </div>
    <k-dropdown class="dropdown dark" placeholder="Select"
      :showSearch="false"
      :options="segmentOptions"
      v-model="segments">
    </k-dropdown>
    <a v-if="this.activeFilters.length > 0" :href="selectedSegmentLogsEndpoint" download="activity-logs.csv">
      <k-tooltip text="Download logs for all completion actions by learners in the current segment - it may take a few seconds">
        <button class="btn btn-primary" @click="$logger.info('Downloaded learner progress data', { segments, filters: activeFilters }, true);">
          Download Activity Logs <i class="fas fa-download"></i>
        </button>
      </k-tooltip>
    </a>
    <div class="progress-container">
      <k-segment-filters
          v-model="activeFilters"
          :selectedData="selectedSegmentData">
      </k-segment-filters>
      <div class="panel panel-body content-container">
      <p class="info-text">
        <span class="fas fa-info-circle"></span>
        The data in this table reflects only <i class="highlight">activity in modules that the learner is expected to have completed in full</i>
        - i.e. those modules that have a completion date in the past.
      </p>
        <div class="selected-filters tags">
          <span class="alt tag-action" v-for="filter in activeFilters" :key="filter">
            {{ filter }} <button  @click="removeFilter(filter)" aria-label="Remove" title="Remove"><i class="fa-solid fa-xmark"></i></button>
          </span>
        </div>
        <k-table class="learner-progress-table" v-if="learnerProgress.length > 0"
            :rows="kableRows"
            :headers="kableHeaders"
            :defaultHeaders="visibleHeaders"
            :max="20"
            :hideable="true"
            :panel="false">
          </k-table>
        <empty-placeholder v-else title="No learners found" :info="noLearnersFound"></empty-placeholder>
      </div>
    </div>
  </div>
</template>

<style>
#learner-progress .panel {
  box-shadow: var(--box-shadow);
}

.learner-progress-table .k-table-main-container {
  overflow-x: unset;
  overflow-y: unset;
}
</style>

<style scoped>
.dropdown {
  margin-bottom: 25px;
  display: inline-block;
  min-width: 200px;
}

.progress-container {
  display: flex;
  gap: 25px;
}

.content-container {
  overflow-x: auto;
  flex: 1;
}

.selected-filters {
  flex-wrap: wrap;
}

</style>

<script>
import ErrorMixin from '../../mixins/error-mixins';
import TimeMixins from '../../mixins/time-mixins';
import formatId from '../../modules/format-user-id';
import KTable from '../../components/k-table.vue';
import KDropdown from '../../components/k-dropdown.vue';
import KTooltip from '../../components/k-tooltip.vue';
import KSegmentFilters from '../components/k-segment-filters.vue';
import EmptyPlaceholder from '../../components/empty-placeholder.vue';
import PageReadyMixin from '../../mixins/page-ready-mixin';

export default {
  components: {
    KTable,
    KDropdown,
    KTooltip,
    KSegmentFilters,
    EmptyPlaceholder,
  },

  mixins: [ErrorMixin, PageReadyMixin, TimeMixins],

  data() {
    return {
      aptemEmployers: [],
      aptemEmployersReady: false,
      programmes: [],
      programmesReady: false,
      learnerProgress: [],
      learnerProgressReady: false,
      coaches: [],
      coachesReady: false,
      mentors: [],
      mentorsReady: false,
      products: [],
      productsReady: false,
      activeFilters: [],
      segments: 'programme',
      segmentOptions: [
        { name: 'Programme', id: 'programme' },
        { name: 'Product', id: 'product' },
        { name: 'Aptem Employer', id: 'aptem_employer' },
        { name: 'Coach', id: 'coach' },
        { name: 'Data Mentor', id: 'data_mentor' },
      ],
      loadingFiltersFromRoute: false,
    };
  },

  watch: {
    segments() {
      if (!this.loadingFiltersFromRoute) {
        // Reset active filters if segment changes (unless we're loading filters from the route)
        this.activeFilters = [];
      }
    },
    activeFilters() {
      this.handleFilterUpdate();
    },
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgrammes();
    this.getCoaches();
    this.getDataMentors();
    this.getProducts();
    this.getAptemEmployers();
  },

  computed: {
    ready() {
      return this.programmesReady && this.aptemEmployersReady && this.coachesReady && this.mentorsReady && this.productsReady;
    },

    kableHeaders() {
      return {
        learner: {
          name: 'User ID',
          sortable: true,
        },
        name: {
          name: 'Name',
          type: 'url',
          filterable: true,
          sortable: true,
          hideable: false,
        },
        email: {
          name: 'Email',
          filterable: true,
          sortable: true,
        },
        latest_timestamp: {
          name: 'Last Activity',
          sortable: true,
          type: 'timestamp',
        },
        programmes: {
          name: 'Programmes',
          filterable: true,
          sortable: true,
        },
        attendance: {
          name: 'Attendance',
          sortable: true,
          type: 'percentage',
          options: {
            color: true,
            tooltip: true,
            tooltipText: 'Attendance to mandatory Events',
          },
        },
        programme_progress: {
          name: 'Progress',
          sortable: true,
          type: 'percentage',
          options: {
            color: true,
            tooltip: true,
            tooltipText: 'Progress % of Modules in selected programme that are currently due',
          },
        },
        assignments: {
          name: 'Assignments',
          sortable: true,
          type: 'percentage',
          options: {
            color: true,
            tooltip: true,
            tooltipText: 'Overall progress % of mandatory Assignments and Quizzes',
          },
        },
      };
    },

    visibleHeaders() {
      // Return the list of headers we want to see by default
      return ['name', 'latest_timestamp', 'programmes', 'attendance', 'programme_progress', 'assignments'];
    },

    kableRows() {
      return this.learnerProgress.map(val => ({
        learner: formatId(val.user_id),
        name: {
          text: val.user_name,
          path: {
            name: 'dashboard_students',
            params: {
              studentId: val.user_id,
            },
            query: {
              programmeId: val.programme_id,
            },
          },
        },
        email: val.email,
        latest_timestamp: val.last_mandatory_activity,
        programmes: val.programme,
        attendance: val.attendance,
        programme_progress: val.programme_progress,
        assignments: val.assignment_progress,
        ...val,
      }));
    },

    selectedSegmentDataIds() {
      return this.selectedSegmentData.filter(x => this.activeFilters.find(y => y === x.name)).map(z => z.id);
    },

    selectedSegmentEndpoint() {
      if (this.activeFilters.length < 1) {
        return undefined;
      }
      return `/api/stats/progress/${this.segments}?id=${this.selectedSegmentDataIds.join(',')}`;
    },

    selectedSegmentLogsEndpoint() {
      if (this.activeFilters.length < 1) {
        return undefined;
      }
      return `/api/stats/progress/${this.segments}/logs?format=csv&id=${this.selectedSegmentDataIds.join(',')}`;
    },

    selectedSegmentData() {
      if (this.segments === 'aptem_employer') {
        return this.aptemEmployers;
      }
      if (this.segments === 'coach') {
        return this.coaches;
      }
      if (this.segments === 'product') {
        return this.products;
      }
      if (this.segments === 'data_mentor') {
        return this.mentors;
      }
      return this.programmes;
    },

    noLearnersFound() {
      if (!this.activeFilters || this.activeFilters.length === 0) {
        return `Please select a ${this.segments}.`;
      }
      if (!this.learnerProgressReady) {
        return 'Loading...';
      }
      return `No learners were found for this ${this.segments}.`;
    },
  },

  methods: {
    loadFiltersFromRoute() {
      // Use loadingFiltersFromRoute boolean to suppress the route update when
      // handleFilterUpdate is triggered by the activeFilters watcher
      this.loadingFiltersFromRoute = true;
      if (this.$route.query.segments) {
        this.segments = this.$route.query.segments;
      }
      if (this.$route.query.activeFilters) {
        const ids = this.$route.query.activeFilters.split(',');
        this.activeFilters = this.selectedSegmentData.filter(x => ids.find(y => y === String(x.id))).map(z => z.name);
      }
      this.$nextTick(() => {
        // Wait for nextTick otherwise activeFilters watcher will trigger __after__ loadingFiltersFromRoute is set to false
        this.loadingFiltersFromRoute = false;
        this.getLearnerProgress();
      });
    },

    pageReadyCallback() {
      this.loadFiltersFromRoute();
    },

    getLearnerProgress() {
      if (this.activeFilters.length === 0) {
        this.$logger.info('No active filters selected');
        this.learnerProgress = [];
        return;
      }
      this.$Loading.start();
      this.learnerProgressReady = false;
      this.$logger.info('Getting learner progress');
      this.$http.get(this.selectedSegmentEndpoint)
        .then(result => {
          this.$logger.info('Got learner progress');
          this.learnerProgress = result.data;
        }).catch(err => {
          this.$logger.error('Error getting learner progress', undefined, err);
        }).then(() => {
          this.learnerProgressReady = true;
          this.$Loading.finish();
        });
    },

    getProgrammes() {
      this.programmesReady = false;
      this.$logger.info('Getting programmes');
      this.$http.get('/api/curriculum/programmes?dashboard=true')
        .then(result => {
          this.$logger.info('Got programmes');
          this.programmes = result.data.programmes;
        }).catch(err => {
          this.$logger.error('Error getting programmes', undefined, err);
        }).then(() => {
          this.programmesReady = true;
        });
    },

    getAptemEmployers() {
      this.aptemEmployersReady = false;
      this.$logger.info('Getting Aptem employers');
      this.$http.get('/api/profile/aptem-employers').then(res => {
        this.$logger.info('Successfully retrieved Aptem employers');
        this.aptemEmployers = res.data.map(x => ({
          id: x,
          name: x,
        }));
      }).catch(err => {
        this.$logger.error('There was an error while attempting to retrieve Aptem employers', undefined, err);
        this.showError(err);
      }).then(() => {
        this.aptemEmployersReady = true;
      });
    },

    getCoaches() {
      this.coachesReady = false;
      this.$logger.info('Getting coaches');
      this.$http.get('/api/profile/coach').then(res => {
        this.$logger.info('Successfully retrieved coaches');
        this.coaches = res.data.coaches;
      }).catch(err => {
        this.$logger.error('There was an error while attempting to retrieve coaches', undefined, err);
        this.showError(err);
      }).then(() => {
        this.coachesReady = true;
      });
    },

    getDataMentors() {
      this.mentorsReady = false;
      this.$logger.info('Getting data mentors');
      this.$http.get('/api/profile/data_mentor').then(res => {
        this.$logger.info('Successfully retrieved data mentors');
        this.mentors = res.data.data_mentors;
      }).catch(err => {
        this.$logger.error('There was an error while attempting to retrieve data mentors', undefined, err);
        this.showError(err);
      }).then(() => {
        this.mentorsReady = true;
      });
    },

    getProducts() {
      this.productsReady = false;
      this.$logger.info('Getting products');
      this.$http.get('/api/curriculum/products').then(res => {
        this.$logger.info('Successfully retrieved products');
        this.products = res.data;
      }).catch(err => {
        this.$logger.error('There was an error while attempting to retrieve products', undefined, err);
        this.showError(err);
      }).then(() => {
        this.productsReady = true;
      });
    },

    handleFilterUpdate() {
      if (!this.loadingFiltersFromRoute) {
        // Load filters into route query
        let res;
        if ((!this.selectedSegmentDataIds || this.selectedSegmentDataIds.length === 0)) {
          res = this.$router.replace({ query: { segments: this.segments } }); // remove query params
        } else {
          res = this.$router.replace({
            query: {
              segments: this.segments,
              activeFilters: this.selectedSegmentDataIds.join(','),
            },
          });
        }
        res.then(() => {
          this.getLearnerProgress();
        });
      }
    },

    removeFilter(filter) {
      this.activeFilters = this.activeFilters.filter(x => x !== filter);
    },
  },
};
</script>
