export default {
  computed: {
    pakMetaEndpoint() {
      // Endpoint for retrieving PAK metadata
      if (this.isDashboard && this.studentId) {
        return `/api/curriculum/pak/${this.modulePakId}/user/${this.studentId}`;
      }
      return `/api/curriculum/pak/${this.modulePakId}`;
    },

    pakContentEndpoint() {
      // Endpoint for retrieving PAK contents - /tree and /file_content endpoints
      if (this.isDashboard && this.studentId) {
        return `/api/curriculum/students/${this.studentId}/pak/${this.modulePakId}`;
      }
      return `/api/curriculum/pak/${this.modulePakId}`;
    },

    pakDownloadEndpoint() {
      if (this.isSolution) {
        // Endpoint for retrieving PAK solution contents - /tree and /file_content endpoints
        return `/api/curriculum/pak/${this.modulePakId}/solution`;
      }
      return this.pakContentEndpoint;
    },
  },
};
