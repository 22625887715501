<template>
  <k-modal class="update-slides-modal" v-if="slides"
    :show="show"
    :closeOnClickAway="false"
    @close="$emit('close')">
    <template #header>
      <h3><i class="fas fa-edit"></i>Edit Slides - {{slides.name}}</h3>
    </template>
    <template #body>
      <div class="slides-form-container col-md-7 col-xs-12">
        <fieldset class="form-entry">
          <label for="update-name-input">Title</label>
          <input id="update-name-input" v-model="newSlidesName" class="form-control" placeholder="Type Slides title here...">
        </fieldset>
        <fieldset class="form-entry">
          <label for="update-description-input">Description</label>
          <textarea v-model="newSlidesDescription"
            id="update-description-input"
            class="form-control"
            type="textarea"
            rows="3"
            placeholder="Briefly describe the slides">
          </textarea>
        </fieldset>
        <div>
          <word-tags v-model="newSlidesTags"
            dropdownPlaceholder="Modules"
            textInputPlaceholder="Type tag here..."
            dropdownSearchPlaceholder="Search by module name or standardised code">
          </word-tags>
        </div>
      </div>
      <div class="col-md-5 col-xs-12">
        <h3>Update Slides</h3>
        <dropfield @file="filesChange"
          ref="slidesUploadField"
          :customMessage="dropfieldMessage"
          :immediatePost="false"
          :disableDropfield="updateInProgress"
          accept="application/pdf"
          url=""
          id="k-update-slides-dropfield">
        </dropfield>
      </div>
    </template>
    <template #footer>
      <button @click="updateSlides" class="btn btn-success send-btn">Update</button>
    </template>
  </k-modal>
</template>

<style>
.update-slides-modal .modal-body {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

/* So the button doesn't grow to the same height as the dropdown container when open */
.update-slides-modal .k-word-tags > form > button {
  height: 38px;
}

.update-slides-modal .k-search-dropdown-adv-content {
  position: unset;
}

#k-update-slides-dropfield {
  padding-top: 15px;
}
</style>

<style scoped>
textarea {
  resize: vertical;
  max-height: 30vh;
  min-height: 70px;
}
</style>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import TimeMixin from '../../../../mixins/time-mixins';
import KModal from '../../../../components/k-modal.vue';
import WordTags from '../../../components/word-tags.vue';
import Dropfield from '../../../../components/dropfield.vue';

export default {
  components: {
    'k-modal': KModal,
    'word-tags': WordTags,
    dropfield: Dropfield,
  },
  mixins: [ErrorMixin, TimeMixin],
  props: {
    slides: {
      type: Object,
    },
  },
  data() {
    return {
      newSlidesName: undefined,
      newSlidesDescription: undefined,
      newSlidesTags: [],
      updateInProgress: false,
      dropfieldMessage: `<i class="fas fa-cloud-upload-alt"></i>
      Click or drag slides file here to update.`,
      file: undefined,
    };
  },
  watch: {
    slides() {
      if (this.slides) {
        this.newSlidesName = this.slides.name;
        this.newSlidesDescription = this.slides.description;
        this.newSlidesTags = this.slides.tags || [];
      } else {
        this.newSlidesName = undefined;
        this.newSlidesDescription = undefined;
        this.newSlidesTags = [];
      }
    },
    updateInProgress() {
      if (this.updateInProgress) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },
  computed: {
    show() {
      return Boolean(this.slides);
    },
    fileName() {
      if (this.file) {
        return this.file[0].name;
      }
      return this.slides.resource_link.split('/').pop();
    },
  },
  methods: {
    updateSlidesMeta() {
      const updatePayload = {
        file_name: this.fileName,
        name: this.newSlidesName,
        description: this.newSlidesDescription,
        tags: this.newSlidesTags,
      };
      this.$logger.info('Updating slides', { slidesId: this.slides.id, updatePayload });
      this.$http.put(`/api/curriculum/admin/slide_deck/${this.slides.id}`, updatePayload).then(() => {
        this.$logger.info('Successfully updated slides', { slidesId: this.slides.id });
        this.$ktoast.success('Slides updated');
        this.clearFields();
        this.$emit('update');
        this.$emit('close');
      }).catch(err => {
        this.showError(err);
        this.$logger.error('Error updating slides', { slidesId: this.slides.id, updatePayload }, err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },

    updateSlides() {
      this.updateInProgress = true;
      if (this.file) {
        const payload = {
          file_name: this.file[0].name,
          file_size: this.file[0].size,
          content_type: this.file[0].type,
        };
        this.$logger.info('Getting signed resumable upload URL');
        this.$http.post(`/api/curriculum/admin/slide_deck/${this.slides.id}/upload-url`, payload)
          .then(response => {
            this.$logger.info('Got signed resumable upload URL');
            const url = response.data.upload_url;
            this.$logger.info('Attempting file upload');
            // PUT request to upload using signed URL
            this.$http.put(url, this.file[0]).then(() => {
              this.$logger.info('File uploaded successfully');
              this.updateSlidesMeta();
            }).catch(err => {
              this.$logger.error('Error uploading slides with signed resumable upload URL', undefined, err);
              this.showError(err);
              this.updateInProgress = false;
            });
          }).catch(err => {
            this.$logger.error('Error getting signed resumable upload URL', undefined, err);
            this.showError(err);
            this.updateInProgress = false;
          });
      } else {
        this.updateSlidesMeta();
      }
    },

    filesChange(file) {
      this.$logger.info('Staged file for update', { file: file.name });
      this.file = file;
    },

    clearFields() {
      this.$refs.slidesUploadField.reset();
      this.newSlidesName = undefined;
      this.newSlidesDescription = undefined;
      this.newSlidesTags = [];
    },
  },
};
</script>
