<template>
  <k-panel title="Manage Modules" :hasContentToggle="false">
    <template #body>
      <div v-if="!modulesReady">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
      <div v-else>
        <manage-module
          v-model="modules"
          :disableCommit="updatingProgrammeModules"
          @reset="reset"
          @orderChanged="setModuleNumbers"
          @remove="removeModule"
          @cancel="cancelModuleUpdate"
          @editMode="toggleEditMode"
          @add="addModule"
          @saveChanges="updateProgrammeModules"
          @release-update="makeModuleUpdate"
        >
          <template #instructions>
            <p>Below is a list of modules for the <b>{{ programme.name }}</b> programme. To reorder the modules, simply drag them.</p>
            <p>To schedule a module for release, click on the module's card, set a release date and click the <i>Schedule Release</i> button. The module will become available to students
            at midnight (UTC) on the specified date.</p>
            <p>To withdraw a released module, click on the module's card and click <i>Withdraw</i>. The module release date will be unset and it will no longer be available to students.</p>
            <p>Note that no changes (reordering, scheduling a release or withdrawing) will be reflected on <b>EDUKATE</b> until you save your changes via the <i>Save Changes</i> button.</p>
          </template>
        </manage-module>
      </div>
    </template>
  </k-panel>
</template>

<style scoped>
.module-blueprint-controls {
  padding-bottom: 20px;
}
</style>

<script>
import ErrorMixin from '../../../mixins/error-mixins';
import TimeMixin from '../../../mixins/time-mixins';
import { sortObjectArray } from '../../../modules/sort-by-object-property';
import ManageModule from './abstract-manage-modules.vue';
import KPanel from '../../../components/k-panel.vue';

export default {
  components: {
    ManageModule,
    KPanel,
  },

  props: {
    programme: {
      type: Object,
    },
  },

  mixins: [ErrorMixin, TimeMixin],

  watch: {
    programme() {
      this.getModules();
    },
    hasEdits: {
      handler(edits) {
        this.$emit('changes', edits);
      },
      deep: true,
    },
    modules: {
      handler() {
        for (let i = 0; i < this.modules.length; i += 1) {
          this.modules[i].index = i;
        }
        if (this.modules.length === this.originalModules.length && !this.modules.every((v, i) => v.id === this.originalModules[i].id)) {
          this.$emit('changes', true);
        }
      },
      deep: true,
    },
    originalModules() {
      for (let i = 0; i < this.originalModules.length; i += 1) {
        this.originalModules[i].index = i;
      }
    },
  },

  data() {
    return {
      modules: [],
      originalModules: [],
      modulesReady: false,
      updatingProgrammeModules: false,
      showModuleBlueprintsModal: false,
      hasModulesRemoved: false,
      removedModules: [],
      modalOpen: false,
      indicesInEditMode: [],
    };
  },

  beforeMount() {
    this.getModules();
  },

  computed: {
    isDraggable() {
      return this.indicesInEditMode.length === 0 || !this.modalOpen;
    },
    hasModuleUpdatesPending() {
      return Boolean(this.modules.find(x => x.updates || x.addedModule));
    },
    hasEdits() {
      return this.hasModuleUpdatesPending || this.hasModulesRemoved;
    },
    disableDragging() {
      return (this.modalOpen || this.updatingProgrammeModules);
    },
  },

  methods: {
    modalClasses() {
      return this.isDraggable ? 'k-module-item draggable' : 'k-module-item';
    },
    toggleEditMode(i, editMode) {
      if (editMode) {
        this.indicesInEditMode.push(i);
      } else if (this.indicesInEditMode.indexOf(i) >= 0) {
        this.indicesInEditMode = this.indicesInEditMode.filter(item => item !== i);
      }
    },
    toggleShowModuleBlueprintsModal() {
      this.showModuleBlueprintsModal = !this.showModuleBlueprintsModal;
    },
    reset() {
      this.modules = JSON.parse(JSON.stringify(this.originalModules));
      this.hasModulesRemoved = false;
      this.removedModules = [];
    },
    removeModule(index) {
      this.removedModules = this.removedModules.concat(this.modules.splice(index, 1));
      this.hasModulesRemoved = true;
      this.setModuleNumbers();
    },
    makeModuleUpdate(index, updatePayload) {
      this.modules.splice(index, 1, {
        ...this.modules[index],
        ...{ updates: updatePayload },
      });
    },
    cancelModuleUpdate(index) {
      const revertedModule = JSON.parse(JSON.stringify(this.modules[index]));
      delete revertedModule.updates;
      this.modules.splice(index, 1, revertedModule);
    },
    setModuleNumbers() {
      const trackCounts = {};
      this.modules = this.modules.map(x => {
        if (!trackCounts[x.track]) {
          trackCounts[x.track] = 0;
        }
        trackCounts[x.track] += 1;
        return {
          ...x,
          number: trackCounts[x.track],
          numberUpdated: x.numberUpdated || trackCounts[x.track] !== x.number,
        };
      });
    },
    addModule(blueprint, track) {
      this.modules.push({
        ...blueprint,
        number: this.modules.filter(x => x.track === track).length + 1,
        programmeBadges: this.programme.badges,
        addedModule: true,
        track,
      });
      this.toggleShowModuleBlueprintsModal();
    },
    getModules() {
      this.$logger.info('Getting module blueprints for programme', { progId: this.programme.id });
      this.modulesReady = false;
      this.modules = [];
      this.$http.get(`/api/curriculum/admin/programmes/${this.programme.id}/modules`).then(res => {
        this.modules = sortObjectArray(res.data.modules, 'number').map(x => ({ programmeBadges: this.programme.badges, ...x }));
        this.originalModules = sortObjectArray(JSON.parse(JSON.stringify(res.data.modules)), 'number');
        this.$logger.info('Got module blueprints for programme', { progId: this.programme.id });
      }).catch(err => {
        this.$logger.error('Error getting module blueprints for programme', { progId: this.programme.id }, err);
        this.showError(err);
      }).then(() => {
        this.modulesReady = true;
      });
    },
    formatDateInPayload(val) {
      return val ? this.formatDate(val) : null;
    },
    createModule(module) {
      this.$logger.info('Adding module to programme', { progId: this.programme.id, moduleId: module.id });
      const payload = {
        programme_id: this.programme.id,
        blueprint_id: module.id,
        number: module.number,
        track: module.track,
        ...module.updates,
      };
      payload.release_date = this.formatDateInPayload(payload.release_date);
      payload.expected_completion_date = this.formatDateInPayload(payload.expected_completion_date);
      return this.$http.post('/api/curriculum/modules', payload);
    },
    deleteModule(module) {
      this.$logger.info('Deleting module from programme', { progId: this.programme.id, moduleId: module.id });
      return this.$http.delete(`/api/curriculum/modules/${module.id}`);
    },
    updateExistingModule(module) {
      const payload = { number: module.number, ...module.updates };
      // Don't format release / completion dates if they're not in the payload - otherwise they
      // can be set to null and overwrite existing dates when re-ordering modules
      if ('release_date' in payload) {
        payload.release_date = this.formatDateInPayload(payload.release_date);
      }
      if ('expected_completion_date' in payload) {
        payload.expected_completion_date = this.formatDateInPayload(payload.expected_completion_date);
      }
      this.$logger.info('Updating released module in programme', { progId: this.programme.id, moduleId: module.id });
      return this.$http.put(`/api/curriculum/modules/${module.id}`, payload);
    },
    updateProgrammeModules() {
      this.setModuleNumbers();
      this.updatingProgrammeModules = true;
      const modules = this.modules.filter(x => x.numberUpdated || x.updates || x.addedModule);
      const calls = [];
      this.$logger.info('Updating modules for programme', { progId: this.programme.id, modules });
      for (let i = 0; i < modules.length; i++) {
        if (modules[i].addedModule) {
          calls.push(this.createModule(modules[i]));
        } else {
          calls.push(this.updateExistingModule(modules[i]));
        }
      }

      for (let i = 0; i < this.removedModules.length; i++) {
        calls.push(this.deleteModule(this.removedModules[i]));
      }

      Promise.all(calls).then(() => {
        this.$logger.info('Successfully updated modules in programme', { progId: this.programme.id, modules }, true);
        this.$ktoast.success('Success');
        // A watch on programme will update the modules
        this.$emit('update-programme');
      }).catch(err => {
        this.$logger.error('Error updating modules in programme', { progId: this.programme.id, modules });
        this.showError(err);
      }).then(() => {
        this.removedModules = [];
        this.updatingProgrammeModules = false;
      });
    },
  },
};
</script>
