<template>
  <k-modal :show="show" @close="$emit('close')">
    <template #header>
      <h2>Select a Badge</h2>
      <k-text-search class="filter-input" v-model="filterText"></k-text-search>
    </template>
    <template #body>
      <badge-list v-if="badgesReady"
        :achievements="sortedFilteredBadges"
        :interact="true"
        @achievement-click="choose($event)"
      ></badge-list>
      <i v-else class="fas fa-spinner fa-spin"></i>
    </template>
  </k-modal>
</template>

<script>
import KModal from '../../components/k-modal.vue';
import BadgeList from './achievement-list.vue';
import { sortObjectArray } from '../../modules/sort-by-object-property';
import KTextSearch from '../../components/k-text-search.vue';
import stringMatch from '../../modules/string-match';
import BadgesMixins from '../../mixins/badges-mixins';

export default {
  components: {
    KTextSearch,
    KModal,
    BadgeList,
  },
  mixins: [BadgesMixins],
  props: {
    show: Boolean,
    specificBadges: {
      type: Array,
      default: () => undefined,
    },
  },

  data() {
    return {
      badges: [],
      badgesReady: false,
      filterText: '',
    };
  },

  beforeMount() {
    if (!this.specificBadges) {
      this.getBadges();
    } else {
      this.badges = this.specificBadges;
      this.badgesReady = true;
    }
  },

  watch: {
    specificBadges() {
      if (this.specificBadges) {
        this.badges = this.specificBadges;
      }
    },
  },

  computed: {
    sortedFilteredBadges() {
      const badgesFiltered = this.badges.filter(
        b => stringMatch(b.name, this.filterText, true)
          || stringMatch(b.description, this.filterText, true),
      );
      return sortObjectArray(badgesFiltered.slice(), 'created_at', true);
    },
  },

  methods: {
    choose(badge) {
      this.$emit('choose', badge);
      this.$emit('close');
    },
  },
};
</script>
