<template>
  <div
    v-if="ready"
    id="achievement-overview"
  >
    <k-panel
      title="Create"
      :duotone="false"
      icon="fa-certificate"
      :show-content="showCreateAchievement"
      @toggle="showCreateAchievement = $event"
    >
      <template #body>
        <create-achievement
          v-if="showCreateAchievement"
          :achievement-type="achievementType"
          @achievement-created="getAchievements()"
        />
      </template>
    </k-panel>
    <k-panel
      title="Current"
      :duotone="false"
      icon="fa-certificate"
      :has-content-toggle="false"
    >
      <template #body>
        <k-table
          @clicked="tableCallback"
          :headers="kableHeaders"
          :rows="kableRows"
          :hideable="true"
          :panel="false"
          :max="20"
        />
      </template>
    </k-panel>
    <update-achievement
      @update="getAchievements"
      :achievement="achievementToUpdate"
      :achievement-type="achievementType"
      @close="closeEditModal"
    />
    <preview-achievement
      :achievement-id="previewAchievementId"
      :achievement-type="achievementType"
      @close="closePreviewModal"
    />
  </div>
</template>

<style>
#achievement-overview img.image-preview {
  height: auto;
  width: 100px;
}

#achievement-overview table tbody tr td {
  vertical-align: middle;
}

#achievement-overview table tbody tr td p.not-in-use {
  color: var(--kate-danger);
  text-align: center;
}

#achievement-overview table tbody tr td p.in-use {
  color: var(--kate-success);
  text-align: center;
}
</style>

<style scoped>
section h2 {
  margin-bottom: 25px;
}

.panel-body > .panel {
  margin-bottom: 0;
}

.create-new > .panel-default {
  padding: 0;
}

</style>

<script>
import ErrorMixin from '../../mixins/error-mixins';
import TimeMixin from '../../mixins/time-mixins';
import PageReadyMixin from '../../mixins/page-ready-mixin';
import getIcon from '../../modules/kate-table-icons';
import { sortObjectArray } from '../../modules/sort-by-object-property';
import { GOOGLE_CLOUD_STORAGE } from '../../constants';
import KPanel from '../../components/k-panel.vue';
import CreateAchievement from './create-achievement.vue';
import KTable from '../../components/k-table.vue';
import UpdateAchievement from './update-achievement.vue';
import PreviewAchievement from './preview-achievement.vue';

export default {
  components: {
    CreateAchievement,
    KTable,
    UpdateAchievement,
    PreviewAchievement,
    KPanel,
  },
  mixins: [ErrorMixin, TimeMixin, PageReadyMixin],

  beforeMount() {
    this.getAchievements();
  },

  props: {
    achievementType: {
      type: String,
      default: 'badge',
    },
  },

  data() {
    return {
      achievements: [],
      achievementsReady: false,
      showCreateAchievement: false,
      achievementToUpdate: undefined,
      previewAchievementId: undefined,
      showPreviewModal: false,
      confirmDeletionToast: undefined,
    };
  },

  computed: {
    ready() {
      return this.achievementsReady;
    },

    sortedAchievements() {
      return sortObjectArray(this.achievements.slice(), 'created_at', true);
    },

    achievementPretty() {
      return this.achievementType === 'badge' ? 'Badge' : 'Certificate';
    },

    achievementEndpoint() {
      return `/api/curriculum/${this.achievementType}`;
    },

    kableHeaders() {
      return {
        edit: {
          name: 'Edit',
          type: 'action',
        },
        preview: {
          name: 'Preview',
          type: 'action',
        },
        name: {
          name: 'Name',
          filterable: true,
          sortable: true,
        },
        description: {
          name: 'Description',
        },
        date_uploaded: {
          name: 'Uploaded',
          sortable: true,
          type: 'timestamp',
        },
        in_use: {
          name: 'In Use',
          type: 'boolean',
          options: {
            true: '<p class="in-use"><i class="fa-solid fa-check"></i></p>',
            false: '<p class="not-in-use"><i class="fa-solid fa-xmark"></i></p>',
          },
        },
        delete: {
          name: 'Delete',
          type: 'action',
        },
      };
    },

    kableRows() {
      return this.sortedAchievements.map((val, index) => ({
        date_uploaded: val.created_at,
        ...val,
        edit: {
          key: index,
          text: getIcon({ icon: 'edit' }),
        },
        preview: {
          key: index,
          text: val.resource_link ? `<img class="image-preview" src="${GOOGLE_CLOUD_STORAGE}${val.resource_link}" alt="Achievement badge"/>` : '<i class="fas fa-certificate"></i>',
        },
        name: val.name,
        description: val.description,
        in_use: this.usedInProgrammeOrBlueprint(val),
        delete: {
          key: index,
          text: getIcon({ icon: 'trash' }),
        },
      }));
    },
  },

  methods: {

    getAchievements() {
      this.$logger.info(`Getting ${this.achievementType}s`);
      this.$Loading.start();
      this.achievementsReady = false;
      this.$http.get(this.achievementEndpoint)
        .then(result => {
          this.achievements = this.achievementType === 'badge' ? result.data.badges : result.data.certificates;
        }).catch(err => {
          if (!this.$http.isWarning(err)) {
            this.$logger.error(`Error getting ${this.achievementType}`, undefined, err);
            this.showError(err);
          } else {
            this.$logger.warn(`No ${this.achievementType} found`);
            this.achievements = [];
          }
        }).then(() => {
          this.achievementsReady = true;
          this.$Loading.finish();
        });
    },

    deleteAchievement(id) {
      this.$logger.info(`Deleting ${this.achievementType}`, { id });
      this.$Loading.start();
      this.$http.delete(`${this.achievementEndpoint}/${id}`)
        .then(() => {
          this.$logger.info(`${this.achievementPretty} deleted`, { id });
          this.$ktoast.success(`${this.achievementPretty} has been deleted`, { goAway: 4000 });
          this.getAchievements();
        }).catch(err => {
          this.handleDeletionError(id, err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    handleDeletionError(id, err) {
      this.$logger.autowarn(`Error deleting ${this.achievementType}`, { id }, err);
      const achievement = this.getProgrammeAndBlueprintsFromId(id);
      let message = 'This achievement is in use in the following programmes/blueprints and cannot be deleted';
      if (achievement?.programmes) {
        message += `<br/>Programmes: ${achievement.programmes.join(',<br/> ')}<br/>`;
        this.$ktoast.warning(message, { goAway: 5000 });
      } else if (achievement?.programmeBlueprints) {
        message += `<br/>Blueprints: ${achievement.programmeBlueprints.join(',<br/> ')}`;
        this.$ktoast.warning(message, { goAway: 5000 });
      } else {
        this.showError(err);
      }
    },

    tableCallback(key, index, row, header) {
      const achievement = this.achievements.find(x => x.id === row.id);
      if (header === 'edit') {
        if (achievement && typeof achievement === 'object') {
          this.achievementToUpdate = JSON.parse(JSON.stringify(achievement));
        } else {
          this.achievementToUpdate = undefined;
        }
      }
      if (header === 'preview') {
        this.previewAchievementId = row.id;
      }
      if (header === 'delete') {
        this.confirmDeletion(row.id);
      }
    },

    confirmDeletion(id) {
      this.$ktoast.confirmToast(
        `You are about to delete this ${this.achievementType}?`,
        'warning',
        () => this.deleteAchievement(id),
      );
    },

    closePreviewModal() {
      this.previewAchievementId = undefined;
    },

    closeEditModal() {
      this.achievementToUpdate = undefined;
    },

    usedInProgrammeOrBlueprint(achievement) {
      return achievement.programmes?.length > 0 || achievement.programme_blueprints?.length > 0;
    },

    getProgrammeAndBlueprintsFromId(id) {
      const achievement = this.achievements.find(x => x.id === id);
      if (achievement) {
        return {
          programmes: achievement.programmes?.map(p => p.programme_name) || null,
          programmeBlueprints: achievement.programme_blueprints?.map(pb => pb.programme_bp_name) || null,
        };
      }
      return undefined;
    },
  },
};
</script>
