<template>
  <div class="row">
    <div class="video-form-container col-md-7 col-xs-12">
      <h3>Details</h3>
      <form class="video-details">
        <fieldset class="form-group form-entry col-md-8">
          <label for="video-title-input">Title
            <input required
              id="video-title-input"
              type="text"
              class="form-control"
              placeholder="Type title"
              v-model="videoTitle"
              :disabled="isUploading">
            <small>Please keep the title generic and not programme specific</small>
          </label>
        </fieldset>
        <fieldset class="form-group form-entry col-md-4">
          <k-date-picker
            inputId="date-recorded-input"
            v-model="recordedDate"
            label="Date Recorded"></k-date-picker>
        </fieldset>
        <fieldset class="form-group form-entry col-md-12 video-description">
          <label for="video-description-input">Description
            <textarea id="video-description-input"
              class="form-control"
              placeholder="Enter description here..."
              rows="3"
              v-model="videoDescription"
              :disabled="isUploading">
            </textarea>
          </label>
        </fieldset>
      </form>
      <word-tags v-model="videoTags" textInputPlaceholder="Add a tag"></word-tags>
    </div>
    <div class="col-md-5 col-xs-12">
      <h3>Upload</h3>
      <dropfield @file="filesChange"
        ref="videoUploadField"
        :customMessage="dropfieldMessage"
        :immediatePost="false"
        :disableDropfield="isUploading"
        accept="video/mp4,video/x-m4v,video/*"
        url=""
        id="k-add-videos-dropfield">
      </dropfield>
    </div>
    <div class="col-md-12">
      <button class="submit-button btn btn-success save-button"
        :disabled="!validForm"
        @click="uploadVideo">
        <i :class="isUploading ? 'fa fa-spinner fa-spin' : 'fas fa-upload'"></i>
        {{ isUploading ? 'Uploading...' : 'Upload Video' }}
      </button>
      <br/>
      <small v-if="isUploading">
        <i>This may take a few minutes. Please do not navigate away from this page.</i>
      </small>
    </div>
  </div>
</template>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
}

section h2 {
  margin-top: 0;
  margin-bottom: 25px;
}

.form-group small {
  font-style: italic;
}

.video-form-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.video-form-container form {
  margin-left: -15px;
}

.video-description label,
.video-details label {
  width: 100%;
}

#k-add-videos-dropfield,
.video-details {
  padding-top: 15px;
}

.video-preview-modal figure {
  text-align: center;
}

.preview-video video {
  max-height: 70vh;
  width: auto;
}
</style>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import TimeMixin from '../../../../mixins/time-mixins';
import Dropfield from '../../../../components/dropfield.vue';
import KDatePicker from '../../../../components/k-date-picker.vue';
import WordTags from '../../../components/word-tags.vue';

export default {
  components: {
    dropfield: Dropfield,
    'k-date-picker': KDatePicker,
    'word-tags': WordTags,
  },

  mixins: [ErrorMixin, TimeMixin],

  data() {
    return {
      videoTitle: '',
      recordedDate: null,
      videoDescription: '',
      videoTags: [],
      file: undefined,
      dropfieldMessage: `<i class="fas fa-cloud-upload-alt"></i>
      Click or drag video file here to upload.`,
      isUploading: false,
    };
  },

  watch: {
    isUploading() {
      if (this.isUploading) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },

  computed: {
    validTitle() {
      return this.videoTitle.trim().length > 0;
    },

    validForm() {
      return Boolean(this.validTitle && this.file && this.recordedDate);
    },
  },

  methods: {
    startProcessing(videoId) {
      this.$logger.info('Starting video procssing', { videoId });
      return this.$http.post(`/api/curriculum/admin/video/${videoId}/processing/start`).then(() => {
        this.$logger.info('Successfully started video processing', { videoId });
      }).catch(err => {
        this.$logger.error('Error starting video processing', { videoId, file: this.file.name }, err);
        throw err;
      });
    },
    updateVideoMeta(videoId) {
      const videoMeta = {
        file_name: this.file[0].name,
        name: this.videoTitle,
        description: this.videoDescription,
        tags: this.videoTags,
        date_recorded: this.formatDate(this.recordedDate),
      };
      this.$logger.info('Updating video database entry with resource link', { videoMeta, file: this.file.name });
      return this.$http.put(`/api/curriculum/admin/video/${videoId}`, videoMeta).then(() => {
        this.$logger.info('Added video to the database succesfully', { videoId });
        this.$ktoast.success('Video uploaded');
        this.clearFields();
        this.$emit('video-uploaded');
      }).catch(err => {
        this.$logger.error('Error updating video resource link', { videoMeta, file: this.file.name }, err);
        throw err;
      });
    },
    uploadVideoToUrl(videoId, url) {
      this.$logger.info('Attempting file upload');
      // PUT request to upload using signed URL
      return this.$http.put(url, this.file[0]).then(() => {
        this.$logger.info('File uploaded successfully', { videoId });
      }).catch(err => {
        this.$logger.error('Error uploading video with signed resumable upload URL', { videoId }, err);
        throw err;
      });
    },
    getUploadUrl(videoId) {
      const payload = {
        file_name: this.file[0].name,
        file_size: this.file[0].size,
        content_type: this.file[0].type,
      };
      // POST request to initiate resumable upload and return upload URL
      this.$logger.info('Getting signed resumable upload URL');
      return this.$http.post(`/api/curriculum/admin/video/${videoId}/upload-url`, payload)
        .then(response => {
          this.$logger.info('Got signed resumable upload URL');
          return response.data.upload_url;
        }).catch(err => {
          this.$logger.error('Error getting signed resumable upload URL', undefined, err);
          throw err;
        });
    },
    addVideoToDB() {
      const videoMeta = {
        name: this.videoTitle,
        description: this.videoDescription,
        tags: this.videoTags,
        date_recorded: this.formatDate(this.recordedDate),
      };
      this.$logger.info('Adding video to database', { videoMeta });
      return this.$http.post('/api/curriculum/admin/video', videoMeta).then(res => {
        this.$logger.info('Added video to the database succesfully', { video_id: res.data.video_id });
        return res.data.video_id;
      }).catch(err => {
        this.$logger.error('Error writing video meta to database', { videoMeta }, err);
        throw err;
      });
    },
    async uploadVideo() {
      this.isUploading = true;
      try {
        const videoId = await this.addVideoToDB();
        const url = await this.getUploadUrl(videoId);

        await this.uploadVideoToUrl(videoId, url);
        await this.updateVideoMeta(videoId);
        await this.startProcessing(videoId);
      } catch (err) {
        this.showError(err);
      }
      this.isUploading = false;
    },

    filesChange(file) {
      this.$logger.info('Staged file for upload', { file: file.name });
      this.file = file;
    },

    clearFields() {
      this.$refs.videoUploadField.reset();
      this.videoTitle = '';
      this.videoDescription = '';
      this.recordedDate = null;
      this.videoTags = [];
    },
  },
};
</script>
