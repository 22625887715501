/* eslint no-param-reassign: "off" */
import { reactive } from 'vue';

const KACHE_KEYSPACE_KEY = 'k-lscache-keyspace';

const KACHE_PREFIX = 'kache-';

class Kache {
  constructor() {
    this.keySpace = Kache.loadKeyspace();
    this.clearOldKeys();
  }

  /*
   * Sets an item
   * @param {String} key object is stored under
   * @param {*} Object to store
   * @param {number} [30] seconds before expiry
   */
  set(key, payload, seconds = 30) {
    const fullKey = Kache.generateFullKey(key);
    const cacheEntry = Kache.generateCacheEntry(payload, seconds);
    this.addToStorage(fullKey, cacheEntry);
  }

  /*
   * Gets an item
   * @param {String} key the object is stored under
   * @return {(Object|null)} Either the object if key exists AND not expired, or null
   */
  get(key) {
    const fullKey = Kache.generateFullKey(key);
    const entry = Kache.loadFromStorage(fullKey);
    // key doesn't exist
    if (!entry) {
      return null;
    }

    const now = Date.now();
    // key is expired
    if (entry.expiry <= now) {
      this.removeFromStorage(fullKey);
      return null;
    }

    return entry.payload;
  }

  /*
   * Clears expired keys by looping through
   * the keyspace and remove them
   */
  clearOldKeys() {
    const now = Date.now();
    let entry;
    const keys = Array.from(this.keySpace);
    for (let i = 0; i < keys.length; i++) {
      entry = Kache.loadFromStorage(keys[i]);
      if (entry === null || entry.expiry <= now) {
        this.removeFromStorage(keys[i]);
      }
    }
  }

  /*
   * Adds key to keyspace
   * @param {String} the FULL key
   */
  addToKeySpace(key) {
    if (this.keySpace.indexOf(key) === -1) {
      this.keySpace.push(key);
      Kache.dumpToStorage(KACHE_KEYSPACE_KEY, this.keySpace);
    }
  }

  removeFromKeySpace(key) {
    const index = this.keySpace.indexOf(key);
    if (index !== -1) {
      this.keySpace.splice(index, 1);
      Kache.dumpToStorage(KACHE_KEYSPACE_KEY, this.keySpace);
    }
  }

  static generateCacheEntry(payload, seconds) {
    const now = Date.now();
    const expiry = now + (seconds * 1000);
    return { payload, expiry };
  }

  static generateFullKey(key) {
    return `${KACHE_PREFIX}${key}`;
  }

  static loadKeyspace() {
    return Kache.loadFromStorage(KACHE_KEYSPACE_KEY) || [];
  }

  addToStorage(key, payload) {
    this.addToKeySpace(key);
    Kache.dumpToStorage(key, payload);
  }

  /**
   * Dump payload to localstorage
   * @param {String} key the key where it will be stored (Preferably component name
   * @param {Object} payload The object to store in localStorage
   */
  static dumpToStorage(key, payload) {
    localStorage.setItem(key, JSON.stringify(payload));
  }

  /**
   * Load object from storage
   * @param {String} Key the value is stored under
   * @return {(Object|undefined)} Either the object if key exists, or undefined
   */
  static loadFromStorage(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  /*
   * Remove item from localStorage
   * @param {String} key the value is stored under
   */
  removeFromStorage(key) {
    this.removeFromKeySpace(key);
    localStorage.removeItem(key);
  }

  removeFromStorageFullCacheKey(key) {
    this.removeFromStorage(Kache.generateFullKey(key));
  }
}

export default {
  install(app) {
    const kache = new Kache();
    app.config.globalProperties.$cache = reactive(kache);
  },
};
