<template>
  <div class="empty-placeholder-content">
    <i :class="faIcon"></i>
    <h1>{{ title }}</h1>
    <p v-html="info"></p>
  </div>
</template>

<style scoped>
.empty-placeholder-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: var(--kate-type-primary);
  margin: 0 auto;
  width: 90%;
  text-align: center;
}

.empty-placeholder-content i,
.empty-placeholder-content h1 {
  color: var(--kate-type-primary);
  text-align: center !important;
}

.empty-placeholder-content i {
  flex: 1 1 100%;
  font-size: 8em;
}

.empty-placeholder-content h1 {
  flex: 1 1 100%;
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'No Feedback',
    },
    info: {
      type: String,
      default: 'Make a submission to generate feedback',
    },
    faIcon: {
      type: String,
      default: 'fas fa-file-code',
    },
  },
};
</script>
