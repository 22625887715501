<template>
  <footer
    id="footer"
    :class="[footerClass, sidebarClass]"
  >
    <div class="row">
      <div class="col-md-12 footer-bottom">
        <div class="footer-copyright">
          &copy; Copyright {{ new Date().getFullYear() }} by <a target="_blank" href="http://edukate.ai/">Cambridge Spark Ltd</a>
        </div>
        <div class="footer-tncs">
          | <router-link :to="{ name: 'policy_page', params:  { policyType: 'terms_and_conditions' } }">Terms</router-link>
          | <router-link :to="{ name: 'policy_page', params:  { policyType: 'privacy_policy' } }">Privacy Policy</router-link>
          | <router-link :to="{ name: 'policy_page', params:  { policyType: 'cookies_policy' } }">Cookies Policy</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
@media (max-width: 767px) {
  #footer.login-footer {
    background-color: transparent;
  }

  #footer.sidebar-open {
    padding-left: 0;
  }
}
</style>

<script>
import SidebarMixin from '../mixins/sidebar-mixin';

export default {
  mixins: [SidebarMixin],

  computed: {
    footerClass() {
      if (this.$route.name === 'login_page' || this.$route.name === 'login_gl_page') {
        return 'login-footer';
      }
      return '';
    },
  },
};
</script>
