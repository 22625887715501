<template>
  <k-panel title="New"
    icon='fa-user-plus'
    :showContent="showContent"
    @toggle="showContent = $event"
  >
    <template #body>
      <div class="k-register-stepper-panel ">
        <k-stepper v-model="currentPage"
          :steps="steps"
          :clickable="true"
          direction="unset"
          :enable-active="getCurrentStep"
          :tooltip="false">
        </k-stepper>
      </div>
      <add-users v-if="isAddUserStage" :base-users="users" :programme="programme" @useradded="addUsers"></add-users>
      <create-accounts v-if="isReviewStage" :users="users" :programme="programme" @finish="finishUp"></create-accounts>
      <div class="k-register-finish-panel" v-if="isFinished">
        <i class="fas fa-check"></i>
        <div>
          {{ pluralize('User', usersCreated.length, true) }} added
        </div>
      </div>
    </template>
  </k-panel>
</template>

<style>
.user-steps .k-step.active .k-stepper-divider {
  background-image: var(--right-gradient);
  height: 6px;
  position: absolute;
  border: 0;
  top: 25%;
}

.user-steps .k-step.active .k-stepper-circle {
  background-color: var(--kate-assignment);
  color: var(--kate-type-dark);
}

.user-steps .k-step.active .k-stepper-label {
  color: var(--kate-assignment);
}

</style>

<style scoped>
.k-register-finish-panel {
  color: var(--kate-success);
  min-height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: xx-large;
}

.k-register-finish-panel i {
  margin-right: 15px;
}

.k-register-stepper-panel {
  text-align: center;
}
</style>

<script>
import KPanel from '../../../components/k-panel.vue';
import KStepper from '../../../components/k-stepper.vue';
import AddUsers from './add-users.vue';
import CreateAccounts from './create-accounts.vue';
import PluralMixin from '../../../mixins/plural-mixin';

const ADD_USER_PAGE = 0;
const REVIEW_USER_PAGE = 1;
const FINISHED_PAGE = 2;

export default {
  components: {
    KPanel,
    KStepper,
    AddUsers,
    CreateAccounts,
  },

  mixins: [PluralMixin],

  props: {
    programme: {
      type: Object,
    },
  },

  data() {
    return {
      currentPage: 0,
      users: [],
      usersCreated: [],
      showContent: false,
    };
  },

  computed: {
    isAddUserStage() {
      return this.currentPage === ADD_USER_PAGE;
    },
    isReviewStage() {
      return this.currentPage === REVIEW_USER_PAGE;
    },
    isFinished() {
      return this.currentPage === FINISHED_PAGE;
    },
    steps() {
      return [
        { icon: 'fas fa-user-plus', name: 'Add Users' },
        { icon: 'fas fa-users', name: 'Create Accounts' },
      ];
    },
    getCurrentStep() {
      return this.currentPage === ADD_USER_PAGE || this.currentPage === REVIEW_USER_PAGE;
    },
  },

  methods: {
    finishUp(users) {
      this.usersCreated = users;
      this.currentPage = FINISHED_PAGE;
      this.$emit('update');
    },
    addUsers(users) {
      this.users = users.map(u => ({ ...u, groupTags: { tags: u.groups } }));
      this.currentPage = REVIEW_USER_PAGE;
    },
  },
};
</script>
