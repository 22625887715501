<template>
  <div class="quiz-question panel row">
    <div class="question-placement-buttons">
      <k-tooltip text="Move question up" text-align="center">
        <button @click="moveUp" title="Move question up" class="btn btn-outlined">
          <span class="fas fa-angle-up"></span>
        </button>
      </k-tooltip>
      <k-tooltip text="Move question down" text-align="center">
        <button @click="moveDown" title="Move question down" class="btn btn-outlined">
          <span class="fas fa-angle-down"></span>
        </button>
      </k-tooltip>
    </div>

    <div class="quiz-question-container">
      <div class="quiz-question-header">
        <h3>Question {{number}}.</h3>
        <k-tooltip text="Remove question" text-align="center">
          <button title="Remove question" @click="removeQuestion" class="btn btn-danger">
            <span class="fas fa-trash-alt"></span>
          </button>
        </k-tooltip>
      </div>
      <hr>
      <div class="detail-form col-md-6 col-xs-12">
        <k-text-editor
          :id="`questionTextEditor-${$.uid}`"
          v-model="question"
          @pasteResources="pasteResources"
        ></k-text-editor>

      </div>
      <fieldset class="detail-form form-entry col-md-6 col-xs-12">
        <label class="hint-label" for="quiz-hint-input">Hint
          <textarea
            id="quiz-hint-input"
            class="form-control"
            rows="3"
            placeholder="A short hint for students if they're stuck"
            v-model="hint">
          </textarea>
        </label>
      </fieldset>
      <div class="col-md-12 " :style="{ 'margin-bottom': '12px' }"><!-- // jscpd:ignore-start -->
        <button title="Upload resources" class="btn btn-primary" @click="openUploadDialog">
          Upload Resources
          <i title="Upload resources" class="fas fa-upload clickable"></i>
        </button>
        <!-- eslint-disable-next-line label-has-for -->
        <input type="file"
          id="upload-file-input"
          name="files"
          style="display: none;"
          ref="filesInput"
          :accept="acceptedResources"
          @change="filesChange($event.target.files)" multiple>
        <k-file-list :files="stagedFiles" @remove-file="removeStagedFile"></k-file-list>
        <k-file-list :files="existingResources" @remove-file="removeResource"></k-file-list>
      </div><!-- // jscpd:ignore-end -->
      <div class="col-md-12">
        <quiz-answer-list v-model="answers"></quiz-answer-list>
      </div>

    </div>

  </div>
</template>

<style scoped>
.quiz-question.panel {
  padding: 15px;
  display: flexbox;
  display: flex;
  flex-wrap: nowrap;
}

.quiz-question-header {
  display: flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 10px 15px;
}

.quiz-question-header + hr {
  margin: 15px;
  border-color: var(--kate-type-alt-dark);
}

.quiz-question-container {
  flex: 1;
}

.detail-form {
  margin-bottom: 5px;
}

.hint-label {
  width: 100%;
}

.question-placement-buttons {
  display: flexbox;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  border-right: 1px solid var(--border-secondary);
  padding-right: 15px;
}

textarea {
  resize: vertical;
  min-height: 30px;
}

button i {
  margin-left: 5px;
}

@media (max-width: 991px) {
  .quiz-question.panel {
    display: block;
  }

  .question-placement-buttons {
    flex-direction: row;
    padding: 0;
    border: 0;
  }

  .quiz-question-container {
    display: flex;
    flex-direction: column;
  }
}
</style>

<script>
import QuizAnswerList from './quiz-answer-list.vue';
import KFileList from '../../../../../components/k-file-list.vue';
import KTooltip from '../../../../../components/k-tooltip.vue';
import KTextEditor from '../../../../../components/k-text-editor.vue';

const ACCEPTED_RESOURCES = 'image/jpeg,image/png';

export default {
  components: {
    'quiz-answer-list': QuizAnswerList,
    'k-file-list': KFileList,
    'k-text-editor': KTextEditor,
    'k-tooltip': KTooltip,
  },

  props: {
    modelValue: {
      type: Object,
    },
  },

  data() {
    return {
      question: this.modelValue.question,
      answers: this.modelValue.answers,
      number: this.modelValue.number,
      hint: this.modelValue.hint,
      resources: this.modelValue.resources,
      stagedFiles: this.modelValue.stagedFiles,
    };
  },

  watch: {
    modelValue() {
      this.question = this.modelValue.question;
      this.answers = this.modelValue.answers;
      this.number = this.modelValue.number;
      this.hint = this.modelValue.hint;
      this.resources = this.modelValue.resources;
      this.stagedFiles = this.modelValue.stagedFiles;
    },
    quizQuestion() {
      this.$emit('update:modelValue', Object.assign(this.modelValue, this.quizQuestion));
    },
  },

  computed: {
    quizQuestion() {
      return {
        // Only these properties can be edited within the quiz question
        question: this.question,
        answers: this.answers,
        hint: this.hint,
        resources: this.resources,
        stagedFiles: this.stagedFiles,
      };
    },
    acceptedResources() {
      return ACCEPTED_RESOURCES;
    },
    existingResources() {
      return this.resources.map(res => ({
        src: res.resource_link,
        name: res.resource_link ? res.resource_link.split('/').pop() : undefined,
        type: 'image', // For now we know they are all images because of ACCEPTED_RESOURCES
      }));
    },
  },

  methods: {
    removeQuestion() {
      this.$emit('remove', this.quizQuestion);
    },
    moveUp() {
      this.$emit('move-up', this.quizQuestion);
    },
    moveDown() {
      this.$emit('move-down', this.quizQuestion);
    },
    openUploadDialog() {
      this.$refs.filesInput.click();
    },
    filesChange(files) {
      this.$logger.info('Staged files for upload', { files: [...files].map(x => x.name) });
      // Array object more flexible than FileList
      this.stagedFiles = Array.from(files);
    },
    removeStagedFile(payload) {
      this.$logger.info(`Removing staged file ${payload.file.name}`);
      this.stagedFiles.splice(payload.index, 1);
    },
    removeResource(payload) {
      this.$logger.info(`Removing existing file ${payload.file.name}`);
      this.resources.splice(payload.index, 1);
    },
    pasteResources(payload) {
      const fileArray = [];
      for (let i = 0; i < payload.files.length; i++) {
        const file = payload.files[i];
        if (this.acceptedResources.includes(file.type)) {
          fileArray.push(file);
        }
      }
      if (fileArray.length > 0) {
        this.filesChange(fileArray);
      }
    },
  },
};
</script>
