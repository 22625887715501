<template>
  <div v-if="canBeRendered" class="exception-renderer-cell-base">
    <div v-for="cell in renderableCells">
      <cell v-if="cell" :cell="cell"></cell>
    </div>
  </div>
</template>

<style>
.exception-renderer-cell-base {
  background-color: var(--kate-background-body-alpha);
  border-radius: 4px;
  padding: 1em;
}
</style>

<script>
import CellRenderer from './renderer.vue';

export default {
  components: {
    cell: CellRenderer,
  },
  props: {
    base: {
      type: Array,
      required: true,
    },
  },
  computed: {
    renderableCells() {
      return this.base.filter(x => Boolean(x));
    },
    canBeRendered() {
      return this.renderableCells.length > 0;
    },
  },
};
</script>
