<template>
  <div class="feedback-block-content">
    <div class="panel panel-default feedback-evaluation">
      <div class="evaluation-title">
        <h2 class="feedback-panel-title">
          <i :class="iconClass"></i> {{title}}
        </h2>
        <div v-if="displayScoringIndicator" class="scoring-indicator">
          <span class="scoring-container">
            <k-tooltip class="success" :text="`<b>${filteredSuccesses.length}</b>/${filteredResult.length} tests <b>passed</b>`">
              <i class="fas fa-check-circle"></i>
              <span class="success-scoring" :class="{ disabled: filteredSuccesses.length === 0 }">{{filteredSuccesses.length}}</span>
            </k-tooltip>
          </span>
          <span class="scoring-container">
            <k-tooltip class="failure" :text="`<b>${filteredFailures.length}</b>/${filteredResult.length} tests <b>failed</b>`">
              <i class="fas fa-exclamation-circle"></i>
              <span class="failure-scoring" :class="{ disabled: filteredFailures.length === 0 }">{{filteredFailures.length}}</span>
            </k-tooltip>
          </span>
        </div>
      </div>
      <div class="feedback-block-filter-title" v-if="filterString">
        <i class="fas fa-filter"></i>
        <span class="filter-string">{{filterString}}</span>
        <span class="clear-filter-button clickable"><i @click="clearFilter" class="fas fa-times" title="Clear filter"></i></span>
      </div>
      <div class="feedback-content" v-if="refinedResult.length">
        <div v-for="(entry, index) in paginatedEntries" class="evaluation-summary">

          <div v-if="entry.additionalEntries" class="file-error-container">
            <p>{{ entry.test_description }}</p>
            <div>
              <p v-if="entry.cell_number > -1">cell number: {{ entry.cell_number + 1 }}</p>
              <pre v-if="entry.info">{{entry.info}}</pre>
            </div>
            <button @click="showDetailsModal = true" class="open-details btn btn-primary btn-block">Why it happened</button>
          </div>

          <ul v-else>
            <li class="summary-list" @click="openFunctionDetails(index)" :class="{'not-clickable' : displayRunnerKeys }">
              <div class="function-details">
                <a v-if="displayRunnerKeys" class="runner-key">{{entry.runner_key}}</a>
                <a class="function-name">
                  {{trimDesc(entry.test_description) || entry.test_name || entry.info}}
                  <br />
                </a>
                <span class="line-number" v-if="entry > -1 && displayRunnerKeys">
                  Line: {{ entry.line_number }}
                  <br />
                </span>
                <span class="trim-description">
                  {{entry.function_tested__name || entry.file_path}}
                  <br />
                </span>
                <pre v-if="displayDescription && !displayRunnerKeys">{{currentEntry.info}}</pre>
              </div>
              <div v-if="!displayRunnerKeys" class="btn-link">
                <span v-if="entry.score" class="extra-points-score">{{entry.score}}</span>
                <span v-if="displayResultTypes" class="label" :class="`k-error-label-${entry.result_type}`">{{entry.result_type}}</span>
                <span v-if="entry.details || displayRunnerKeys" class="open-modal-icon">
                  <i class="fas fa-external-link-alt"></i>
                </span>
              </div>
            </li>
          </ul>
        </div>
        <pagination class="feedback-block-pagination" v-if="numOfPages !== undefined"
          v-model="currentPage"
          :max="10"
          :count="numOfPages"
          :total-entries="resultEntries.length"
          :displayed-entries="paginatedEntries.length">
        </pagination>

        <k-modal v-if="currentEntry && currentEntry.additionalEntries" class="file-level-modal" :show="showDetailsModal" @close="showDetailsModal = false">
          <template #header>
            <div class="header-container">
              <div class="header-details">
                <h4>While running the notebook, KATE came across this error:</h4>
              </div>
              <router-link v-if="currentEntry.cell_number > -1 && !(currentEntryIsNotImplemented)"
                          class="btn btn-primary modal-default-button view-btn"
                          :to="{ name: 'pak_ov_notebook', query: { highlight: currentEntry.cell_number } }">
                <i class="fas fa-book-open"></i> Show in notebook
              </router-link>
              <div class="toggle-error-list-container">
                <a aria-label="Toggle" title="Toggle" @click="toggledList = !toggledList" :class="{ toggled : toggledList }" class="toggle-link">
                  See all tests that failed because of this error <i class="toggle-icon fas" :class="toggleIconClass"></i>
                </a>
                <ul class="matching-errors-list">
                  <li v-for="entry in currentEntry.additionalEntries" :key="entry.value_tested">{{ entry.value_tested }}</li>
                </ul>
              </div>
            </div>
          </template>
          <template #body>
            <div>
              <span class="line-number" v-if="currentEntry.cell_number > -1">cell number: {{ currentEntry.cell_number + 1 }}</span><br />
              <pre class="alert alert-danger" v-if="currentEntry.details">{{getPrettyText(currentEntry.details)}}</pre>
              <pre v-if="currentEntry.info">{{currentEntry.info}}</pre> <!-- // jscpd:ignore-start -->
              <k-editor
                  class="notebook-cell"
                  v-if="notebook && currentEntry.cell_number > -1 && !(currentEntryIsNotImplemented) && getNotebookCell(currentEntry.cell_number)"
                  read-only="nocursor"
                  syntax="python"
                  :line-numbers="false"
                  :text="getNotebookCell(currentEntry.cell_number)"
                  :auto-refresh="true"></k-editor> <!-- // jscpd:ignore-end -->
              <pre class="full-desc" v-if="currentEntry.returned">{{currentEntry.returned}}</pre>
              <pre v-if="displayDescription">{{currentEntry.info}}</pre>
            </div>
            <div class="modal-text">
              <h5>Why did your score drop to 0% when previous questions may be correct?</h5>
              <p>There are two types of problems that can occur when running code:
                <ul>
                  <li><b>Logical problems:</b> your code runs but is providing a wrong answer. This is represented as a “failure” or "issues" on KATE</li>
                  <li>
                    <b>Errors:</b> this is a Python exception and when raised the code can’t continue to run.
                  </li>
                </ul>
              </p>
              <p>
                In this scenario, KATE was unable to evaluate your code because your code raised an <code>Error</code> and is unable to run. <br />
                <br/>
                KATE evaluates a notebook by running all the lines of codes in one go - irrespective of individual exercises.
                If any cell in the notebook raises an Error, then the notebook can not carry on being evaluated and all exercises will appear as an "Error" submission.
                As a result, you will not be able to see if any exercises are correctly implemented and the score will show as 0%.
              </p>
              <h5>How can I fix this?</h5>
              <p>
                You will need to locate the place in your notebook that is raising an Error and fix it.<br />
                Common errors include:
                <ul>
                  <li><code>NameError</code> you have a variable somewhere that is not defined.
                    To fix this, check all the cells in your notebook to see if a variable was declared before usage.</li>
                  <li><code>SyntaxError</code> a cell in your code has invalid syntax, check your code and comment out if necessary.</li>
                  <li><code>ValueError</code> while running the whole notebook, there was an issue related to a value entered.</li>
                  <li><code>FileNotFoundError</code> a file you loaded could not be found.
                    Make sure to only use files included in the assignment and specify paths starting from within the assignment directory,
                    e.g. <code>data/scores.csv</code> instead of <code>User/JaneDoe/CSpark/assignment_1/data/scores.csv</code></li>
                </ul>
              </p>
              <p>You can read more about errors in Python here: <a href="https://docs.python.org/3/library/exceptions.html" target="_blank">
              https://docs.python.org/3/library/exceptions.html</a></p>

              <p>The best way to address these problems are:
                <ul>
                  <li>Make sure you restart your notebook, then try to run all the cells one by one, in order.</li>
                  <li>You should then find the same error that you see on KATE</li>
                  <li>If you do not understand the error message, try searching on StackOverflow.</li>
                </ul>
                To do so, copy either the error you find in your notebook, or the one displayed on KATE, and search it on Google.
                In general, you will find a link to StackOverflow, where this error is discussed in details and a fix is proposed.
              </p>
            </div>
          </template>
          <template #footer>
            <button class="modal-default-button btn btn-danger" @click="showDetailsModal = false">
              Close
            </button>
          </template>
        </k-modal>

        <k-modal
          class="function-error-modal"
          v-bind:show="openModal"
          v-if="currentEntry"
          @close="closeFunctionDetails"
          >
          <template #header>
            <div class="header-container">
              <div class="header-details">
                <h4 v-if="currentEntry.function_tested__name || currentEntry.test_description">
                  {{currentEntry.function_tested__name || currentEntry.test_description}}
                </h4>
                <span class="line-number" v-if="currentEntry.cell_number > -1">
                  cell number: {{ currentEntry.cell_number + 1 }}
                  <br />
                </span>
                <span class="line-number" v-else-if="currentEntry.line_number > -1">
                  Line: {{currentEntry.line_number}}
                  <br />
                </span>
                <span class="code-quality-function" v-if="currentEntry.function_name">function: {{currentEntry.function_name}}</span>
              </div>
              <router-link v-if="currentEntry > -1 && !(currentEntryIsNotImplemented)"
                          class="btn btn-primary modal-default-button view-btn"
                          :to="{ name: 'pak_ov_notebook', query: { highlight: currentEntry.cell_number } }">
                <i class="fas fa-book-open"></i> Show in notebook
              </router-link>
            </div>
          </template>
          <template #body>
            <button v-if="refinedResult.length > 1" class="direction-arrow arrow-left" @click="decrementIndex" aria-label="Previous" title="Previous"><i class="fas fa-angle-left"></i></button>
            <div class="full-desc" v-if="currentEntry.test_description" v-html="$kpurify.sanitise(markedTestDescription)"></div>
            <pre class="alert alert-danger" v-if="currentEntry.details">{{getPrettyText(currentEntry.details)}}</pre>
            <pre class="alert alert-danger" v-else-if="currentEntry.info">{{getPrettyText(currentEntry.info)}}</pre>
              <k-editor
                  class="notebook-cell"
                  v-if="notebook && currentEntry.cell_number > -1 && !(currentEntryIsNotImplemented) && getNotebookCell(currentEntry.cell_number)"
                  read-only="nocursor"
                  syntax="python"
                  :line-numbers="false"
                  :text="getNotebookCell(currentEntry.cell_number)"></k-editor>
            <pre class="full-desc" v-if="currentEntry.returned">{{currentEntry.returned}}</pre>
            <pre v-if="displayDescription">{{currentEntry.details}}</pre>
            <span v-if="currentEntry.details == undefined">No traceback available for this error</span>
            <button v-if="refinedResult.length > 1" class="direction-arrow arrow-right" @click="incrementIndex" aria-label="Next" title="Next"><i class="fas fa-angle-right"></i></button>
            <div v-if="currentEntry.render">
              <exception-renderer :base="currentEntry.render"></exception-renderer>
            </div>
          </template>
          <template #footer>
            <template v-if="refinedResult.length > 1">
              <button class="modal-default-button btn btn-outlined" @click="decrementIndex">
                Prev
              </button>
              <button class="modal-default-button btn btn-primary" @click="incrementIndex">
                Next
              </button>
            </template>
            <button class="modal-default-button btn btn-danger" @click="closeFunctionDetails">
              Close
            </button>
          </template>
        </k-modal>
      </div>
      <div class="good-style-container" v-else>
        <p class="good-style">{{allGoodMessage}}</p>
      </div>
    </div>
  </div>
</template>

<style>
/* File level Modal */
.function-error-modal .cm-editor {
  max-height: 12em;
  height: auto;
  font-size: smaller;
}

.function-error-modal .cm-scroller {
  height: auto;
  max-height: 12em;
}

.file-level-modal .modal-container {
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.file-level-modal .header-container {
  justify-content: space-between;
  margin-right: 25px;
}

.file-level-modal h5 {
  color: var(--kate-secondary);
  font-size: 1.1em;
}

.file-level-modal .modal-body::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 0;
  box-shadow: none;
}

.file-level-modal .modal-body::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.scoring-container .success b {
  color: var(--kate-success);
}

.scoring-container .failure b {
  color: var(--kate-danger-alt);
}

.feedback-block-pagination.panel-pagination.row {
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 15px;
}
</style>

<style scoped>
/* File level error Container */
.file-error-container {
  min-height: 245px;
  padding-top: 60px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feedback-panel-title {
  font-size: 1.25em;
}

.file-error-container > p {
  color: var(--kate-danger-light);
  margin-bottom: 15px;
}

.file-error-container button {
  width: 50%;
  max-width: 160px;
  margin: 15px auto 0;
  box-shadow: var(--box-shadow);
}

.file-error-container button:hover {
  box-shadow: var(--box-shadow);
  transition: all 0.3s;
}

.file-error-container button:focus {
  outline: 0;
  color: var(--kate-mono-0);
}

/* Kate Behaviour Modal */

.toggle-error-list-container {
  width: 100%;
}

.toggle-link + .matching-errors-list {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s;
  position: relative;
  opacity: 0;
}

.toggle-link.toggled + .matching-errors-list {
  max-height: 370px;
  overflow: auto;
  opacity: 1;
  transition: max-height 0.3s, opacity 0.1s;
  align-items: center;
  background-color: rgb(0 38 47 / 0.5);
  padding: 15px;
}

.file-level-modal .modal-text {
  margin-top: 20px;
}

ul.matching-errors-list li {
  color: var(--kate-type-primary);
  line-height: 1.5;
  list-style: none;
}

.file-level-modal .modal-text ul {
  padding-left: 15px;
}

.modal-text ul li {
  color: var(--kate-type-primary);
  margin-left: 15px;
  padding: 5px 0;
}

.modal-text ul li b {
  text-decoration: underline;
}

/* _ */
.clear-filter-button i {
  padding: 2px 3px;
  border-radius: 2px;
  color: var(--kate-type-dark);
  font-size: 11px;
  background-color: var(--kate-type-primary);
}

.clear-filter-button:hover i {
  background-color: var(--kate-type-primary);
}

.feedback-block-filter-title {
  color: var(--kate-type-primary);
  font-family: monospace;
  font-size: smaller;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.feedback-block-filter-title .filter-string {
  padding: 0 10px 0 5px;
}

.good-style {
  color: var(--kate-type-success);
  font-size: 30px;
  font-weight: bold;
  display: block;
  text-align: center;
  margin: 10px 0;
}

.code-quality-function,
.runner-key {
  display: block;
  color: var(--kate-logo-lime-green);
}

.feedback-block-content {
  display: block;
  width: 100%;
}

.summary-list.not-clickable {
  pointer-events: none;
}

.line-number {
  color: var(--kate-type-light);
}

.header-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header-details {
  margin-right: 30px;
}

/* Directional Arrows and Container */

button.direction-arrow {
  background-color: transparent;
  border: 0;
  color: var(--kate-button-primary);
  position: absolute;
  top: 50%;
  transform: translateY(-25%);
  font-size: 1.5em;
  padding: 10px 12px;
}

button.direction-arrow:hover {
  color: var(--kate-button-secondary);
}

.arrow-left {
  left: 0;
}

.arrow-right {
  right: 0;
}

/* Scoring */
.scoring-container {
  position: relative;
}

.scoring-container i {
  color: var(--kate-type-light);
  font-size: 1.3em;
  cursor: pointer;
}

.scoring-indicator > span {
  position: relative;
  margin: 0 20px;
}

.scoring-indicator span .failure-scoring,
.scoring-indicator span .success-scoring {
  padding: 1px 7px;
  font-size: 12px;
  border-radius: 50px;
  position: absolute;
  left: 12px;
  top: -6px;
  opacity: 1;
}

.failure-scoring {
  background-color: var(--kate-danger);
  color: var(--kate-type-light);
}

.success-scoring.disabled,
.failure-scoring.disabled {
  background-color: var(--kate-disabled);
  color: var(--kate-type-light);
}

.success-scoring {
  background-color: var(--kate-success);
  color: var(--kate-type-dark);
}

/* Feedback Evaluation */

.feedback-evaluation {
  display: block;
  width: 100%;
  padding: 15px;
}

.feedback-block-content .feedback-evaluation .feedback-content {
  position: relative;
  padding-bottom: 15px;
}

.feedback-evaluation .evaluation-title {
  min-height: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.feedback-evaluation > div.good-style-container {
  padding: 10px;
}

.feedback-block-content .feedback-evaluation > div.good-style-container {
  min-height: auto;
}

.feedback-evaluation ul {
  padding-left: 0;
}

.feedback-evaluation .evaluation-summary {
  border: 0;
}

.function-details {
  margin: 0;
  flex: 1 1 50%;
}

.summary-list {
  cursor: pointer;
  list-style: none;
  border-bottom: 1px solid var(--kate-type-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.summary-list:hover {
  background-color: rgb(0 38 47 / 0.5);
}

.summary-list:hover a {
  color: var(--kate-secondary);
  text-decoration: underline;
}

.summary-list .btn-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 8px 0;
  color: var(--kate-type-blue);
}

.summary-list .btn-link:hover {
  text-decoration: none;
}

.summary-list .k-error-label-error {
  padding: 5px 15px;
  color: var(--kate-type-light);
  background-color: var(--kate-danger);
  border-radius: 4px;
}

.summary-list .k-error-label-failure {
  padding: 5px 15px;
  color: var(--kate-type-dark);
  background-color: var(--kate-secondary);
  border-radius: 4px;
}

.open-modal-icon {
  padding-left: 10px;
}

.function-error-modal pre {
  margin: 10px 0;
}

.k-feedback-block-collapse pre {
  white-space: pre;
  overflow-x: auto;
}

.k-feedback-block-collapse {
  height: auto;
  overflow: hidden;
  width: 100%;
}

.k-feedback-block-collapse.collapsed {
  height: 0;
}

pre.k-feedback-result {
  background-color: var(--kate-warning-light);
}

@media only screen and (max-width: 767px) {
  .modal-body pre {
    white-space: normal;
    word-break: break-word;
  }
}
</style>

<script>
import { marked } from 'marked';
import dedent from 'dedent';
import KModal from '../components/k-modal.vue';
import Pagination from '../components/k-pagination.vue';
import ExceptionRenderer from './exception-renderer/base.vue';
import KTooltip from '../components/k-tooltip.vue';
import KEditor from '../ide/k-editor.vue';

export default {
  props: {
    resultEntries: Array,
    layoutObject: Object,
    filePath: String,
    functionTestedName: String,
    ignoreSuccess: {
      type: Boolean,
      default: true,
    },
    maxItemsPerPage: {
      type: Number,
      default: 8,
    },
    title: String,
    iconClass: String,
    notebook: {
      type: Object,
    },
    displayRunnerKeys: {
      type: Boolean,
      default: false,
    },
    displayResultTypes: {
      type: Boolean,
      default: true,
    },
    displayDescription: {
      type: Boolean,
      default: false,
    },
    displayScoringIndicator: {
      type: Boolean,
      default: true,
    },
    allGoodMessage: {
      type: String,
      default: 'All Good!',
    },
    sortPriority: {
      type: Array,
    },
  },

  data() {
    return {
      toggledList: false,
      currentPage: 0,
      currentIndex: 0,
      openModal: false,
      showDetailsModal: false,
    };
  },

  components: {
    'k-modal': KModal,
    'k-editor': KEditor,
    pagination: Pagination,
    'exception-renderer': ExceptionRenderer,
    KTooltip,
  },

  watch: {
    filterKeys() {
      this.currentIndex = 0;
    },
    numOfPages() {
      this.currentPage = 0;
    },
    resultEntries() {
      this.currentIndex = 0;
    },
  },
  computed: {
    filterString() {
      if (!this.filePath) {
        return null;
      }
      return `${this.filePath}${this.functionTestedName ? `: ${this.functionTestedName}` : ''}`;
    },
    filterKeys() {
      return [this.filePath, this.functionTestedName];
    },
    currentEntry() {
      return this.refinedResult[this.currentIndex];
    },
    currentLayoutEntry() {
      if (!this.layoutObject || !this.currentEntry) {
        return undefined;
      }
      // This only works for evaluation test entries since extra points results for example
      // don't have a value_tested of function_tested__name
      return this.layoutObject[this.currentEntry.file_path].functions
        .filter(x => (x.function_name === this.currentEntry.value_tested || x.function_name === this.currentEntry.function_tested__name))[0];
    },
    currentEntryIsNotImplemented() {
      if (this.currentLayoutEntry) {
        return this.currentLayoutEntry.state === 'not_implemented';
      }
      if (this.currentEntry) {
        return this.checkResultIsNotImplemented(this.currentEntry);
      }
      return false;
    },
    entriesAssociatedWithFiles() {
      return this.resultEntries.filter(x => x.file_path);
    },
    entriesNotAssociatedWithFiles() {
      return this.resultEntries.filter(x => !x.file_path);
    },
    filteredResult() {
      if (this.entriesNotAssociatedWithFiles.length === this.resultEntries.length) {
        // If no entries are associated with specific files - ignore filters
        return this.resultEntries;
      }
      // Always display unassociated results first, irrespective of filter, followed by file-function filtered results
      const filteredEntries = this.entriesAssociatedWithFiles.filter(
        x => (this.filePath === undefined || x.file_path === this.filePath)
          && (this.functionTestedName === undefined
            || (x.function_tested__name || x.value_tested) === this.functionTestedName),
      );
      return this.entriesNotAssociatedWithFiles.concat(filteredEntries);
    },
    refinedResult() {
      let results = []; // results to return
      const processedHashes = []; // list of entries that have already been processed
      const resultsExcludingNotImplementedAndTimeouts = this.filteredResult.filter(
        x => (!this.ignoreSuccess || x.result_type !== 'success')
              && !this.checkResultIsNotImplemented(x)
              && !this.checkResultIsTimeout(x),
      );
      for (let i = 0; i < resultsExcludingNotImplementedAndTimeouts.length; i++) {
        const res = resultsExcludingNotImplementedAndTimeouts[i];
        const errorHash = `${res.line_number}${res.cell_number}`;
        // Add failures
        if (res.result_type === 'failure') {
          results.push(res);
        } else if (processedHashes.indexOf(errorHash) === -1) {
          // Entry is an error and has not already been processed - get other entries with same line / cell number
          const matchingEntries = resultsExcludingNotImplementedAndTimeouts
            .filter(x => this.matchingLineNumber(x, res) && this.matchingCellNumber(x, res));
          if (matchingEntries.length > 1 && this.notebook) {
            // Create an aggregate entry if there is > 1 matching entry
            const aggregateEntry = {
              details: res.details,
              info: res.info,
              line_number: res.line_number,
              cell_number: res.cell_number,
              file_path: res.file_path,
              test_description: `An error was found in your code that prevented it from loading properly and caused ${matchingEntries.length} tests to fail. Fix the error to resume your progress!`,
              additionalEntries: matchingEntries,
            };
            results.push(aggregateEntry);
            // Mark the entry as having been processed
            processedHashes.push(errorHash);
          } else {
            // Entry has no other matching entries, add to results as normal
            results.push(res);
          }
        }
      }
      // Add the NotImplemented and Timeout errors back in
      results = results.concat(this.filteredResult
        .filter(x => (!this.ignoreSuccess || x.result_type !== 'success')
          && (this.checkResultIsNotImplemented(x) || this.checkResultIsTimeout(x))));
      return results.sort(this.resultSortFunction);
    },
    /* Pagination */
    numOfPages() {
      if (this.refinedResult.length <= this.maxItemsPerPage) {
        return undefined;
      }
      return Math.ceil(this.refinedResult.length / this.maxItemsPerPage);
    },
    paginatedEntries() {
      if (!this.numOfPages) {
        return this.refinedResult;
      }
      const startingIndex = this.maxItemsPerPage * this.currentPage;
      const endingIndex = Math.min(this.maxItemsPerPage + startingIndex, this.refinedResult.length);
      return this.refinedResult.slice(startingIndex, endingIndex);
    },
    /* Filtering Failures and Successes */
    filteredFailures() {
      return this.filteredResult
        .filter(x => (!this.ignoreSuccess || x.result_type !== 'success'));
    },
    filteredSuccesses() {
      return this.filteredResult.filter(
        item => item.result_type === 'success',
      );
    },
    toggleIconClass() {
      return this.toggledList ? 'fa-caret-up' : 'fa-caret-down';
    },
    markedTestDescription() {
      return this.markdownToHtml(this.currentEntry.test_description);
    },
  },

  methods: {
    matchingLineNumber(a, b) {
      if (a.line_number > -1 && b.line_number > -1) {
        return a.line_number === b.line_number;
      }
      return false;
    },
    matchingCellNumber(a, b) {
      if (a.cell_number > -1 && b.cell_number > -1) {
        return a.cell_number === b.cell_number;
      }
      return false;
    },
    checkResultIsNotImplemented(res) {
      if (res.details) {
        if (res.details === 'Not Implemented' || res.details.includes('NotImplementedError')) {
          return true;
        }
      }
      return false;
    },
    checkResultIsTimeout(res) {
      if (res.details) {
        return res.details.slice(0, 16) === 'TestTimeoutError' || res.details.slice(0, 12) === 'TimeoutError';
      }
      return false;
    },
    markdownToHtml(snippet) {
      if (snippet) {
        return marked(snippet);
      }
      return undefined;
    },
    resultSortFunction(a, b) {
      if (!this.sortPriority) {
        return 0; // no sorting priority supplied
      }
      const getResultVal = x => this.sortPriority
        .indexOf(`${x.file_path}:${x.function_tested__name || x.value_tested}`);

      return getResultVal(a) - getResultVal(b);
    },
    clearFilter() {
      this.$emit('clearfilter');
    },
    getNotebookCell(cellNumber) {
      if (!this.notebook || !this.notebook.cells[cellNumber] || !Array.isArray(this.notebook.cells[cellNumber].source)) {
        return '';
      }
      const code = this.notebook.cells[cellNumber].source.join('');
      return code;
    },
    incrementIndex() {
      this.currentIndex = (this.currentIndex + 1) % (this.refinedResult.length);
    },
    decrementIndex() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.refinedResult.length - 1;
      } else {
        this.currentIndex -= 1;
      }
    },
    openFunctionDetails(index) {
      let startingIndex;
      if (this.numOfPages) {
        startingIndex = this.maxItemsPerPage * this.currentPage;
      } else {
        startingIndex = 0;
      }
      this.addKeyListener();
      this.currentIndex = index + startingIndex;
      this.openModal = true;
    },
    closeFunctionDetails() {
      this.removeKeyListener();
      this.openModal = false;
    },
    trimDesc(str) {
      if (!str) {
        return '';
      }
      return str.trim().split('\n')[0];
    },
    getPrettyText(str) {
      if (!str) {
        return '';
      }
      return dedent(str.trim());
    },
    handleKeyPress(e) {
      this.openModal = true;
      if (e.keyCode === 37) {
        this.decrementIndex();
      }
      if (e.keyCode === 39) {
        this.incrementIndex();
      }
    },
    addKeyListener() {
      document.addEventListener('keydown', this.handleKeyPress);
    },
    removeKeyListener() {
      document.removeEventListener('keydown', this.handleKeyPress);
    },
  },
};
</script>
