<template>
  <tr class="k-editable-table-row" :class="classFunc(row)" >
    <td v-for="(header, index) in headers">
      <k-cell
        :row="row"
        :header="header"
        :entry="row[header.key]"
        @update="updateCell(header.key, $event)"
        @commitEdits="commitEdits"
        :key="index"
        :timezone="timezone"
      ></k-cell>
    </td>
  </tr>
</template>

<script>
import KCell from './k-editable-table-cell.vue';

export default {
  components: {
    'k-cell': KCell,
  },
  props: {
    row: Object,
    headers: Array,
    classFunc: {
      type: Function,
      default: () => '',
    },
    timezone: String,
  },
  methods: {
    update(newRow) {
      this.$emit('update', newRow);
    },
    updateCell(key, nuval) {
      const newRow = { ...this.row };
      newRow[key] = nuval;
      this.update(newRow);
    },
    commitEdits(payload) {
      this.$emit('commitEdits', payload);
    },
  },
};
</script>
