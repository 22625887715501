<template>
  <k-panel :hasContentToggle="false">
    <template #body>
      <div class="blueprint-meta">
        <k-editable-text-field v-model="programmeBlueprintName" @editing="(val) => (editName = val)">
          <h2>{{ programmeBlueprintName }}</h2>
        </k-editable-text-field>
        <k-editable-text-editor
          v-model:text="programmeBlueprintDescription"
          v-model:editMode="editDescription"
          label="Update programme description"
          placeholder="Add a description for the programme blueprint">
        </k-editable-text-editor>
        <k-editable-dropdown-field v-model="programmeBlueprintProductId"
          :options="products"
          @editing="(val) => (editProductId = val.id)"
        >
          <p>{{ productName || "No product assigned to this programme" }}</p>
        </k-editable-dropdown-field>
        <fieldset class="detail-form form-entry">
          <label class="include-in-reporting">
            <input type="checkbox" v-model="programmeBlueprintIncludeInReport">
            Include in reporting? If programme is included in reporting, learner data will be added to the Tableau dashboards
          </label>
        </fieldset>
      </div>
      <div class="manage-module-blueprint">
        <manage-module
          v-model="programmeModuleBlueprints"
          :is-blueprint="true"
          :draggable="true"
          :disable-commit="!validName"
          @reset="reset"
          @save-changes="updateProgrammeBlueprint"
          @remove="removeModuleBlueprint"
          @add="addModuleBlueprint"
          @release-update="makeModuleBlueprintUpdate"
          @cancel="cancelModuleBlueprintUpdate"
        >
          <template #instructions>
            <p>
              Below is a list of modules for the <b>{{ programmeBlueprintName }}</b> programme blueprint.
              Drag the modules to re-order them and click on a module to edit its details.
            </p>
            <p>Click <i>Save Changes</i> when you're ready to update the blueprint.</p>
          </template>
        </manage-module>
      </div>
    </template>
  </k-panel>
</template>

<style scoped>
.manage-module-blueprint {
  padding-bottom: 20px;
}
</style>

<script>
import ErrorMixin from '../../../mixins/error-mixins';
import PageReadyMixin from '../../../mixins/page-ready-mixin';
import ManageModule from '../../programmes/modules/abstract-manage-modules.vue';
import KEditableTextField from '../../../components/k-editable-text-field.vue';
import KEditableDropdownField from '../../../components/k-editable-dropdown-field.vue';
import KPanel from '../../../components/k-panel.vue';
import { sortObjectArray } from '../../../modules/sort-by-object-property';
import copyObject from '../../../modules/copy-object';
import KEditableTextEditor from '../../../components/k-editable-text-editor.vue';

export default {
  components: {
    KEditableDropdownField,
    KEditableTextField,
    KPanel,
    ManageModule,
    KEditableTextEditor,
  },

  mixins: [ErrorMixin, PageReadyMixin],

  props: {
    programmeBlueprint: {
      type: Object,
    },
    programmeBlueprintReady: {
      type: Boolean,
    },
  },

  data() {
    return {
      programmeModuleBlueprints: [],
      originalProgrammeModuleBlueprints: [],
      programmeBlueprintName: '',
      programmeBlueprintDescription: '',
      programmeBlueprintProductId: undefined,
      programmeBlueprintIncludeInReport: false,
      productsReady: false,
      editName: false,
      editDescription: false,
      editProductId: false,
      products: [],
      updateInProgress: false,
      selectedCertificate: undefined,
      showModuleBlueprintsModal: false,
      modalOpen: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.loadProgrammeBlueprintData();
    this.getProducts();
  },

  watch: {
    programmeBlueprint: {
      handler() {
        this.loadProgrammeBlueprintData();
      },
      deep: true,
    },
    programmeModuleBlueprints: {
      handler() {
        for (let i = 0; i < this.programmeModuleBlueprints.length; i += 1) {
          this.programmeModuleBlueprints[i].index = i;
        }
      },
      deep: true,
    },
    originalProgrammeModuleBlueprints: {
      handler() {
        for (let i = 0; i < this.originalProgrammeModuleBlueprints.length; i += 1) {
          this.originalProgrammeModuleBlueprints[i].index = i;
        }
      },
      deep: true,
    },
  },

  computed: {
    ready() {
      return this.programmeBlueprintReady && this.productsReady && !this.updateInProgress;
    },
    programmeBlueprintId() {
      return parseInt(this.$route.params.programmeBlueprintId, 10);
    },
    validName() {
      return Boolean(this.programmeBlueprintName.trim().length > 0 && this.programmeBlueprintName.trim().length < 256);
    },
    editing() {
      return this.editName || this.editDescription || this.editProductId;
    },
    productName() {
      const prodIndex = this.products.findIndex(x => x.id === this.programmeBlueprintProductId);
      if (prodIndex < 0) {
        return null;
      }
      return this.products[prodIndex].name;
    },
  },

  methods: {
    loadProgrammeBlueprintData() {
      if (!this.programmeBlueprint) {
        return;
      }
      // Copies data from the blueprint to component data which can be used for update
      const val = copyObject(this.programmeBlueprint);
      this.programmeBlueprintName = val.name;
      this.programmeBlueprintDescription = val.description;
      this.programmeBlueprintProductId = val.product_id;
      this.programmeBlueprintIncludeInReport = val.include_in_reporting;
      const moduleBlueprints = val.module_blueprints.map(x => ({ programmeBlueprintBadges: val.badges, ...x }));
      this.programmeModuleBlueprints = sortObjectArray(this.appendBadgesToBlueprintModules(moduleBlueprints), 'number');
      this.originalProgrammeModuleBlueprints = JSON.parse(
        JSON.stringify(sortObjectArray(this.appendBadgesToBlueprintModules(moduleBlueprints), 'number')),
      );
      this.selectedBadges = val.badges || [];
      this.selectedCertificate = val.certificate ? [val.certificate] : undefined;
    },
    reset() {
      this.programmeModuleBlueprints = JSON.parse(JSON.stringify(this.originalProgrammeModuleBlueprints));
      this.programmeBlueprintName = this.programmeBlueprint.name;
      this.programmeBlueprintDescription = this.programmeBlueprint.description;
      this.programmeBlueprintProductId = this.programmeBlueprint.product_id;
      this.programmeBlueprintIncludeInReport = this.programmeBlueprint.include_in_reporting;
      this.editDescription = false;
    },
    appendBadgesToBlueprintModules(moduleBlueprints) {
      return moduleBlueprints.map(mod => ({
        badges_in_module_blueprint: this.programmeBlueprint.badges.filter(
          b => b.module_blueprints?.map(mb => mb.module_blueprint_id)?.includes(mod.id),
        ) || [],
        ...mod,
      }));
    },
    removeModuleBlueprint(index) {
      this.programmeModuleBlueprints.splice(index, 1);
    },
    addModuleBlueprint(blueprint, track) {
      this.programmeModuleBlueprints.push({ ...blueprint, track, programmeBlueprintBadges: this.programmeBlueprint.badges });
      this.showModuleBlueprintsModal = false;
    },
    makeModuleBlueprintUpdate(index, updatePayload) {
      this.programmeModuleBlueprints.splice(index, 1, {
        ...this.programmeModuleBlueprints[index],
        updatePending: true,
        ...updatePayload,
      });
    },
    cancelModuleBlueprintUpdate(index) {
      const revertedModule = JSON.parse(JSON.stringify(this.programmeModuleBlueprints[index]));
      delete revertedModule.newReleaseAfterDays;
      delete revertedModule.newExpectedCompletionDays;
      delete revertedModule.newBadgesInProgrammeBPModule;
      delete revertedModule.updatePending;
      this.programmeModuleBlueprints.splice(index, 1, revertedModule);
    },
    getModuleBlueprintUpdatePayload(mod, idx) {
      let badges = mod.newBadgesInProgrammeBPModule ? mod.newBadgesInProgrammeBPModule : mod.badges_in_module_blueprint || [];
      // Only need badge IDs for payload
      badges = badges.map(badge => ({ id: badge.id }));
      return {
        module_blueprint_id: mod.id,
        number: idx + 1,
        release_after_days: mod.newReleaseAfterDays ? mod.newReleaseAfterDays : mod.release_after_days || null,
        expected_completion_days: mod.newExpectedCompletionDays ? mod.newExpectedCompletionDays : mod.expected_completion_days || null,
        badges_in_module_blueprint: badges,
        track: mod.track,
      };
    },
    updateProgrammeBlueprint() {
      const updatePayload = {
        name: this.programmeBlueprintName.trim(),
        description: this.programmeBlueprintDescription || null,
        product_id: this.programmeBlueprintProductId || null,
        include_in_reporting: Boolean(this.programmeBlueprintIncludeInReport),
        module_blueprints: this.programmeModuleBlueprints.map((x, idx) => this.getModuleBlueprintUpdatePayload(x, idx)),
      };
      this.$logger.info('Updating blueprint', { programmeBlueprintId: this.programmeBlueprint.id, updatePayload });
      this.updateInProgress = true;
      this.$http.put(`/api/curriculum/blueprints/programmes/${this.programmeBlueprintId}`, updatePayload).then(() => {
        this.$logger.info('Successfully updated blueprint', { programmeBlueprintId: this.programmeBlueprint.id, updatePayload });
        this.$ktoast.success('Blueprint updated');
        this.$emit('refresh');
      }).catch(err => {
        if (this.$http.isWarning(err)) {
          this.$logger.warn('Error updating blueprint', { programmeBlueprintId: this.programmeBlueprint.id, updatePayload }, err);
          this.showError(err);
        } else {
          this.$logger.error('Error updating blueprint', { programmeBlueprintId: this.programmeBlueprint.id, updatePayload }, err);
          this.showError(err);
        }
      }).then(() => {
        this.editDescription = false;
        this.updateInProgress = false;
      });
    },
    getProducts() {
      this.productsReady = false;
      this.$logger.info('Getting products');
      return this.$http.get('/api/curriculum/products').then(res => {
        this.products = res.data;
      }).catch(err => {
        this.$logger.autowarn('Could not get products', undefined, err);
        this.showError(err);
      }).then(() => {
        this.productsReady = true;
      });
    },
  },
};
</script>
