<template>
  <div>
    <k-modal @close="close" :show="show">
      <template #header>
        <h3>Edit Note</h3>
      </template>
      <template #body>
        <k-text-editor id="note-edit" v-model="body">
        </k-text-editor>
        <label class="k-custom-checkbox share-note-check">
          <input type="checkbox" v-model="shareWithEmployer" class="hidden-checkbox"/>
          <span class="k-custom-checkbox-box light"></span>
          <span class="k-custom-checkbox-text">Share note with employer</span>
        </label>
      </template>
      <template #footer>
        <button class="modal-return-button btn btn-default cancel-btn" @click="close">Cancel</button>
        <button v-if="isSaving" class="btn btn-success" disabled>
          <i class="fa-duotone fa-spinner fa-spin"></i> Updating note...
        </button>
        <button v-else :disabled="!hasChanges" class="btn btn-success" @click="editNote()">Update note</button>
      </template>
    </k-modal>
    <k-modal :show="showDiscardModal" @close="closeDiscardModal" class="confirm-discard-modal">
      <template #header>
        <h3>You have unsaved changes</h3>
      </template>
      <template #body>
        <div class="confirm-discard-modal-body">
          <p>Are you sure you want to stop editing this note?</p>
          <b>Your changes will not be saved.</b>
        </div>
      </template>
      <template #footer>
        <button class="modal-return-button btn btn-default cancel-btn" @click="closeDiscardModal">Cancel </button>
        <button class="btn btn-danger modal-delete-button" @click="discardChanges()">
          Discard Changes
        </button>
      </template>
    </k-modal>
  </div>
</template>

<script>
import ErrorMixin from '../../../mixins/error-mixins';
import KModal from '../../../components/k-modal.vue';
import KTextEditor from '../../../components/k-text-editor.vue';

export default {
  components: {
    KModal,
    KTextEditor,
  },

  mixins: [ErrorMixin],

  props: {
    programmeMemberId: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
    note: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showDiscardModal: false,
      isSaving: false,
      shareWithEmployer: true,
      body: '',
    };
  },

  mounted() {
    this.body = this.note.note;
    this.shareWithEmployer = this.note.share_with_employer;
  },

  watch: {
    note() {
      this.body = this.note.note;
      this.shareWithEmployer = this.note.share_with_employer;
    },
  },

  computed: {
    hasChanges() {
      return this.note.note !== this.body || this.note.share_with_employer !== this.shareWithEmployer;
    },
  },

  methods: {
    discardChanges() {
      this.closeDiscardModal();
      this.$emit('close');
    },

    close() {
      if (this.isSaving) {
        return;
      }
      if (this.hasChanges) {
        this.openDiscardModal();
      } else {
        this.$emit('close');
      }
    },

    openDiscardModal() {
      this.showDiscardModal = true;
    },

    closeDiscardModal() {
      this.showDiscardModal = false;
    },

    editNote() {
      this.isSaving = true;
      this.$logger.info('Editing note');
      this.$http.put(`/api/profile/programme-member/${this.programmeMemberId}/notes`, {
        note_id: this.note.note_id,
        body: this.body,
        share_with_employer: this.shareWithEmployer,
        source: this.note.source,
      }).then(() => {
        this.$ktoast.success('Note has been updated');
        this.$logger.info('Edited note', { noteId: this.note.note_id });
        this.$emit('edited');
        this.body = '';
        this.$emit('close');
      }).catch(err => {
        this.$logger.autowarn('Error editing note', { programmeMemberId: this.programmeMemberId }, err);
        this.showError(err);
      }).then(() => {
        this.isSaving = false;
      });
    },

  },
};
</script>
