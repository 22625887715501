<template>
  <ul class="k-asset-count" :class="{ 'border': border }">
    <li v-for="assetType in filteredAssetTypes">
      <i class="k-asset-icon" :class="getAssetIcon(assetType)"></i>
      <span class="k-asset-count-text">
        {{ pluralize(prettyNames[assetType], assetCount[assetType], true) }}
      </span>
    </li>
  </ul>
</template>

<style scoped>
ul.k-asset-count {
  list-style: none;
  padding: 0;
}

ul.k-asset-count li {
  display: inline;
  margin-right: 15px;
}

ul.k-asset-count li:last-child {
  margin-right: 0;
}

ul.k-asset-count .k-asset-count-text {
  color: var(--kate-type-light);
}

ul.k-asset-count.border li {
  border-right: 1px solid var(--kate-type-primary);
  margin: 0;
  padding: 0 15px;
}

ul.k-asset-count.border li i {
  margin-right: 5px;
}

ul.k-asset-count.border li:first-child {
  padding-left: 0;
}

ul.k-asset-count.border li:last-child {
  border: none;
  padding-right: 0;
}
</style>

<script>
import PluralMixin from '../mixins/plural-mixin';
import AssetIconMixin from '../mixins/asset-icon-mixin';
import { ASSET_TYPES } from '../constants';

export default {
  mixins: [PluralMixin, AssetIconMixin],
  props: {
    assets: {
      type: Array,
      default: () => [],
    },
    border: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    assetCount() {
      return Object.fromEntries(
        this.assetTypes.map(at => [at, this.assets.filter(asset => asset.asset_type === at).length]),
      );
    },
    prettyNames() {
      return Object.fromEntries(
        this.assetTypes.map(at => [at, ASSET_TYPES[at].prettyName]),
      );
    },
    assetTypes() {
      return Object.keys(ASSET_TYPES);
    },
    filteredAssetTypes() {
      return this.assetTypes.filter(at => this.assetCount[at]);
    },
  },
};
</script>
