<template>
  <div class="percentage-range-slider">
    <label class="range-label">
      <span>{{ valueMin }}%</span>
      <input type="range"
        ref="percentageSlider"
        id="range-slider"
        :min="valueMin"
        :max="valueMax"
        step="1"
        aria-label="Percentage slider"
        title="Percentage slider"
        v-model.number="selectedPercentage"
        :style="{'background-size': backgroundTrackSize}"
        :disabled="readOnly"
      >
      <span v-if="!showDonut" class="range-text">{{ displayValue }}%</span>
      <span>{{ valueMax }}%</span>
    </label>
    <k-progress v-if="showDonut"
      class="percentage-donut"
      :percentage="displayValue"
      shape="circle"
      size="medium"
      :precision="0"
      progressTheme="duotone"
      ariaLabel="Selected percentage"
    ></k-progress>
  </div>
</template>

<style scoped>
.percentage-range-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label.range-label {
  width: 50vw;
  display: flex;
  gap: 10px;
  margin: 20px 0 0 12px;
  align-items: center;
  position: relative;
}

label.range-label span {
  font-size: 0.85em;
}

.range-label input[type="range"] {
  height: 8px;
}

.range-label input[type="range"]::-webkit-slider-thumb {
  box-shadow: none;
  border: 4px solid #1cf;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #1cf;
  cursor: pointer;
  appearance: none;
  margin-top: -8px;
  position: relative;
}

.range-label input[type="range"]::-moz-range-thumb {
  box-shadow: none;
  border: 4px solid #1cf;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #1cf;
  cursor: pointer;
  appearance: none;
  margin-top: -8px;
  position: relative;
}

.range-text {
  position: absolute;
  color: var(--kate-type-light);
  top: -20px;
  margin-left: -8px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--kate-background);
  width: 60px;
  padding: 3px 0;
  text-align: center;
}
</style>

<script>
import KProgress from './k-progress.vue';

export default {
  components: {
    KProgress,
  },

  props: {
    modelValue: {
      type: Number,
    },
    showDonut: {
      type: Boolean,
      default: true,
    },
    defaultValue: {
      type: Number,
      default: 50,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedPercentage: this.modelValue,
      valueMin: 0,
      valueMax: 100,
      isGrabbingSlider: false,
    };
  },

  watch: {
    modelValue() {
      this.selectedPercentage = this.modelValue;
    },
    selectedPercentage() {
      this.emitAnswer();
    },
  },

  computed: {
    displayValue() {
      // Allows the underlying value to be null or undefined, while still displaying a value
      return this.selectedPercentage == null ? this.defaultValue : this.selectedPercentage;
    },
    backgroundTrackSize() {
      // Best calculation for all browsers to avoid either going over or short of the range thumb
      return `${Math.round((parseInt(this.displayValue, 10) - this.valueMin) * (100 / (this.valueMax - this.valueMin)))}% 100%`;
    },
  },

  methods: {
    emitAnswer() {
      this.$emit('update:modelValue', this.selectedPercentage);
    },
  },
};

</script>
