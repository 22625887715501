<template>
  <li class="nav-sub-group">
    <div class="nav-sub-group-title">
      <router-link :to="assetRoute" class="nav-sub-group-link">
        <k-progress v-if="isScoredAsset && defaultChapterAsset"
            shape="circle"
            size="xsmall"
            :ariaLabel="prettyScoreAssetType"
            :percentage="progressPercent">
          </k-progress>
        <i v-else class="k-asset-icon fas" :class="getAssetIcon(asset.asset_type)"></i>
        <div class="chapter-details-container">
          <div class="asset-title">
            <span>
              <b v-if="defaultChapterAsset && asset.number" class="number">{{ asset.number ? `${asset.number} - ` : '' }}</b>
              <b class="name">{{ assetTitle }}</b>
            </span>
            <i v-if="asset.is_complete " class="fas fa-check completed"></i>
          </div>
          <div v-if="asset.event_start" class="event-date">
            When: <b>{{ parseTimestamp(asset.event_start, undefined, "do MMM yyyy HH:mm") }}</b>
          </div>
          <span v-if="assetDeadline" class="due-date">
            Due: <b>{{ parseTimestamp(assetDeadline, undefined, "do MMM yyyy") }}</b>
          </span>
          <k-asset-tags :asset="asset"></k-asset-tags>
          <div v-if="isScoredAsset && defaultChapterAsset" class="activity-container">
            <div v-if="asset.latest_timestamp" class="latest-activity">
              <span>Latest activity: {{ parseTimestamp(asset.latest_timestamp) }}</span>
            </div>
            <div v-else class="latest-activity">
              <span>No activity yet</span>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </li>
</template>

<style scoped>
.chapter-details-container {
  width: 100%;
}

.chapter-details-container .asset-title {
  font-weight: bold;
  color: var(--kate-type-light);
}

.event-date,
.due-date,
.latest-activity {
  font-size: 0.85em;
}

.latest-activity {
  color: var(--kate-type-alt);
}

.event-date,
.due-date {
  color: var(--kate-danger-light);
  font-weight: bold;
  padding-bottom: 3px;
}
</style>

<script>
import TimeMixin from '../../mixins/time-mixins';
import AssetIconMixin from '../../mixins/asset-icon-mixin';
import KAssetTags from '../../components/k-asset-tags.vue';
import KProgress from '../../components/k-progress.vue';
import { ASSET_TYPES } from '../../constants';

export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
    defaultChapterAsset: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    KAssetTags,
    KProgress,
  },

  mixins: [TimeMixin, AssetIconMixin],

  computed: {
    prettyScoreAssetType() {
      if (this.isScoredAsset) {
        if (this.asset.asset_type === 'pak') {
          return 'Assignment Score';
        }
        if (this.asset.asset_type === 'questionnaire') {
          return 'Questionnaire Progress';
        }
        return 'Quiz Score';
      }
      return 'Content Completion';
    },

    moduleId() {
      return this.$route.params.moduleId;
    },

    isScoredAsset() {
      return ASSET_TYPES[this.asset.asset_type]?.completion_type === 'submission';
    },

    progressPercent() {
      // Only latest score because it's the actual score on the assignment
      if (this.asset && this.isScoredAsset) {
        return this.asset.latest_score || 0;
      }
      return undefined;
    },

    assetTitle() {
      return this.asset.name || this.asset.title;
    },

    assetDeadline() {
      return this.asset.end_date || this.asset.deadline;
    },

    assetRoute() {
      let route = {
        params: { moduleId: this.moduleId },
      };
      switch (this.asset.asset_type) {
        case 'learning_unit':
          route.name = 'module_lu';
          route.params.moduleLuId = this.asset.id;
          break;
        case 'pak':
          route.name = 'pak_ov_instructions';
          route.params.modulePakId = this.asset.id;
          break;
        case 'quiz':
          route.name = 'module_quiz';
          route.params.moduleQuizId = this.asset.id;
          break;
        case 'video':
          route.name = 'module_video';
          route.params.moduleVideoId = this.asset.id;
          break;
        case 'slide_deck':
          route.name = 'module_slides';
          route.params.moduleSlidesId = this.asset.id;
          break;
        case 'calendar_event':
          route.name = 'module_calendar_event';
          route.params.moduleCalendarEventId = this.asset.id;
          break;
        case 'meeting':
          route.name = 'module_meeting';
          route.params.moduleMeetingId = this.asset.id;
          break;
        case 'markdown_page':
          route.name = 'module_markdown_page';
          route.params.moduleMarkdownPageId = this.asset.id;
          break;
        case 'questionnaire':
          route.name = 'module_questionnaire';
          route.params.moduleQuestionnaireId = this.asset.id;
          break;
        default:
          route = '';
      }
      return route;
    },
  },
};
</script>
