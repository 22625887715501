<template>
  <div class="k-editable-text-field">
    <slot v-if="label" name="label">
      <label for="editable-text-input" class="field-label">{{ label }}:</label>
    </slot>
    <fieldset v-if="actualEdit" class="detail-form form-entry">
      <textarea v-if="multiline"
        id="editable-text-input"
        v-model="textField"
        :placeholder="placeholder || textField"
        :maxlength="maxLength"
        @keyup.enter="confirm"
        class="form-control"
      ></textarea>
      <input v-else
        id="editable-text-input"
        v-model="textField"
        :placeholder="placeholder || textField"
        :maxlength="maxLength"
        @keyup.enter="confirm"
        class="form-control"
      />
      <button @click="confirm" v-if="showControls" class="btn btn-success" title="Confirm changes" aria-label="Confirm changes">
        <i class="fas fa-check"></i>
      </button>
    </fieldset>
    <div class="no-edit" v-else>
      <div class="slot-content">
        <slot>
          <span>
            {{ modelValue }}
          </span>
        </slot>
      </div>
      <button @click="edit = true" class="btn btn-primary" v-if="showControls" title="Edit" aria-label="Edit">
        <i class="fas fa-edit"></i>
      </button>
    </div>
  </div>
</template>

<style scoped>
.k-editable-text-field {
  margin-bottom: 1em;
}

.field-label {
  display: block;
  font-style: italic;
  color: var(--kate-primary);
}

fieldset,
.no-edit {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
}

.no-edit .slot-content h1,
.no-edit .slot-content h2,
.no-edit .slot-content h3,
.no-edit .slot-content h4,
.no-edit .slot-content h5,
.no-edit .slot-content h6,
.no-edit .slot-content p {
  margin: 0;
}

.btn.btn-danger,
.btn.btn-success {
  padding: 4px 8px;
}

.btn.btn-primary {
  padding: 3px 6px 4px 8px;
}
</style>

<script>

export default {
  props: {
    modelValue: {
      type: [String, Array],
    },
    type: {
      type: String,
      default: 'default',
      enum: ['default', 'area'],
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 255,
    },
    editOverride: {
      default: undefined,
    },
    showControls: {
      type: Boolean,
      default: true,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      edit: false,
      textField: '',
    };
  },

  mounted() {
    this.textField = this.modelValue;
  },

  watch: {
    modelValue(val) {
      this.textField = val;
    },
    edit(val) {
      this.$emit('editing', val);
    },
    textField(val) {
      this.$emit('update', val); // Might need to rename this event. This is not the v-model update.
    },
  },

  computed: {
    actualEdit() {
      if (this.editOverride !== undefined) {
        return this.editOverride;
      }
      return this.edit;
    },
  },

  methods: {
    confirm() {
      this.edit = false;
      this.$emit('update:modelValue', this.textField);
    },
  },
};
</script>
