export default {
  mounted() {
    this.$emit('setVisibility', this.isVisible);
  },

  watch: {
    isVisible() {
      this.$emit('setVisibility', this.isVisible);
    },
  },
};
