<template>
  <div class="k-text-input">
    <fieldset class="detail-form form-entry">
      <label for="k-text-input" class="field-label">{{ label }}</label>
      <i class="fas fa-times reset-icon" @click="resetInput" title="Reset" v-if="showReset"/>
      <textarea id="k-text-input" v-if="multiline"
        v-model="inputText"
        :placeholder="inputPlaceholder"
        @keyup.enter="confirm"
        :readonly="readOnly"
        class="form-control"
      ></textarea>
      <input id="k-text-input" v-else class="form-control"
        :placeholder="inputPlaceholder"
        :readonly="readOnly"
        v-model="inputText"
      >
    </fieldset>
  </div>
</template>

<style scoped>
.k-text-input {
  margin-bottom: 1em;
  position: relative;
}

.field-label {
  display: block;
  font-style: italic;
  color: var(--kate-type-link) !important;
}

.k-text-input i.reset-icon {
  position: absolute;
  cursor: pointer;
  padding: 8px 8px 10px;
  right: 0;
  color: var(--kate-type-primary);
}

.k-text-input i.reset-icon:hover {
  color: var(--kate-type-danger);
}

.k-text-input .form-control[readonly] {
  background-color: var(--input-background);
  pointer-events: none;
}
</style>

<script>
export default {
  props: {
    modelValue: {
      type: String,
    },
    inputPlaceholder: {
      type: String,
      required: false,
      default: 'Enter text...',
    },
    showReset: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputText: this.modelValue,
    };
  },
  watch: {
    modelValue() {
      this.inputText = this.modelValue;
    },
    inputText() {
      this.$emit('update:modelValue', this.inputText);
    },
  },
  methods: {
    resetInput() {
      this.inputText = '';
      this.$emit('reset');
    },
  },
};
</script>
