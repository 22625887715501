<template>
  <tr >
    <td>
      <a :href="`/api/curriculum/pak/reading/resources/${id}`" target="_blank" class="reading-link">
        {{title || url}}<i class="fas fa-external-link-alt"></i>
      </a>
    </td>
    <td>
      <span class="rating-icons">
        <button class="upvote" :class="rating ? '' : 'greyed'" @click="updateVote(true)" aria-label="Upvote" title="Upvote"><i :class="rating !== true  ? 'fa-regular' : 'fa-solid' " class=" fa-thumbs-up"></i></button>
        <button class="downvote" :class="rating === false ? '' : 'greyed'" @click="updateVote(false)" aria-label="Downvote" title="Downvote"><i :class="rating !== false ? 'fa-regular' : 'fa-solid'" class=" fa-thumbs-down"></i></button>
      </span>
    </td>
  </tr>
</template>

<style>
.recommandations-table tbody td:last-child {
  width: 110px;
}

.recommandations-table .fa-external-link-alt {
  padding-left: 5px;
}

span.rating-icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
}

.rating-icons button {
  background-color: transparent;
  font-size: 1.15em;
  transition: all 0.2s ease-in-out;
  padding: 3px 8px;
}

.rating-icons button.upvote {
  color: var(--kate-success);
}

.rating-icons button.upvote:hover {
  color: var(--kate-success-alt);
}

.rating-icons button.downvote {
  color: var(--kate-danger-alt);
}

.rating-icons button.downvote:hover {
  color: var(--kate-danger-light);
}

.rating-icons button.greyed {
  color: var(--kate-type-primary);
}
</style>

<script>
export default {
  props: {
    rating: Boolean,
    title: String,
    url: String,
    id: Number,
  },

  methods: {
    updateVote(rating) {
      if (rating === this.rating) {
        this.$emit('rated', { rating: null, id: this.id });
      } else {
        this.$emit('rated', { rating, id: this.id });
      }
    },
  },
};
</script>
