export default {
  data() {
    return {
      selectedCoachId: undefined,
      selectedDataMentorId: undefined,
    };
  },

  computed: {
    userCoaches() {
      if (this.store && this.store.userCoaches) {
        return this.store.userCoaches;
      }
      return [];
    },
    hasCoaches() {
      return this.userCoaches.length > 0;
    },
    userDataMentors() {
      if (this.store && this.store.userDataMentors) {
        return this.store.userDataMentors;
      }
      return [];
    },
    hasDataMentors() {
      return this.userDataMentors.length > 0;
    },
    coachOptions() {
      return this.userCoaches.map(x => ({
        id: `${x.user_id}-${x.programme_id}`,
        name: `${x.name} - ${x.programme_name}`,
      }));
    },
    selectedCoach() {
      if (!this.selectedCoachId) {
        return undefined;
      }
      return this.userCoaches.find(x => `${x.user_id}-${x.programme_id}` === this.selectedCoachId);
    },
    dataMentorOptions() {
      return this.userDataMentors.map(x => ({
        id: `${x.user_id}-${x.programme_id}`,
        name: `${x.name} - ${x.programme_name}`,
      }));
    },
    selectedDataMentor() {
      if (!this.selectedDataMentorId) {
        return undefined;
      }
      return this.userDataMentors.find(x => `${x.user_id}-${x.programme_id}` === this.selectedDataMentorId);
    },
  },
};
