<template>
  <k-modal class="add-module-to-programme-modal" @close="close" :show="show">
    <template #header>
      <h3>Add Module</h3>
    </template>
    <template #body>
      <template v-if="moduleBlueprintsReady">
        <div class="add-module-to-programme-container">
          <div class="add-new-module-blueprint">
            <div class="add-module-filter-form">
              <label for="search-mod-input">Search</label>
              <input id="search-mod-input" v-model="moduleBlueprintsFilter">
            </div>
            <div class="add-module-filter-results">
              <k-module
                v-for="mod in paginatedEntries"
                class="k-module-item"
                :key="`add-${mod.id}`"
                :module="mod"
                :id="mod.id"
                :is-blueprint="isBlueprint"
                :show-add="true"
                :disable-add="disableAdd(mod.id)"
                :tags=mod.tags
                @add="addModule(mod)"
              >
              </k-module>
            </div>
            <pagination
              v-if="numOfPages"
              v-model="currentPage"
              :count="numOfPages"
              :total-entries="refinedModuleBlueprintSearch.length"
              :displayed-entries="paginatedEntries.length">
            </pagination>
          </div>
        </div>
      </template>
      <template v-else>
        <i class="fas fa-spinner fa-spin"></i>
      </template>
    </template>
  </k-modal>
</template>

<style scoped>
.add-module-filter-form {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: right;
}

.add-module-filter-results ul {
  list-style: none;
  padding-left: 0;
}
</style>

<script>
import KModal from '../../../components/k-modal.vue';
import Pagination from '../../../components/k-pagination.vue';
import stringMatch from '../../../modules/string-match';
import KModule from '../../programmes/modules/release-module.vue';
import ErrorMixin from '../../../mixins/error-mixins';

export default {
  components: {
    'k-modal': KModal,
    pagination: Pagination,
    KModule,
  },
  mixins: [ErrorMixin],
  props: {
    isBlueprint: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    maxItemsPerPage: {
      type: Number,
      default: 10,
    },
    excludedModules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moduleBlueprints: [],
      moduleBlueprintsReady: false,
      moduleBlueprintsFilter: '',
      currentPage: 0,
    };
  },
  beforeMount() {
    this.getModuleBlueprints();
  },
  computed: {
    refinedModuleBlueprintSearch() {
      return this.moduleBlueprints.filter(x => (stringMatch(x.name, this.moduleBlueprintsFilter)));
    },
    numOfPages() {
      if (this.refinedModuleBlueprintSearch.length <= this.maxItemsPerPage) {
        return undefined;
      }
      return Math.ceil(this.refinedModuleBlueprintSearch.length / this.maxItemsPerPage);
    },
    paginatedEntries() {
      if (!this.numOfPages) {
        return this.refinedModuleBlueprintSearch;
      }
      const startingIndex = this.maxItemsPerPage * this.currentPage;
      const endingIndex = Math.min(this.maxItemsPerPage + startingIndex, this.refinedModuleBlueprintSearch.length);
      return this.refinedModuleBlueprintSearch.slice(startingIndex, endingIndex);
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    addModule(mod) {
      this.$emit('add', mod);
    },
    disableAdd(id) {
      return this.excludedModules && this.isBlueprint && this.excludedModules.indexOf(id) !== -1;
    },
    getModuleBlueprints() {
      this.$logger.info('Getting module blueprints');
      this.moduleBlueprintsReady = false;
      this.moduleBlueprints = [];
      return this.$http.get('/api/curriculum/blueprints/modules').then(res => {
        this.moduleBlueprints = res.data.blueprints;
        this.$logger.info('Got module blueprints');
      }).catch(err => {
        if (this.$http.isWarning(err)) {
          this.$logger.warn('Error getting module blueprints', {}, err);
        } else {
          this.$logger.error('Error getting module blueprints', {}, err);
        }
        this.showError(err);
      }).then(() => {
        this.moduleBlueprintsReady = true;
      });
    },

  },
};

</script>
