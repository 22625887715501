<template>
  <div id="dashboard-landing" :class="sidebarClass">
    <router-view class="content-with-spacing" v-bind="pageProps"></router-view>
  </div>
</template>

<style>
.breadcrumb-container + #dashboard-landing {
  margin-top: 45px;
}

#content #dashboard-landing h1 {
  margin: 15px 0;
  padding: 10px;
}
</style>

<script>
import SidebarMixin from '../mixins/sidebar-mixin';

export default {

  mixins: [SidebarMixin],

  computed: {
    pageProps() {
      switch (this.$route.name) {
        case 'users_trainer':
          return { mentorType: 'Trainer' };
        default:
          return {};
      }
    },
  },
};
</script>
