<template>
  <button class="item">
    <k-tooltip text="Click to add to module" class="release-object-tooltip-box">
      <div class="release-button-content">
        <div><i :class="getAssetIcon(assetType)"></i></div>
        <div>
          <div class="asset-name">{{ name }}</div>
          <div class="tag-container">
            <div class="hash-tags" v-for="tag in tags"><i class="fas fa-hashtag"></i> {{ tag }}</div>
          </div>
        </div>
        <div><i class="fas fa-plus-circle add-icon"></i></div>
      </div>
    </k-tooltip>
  </button>
</template>

<script>
import KTooltip from '../../../../components/k-tooltip.vue';
import AssetIconMixin from '../../../../mixins/asset-icon-mixin';

export default {
  components: {
    KTooltip,
  },

  mixins: [AssetIconMixin],

  props: {
    name: String,
    assetType: String,
    tags: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
button .release-button-content {
  text-align: center;
  align-items: center;
  justify-content: center;
}

button .release-button-content,
button .release-button-content div .tag-container {
  display: flex;
  flex-direction: row;
}

button .release-button-content div .asset-name {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

button .release-button-content > div {
  flex: 75%;
  overflow: hidden;
}

button .release-button-content > div > i {
  color: var(--kate-background-body);
}

button .release-button-content div:last-child,
button .release-button-content div:first-child {
  flex: 10%;
}

button .release-button-content div .tag-container .hash-tags {
  font-size: 0.75em;
  color: var(--kate-type-primary);
  white-space: nowrap;
}

button .release-button-content div .tag-container {
  overflow-x: hidden;
  gap: 20px;
  max-width: 100%;
}

button .release-button-content div .tag-container .hash-tags i {
  color: var(--kate-warning);
}

.release-object-tooltip-box {
  width: 100%;
}
</style>
