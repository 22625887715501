<template>
  <div id="multi-input-form">
    <slot v-if="label" name="label">
      <span class="field-label">{{ label }}:</span>
    </slot>
    <div v-if="modelValue.length" class="text-area-array-container">
      <ul>
        <li v-for="(item, index) in modelValue">
          <div class="text-area-entry">
            <k-editable-text-field
              :modelValue="item"
              @update="val => updateData(val, index)"
              :placeholder="inputPlaceholder"
              :show-controls = "false"
              :multiline="multiline"
              :edit-override="editMode"
            ></k-editable-text-field>
            <button v-if="editMode" @click="removeTextField(index)" class="btn btn-danger" aria-label="Remove" title="Remove">
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </li>
      </ul>
    </div>
    <p v-else>
      <i class="fas fa-exclamation-triangle"></i> {{ emptyMessage }}
    </p>
    <button v-if="editMode" @click="addNewTextField" aria-label="Add" title="Add" class="btn btn-primary">
      <i class="fas fa-plus"></i>
    </button>
  </div>
</template>

<style scoped>
#multi-input-form {
  width: 100%;
}

.text-area-array-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.text-area-array-container {
  display: flex;
  flex-direction: column;
}

.k-editable-text-field {
  margin: 0 !important;
  width: 100%;
}

.field-label {
  display: block;
  font-style: italic;
  color: var(--kate-primary) !important;
}

.text-area-entry {
  flex-direction: row;
}
</style>

<script>
import KEditableTextField from './k-editable-text-field.vue';

export default {
  components: { KEditableTextField },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    inputPlaceholder: {
      type: String,
      required: false,
      default: 'Enter value...',
    },
    nPlaceholders: {
      type: Number,
      required: false,
      default: 3,
    },
    emptyMessage: {
      type: String,
      required: false,
      default: 'There are no items to display',
    },
    maxlength: {
      type: Number,
      required: false,
      default: 1024,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: undefined,
    },
    multiline: {
      type: Boolean,
      default: true,
    },
  },

  beforeMount() {
    this.defaultState(this.modelValue);
  },

  methods: {
    defaultState(values) {
      const defaultValues = values || [];
      const valCopy = defaultValues.length >= this.nPlaceholders ? defaultValues : [
        ...defaultValues,
        ...Array(this.nPlaceholders - defaultValues.length).fill('')];
      this.$emit('update:modelValue', valCopy);
    },
    updateData(val, index) {
      const valCopy = [...this.modelValue];
      valCopy[index] = val;
      this.$emit('update:modelValue', valCopy);
    },
    addNewTextField() {
      this.$emit('update:modelValue', [...this.modelValue, '']);
    },
    removeTextField(index) {
      this.$emit('update:modelValue', this.modelValue.filter((el, i) => i !== index));
    },
  },
};
</script>
