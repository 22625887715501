import ErrorMixin from './error-mixins';
import NotebookMixin from './notebook-mixin';
import { copyToClipboard } from '../modules/copy-to-clipboard';

export default {
  mixins: [ErrorMixin, NotebookMixin],

  data() {
    return {
      cloneToast: undefined,
      repoAccessToken: undefined,
      repoReady: false,
    };
  },
  methods: {
    createRepoAccessToken() {
      this.repoReady = false;
      this.$http.post('/api/auth/gitlab/repo_access_token/repository').then(response => {
        this.repoAccessToken = response.data.token;
        this.repoReady = true;
      }).catch(err => {
        this.showError(err, true);
      });
    },
    getRepoAccessToken() {
      this.repoReady = false;
      this.$http.get('/api/auth/gitlab/repo_access_token/repository').then(response => {
        this.repoAccessToken = response.data.token;
        this.repoReady = true;
      }).catch(err => {
        this.showError(err, true);
      });
    },
    getHttpUrl(url) {
      return `${url.substring(0, 8)}oauth2:${this.repoAccessToken}@${url.substring(8)}`;
    },
    copyHttp(url) {
      copyToClipboard(this.getHttpUrl(url));
    },
    copySsh(url) {
      copyToClipboard(url);
    },
  },
};
