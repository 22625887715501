import { reactive } from 'vue';
import flatten from '../modules/flatten';
import { SIDEBAR_ENABLED_PAGES, ASSET_TYPES } from '../constants';

export class SidebarHandler {
  constructor() {
    this.moduleId = undefined;
    this.moduleDetails = undefined;
    this.chapters = undefined;
    this.threads = undefined;
    this.ready = false;
    this.loadModuleDataCallback = undefined;
    this.refreshThreadsCallback = undefined;
    this.visibility = true;
  }

  updateAssetScore(assetType, moduleAssetId, score) {
    if (ASSET_TYPES[assetType].completion_type === 'submission') {
      // NOTE: This .find will only work if moduleAssetId is an INTEGER
      // which it may not be if it has come from a route parameter (could use == instead of ===)
      const asset = flatten(this.chapters.map(ch => ch.assets || []))
        .find(a => a.asset_type === assetType && a.id === moduleAssetId);
      if (asset) {
        asset.latest_score = score;
      }
    }
  }

  updateCompletionStatus(assetType, moduleAssetId, isComplete) {
    const asset = flatten(this.chapters.map(ch => ch.assets.filter(a => a.asset_type === assetType))).find(
      ma => ma.id === moduleAssetId,
    );
    if (asset) {
      asset.is_complete = isComplete;
    }
  }

  setRefreshThreadsCallback(refreshThreadsCallback) {
    this.refreshThreadsCallback = refreshThreadsCallback;
  }

  refreshThreads() {
    if (this.refreshThreadsCallback) {
      this.refreshThreadsCallback();
    }
  }

  setLoadModuleDataCallback(loadModuleDataCallback) {
    this.loadModuleDataCallback = loadModuleDataCallback;
  }

  loadModuleData() {
    if (this.loadModuleDataCallback) {
      this.loadModuleDataCallback();
    }
  }

  setModuleId(id) {
    // Called when changing modules
    if (this.moduleId !== id) {
      this.moduleId = id;
      this.loadModuleData();
    }
  }

  static isEnabled(name) {
    return SIDEBAR_ENABLED_PAGES.indexOf(name) !== -1;
  }

  setVisibility(visibility) {
    this.visibility = visibility;
  }
}

export default {
  install(app) {
    const handler = new SidebarHandler();
    app.config.globalProperties.$sidebar = reactive(handler); // eslint-disable-line no-param-reassign
    app.config.globalProperties.$sidebar.isEnabled = SidebarHandler.isEnabled; // eslint-disable-line no-param-reassign
  },
};
