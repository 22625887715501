<template>
  <div>
    <template v-if="grey">
      <a class="k-file-link grey">{{ filename }}
        <span v-if="fileLevelError" class="label syn-error-label k-layout-label-not_implemented">File Error</span>
      </a>
    </template>
    <template v-else>
      <a class="k-file-link clickable" @click="emitSelect(filename)" :class="{ toggled : toggled }">
        <span class="underline-on-hover-active">{{ filename }}</span>
        <span v-if="entry.error_count" class="error-badge" title="Tests failed">
          {{ entry.error_count }}
        </span>
        <!-- Hack for summative pls remove -->
        <template v-else-if="!entry.functions.length">
          <span class="error-badge"><i class="fas fa-times"></i></span>
          <span class="label syn-error-label k-layout-label-not_implemented">File Error</span>
        </template>
        <span v-if="fileLevelError" class="label syn-error-label k-layout-label-not_implemented">File Error</span>
        <span v-else-if="!entry.error_count" class="success-badge"><i class="fas fa-check"></i></span>
        <i
          v-if="entry.functions.length"
          class="toggle-icon fas"
          :class="iconClass"
        ></i>
      </a>
      <ul class="k-function-list">
        <li
          v-for="func in entry.functions"
          :key="func.function_name"
          @click="emitSelect(filename, func.function_name), isActive = func.function_name"
          :data-id="func.function_name"
          class="clickable function-entry"
        >
          <a class="k-function-link" :class="{ active : func.function_name == isActive }">{{ func.function_name }}</a>
          <span class="label" :class="`k-layout-label-${func.state}`">{{ getTag(func.state) }}</span>
        </li>
      </ul>
    </template>
  </div>
</template>

<style scoped>
.syn-error-label {
  float: right;
  line-height: 12pt;
}

.success-badge {
  color: var(--kate-success);
}

.error-badge {
  margin: 0 4px 0 2px;
  padding-left: 7px;
}

.function-entry:hover a {
  color: var(--kate-logo-green);
  text-decoration: underline;
}

a.k-file-link:hover,
a.k-file-link:active {
  text-decoration: none;
}

a.k-file-link:hover .underline-on-hover-active,
a.k-file-link:active .underline-on-hover-active {
  text-decoration: underline;
}

.k-file-link.grey {
  color: var(--kate-type-primary);
  cursor: default;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.k-file-link.k-file-link.grey:hover {
  text-decoration: none;
  color: var(--kate-type-primary);
}

.k-file-link + .k-function-list {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
  position: relative;
}

.k-file-link.toggled + .k-function-list {
  max-height: 700px;
  transition: max-height 0.3s;
  align-items: center;
  background-color: var(--kate-background-body-alpha);
}

.k-file-link.toggled {
  font-weight: bold;
  text-decoration: none;
  color: var(--kate-logo-lime-green);
}

.k-function-list span {
  align-self: center;
  padding: 5px 8px;
}

.k-function-list::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: var(--border-primary);
}

.k-function-list li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  position: relative;
}

.k-function-link.active {
  color: var(--kate-logo-lime-green);
}

.k-layout-label-success,
.k-layout-label-implemented {
  background-color: var(--kate-success-dark);
}

.k-layout-label-failure,
.k-layout-label-issues,
.k-layout-label-issue {
  background-color: var(--kate-secondary);
}

.k-layout-label-not_implemented {
  background-color: var(--kate-logo-magenta);
}

.k-layout-label-error {
  background-color: var(--kate-danger);
}

.k-layout-label-failure,
.k-layout-label-success,
.k-layout-label-implemented {
  color: var(--kate-type-dark);
}
</style>

<script>
const TAG_MAPPING = {
  failure: 'Issues',
  issues: 'Issues',
  issue: 'Issue',
  success: 'Passing',
  implemented: 'Implemented',
  not_implemented: 'Not Implemented',
  // unknown: 'Error',
  // Hack while we figure out how to handle summary without evaluation
  unknown: '',
  error: 'Error',
};

export default {
  props: {
    entry: Object,
    filename: String,
    startToggled: {
      type: Boolean,
      default: false,
    },
    fileLevelError: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggled: false,
      isActive: undefined,
    };
  },

  beforeMount() {
    this.toggled = this.startToggled;
  },

  watch: {
    startToggled() {
      this.toggled = this.startToggled;
    },
  },

  computed: {
    iconClass() {
      if (this.fileLevelError) {
        return '';
      }
      return this.toggled ? 'fa-caret-up' : 'fa-caret-down';
    },
  },

  methods: {
    getTag(key) {
      return TAG_MAPPING[key] || '';
    },

    emitSelect(filename, functionName) {
      if (!this.fileLevelError && !functionName) {
        // Clicking on a non-file-error file toggles the function list
        this.toggled = !this.toggled;
      }
      this.$emit('select', filename, functionName);
    },
  },
};
</script>
