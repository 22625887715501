<template>
  <div>
    <div class="panel panel-default" v-if="InfoResults.length">
      <div class="row summary-panel k-feedback-test-block">
        <h4 class="evaluation-title feedback-panel-title">
          <i class="fas fa-code"></i> Information
        </h4>
      </div>
      <div class="info-results">
        <div class="single-result" v-for="entry in InfoResults">
          <h5 class="report-title">{{entry.test_description}}</h5>
          <pre class="result-pre">{{entry.returned}}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.report-title {
  font-size: larger;
}

.info-results {
  display: block;
  width: 100%;
  padding: 0 15px 15px;
}

h4.evaluation-title {
  padding: 15px;
  width: 100%;
}

.panel-default {
  padding: 10px;
}

</style>

<script>
export default {
  props: {
    InfoResults: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
