<template>
  <li v-if="isVisible" class="nav-group" :class="`${toggled ? 'opened-dropdown': 'closed-dropdown'}`">
    <button class="nav-group-title" aria-label="Toggle dropdown" title="Toggle dropdown" @click="toggled = !toggled">
      <i class="k-asset-icon fa-duotone fa-building-user"></i><span>Users</span>
      <i class="fas fa-angle-down"></i>
    </button>
    <ul class="second-level" v-if="toggled">
      <li v-if="$permissions.hasPermission('manage_coaches') || $permissions.hasPermission('manage_mentors')" class="nav-sub-group">
        <div class="nav-sub-group-title">
          <router-link :to="{  name: 'learner_support'}">
            <span>Learner Support</span>
          </router-link>
        </div>
      </li>
      <li class="nav-sub-group">
        <div class="nav-sub-group-title">
          <router-link :to="{  name: 'users_trainer'}">
            <span>Trainers</span>
          </router-link>
        </div>
      </li>
      <li v-if="$permissions.hasPermission('manage_roles')" class="nav-sub-group">
        <div class="nav-sub-group-title">
          <router-link :to="{ name: 'users_privileged_users'}">
            <span>Privileged Users</span>
          </router-link>
        </div>
      </li>
    </ul>
  </li>
</template>

<style>
  .sidebar-panel .nav-sub-group.nav-sub-dropdown.no-dropdown .nav-sub-group-dropdown-title {
    padding-right: 0;
  }
</style>

<script>
import SidebarEntryMixin from '../../mixins/sidebar-entry-mixin';

export default {
  mixins: [SidebarEntryMixin],

  data() {
    return {
      toggled: false,
    };
  },

  computed: {
    isVisible() {
      return this.hasUsersPermissions;
    },

    hasUsersPermissions() {
      return this.$permissions.hasPermission('manage_coaches')
        || this.$permissions.hasPermission('manage_mentors')
        || this.$permissions.hasPermission('manage_roles');
    },
  },
};
</script>
