<template>
  <k-error-page>
    <template #code>
      <h1>403</h1>
    </template>
    <template #error-message>
      <p>You do not seem to have access to this page.<br/>If you think this is a mistake please contact us.</p>
    </template>
  </k-error-page>
</template>

<script>
import KErrorPage from './k-error-page.vue';

export default {
  components: {
    KErrorPage,
  },
};
</script>
