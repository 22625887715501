import Pluralize, { plural } from 'pluralize';

export default {
  methods: {
    pluralize(word, count, inclusive) {
      return Pluralize(word, count, inclusive);
    },
    plural(word) {
      return plural(word);
    },
  },
};
