<template>
  <div>
    <section v-if="allowAssetCreation" class="panel panel-default">
      <div class="panel-body">
        <h2 aria-label="Toggle" title="Toggle" @click="toggleUploadAsset" class="clickable">
          <i class="fa-duotone k-asset-icon" :class="assetIcon"></i> New
          <i class="fas" :class="toggleClass"></i>
        </h2>
        <template v-if="showNewAsset">
          <slot name="new-asset"></slot>
        </template>
      </div>
    </section>
    <section class="panel panel-default">
      <div class="panel-body">
        <div class="header">
          <h2>
            <i class="fa-duotone k-asset-icon" :class="assetIcon"></i> Existing
          </h2>
          <div class="toggle-container">
            <k-toggle v-model="showArchived" :round="true" label="Show Archived" title="Check this box to display archived asset"></k-toggle>
          </div>
        </div>
        <div class="row" id="k-add-asset-table-container">
          <k-table
            :headers="kableHeaders"
            :rows="kableRows"
            :max="20"
            :hideable="true"
            :panel="false"
            @clicked="tableCallback">
          ></k-table>
        </div>
      </div>
    </section>
    <slot name="preview-asset"></slot>
  </div>
</template>

<style>
#k-add-asset-table-container .k-table-container {
  width: 100%;
}

#k-add-asset-table-container .k-table-container th {
  min-width: unset;
}

#k-add-asset-table-container th.k-table-header.k-table-header-repository {
  min-width: 150px;
}

#k-add-asset-table-container td.k-table-string.k-table-key-repository {
  word-break: break-all;
}

#k-add-asset-table-container th:last-child {
  min-width: 85px;
}

#k-add-asset-table-container table > tbody > tr > td.clickable {
  vertical-align: middle;
  text-align: center;
}

#k-add-asset-table-container .k-table-key-title {
  color: var(--kate-type-light);
}

#k-add-asset-table-container td.k-table-string.k-table-key-description {
  max-width: 600px;
  min-width: 250px;
}

.video-preview-modal .modal-container,
.slides-preview-modal .modal-container {
  max-height: 90vh;
}

#k-add-videos-dropfield p i,
#k-add-slides-dropfield p i {
  display: block;
  font-size: 2.5em;
  padding: 0 0 15px;
}

/* Tags */
.video-tags .k-search-dropdown-button,
.slides-tags .k-search-dropdown-button,
.calendar-events-tags .k-search-dropdown-button {
  padding: 6px 12px;
  border: 0;
  font-size: 0.875em;
  min-width: 200px;
}

.video-tags .k-search-dropdown-adv-content,
.slides-tags .k-search-dropdown-adv-content,
.calendar-events-tags .k-search-dropdown-adv-content {
  min-width: 350px;
}

.k-slides-key-displayTags span.badge,
.k-calendar-events-key-displayTags span.badge {
  font-size: 0.875em;
  font-weight: normal;
  color: var(--kate-type-primary);
}

span.module-released {
  display: inline-block;
  margin-left: 20px;
}
</style>

<style scoped>
h2 > i.fas.fa-plus,
h2 > i.fas.fa-minus {
  float: right;
}

.header {
  display: flex;
  justify-content: space-between;
}

.k-toggle {
  display: flex;
  justify-content: center;
}

.k-table-action {
  text-align: center;
}
</style>

<script>
import KTable from '../../../components/k-table.vue';
import KToggle from '../../../components/k-toggle.vue';

export default {
  components: {
    KTable,
    KToggle,
  },

  props: {
    assets: {
      type: Array,
      required: true,
    },
    assetIcon: String,
    kableHeaders: Object,
    kableRows: Array,
    allowAssetCreation: {
      type: Boolean,
      default: true,
    },
    showArchivedAssets: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showNewAsset: false,
      showArchived: this.showArchivedAssets,
      previewAssetId: undefined,
    };
  },

  watch: {
    showArchived() {
      this.$emit('archive-toggle', this.showArchived);
    },
    showArchivedAssets() {
      this.showArchived = this.showArchivedAssets;
    },
  },

  computed: {
    // == Rendering
    toggleClass() {
      return this.showNewAsset ? 'fa-minus' : 'fa-plus';
    },
  },

  methods: {
    toggleUploadAsset() {
      this.showNewAsset = !this.showNewAsset;
    },

    // == Table callbacks
    tableCallback(key, index, row, header) {
      this.$emit('tablecallback', key, index, row, header);
    },
  },
};
</script>
