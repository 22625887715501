<template>
  <div v-if="ready && currentModule" class="content-with-spacing" id="manage-module">
    <h1>{{ currentModule.name }}</h1>
    <div class="row">
      <!-- Asset release navigation -->
      <aside class="release-nav">
        <ul>
          <li>
            <router-link :to="metaTo">
              <i class="k-asset-icon fas fa-bullseye"></i>
              <b>Module Meta</b>
              <i class="fas fa-angle-right"></i>
            </router-link>
          </li>
          <li>
            <router-link :to="chapterTo">
              <i class="k-asset-icon fas fa-book"></i>
              <b>Chapters</b>
              <i class="fas fa-angle-right"></i>
            </router-link>
          </li>
          <!-- Assets -->
          <li v-for="assetType in Object.keys(ASSET_TYPES)" :key="assetType">
            <router-link :to="assetTo(assetType)">
              <i class="k-asset-icon" :class="getAssetIcon(assetType)"></i>
              <b>{{ plural(ASSET_TYPES[assetType].prettyName) }}</b>
              <i class="fas fa-angle-right"></i>
            </router-link>
          </li>
        </ul>
      </aside>
      <!-- Release panel -->
      <section class="release-panel">
        <div class="panel panel-default">
          <router-view v-bind="pageProps($route.name)"
            @update="updateModuleMeta"
            @chaptersUpdated="getModuleData"
            @assetsUpdated="assetCallback"
          ></router-view>
        </div>
      </section>
    </div>
  </div>
</template>

<style>
#manage-module .k-editable-table-container {
  overflow: auto;
}

#manage-module .k-edit-table-th:first-child {
  min-width: 200px;
}

#manage-module .k-edit-table-th:last-child .k-edit-table-header .header-left {
  justify-content: center;
}

#manage-module .k-editable-table-pagination.panel-pagination.row {
  margin: 0 0 15px;
}

#manage-module .k-editable-table-container .cell-row.date,
#manage-module .k-editable-table-container .cell-row.datetime {
  min-width: 220px;
}

#manage-module .panel-pagination.row {
  position: sticky;
  left: 0;
}

#manage-module .splitpanes.custom-theme.modal-theme .splitpanes__splitter {
  min-height: 500px;
  height: unset;
}

#manage-module .splitpanes.custom-theme.modal-theme .splitpanes__pane.left-pane {
  max-height: 90vh;
  overflow-y: auto;
}

#manage-module .table-controls {
  display: flex;
  justify-content: right;
  gap: 10px;
}

/*  Tables */
#manage-module .release-table {
  margin-left: 15px;
}

#manage-module .release-table .k-editable-table-container {
  margin-bottom: 20px;
  min-height: 300px;
}

#manage-module .release-table .btn-remove {
  background-color: transparent;
  padding: 5px 8px;
}

#manage-module .release-table .btn-remove i {
  color: var(--kate-danger-alt);
}

#manage-module .release-table .btn-remove:hover i {
  color: var(--kate-danger);
}

#manage-module .release-table td:last-child {
  text-align: center;
}

.k-text-search.filter-input {
  margin: 0 15px;
}

@media screen and (max-width: 767px) {
  #manage-module .release-table .k-editable-table-container,
  #k-released-release-table-container table {
    overflow: auto;
  }
}

.release-asset-container tr.k-editable-table-row.unreleased:nth-child(odd) {
  background-color: var(--kate-success);
}

.release-asset-container tr.k-editable-table-row.unreleased:nth-child(even) {
  background-color: var(--kate-success-dark);
}

/* For module release */
.release-asset-container ul {
  padding: 0 !important;
}

.release-asset-container li.list-items {
  color: unset;
  list-style: none;
}

.release-asset-container li.list-items .item {
  width: 90%;
  padding: 5px;
  display: block;
  margin: 8px auto 0;
  border-radius: 3px;
  transition: all 0.1s ease;
  color: var(--kate-type-white);
  background-color: var(--kate-primary-dark);
}

.release-asset-container li.list-items .item:hover {
  background-color: var(--kate-panel-alt);
}

.release-asset-container li.list-items .item.selected {
  transform: translateX(20px);
  background-color: var(--kate-background-body);
}

.release-asset-container .k-search-dropdown-menu .btn-primary {
  padding: 5px 12px;
}

#manage-module .loading {
  font-size: 2em;
  padding: 15px;
  text-align: center;
}

#manage-module .loading .fa-spin {
  background: var(--right-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

<style scoped>
#manage-module h1 {
  margin-top: 0;
}

.button-container {
  height: 55px;
}

.learning-outcomes-mock ol li {
  display: block;
  margin-top: 20px;
}

.learning-outcomes-mock ol li i {
  color: var(--kate-type-accent);
}

.row {
  gap: 15px;
  display: flex;
  margin: 0;
}

.release-nav ul .fa-angle-right {
  margin-top: 3px;
  margin-left: 1em;
}

/* Release nav */
.release-nav {
  width: 48px;
  height: 100%;
  overflow: hidden;
  transition: overflow 0.3s ease-in-out, width 0.4s ease-in-out;
  background-color: var(--kate-panel);
  box-shadow: var(--box-shadow);
  border-radius: 15px;
}

.release-nav:hover {
  width: 250px;
  overflow: visible;
  transition: overflow 0.3s ease-in-out, width 0.4s ease-in-out;
}

.release-nav b,
.release-nav .fa-angle-right {
  opacity: 0;
  transition: opacity 0.4s;
  transition-delay: 2ms;
}

.release-nav:hover b,
.release-nav:hover .fa-angle-right {
  opacity: 1;
  transition: opacity 0.4s;
  transition-delay: 140ms;
}

.release-nav ul {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.release-nav ul li {
  list-style: none;
  padding: 0;
}

.release-nav ul li a {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 15px;
  color: var(--kate-type-primary);
}

.release-nav ul li a:active,
.release-nav ul li a.router-link-exact-active {
  text-decoration: none;
}

.release-nav ul li:hover {
  background-color: var(--kate-background-alpha);
  color: var(--kate-type-light);
}

.release-nav ul li:first-child,
.release-nav ul li:first-child .router-link-exact-active {
  border-radius: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.release-nav ul li .router-link-exact-active {
  color: var(--kate-primary);
  background-color: var(--kate-background-alpha);
}

.release-nav ul li a b {
  text-align: left;
  flex: 1;
}

.release-nav ul li a i {
  text-align: center;
}

/* Release panel */
.release-panel {
  flex: 1;
  width: 80%;
}

.release-panel .panel {
  padding: 15px;
  min-height: 592px;
}
</style>

<script>
import ErrorMixin from '../../../mixins/error-mixins';
import TimeMixin from '../../../mixins/time-mixins';
import ChaptersMixin from '../../../mixins/chapters-mixin';
import PluralMixin from '../../../mixins/plural-mixin';
import AssetIconMixin from '../../../mixins/asset-icon-mixin';
import { sortObjectArray } from '../../../modules/sort-by-object-property';
import getOrNull from '../../../modules/get-or-null';
import { ASSET_TYPES } from '../../../constants';

export default {
  mixins: [ErrorMixin, TimeMixin, ChaptersMixin, AssetIconMixin, PluralMixin],

  data() {
    return {
      programme: {},
      programmeReady: false,
      trainers: [],
      trainersReady: false,
      chapters: [],
      modules: [],
      modulesReady: false,
      chaptersReady: false,
      ASSET_TYPES,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgramme();
    this.getModuleData();
    this.getTrainers();
  },

  watch: {
    ready() {
      if (this.ready) {
        this.$Loading.finish();
        this.registerCrumbs();
      } else {
        this.$Loading.start();
      }
    },
    route() {
      // Re-register crumbs (nav guard clears them on route change)
      this.registerCrumbs();
    },
  },

  computed: {
    ready() {
      let ready = this.chaptersReady && this.modulesReady;
      if (!this.isBlueprint) {
        ready = ready && this.programmeReady && this.trainersReady;
      }
      return ready;
    },
    programmeId() {
      return parseInt(this.$route.params.programmeId, 10);
    },
    programmeGroups() {
      return getOrNull('programme_groups', this.programme, []);
    },
    moduleIdFromRoute() {
      if (this.isBlueprint) {
        return parseInt(this.$route.params.moduleBlueprintId, 10);
      }
      return parseInt(this.$route.params.moduleId, 10);
    },
    currentModule() {
      if (this.ready) {
        return this.modules.find(x => x.id === this.moduleIdFromRoute);
      }
      return undefined;
    },
    learningOutcomes() {
      if (this.currentModule) {
        return this.currentModule.learning_outcomes;
      }
      return undefined;
    },
    isBlueprint() {
      return [
        'module_blueprint_meta_manager',
        'module_blueprint_chapter_manager',
        'module_blueprint_asset_release',
      ].includes(this.$route.name);
    },
    chapterEndpoint() {
      if (this.isBlueprint) {
        return `/api/curriculum/blueprints/modules/${this.moduleIdFromRoute}/chapters`;
      }
      return `/api/curriculum/admin/modules/${this.moduleIdFromRoute}/chapters`;
    },
    metaTo() {
      return { name: `module_${this.isBlueprint ? 'blueprint_' : ''}meta_manager` };
    },
    chapterTo() {
      return { name: `module_${this.isBlueprint ? 'blueprint_' : ''}chapter_manager` };
    },
    route() {
      return this.$route;
    },
  },

  methods: {
    assetTo(assetType) {
      return {
        name: `module_${this.isBlueprint ? 'blueprint_' : ''}asset_release`,
        params: { assetType },
      };
    },
    pageProps(pageName) {
      switch (pageName) {
        case `module_${this.isBlueprint ? 'blueprint_' : ''}meta_manager`:
          return {
            moduleMeta: this.currentModule,
            isBlueprint: this.isBlueprint,
          };
        case `module_${this.isBlueprint ? 'blueprint_' : ''}chapter_manager`:
          return {
            id: this.moduleIdFromRoute,
            isBlueprint: this.isBlueprint,
            chapters: this.chapters,
          };
        case `module_${this.isBlueprint ? 'blueprint_' : ''}asset_release`:
          return this.assetPageProps(this.$route.params.assetType);
        default:
          return {};
      }
    },
    assetPageProps(assetType) {
      const props = {
        id: this.moduleIdFromRoute,
        isBlueprint: this.isBlueprint,
        assetType,
        releasedAssets: this.assetsOfType(assetType),
      };
      if (this.isBlueprint) {
        return props;
      }
      props.programmeGroups = this.programmeGroups;
      switch (assetType) {
        case 'pak':
          return {
            ...props,
            requireWithdrawalConfirmation: true,
          };
        case 'learning_unit':
          return {
            ...props,
            requireWithdrawalConfirmation: true,
          };
        case 'quiz':
          return {
            ...props,
            requireWithdrawalConfirmation: true,
          };
        case 'calendar_event':
          return {
            ...props,
            moduleReleaseDate: this.currentModule.release_date,
            moduleCompletionDate: this.currentModule.expected_completion_date,
            useTimezones: !this.isBlueprint,
            trainers: this.trainers,
          };
        // TODO: Questionnaires should have a check to see if there have been submissions - DATA LOSS RISK
        default:
          return props;
      }
    },
    registerCrumbs() {
      let crumbs;
      if (!this.currentModule) {
        return;
      }
      if (this.isBlueprint) {
        crumbs = [
          {
            text: 'Manage module blueprints',
            active: false,
            path: {
              name: 'curriculum_blueprints_modules',
            },
          },
          {
            text: this.currentModule.name,
            active: true,
          },
        ];
      } else {
        crumbs = [
          {
            text: 'Manage programmes',
            path: {
              name: 'programmes_overview',
            },
          },
          {
            text: this.programme.name,
            path: {
              name: 'manage_programme_content',
              params: { programmeId: this.programmeId },
            },
          },
          {
            text: this.currentModule.name,
            active: true,
          },
        ];
      }
      this.$crumbs.register(crumbs);
    },
    getModuleData() {
      const promises = [];
      if (this.isBlueprint) {
        promises.push(this.getModuleBlueprint());
        promises.push(this.getModuleBlueprintChapters());
      } else {
        promises.push(this.getModuleChapters());
        promises.push(this.getModules());
      }
    },
    getProgramme() {
      if (this.isBlueprint) {
        return;
      }
      this.programmeReady = false;
      this.$logger.info('Getting programme info', { programmeId: this.programmeId });
      this.$http.get(`/api/curriculum/programmes/${this.programmeId}`).then(res => {
        this.$logger.info('Successfully fetched programme info', { programmeId: this.programmeId });
        this.programme = res.data;
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          this.$router.push({ name: '404' });
        } else if (this.$http.isWarning(err)) {
          this.$logger.warn('Could not retrieve programme', { programmeId: this.programmeId }, err);
          this.showError(err);
        } else {
          this.$logger.error('Error fetching programme info', { programmeId: this.programmeId }, err);
        }
      }).then(() => {
        this.programmeReady = true;
      });
    },
    getTrainers() {
      if (this.isBlueprint) {
        return;
      }
      this.$logger.info('Getting all trainers');
      this.trainersReady = false;
      this.$http.get('/api/profile/trainer').then(result => {
        this.$logger.info('Got trainers');
        this.trainers = result.data.trainers;
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          this.$logger.warn('No trainers found');
        } else {
          this.showError(err);
          this.$logger.autowarn('Not able to get trainers', undefined, err);
        }
      }).then(() => {
        this.trainersReady = true;
      });
    },
    getModules() {
      this.$logger.info('Getting module for programme', { progId: this.programmeId });
      this.modulesReady = false;
      this.modules = [];
      return this.$http.get(`/api/curriculum/admin/programmes/${this.programmeId}/modules`).then(res => {
        this.modules = sortObjectArray(res.data.modules, 'number');
        this.originalModules = sortObjectArray(JSON.parse(JSON.stringify(res.data.modules)), 'number');
        this.$logger.info('Got module for programme', { progId: this.programmeId });
      }).catch(err => {
        this.$logger.autowarn('Error getting module for programme', { progId: this.programmeId }, err);
        this.showError(err);
      }).then(() => {
        this.modulesReady = true;
        this.$Loading.finish();
      });
    },
    getModuleChapters() {
      this.$logger.info('Getting chapters');
      this.chaptersReady = false;
      return this.$http.get(this.chapterEndpoint).then(res => {
        this.$logger.info('Got chapters');
        this.chapters = res.data.chapters;
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          this.$router.push({ name: '404' });
        } else {
          this.showError(err);
          this.$logger.autowarn('Error getting chapters', { id: this.id }, err);
        }
      }).then(() => {
        this.chaptersReady = true;
      });
    },
    getModuleBlueprint() {
      this.$logger.info('Getting module blueprint');
      this.modulesReady = false;
      return this.$http.get(`/api/curriculum/blueprints/modules/${this.moduleIdFromRoute}`)
        .then(res => {
          this.modules = [res.data.blueprint];
          this.$logger.info('Successfully retrieved module blueprints');
        })
        .catch(err => {
          if (this.$http.errIn(err, [404])) {
            this.$router.push({ name: '404' });
          } else {
            this.$logger.autowarn('Could not retrieve module blueprint', { moduleBlueprintId: this.moduleIdFromRoute }, err);
            this.showError(err);
          }
        })
        .then(() => {
          this.modulesReady = true;
        });
    },
    getModuleBlueprintChapters() {
      this.$logger.info('Getting module blueprint PAKs');
      this.chaptersReady = false;
      return this.$http.get(`/api/curriculum/blueprints/modules/${this.moduleIdFromRoute}/chapters`)
        .then(res => {
          this.chapters = res.data.chapters;
          this.$logger.info('Successfully retrieved chapters for module blueprint');
        })
        .catch(err => {
          if (this.$http.errIn(err, [404])) {
            this.$router.push({ name: '404' });
          } else {
            this.$logger.autowarn('Error getting module blueprint chapters', { moduleBlueprintId: this.moduleIdFromRoute }, err);
            this.showError(err);
          }
        })
        .then(() => {
          this.chaptersReady = true;
        });
    },
    updateModuleMeta(payload) {
      const index = this.modules.findIndex(x => x.id === this.moduleIdFromRoute);
      this.modules.splice(index, 1, {
        ...this.modules[index],
        ...payload,
      });
    },
    calendarEventEmailFailures(tasks) {
      if (!this.isBlueprint) {
        // Cloud tasks fail when scheduling tasks for events that are more than 30 days in the future
        // We have a workaround for this, so we will exclude the error messages related to this as users don't need to know about it
        const excludedErrorMessage = 'Schedule time must be no more than 720h in the future.';
        let failureList = tasks.filter(task => task.error && task.error.indexOf(excludedErrorMessage) < 0).map(
          task => this.events.find(ce => ce.calendar_event_id === task.calendar_event_id)?.name,
        );
        failureList = failureList.filter((task, pos) => failureList.indexOf(task) === pos);
        if (failureList.length) {
          const toastMessage = `Emails failed to be scheduled for the following events: ${failureList.join(', ')}`;
          this.$ktoast.warning(`${toastMessage}`, { goAway: 5000 });
        }
      }
    },
    assetCallback(assetType, callbackData) {
      switch (assetType) {
        case 'calendar_event':
          this.calendarEventEmailFailures(callbackData);
          break;
        default:
          break;
      }
      this.getModuleData();
    },
  },
};
</script>
