export default {
  data() {
    return {
      confirmAssetArchiveToast: undefined,
    };
  },

  methods: {
    assetArchiveButton(isActive) {
      if (isActive) {
        return '<button class="btn btn-warning">Archive</button>';
      }
      return '<button class="btn btn-primary">Unarchive</button>';
    },

    getAssetArchiveToastMessage(row) {
      if (row.is_active) {
        return 'Archived asset cannot be added to blueprints or modules '
        + 'but can still accessed if already assigned to a module.';
      }
      return 'Unarchived asset will be available to add to blueprints and modules.';
    },

    toastArchiveCallback(row) {
      if (row.is_active) {
        this.archiveCallback(row.id);
      } else {
        this.unarchiveCallback(row.id);
      }
    },

    tableArchiveCallback(row) {
      this.$ktoast.confirmToast(this.getAssetArchiveToastMessage(row), 'warning', () => {
        this.toastArchiveCallback(row);
      });
    },
  },
};
