<template>
  <span class="label" :class="labelClass">
    {{ label }}
  </span>
</template>

<style scoped>
span.label {
  font-family: apercuregular, sans-serif;
  font-size: 0.75rem;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
}

.primary-label {
  background-color: var(--kate-button-primary);
  color: var(--kate-type-dark);
}

.secondary-label {
  background-color: var(--kate-button-secondary);
  color: var(--kate-type-dark);
}

.tertiary-label {
  background-color: var(--kate-button-tertiary);
}
</style>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },

  computed: {
    labelClass() {
      if (this.label === 'Trainer Led') {
        return 'primary-label';
      }
      if (this.label === 'Self Study') {
        return 'secondary-label';
      }
      return 'tertiary-label';
    },
  },
};
</script>
