<template>
  <div class="dashboard-sidebar">
    <ul class="sidebar-panel-nav">
      <monitor-dropdown @setVisibility="(visibility) => this.setEntryVisibility(0, visibility)"></monitor-dropdown>
      <curriculum-dropdown @setVisibility="(visibility) => this.setEntryVisibility(1, visibility)"></curriculum-dropdown>
      <programmes-dropdown @setVisibility="(visibility) => this.setEntryVisibility(2, visibility)"></programmes-dropdown>
      <users-dropdown @setVisibility="(visibility) => this.setEntryVisibility(3, visibility)"></users-dropdown>
      <achievements-dropdown @setVisibility="(visibility) => this.setEntryVisibility(4, visibility)"></achievements-dropdown>
      <knowledge-dropdown @setVisibility="(visibility) => this.setEntryVisibility(5, visibility)"></knowledge-dropdown>
    </ul>
  </div>
</template>

<script>
import ProgrammesDropdown from './programmes-dropdown.vue';
import KnowledgeDropdown from './knowledge-base-dropdown.vue';
import CurriculumDropdown from './curriculum-dropdown.vue';
import MonitorDropdown from './monitor-dropdown.vue';
import AchievementsDropdown from './achievements-dropdown.vue';
import UsersDropdown from './users-dropdown.vue';

export default {
  components: {
    'programmes-dropdown': ProgrammesDropdown,
    'users-dropdown': UsersDropdown,
    'knowledge-dropdown': KnowledgeDropdown,
    'curriculum-dropdown': CurriculumDropdown,
    'monitor-dropdown': MonitorDropdown,
    'achievements-dropdown': AchievementsDropdown,
  },

  data() {
    return {
      visibleEntries: [],
    };
  },

  mounted() {
    this.$sidebar.setVisibility(this.sidebarVisibility);
    this.$sidebar.ready = true;
  },

  watch: {
    sidebarVisibility: {
      handler() {
        this.$sidebar.setVisibility(this.sidebarVisibility);
      },
      deep: true,
    },
  },

  computed: {
    sidebarVisibility() {
      for (let i = 0; i < this.visibleEntries.length; i++) {
        // checking if any component is visible, if so, return true
        if (this.visibleEntries[i]) {
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    setEntryVisibility(index, value) {
      this.visibleEntries[index] = value;
    },
  },
};
</script>
