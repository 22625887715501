<template>
  <li v-if="isVisible" class="nav-group" :class="`${toggled ? 'opened-dropdown': 'closed-dropdown'}`">
    <div class="nav-group-title" aria-label="Toggle dropdown" title="Toggle dropdown" @click="toggled = !toggled">
      <a><i class="k-asset-icon fa-duotone fa-magnifying-glass-chart"></i><span>Monitor</span></a>
      <i class="fas fa-angle-down"></i>
    </div>
    <ul class="second-level">
      <li v-if="hasMonitorProgrammes" class="nav-sub-group">
        <div class="nav-sub-group-title">
          <router-link :to="{  name: 'monitor_programme_summary'}">
            <span>Programme Summary</span>
          </router-link>
        </div>
      </li>
      <li v-if="hasMonitorProgrammes" class="nav-sub-group">
        <div class="nav-sub-group-title">
          <router-link :to="{ name: 'monitor_learner_progress' }">
            <span>Learner Progress</span>
          </router-link>
        </div>
      </li>
      <li v-if="hasViewEmployerLearners" class="nav-sub-group">
        <div class="nav-sub-group-title">
          <router-link :to="{ name: 'employer_learner_summary' }">
            <span>Employer Summary</span>
          </router-link>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import SidebarEntryMixin from '../../mixins/sidebar-entry-mixin';

export default {
  mixins: [SidebarEntryMixin],

  data() {
    return {
      toggled: false,
    };
  },

  computed: {
    hasMonitorProgrammes() {
      return this.$permissions.hasPermission('monitor_programmes');
    },
    hasViewEmployerLearners() {
      return this.$permissions.hasPermission('view_employer_learners');
    },
    isVisible() {
      return this.hasMonitorProgrammes || this.hasViewEmployerLearners;
    },
  },
};
</script>
